import { AddCircleRounded, DeleteForeverRounded } from '@mui/icons-material';
import {
	Button,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Tooltip,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
	addProgramCommission,
	editProgramCommission,
	removeProgramCommission,
} from '../../../../redux/programSlice';

export default function Commission({ newProgram, handleNext, handleBack }) {
	const dispatch = useDispatch();

	function handleChange(event, id) {
		dispatch(
			editProgramCommission({
				program: newProgram._id,
				id: id,
				name: event.target.name,
				value: event.target.value,
			})
		);
	}

	function AddCommission() {
		const newCommision = {
			_id: `new-commission-${crypto.randomUUID()}`,
			IsActive: true,
			Status: '',
			Commission: '',
		};

		dispatch(
			addProgramCommission({
				program: newProgram._id,
				commission: newCommision,
			})
		);
	}

	function removeCommission(id) {
		dispatch(
			removeProgramCommission({
				program: newProgram.id,
				commission: id,
			})
		);
	}

	function submit(e) {
		e.preventDefault();

		handleNext();
	}

	return (
		<Box
			component={'form'}
			onSubmit={submit}
		>
			<Grid container>
				<Grid
					item
					sx={{ flexGrow: 1 }}
				></Grid>
				<Grid item>
					<Tooltip title={'Add Commission'}>
						<span>
							<IconButton
								onClick={AddCommission}
								//sx={{ marginRight: 2 }}
							>
								<AddCircleRounded
									sx={{
										color: blueGrey[50],
										fontSize: '1.5em',
										cursor: 'pointer',
									}}
								/>
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
			{newProgram.Commissions.map((commission, index) => (
				<Box
					key={commission._id}
					sx={{
						backgroundColor: blueGrey[600],
						borderRadius: 2,
						padding: 2,
						marginBottom: 4,
					}}
				>
					<Grid container>
						<Grid
							item
							sx={{ flexGrow: 1, marginBottom: 4 }}
						>
							<Box
								sx={{
									backgroundColor: orange[700],
									color: blueGrey[50],
									borderRadius: 48,
									width: 24,
									height: 24,
								}}
							>
								{index + 1}
							</Box>
						</Grid>
						<Grid item>
							{index > 0 && (
								<Tooltip title={'Remove Rights'}>
									<IconButton onClick={() => removeCommission(commission._id)}>
										<DeleteForeverRounded
											sx={{
												color: blueGrey[50],
												fontSize: '1.5em',
												cursor: 'pointer',
											}}
										/>
									</IconButton>
								</Tooltip>
							)}
						</Grid>
					</Grid>
					<Grid container>
						<Grid
							item
							sm={6}
						>
							<TextField
								color='secondary'
								required
								select
								variant='outlined'
								label='Program Status'
								value={commission.Status}
								name='Status'
								onChange={(e) => handleChange(e, commission._id)}
								sx={textFields}
								style={{ width: '80%' }}
							>
								<MenuItem
									value={''}
									disabled
								>
									SELECT Status
								</MenuItem>
								<MenuItem value={'Co-Production'}>Co-Production</MenuItem>
								<MenuItem value={'Pre-Sale'}>Pre-Sale</MenuItem>
								<MenuItem value={'Finished'}>Finished</MenuItem>
							</TextField>
						</Grid>
						<Grid
							item
							sm={6}
						>
							<TextField
								color='secondary'
								required
								type='number'
								variant='outlined'
								label='Commission %'
								value={commission.Commission}
								name='Commission'
								onChange={(e) => handleChange(e, commission._id)}
								sx={textFields}
							/>
						</Grid>
					</Grid>
				</Box>
			))}
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					//disabled={activeStep === 0}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
						//display: `${activeStep === 0 ? 'none' : 'block'}`,
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
}
const textFields = {
	minWidth: '98%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-root.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
		color: orange[500],
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

export const textFieldStylesWithEdit = {
	minWidth: '85%',
	textAlign: 'left',
	color: blueGrey[50],
	backgroundColor: blueGrey[700],
	marginTop: 2,
	marginBottom: 2,
	pointer: 'none !important',
	'& .MuiInput-input': {
		color: blueGrey[50],
		//cursor: 'pointer !important',
		//cursor: 'none !important',
	},
	'& .MuiInput-root': {
		color: blueGrey[50],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiInputRoot:after': {
		borderColor: blueGrey[900],
	},
	'& .MuiDisabled:': {
		color: blueGrey[50],
		opacity: '1 !important',
	},
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: blueGrey[50],
		//opacity: '1 !important',
		//cursor: 'none !important',
	},
};
