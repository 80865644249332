import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentTab, selectTabs } from '../redux/tabsSlice';
import { blueGrey } from '@mui/material/colors';
import NewAccountStepper from '../components/shared/newAccount/NewAccountStepper';
import AccountsTable from '../components/shared/accountsTable/AccountsTable';
import AccountDetails from '../components/shared/accountDetails/AccountDetails';

export default function Finance({ setParams }) {
	const params = useParams();
	const location = useLocation();
	const currentTab = useSelector(selectCurrentTab);

	useEffect(() => {
		setParams(params);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	let tabs = useSelector(selectTabs);
	let tab = tabs.find((tab) => tab.link === location.pathname);

	function renderMainContent(tab) {
		if (tab) {
			if (tab.type === 'others' && !params.id) {
				return <AccountsTable type={'Other'} />;
			}

			if (tab.type === 'others' && params.id === 'new-other') {
				return (
					<NewAccountStepper
						type={'Other'}
						id={params.id}
					/>
				);
			}

			if (tab.type === 'others' && params.id) {
				return (
					<AccountDetails
						id={params.id}
						type={'Other'}
						currentTab={currentTab}
					/>
				);
			}
		}
	}

	return (
		<div
			style={{
				padding: 16,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
				textAlign: 'center',
				backgroundColor: blueGrey[500],
				color: 'white',
				borderRadius: '8px',
			}}
		>
			{tab && renderMainContent(tab)}
		</div>
	);
}
