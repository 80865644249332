import React from 'react';
import {
	Grid,
	IconButton,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { AddCircle, Cancel, Refresh } from '@mui/icons-material';
import { removePrograms } from '../../../../redux/programSlice';

const gridStyles = {
	paddingTop: 1,
	paddingLeft: 4,
	paddingRight: 4,
	paddingBottom: 1,
	textAlign: 'center',
	//backgroundColor: blueGrey[50],
	borderRadius: 2,
	fontSize: '.65em',
	display: 'flex',
	alignItems: 'center',
	alignContent: 'center',
};

const gridItemStyles = {
	//color: blueGrey[900],
	padding: 0,

	'& .MuiGridItem-root': {
		padding: 0,
	},
};

export default function ProgramsTableSearchBar({
	dispatch,
	navigate,
	programsTableSettings,
	changeProgramsTableSettings,
	handleChange,
}) {
	function clearSearchTerm() {
		if (programsTableSettings.searchTerm !== '') {
			dispatch(
				changeProgramsTableSettings({
					name: 'searchTerm',
					value: '',
				})
			);
		}
	}

	function refreshPrograms() {
		dispatch(removePrograms());
	}

	function addNewProgram() {
		navigate('/acquisitions/programs/new-program');
	}

	return (
		<Grid
			container
			sx={gridStyles}
		>
			<Grid
				sm={10}
				sx={gridItemStyles}
				item
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'middle',
						alignText: 'center',
					}}
				>
					<Typography sx={{ marginLeft: 9 }}>Search:</Typography>
					<TextField
						variant='outlined'
						size='small'
						onChange={handleChange}
						value={programsTableSettings.searchTerm}
						name='searchTerm'
						sx={{
							color: 'inherit',
							marginLeft: 2,
							marginRight: 2,
							width: { sm: 200, md: 500, lg: 600 },
							'& .MuiInputBase-root': {
								color: 'inherit',
								height: 30,
							},
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: `${blueGrey[50]} !important`,
								},
								'&.Mui-focused fieldset': {
									borderColor: `${blueGrey[50]} !important`,
								},
							},
						}}
					/>
					<Tooltip
						title='Clear Search'
						arrow={true}
					>
						<IconButton
							onClick={clearSearchTerm}
							sx={{ color: 'inherit' }}
						>
							<Cancel
								sx={{
									color: 'inherit',
									fontSize: '1.5em',
								}}
							/>
						</IconButton>
					</Tooltip>
				</Box>
			</Grid>
			<Grid
				sm={2}
				sx={gridItemStyles}
				item
				style={{ display: 'flex' }}
			>
				<Grid sx={{ flexGrow: 1 }}></Grid>
				<Tooltip
					title={`Refresh Programs`}
					arrow={true}
				>
					<IconButton
						onClick={refreshPrograms}
						sx={{ color: 'inherit' }}
					>
						<Refresh
							sx={{
								color: 'inherit',
								fontSize: '1.5em',
							}}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip
					title='Add New Program'
					arrow={true}
				>
					<IconButton
						onClick={addNewProgram}
						sx={{ color: 'inherit' }}
					>
						<AddCircle
							sx={{
								color: 'inherit',
								fontSize: '1.5em',
							}}
						/>
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid
				sm={4}
				sx={gridItemStyles}
				item
			>
				<Typography sx={{ display: 'inline' }}>Search Some</Typography>
				<Switch
					checked={programsTableSettings.searchEvery}
					onChange={handleChange}
					name='searchEvery'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Search All</Typography>
			</Grid>
			<Grid
				sm={3}
				sx={gridItemStyles}
				item
			>
				<Typography sx={{ display: 'inline' }}>Show All</Typography>
				<Switch
					checked={programsTableSettings.showActive}
					onChange={handleChange}
					name='showActive'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Show Active</Typography>
			</Grid>
		</Grid>
	);
}
