import { AddCircle } from '@mui/icons-material';
import {
	Button,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Notes from '../../../../shared/Notes';
import ProgramItemModal from './ProgramItemModal';

export default function ProgramItemsTab({ editingProgram }) {
	const [programItem, setProgramItem] = useState(null);
	const [programItemModalStatus, setProgramItemModalStatus] = useState(false);

	useEffect(() => {}, [editingProgram]);

	function handleProgramItemModal(e, programItem = null) {
		setProgramItem(programItem);
		setProgramItemModalStatus(!programItemModalStatus);
	}

	return (
		<Box
			sx={{
				height: 'calc(100vh - 265px)',
				overflowY: 'auto',
				padding: 0,
				paddingLeft: 4,
				paddingRight: 4,
			}}
		>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[50],
					marginBottom: 2,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					color: blueGrey[900],
					display: 'flex',
					alignItems: 'center',
					padding: 0,
				}}
			>
				<Grid
					item
					sx={{
						flexGrow: 1,
						marginLeft: 5,
						fontWeight: 'bold',
						color: blueGrey[700],
					}}
				>
					Program Items
				</Grid>
				<Grid
					item
					sx={{ flexGrow: 0, marginRight: 2 }}
				>
					<Tooltip
						title='Add Program Item'
						arrow={true}
					>
						<IconButton onClick={handleProgramItemModal}>
							<AddCircle
								sx={{
									color: blueGrey[600],
									backgroundColor: 'transparent',
									borderBottomRadius: 10,
									borderTopRadius: 10,
									fontSize: '1.5em',
									'&:hover': {
										color: blueGrey[700],
									},
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell sx={{ textAlign: 'center' }}>Format</TableCell>
							<TableCell sx={{ textAlign: 'center' }}>Definition</TableCell>
							<TableCell sx={{ textAlign: 'center' }}>RunningTime</TableCell>
							{editingProgram.Format === 'Series' && (
								<TableCell sx={{ textAlign: 'center' }}>Season</TableCell>
							)}
							{editingProgram.Format === 'Series' && (
								<TableCell sx={{ textAlign: 'center' }}>Episode</TableCell>
							)}
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{editingProgram.ProgramItems.map((programItem) => (
							<TableRow key={programItem._id}>
								<TableCell>{programItem.Name}</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>
									{programItem.Format}
								</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>
									{programItem.Definition}
								</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>
									{programItem.RunningTime}
								</TableCell>
								{programItem.Format === 'Series' && (
									<TableCell sx={{ textAlign: 'center' }}>
										{programItem.SeasonNumber}
									</TableCell>
								)}
								{programItem.Format === 'Series' && (
									<TableCell sx={{ textAlign: 'center' }}>
										{programItem.EpisodeNumber}
									</TableCell>
								)}

								<TableCell sx={{ textAlign: 'center' }}>
									<Button
										onClick={(e) => handleProgramItemModal(e, programItem)}
										sx={{
											backgroundColor: blueGrey[600],
											color: blueGrey[50],
											'&:hover': { backgroundColor: blueGrey[700] },
										}}
									>
										Edit
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{
					color: 'inherit',
					width: '100%',
					marginTop: 8,
					height: '1px',
					//border: `1px solid ${blueGrey[600]}`,
				}}
			/>
			<Box
				sx={{
					marginTop: 4,
					borderRadius: 2,
					backgroundColor: blueGrey[50],
					color: blueGrey[800],
				}}
			>
				<Notes
					id={editingProgram._id}
					type={'Program'}
					notes={editingProgram && editingProgram.Notes}
				/>
			</Box>
			{programItemModalStatus && (
				<ProgramItemModal
					program={editingProgram}
					programItem={programItem}
					programItemModalStatus={programItemModalStatus}
					handleProgramItemModal={handleProgramItemModal}
				/>
			)}
		</Box>
	);
}
