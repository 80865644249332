import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { selectIsAuthenticated } from '../../redux/authSlice';
import DrawerHeader from './DrawerHeader';
import Login from '../../auth/Login';
import Dashboard from './../../pages/Dashboard';
import Acquisitions from '../../pages/Acquisitions';
import Support from '../../pages/Support';
import Finance from '../../pages/Finance';
import { styled } from '@mui/material/styles';
import Sales from '../../pages/Sales';
import TabBarMenu from './TabBarMenu';
import Admin from '../../pages/Admin';
import { useParams, useLocation } from 'react-router-dom';
import Reports from '../../pages/Reports';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open, drawerwidth }) => ({
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: `-${drawerwidth}px`,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	})
);

export default function MainContent({ open, drawerwidth }) {
	const navigate = useNavigate();
	let isAuthenticated = useSelector(selectIsAuthenticated);

	const [params, setParams] = useState(useParams());

	let location = useLocation();

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/login');
		}
	}, [isAuthenticated, navigate]);

	let width = window.innerWidth - drawerwidth;

	function handleResize() {
		//console.log(window.innerWidth - drawerwidth);
		width = window.innerWidth - drawerwidth;
	}

	window.addEventListener('resize', handleResize);

	return (
		<Main
			open={open}
			drawerwidth={drawerwidth}
			sx={{
				minWidth: '100px',
				maxWidth: `${open ? `${width}px !important` : '100% !important'}`,
				padding: '1em',
				color: 'black',
				height: '100%',
			}}
		>
			<DrawerHeader />
			{isAuthenticated && <TabBarMenu params={params} />}
			<Routes>
				<Route
					path='/login'
					element={<Login />}
				/>
				<Route
					path='/'
					element={<Dashboard setParams={setParams} />}
				/>
				<Route
					path='/sales/:sub/'
					element={<Sales setParams={setParams} />}
				/>
				<Route
					path='/sales/:sub/:id'
					element={<Sales setParams={setParams} />}
				/>
				<Route
					path='/acquisitions/:sub/'
					element={<Acquisitions setParams={setParams} />}
				/>
				<Route
					path='/acquisitions/:sub/:id'
					element={<Acquisitions setParams={setParams} />}
				/>
				<Route
					path='/support'
					element={<Support setParams={setParams} />}
				/>
				<Route
					path='/finance'
					element={<Finance setParams={setParams} />}
				/>
				<Route
					path='/finance/:sub/'
					element={<Finance setParams={setParams} />}
				/>
				<Route
					path='/finance/:sub/:id'
					element={<Finance setParams={setParams} />}
				/>
				<Route
					path='/admin/:sub/'
					element={
						<Admin
							setParams={setParams}
							location={location}
						/>
					}
				/>
				<Route
					path='/reports/:sub/'
					element={
						<Reports
							setParams={setParams}
							location={location}
						/>
					}
				/>
			</Routes>
		</Main>
	);
}

MainContent.propTypes = {
	open: PropTypes.bool.isRequired,
	drawerwidth: PropTypes.number.isRequired,
};
