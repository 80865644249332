import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { selectAlerts, deleteAlert } from '../../redux/alertsSlice';

export default function Alerts() {
	let alerts = useSelector(selectAlerts);
	let dispatch = useDispatch();

	const handleClose = (id) => {
		// Dispatch to action to remove alert by id
		dispatch(deleteAlert(id));
	};

	return (
		alerts &&
		alerts.length > 0 && (
			<div>
				<Snackbar
					open={true}
					autoHideDuration={6000}
					TransitionComponent={Slide}
				>
					<div>
						{alerts.map((alert) => (
							<Alert
								key={alert.id}
								onClose={() => handleClose(alert.id)}
								severity={alert.severity}
								sx={{
									width: '100%',
									margin: '1em',
									//backgroundColor: blueGrey[800],
								}}
								variant='filled'
							>
								{alert.message}
							</Alert>
						))}
					</div>
				</Snackbar>
			</div>
		)
	);
}
