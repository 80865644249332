import { Button, Grid, TextField } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { gridItemStyles } from '../shared/ComponentStyles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getScreenerReport,
	getScreenrReportAsync,
	selectScreenerReport,
} from '../../redux/reportSlice';

export default function ScreenerReports() {
	const dispatch = useDispatch();
	const screenerReport = useSelector(selectScreenerReport);
	const [formData, setFormData] = useState({
		StartDate: new Date(),
		EndDate: new Date(),
	});

	function handleCalendarChange(e, name) {
		let newDate = new Date(e.$d);
		setFormData({ ...formData, [name]: newDate });
	}

	function handleButtonClick(e, name) {
		dispatch(getScreenrReportAsync(formData));
	}

	const downloadFile = ({ data, fileName, fileType }) => {
		const blob = new Blob([data], { type: fileType });
		const a = document.createElement('a');
		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);
		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		a.dispatchEvent(clickEvt);
		a.remove();
	};

	const exportToCsv = (e) => {
		e.preventDefault();
		// Headers
		let headers = [
			'Account,Program,ProductionCompany,Territory,Status,Feedback,CreatedDate,LastModifiedDate',
		];

		// Data: Iterate through ScreenerReports
		let report = [];

		screenerReport.forEach((screener) => {
			const created = new Date(screener.CreatedDate);
			const modified = new Date(screener.LastModifiedDate);

			screener.ScreenerItems.forEach((screenerItem) => {
				let reportItem = [
					screener.Account.Name.replaceAll(',', ' '),
					screenerItem.Program.Name.replaceAll(',', ' '),
					screenerItem.Program.ProductionCompany.Name.replaceAll(',', ' '),
					screener.Territory.replaceAll(',', '/'),
					screenerItem.Status,
					screenerItem.Feedback,
					`${created.getDate()}-${
						created.getMonth() + 1
					}-${created.getFullYear()}`,
					`${modified.getDate()}-${
						modified.getMonth() + 1
					}-${modified.getFullYear()}`,
				];

				report.push(reportItem.join(','));
			});
		});
		downloadFile({
			data: [...headers, ...report].join('\n'),
			fileName: `ScreenerReport.${formData.StartDate.getDate()}-${
				formData.StartDate.getMonth() + 1
			}-${formData.StartDate.getFullYear()}--${formData.EndDate.getDate()}-${
				formData.EndDate.getMonth() + 1
			}-${formData.EndDate.getFullYear()}.csv`,
			fileType: 'text/csv',
		});
	};

	useEffect(() => {
		return () => {
			if (screenerReport) {
				dispatch(getScreenerReport(null));
			}
		};

		//eslint-disable-nextline
	}, [screenerReport]);

	return (
		<Grid container>
			<Grid
				item
				sm={12}
				sx={{ fontSize: 30, marginTop: 4, marginBottom: 12 }}
			>
				Screener Reports
			</Grid>
			<Grid
				item
				sm={6}
				sx={gridItemStyles}
			>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DesktopDatePicker
						required
						key='StartDate'
						name='StartDate'
						label='Date desktop'
						inputFormat='DD/MM/YYYY'
						value={formData.StartDate}
						onChange={(e) => handleCalendarChange(e, 'StartDate')}
						sx={pickerStyles}
						style={pickerStyles}
						InputProps={{
							sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
						}}
						PopperProps={{ sx: pickerStyles }}
						renderInput={(params) => (
							<TextField
								{...params}
								key='StartDate'
								required
								disabled
								variant='outlined'
								label='Start Date'
								value={formData.StartDate}
								onChange={(e) => handleCalendarChange(e, 'StartDate')}
								name='StartDate'
								color='secondary'
								sx={textFields}
							/>
						)}
					/>
				</LocalizationProvider>
			</Grid>
			<Grid
				item
				sm={6}
				sx={gridItemStyles}
			>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DesktopDatePicker
						required
						key='EndDate'
						name='EndDate'
						label='Date desktop'
						inputFormat='DD/MM/YYYY'
						value={formData.EndDate}
						onChange={(e) => handleCalendarChange(e, 'EndDate')}
						sx={pickerStyles}
						style={pickerStyles}
						InputProps={{
							sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
						}}
						PopperProps={{ sx: pickerStyles }}
						renderInput={(params) => (
							<TextField
								{...params}
								key='EndDate'
								required
								disabled
								variant='outlined'
								label='End Date'
								value={formData.EndDate}
								onChange={(e) => handleCalendarChange(e, 'EndDate')}
								name='EndDate'
								color='secondary'
								sx={textFields}
							/>
						)}
					/>
				</LocalizationProvider>
			</Grid>
			<Grid
				item
				sm={12}
				sx={{ marginTop: 12 }}
			>
				<Button
					onClick={handleButtonClick}
					sx={{
						color: blueGrey[50],
						backgroundColor: blueGrey[700],
						border: `2px solid ${blueGrey[800]}`,
						paddingTop: 2,
						paddingBottom: 2,
						paddingLeft: 8,
						paddingRight: 8,
						'&:hover': {
							backgroundColor: blueGrey[900],
						},
					}}
				>
					Submit
				</Button>
			</Grid>
			{screenerReport && (
				<>
					<Grid
						item
						sm={12}
						sx={{ fontSize: 20, marginTop: 12 }}
					>
						{screenerReport.length} Items Found
					</Grid>
					<Grid
						item
						sm={12}
						sx={{ marginTop: 12 }}
					>
						<Button
							onClick={exportToCsv}
							sx={{
								color: blueGrey[50],
								backgroundColor: blueGrey[700],
								border: `2px solid ${blueGrey[800]}`,
								paddingTop: 2,
								paddingBottom: 2,
								paddingLeft: 8,
								paddingRight: 8,
								'&:hover': {
									backgroundColor: blueGrey[900],
								},
							}}
						>
							Download Spreadsheet
						</Button>
					</Grid>
				</>
			)}
		</Grid>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
