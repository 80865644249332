import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { getRoles } from './roleSlice';
import { getDepartments } from './departmentSlice';
import { getCurrencies } from './currencySlice';
import { getUsers } from './userSlice';
import { getGenres } from './genreSlice';
import { getFormats } from './formatSlice';
import { getLanguages } from './languageSlice';
import { getDefinitions } from './definitionSlice';
import { getLicensedMedias } from './licensedmediaSlice';
import { getTerritories } from './territorySlice';

const initialState = {
	user: null,
	isAuthenticated: false,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		login: (state, action) => {
			state.isAuthenticated = true;
			state.user = action.payload;
		},
		clearAuth: (state) => {
			//console.log('clearAuth');
			state.isAuthenticated = false;
			state.user = null;
		},
		logout: () => initialState,
	},
});

export const { login, clearAuth, logout } = authSlice.actions;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;

// Login Async
export const loginAsync = (email, password) => async (dispatch) => {
	try {
		const body = { email, password };
		await api.post('/auth', body, { withCredentials: true }).then((res) => {
			dispatch(login(res.data.user));
			dispatch(getAppDataAsync());
			dispatch(
				createAlertAsync({
					message: 'Login Successful',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		});
	} catch (error) {
		dispatch(handleErrorAlertAsync(error));
	}
};

// Get Data Async
export const getAppDataAsync = () => async (dispatch) => {
	try {
		await api.get('/auth', { withCredentials: true }).then((res) => {
			dispatch(getRoles(res.data.roles));
			dispatch(getDepartments(res.data.departments));
			dispatch(getCurrencies(res.data.currencies));
			dispatch(getGenres(res.data.genres));
			dispatch(getFormats(res.data.formats));
			dispatch(getLanguages(res.data.languages));
			dispatch(getDefinitions(res.data.definitions));
			dispatch(getUsers(res.data.users));
			dispatch(getLicensedMedias(res.data.licensedmedias));
			dispatch(getTerritories(res.data.territories));
		});
	} catch (error) {
		dispatch(handleErrorAlertAsync(error));
	}
};

// Login Async
export const logoutAsync = () => async (dispatch) => {
	try {
		await api
			.get('/auth/logout', { withCredentials: true })
			.then((res) => {
				dispatch(logout());
			})
			.catch((error) => {
				throw error;
			});
	} catch (error) {
		dispatch(handleErrorAlertAsync(error));
	}
};

export const verifyAuthAsync = (error) => async (dispatch) => {
	if (error.includes('401')) {
		console.log('Clearing Auth');
		dispatch(clearAuth());
	}
};

export default authSlice.reducer;
