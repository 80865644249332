import * as React from 'react';
import {
	Box,
	IconButton,
	Collapse,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { blueGrey } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

export default function ScreenersTabRow({ screener }) {
	const [open, setOpen] = React.useState(false);

	function renderScreenerNumber(screenerNumber) {
		screenerNumber = screenerNumber.toString();
		while (screenerNumber.length < 6) screenerNumber = '0' + screenerNumber;
		return 'SCRN' + screenerNumber;
	}

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						aria-label='expand row'
						size='small'
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell
					component='th'
					scope='row'
					align='center'
				>
					<Link
						to={`/sales/screeners/${screener._id}`}
						component={RouterLink}
						sx={{
							textDecoration: 'none',
							color: blueGrey[800],
							fontWeight: '600',
							'&:hover': { color: blueGrey[600] },
						}}
					>
						{renderScreenerNumber(screener.ScreenerNumber)}
					</Link>
				</TableCell>
				<TableCell align='center'>{screener.Name}</TableCell>
				<TableCell align='center'>{screener.DateSent}</TableCell>
				<TableCell align='center'>{screener.ScreenerItems.length}</TableCell>
				<TableCell align='center'>{screener.Status}</TableCell>
				<TableCell align='center'>
					{screener.Owner.FirstName} {screener.Owner.LastName}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
				>
					<Collapse
						in={open}
						timeout='auto'
						unmountOnExit
					>
						<Box sx={{ margin: 1 }}>
							<Typography
								gutterBottom
								component='div'
								sx={{ fontWeight: '500', paddingLeft: 16 }}
							>
								Screener Items
							</Typography>
							<Table size='small'>
								<TableHead>
									<TableRow>
										<TableCell
											align='left'
											sx={{ paddingLeft: 16, fontWeight: '500' }}
										>
											Name
										</TableCell>
										<TableCell align='center'>Program Name</TableCell>
										<TableCell align='center'>Status</TableCell>
										<TableCell align='center'>Comments</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{screener.ScreenerItems.map((screenerItem) => (
										<TableRow key={screenerItem._id}>
											<TableCell
												component='th'
												scope='row'
												align='left'
												sx={{ paddingLeft: 16 }}
											>
												{screenerItem.Name}
											</TableCell>
											<TableCell align='center'>
												{screenerItem.Program.Name}
											</TableCell>
											<TableCell align='center'>
												{screenerItem.Status}
											</TableCell>
											<TableCell align='center'>
												{screenerItem.Comments}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}
