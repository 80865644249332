export function accountCells(type) {
	if (type === 'Buyer') {
		return [
			{
				id: 'AccountNumber',
				sortable: true,
				align: 'left',
				disablePadding: true,
				label: 'Account #',
			},
			{
				id: 'Name',
				sortable: true,
				align: 'left',
				disablePadding: true,
				label: 'Name',
			},
			{
				id: 'Phone',
				sortable: true,
				align: 'center',
				disablePadding: false,
				label: 'Phone',
			},
			{
				id: 'Territory',
				sortable: true,
				align: 'center',
				disablePadding: false,
				label: 'Territory',
			},
		];
	}
	if (type === 'Producer') {
		return [
			{
				id: 'AccountNumber',
				sortable: true,
				align: 'left',
				disablePadding: true,
				label: 'Account #',
			},
			{
				id: 'Name',
				sortable: true,
				align: 'left',
				disablePadding: false,
				label: 'Name',
			},
			{
				id: 'Phone',
				sortable: true,
				align: 'center',
				disablePadding: false,
				label: 'Phone',
			},
		];
	}
	if (type === 'Other') {
		return [
			{
				id: 'AccountNumber',
				sortable: true,
				align: 'left',
				disablePadding: true,
				label: 'Account #',
			},
			{
				id: 'Name',
				sortable: true,
				align: 'left',
				disablePadding: true,
				label: 'Name',
			},
			{
				id: 'Phone',
				sortable: true,
				align: 'center',
				disablePadding: false,
				label: 'Phone',
			},
		];
	}
}
