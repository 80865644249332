import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
	Grid,
	TextField,
	MenuItem,
	Tooltip,
	IconButton,
	CircularProgress,
} from '@mui/material';
import { gridItemStyles } from '../../../shared/ComponentStyles';
import { selectUsers } from '../../../../redux/userSlice';
import { blueGrey, orange } from '@mui/material/colors';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { selectCurrencies } from '../../../../redux/currencySlice';
import {
	EditSharp,
	Save,
	CurrencyExchange as Convert,
} from '@mui/icons-material';
import { changeEditingScreener } from '../../../../redux/screenerSlice';

export default function ScreenerDetailsForm({
	id,
	saving,
	handleRightsModal,
	originalScreener,
	editingScreener,
	convertingToSale,
	handleConvertToSaleModal,
}) {
	const dispatch = useDispatch();
	const currencies = useSelector(selectCurrencies);
	const users = useSelector(selectUsers);

	function renderScreenerNumber(screenerNumber) {
		screenerNumber = screenerNumber.toString();
		while (screenerNumber.length < 6) screenerNumber = '0' + screenerNumber;
		return 'SCRN' + screenerNumber;
	}

	function handleChange(e) {
		dispatch(
			changeEditingScreener({
				id: id,
				name: e.target.name,
				value: e.target.value,
			})
		);
	}

	function handleCalendarChange(e) {
		let newDate = new Date(e.$d);

		dispatch(
			changeEditingScreener({
				id: id,
				name: 'FollowUpDate',
				value: newDate.toDateString(),
			})
		);
	}

	return (
		<>
			<Grid container>
				<Grid
					sm={10}
					sx={gridItemStyles}
					item
				></Grid>
				<Grid
					sm={1}
					sx={gridItemStyles}
					item
				>
					{editingScreener._id !== 'new-screener' && (
						<Tooltip
							title={
								_.isEqual(originalScreener, editingScreener)
									? 'Convert to Sale'
									: 'Save Before Converting to Sale'
							}
						>
							<span>
								<IconButton
									disabled={
										saving ||
										convertingToSale ||
										!_.isEqual(originalScreener, editingScreener)
									}
									onClick={handleConvertToSaleModal}
									style={{ border: 'none' }}
								>
									{(window.location.host.includes('lightandfire') ||
										window.location.host.includes('localhost')) && (
										<Convert
											style={{
												color: `${
													!_.isEqual(originalScreener, editingScreener)
														? blueGrey[50]
														: orange[800]
												}`,

												fontSize: '2em',
												cursor: 'pointer',
											}}
										/>
									)}
								</IconButton>
							</span>
						</Tooltip>
					)}
				</Grid>
				<Grid
					sm={1}
					sx={gridItemStyles}
					item
				>
					<Tooltip title='Save'>
						<span>
							<IconButton
								disabled={
									saving || _.isEqual(originalScreener, editingScreener)
								}
								type='submit'
								style={{ border: 'none' }}
							>
								{saving ? (
									<CircularProgress color='warning' />
								) : (
									<Save
										style={{
											color: `${
												_.isEqual(originalScreener, editingScreener)
													? blueGrey[50]
													: orange[800]
											}`,

											fontSize: '2em',
											cursor: 'pointer',
										}}
									/>
								)}
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
				<Grid
					item
					sm={6}
					sx={gridItemStyles}
				>
					<TextField
						key='Status'
						select
						required
						variant='outlined'
						label='Status'
						value={editingScreener.Status}
						name='Status'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					>
						<MenuItem value={'Draft'}>Draft</MenuItem>
						<MenuItem value={'Sent'}>Sent</MenuItem>
						<MenuItem value={'Pending'}>Pending</MenuItem>
						<MenuItem value={'Sale Made'}>Sale Made</MenuItem>
						<MenuItem value={'Declined'}>Declined</MenuItem>
					</TextField>
				</Grid>
				<Grid
					item
					sm={6}
					sx={gridItemStyles}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DesktopDatePicker
							required
							key='FollowUpDate'
							name='FollowUpDate'
							label='Date desktop'
							inputFormat='DD/MM/YYYY'
							value={editingScreener.FollowUpDate}
							onChange={handleCalendarChange}
							sx={pickerStyles}
							style={pickerStyles}
							InputProps={{
								sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
							}}
							PopperProps={{ sx: pickerStyles }}
							renderInput={(params) => (
								<TextField
									{...params}
									key='FollowUpDate'
									required
									disabled
									variant='outlined'
									label='FollowUpDate'
									value={editingScreener.FollowUpDate}
									name='FollowUpDate'
									//onChange={handleCalendarChange}
									color='secondary'
									sx={textFields}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid
					item
					sm={6}
					sx={gridItemStyles}
				>
					<TextField
						key='Account'
						disabled
						variant='outlined'
						label='Account Name'
						value={editingScreener.Account ? editingScreener.Account.Name : ''}
						name='Account'
						color='secondary'
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
					sx={gridItemStyles}
				>
					<TextField
						key='Owner'
						select
						required
						variant='outlined'
						label='Owner'
						value={editingScreener.Owner ? editingScreener.Owner : ''}
						name='Owner'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					>
						{users &&
							users.map((user) => (
								<MenuItem
									key={user._id}
									value={user._id}
									disabled={!user.IsActive}
									sx={{
										display: `${
											user.IsActive || editingScreener.Owner === user._id
												? 'block'
												: 'none'
										}`,
									}}
								>{`${user.FirstName} ${user.LastName}`}</MenuItem>
							))}
					</TextField>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
					sx={gridItemStyles}
				>
					<TextField
						key='Name'
						required
						variant='outlined'
						label='Screener Name'
						value={editingScreener.Name}
						name='Name'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
					sx={gridItemStyles}
				>
					{editingScreener.ScreenerNumber && (
						<TextField
							key='ScreenerNumber'
							disabled
							variant='outlined'
							label='Screener Number'
							value={renderScreenerNumber(editingScreener.ScreenerNumber)}
							name='Account'
							color='secondary'
							sx={textFields}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						key='Contact'
						select
						required
						variant='outlined'
						label='Contact'
						value={editingScreener.Contact}
						name='Contact'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					>
						{editingScreener.Account &&
							editingScreener.Account.Contacts &&
							editingScreener.Account.Contacts.map((contact) => (
								<MenuItem
									key={contact._id}
									value={contact._id}
								>{`${contact.FirstName} ${contact.LastName}`}</MenuItem>
							))}
					</TextField>
				</Grid>
				<Grid
					item
					sm={6}
					sx={gridItemStyles}
				>
					<div>
						<TextField
							key='Territory'
							variant='outlined'
							required
							disabled={true}
							label='Territory'
							value={editingScreener.Territory && editingScreener.Territory}
							name='Territory'
							multiline
							sx={{
								minWidth: '85%',
								textAlign: 'left',
								color: blueGrey[50],
								backgroundColor: blueGrey[700],
								pointer: 'none !important',
								borderRadius: 8,
								marginTop: 2,
								marginBottom: 2,
								'& .MuiOutlinedInput-root': {
									color: blueGrey[50],
									backgroundColor: blueGrey[700],
								},
								'& .MuiInput-input': {
									color: blueGrey[50],
									backgroundColor: blueGrey[700],
								},
								'& .MuiInputLabel-root': {
									color: blueGrey[50],
								},
								'& .MuiSelect-iconOutlined': {
									color: blueGrey[50],
								},
								'& .MuiInput-root:after': {
									borderColor: blueGrey[50],
								},
								'& .MuiOutlinedInput-input .Mui-disabled': {
									color: `${blueGrey[50]} !important`,
									WebkitTextFillColor: `${blueGrey[50]} !important`,
								},
								'& .Mui-disabled': {
									color: `${blueGrey[50]} !important`,
									WebkitTextFillColor: `${blueGrey[50]} !important`,
								},
								'& .MuiOutlinedInput-input.Mui-disabled': {
									color: `${blueGrey[50]} !important`,
									WebkitTextFillColor: `${blueGrey[50]} !important`,
								},
								'& .MuiInputLabel-shrink': {
									top: -6,
									left: -6,
									borderTopLeftRadius: 4,
									borderTopRightRadius: 4,
									border: `1px solid ${blueGrey[900]}`,
									borderBottom: 'none',
								},
								'& .MuiInputLabel-shrink.Mui-focused': {
									top: -9,
									left: -6,
									borderTopLeftRadius: 8,
									borderTopRightRadius: 8,
									border: `4px solid ${orange[500]}`,
									borderBottom: 'none',
								},
								'& .MuiInputLabel-outlined': {
									color: blueGrey[50],
									backgroundColor: blueGrey[700],

									paddingLeft: 2,
									paddingRight: 2,
									textShadow: `0px 2px ${blueGrey[900]}`,
								},
								'& .MuiPaper-root.MuiPickersPopper-root': {
									color: blueGrey[50],
									backgroundColor: blueGrey[700],
									paddingLeft: 2,
									paddingRight: 2,
									textShadow: `0px 2px ${blueGrey[900]}`,
								},

								'& .MuiInput-root': {
									color: blueGrey[50],
								},

								'& .MuiDisabled:': {
									color: blueGrey[50],
									opacity: '1 !important',
								},
								'& .MuiInputBase-input.Mui-disabled': {
									WebkitTextFillColor: blueGrey[50],
									//opacity: '1 !important',
									//cursor: 'none !important',
								},
							}}
						/>
						<div
							style={{
								display: 'inline-flex',
								minWidth: '10%',
								maxWidth: '10%',
								paddingLeft: '5%',
								marginTop: 16,
							}}
						>
							<Tooltip title='Edit Rights Tree'>
								<IconButton onClick={handleRightsModal}>
									<EditSharp
										sx={{
											color: blueGrey[50],
											backgroundColor: 'transparent',
											borderRadius: 2,
										}}
									/>
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						key='Email'
						disabled
						variant='outlined'
						label='Email'
						value={
							editingScreener.Account &&
							editingScreener.Account.Contacts &&
							editingScreener.Account.Contacts.find(
								(contact) => contact._id === editingScreener.Contact
							).Email
						}
						name='Email'
						color='secondary'
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						key='Title'
						disabled
						variant='outlined'
						label='Title'
						value={
							editingScreener.Account &&
							editingScreener.Account.Contacts &&
							editingScreener.Account.Contacts.find(
								(contact) => contact._id === editingScreener.Contact
							).Title
						}
						name='Title'
						color='secondary'
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						key='Phone'
						disabled
						variant='outlined'
						label='Phone'
						value={
							editingScreener.Account &&
							editingScreener.Account.Contacts &&
							editingScreener.Account.Contacts.find(
								(contact) => contact._id === editingScreener.Contact
							).Phone
						}
						name='Phone'
						color='secondary'
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						key='Department'
						disabled
						variant='outlined'
						label='Department'
						value={
							editingScreener.Account &&
							editingScreener.Account.Contacts &&
							editingScreener.Account.Contacts.find(
								(contact) => contact._id === editingScreener.Contact
							).Department.join(', ')
						}
						name='Department'
						color='secondary'
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						select
						variant='outlined'
						label='Currency'
						value={editingScreener.Currency}
						name='Currency'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					>
						{currencies.map((currency) => (
							<MenuItem
								key={currency._id}
								value={currency.Name}
							>
								{currency.Name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			</Grid>
		</>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
