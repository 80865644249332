import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	currencies: null,
	editing: null,
};

export const currencySlice = createSlice({
	name: 'currency',
	initialState: initialState,
	reducers: {
		createCurrency: (state, action) => {
			state.currencies.push(action.payload);

			state.currencies = state.currencies.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.currencies = state.currencies.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getCurrency: (state, action) => {
			state.editing = action.payload;
		},
		getCurrencies: (state, action) => {
			state.currencies = action.payload;

			state.currencies = state.currencies.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.currencies = state.currencies.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateCurrency: (state, action) => {
			const currencyIndex = state.currencies.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.currencies[currencyIndex] = action.payload;

			state.currencies = state.currencies.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.currencies = state.currencies.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeCurrencies: (state, action) => {
			state.currencies = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeCurrency: (state, action) => {
			state.editing = null;
		},
		resetCurrencies: () => initialState,
	},
});

export const {
	createCurrency,
	getCurrency,
	getCurrencies,
	updateCurrency,
	removeCurrencies,
	setEditing,
	removeCurrency,
	resetCurrencies,
} = currencySlice.actions;

// Selectors
export const selectCurrencies = (state) => state.currency.currencies;

export const selectCurrency = () => (state) => {
	if (state.currency.currencies && state.currency.editing) {
		return state.currency.currencies.find(
			(currency) => currency._id === state.currency.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.currency.editing;
};

// Create Currencies Async
export const createCurrencyAsync = (currency) => async (dispatch) => {
	console.log(currency);
	await api
		.post(`/admin/currency`, { Name: currency }, { withCredentials: true })
		.then((res) => {
			dispatch(createCurrency(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Currencies Async
export const getCurrenciesAsync = () => async (dispatch) => {
	await api
		.get(`/admin/currency`, { withCredentials: true })
		.then((res) => {
			dispatch(getCurrencies(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Currency Async
export const getCurrencyAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/currency/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getCurrency(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Currency Async
export const updateCurrencyAsync = (currency) => async (dispatch) => {
	await api
		.put(`/admin/currency/${currency._id}`, currency, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateCurrency(res.data));
			dispatch(
				createAlertAsync({
					message: 'Currency Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default currencySlice.reducer;
