import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Account from './Account';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectEditingAccount,
	addEditingAccount,
} from '../../../redux/accountSlice';
import { selectUser } from '../../../redux/authSlice';
import { selectUsers } from '../../../redux/userSlice';
import rightsTree from '../territories.json';
import Contact from './Contact';
import { blueGrey, orange } from '@mui/material/colors';
import { Fade } from '@mui/material';
import Submit from './Submit';

const steps = [
	'Enter Company Information',
	'Enter Contact Information',
	`Create New Account `,
];

export default function NewAccountStepper({ id, type }) {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const users = useSelector(selectUsers);
	const newAccount = useSelector(selectEditingAccount(id));
	const [activeStep, setActiveStep] = useState(0);

	useEffect(() => {
		if (!newAccount) {
			dispatch(
				addEditingAccount({
					saving: false,
					saved: false,
					newId: null,
					_id: id,
					Owner: user._id,
					Parent: undefined,
					Name: '',
					Type: type,
					Territory: '',
					RightsTree: rightsTree,
					Currency: '',
					Website: '',
					Phone: '',
					Contacts: [
						{
							_id: id,
							Owner: user._id,
							Salutation: '',
							FirstName: '',
							LastName: '',
							Title: '',
							Territory: '',
							Department: [],
							Phone: '',
							MobilePhone: '',
							Email: '',
							Address1: '',
							Address2: '',
							City: '',
							State: '',
							PostalCode: '',
							Country: '',
							Notes: '',
						},
					],
					Notes: '',
				})
			);
			// dispatch(
			// 	addEditingAccount({
			// 		saving: false,
			// 		saved: false,
			// 		newId: null,
			// 		_id: id,
			// 		AccountNumber: 0,
			// 		Owner: user._id,
			// 		Parent: undefined,
			// 		Name: `A New ${type} Account`,
			// 		Type: type,
			// 		Territory: 'Africa',
			// 		RightsTree: rightsTree,
			// 		Currency: 'AUD',
			// 		Website: 'http://google.com',
			// 		Phone: '+13234343261',
			// 		Contacts: [
			// 			{
			// 				_id: id,
			// 				Owner: user._id,
			// 				Salutation: 'Mr',
			// 				FirstName: 'John',
			// 				LastName: 'Smith',
			// 				Title: 'Head of Tacos',
			// 				Territory: 'Africa',
			// 				Department: ['Billing', 'Sales'],
			// 				Phone: '+13234343261',
			// 				MobilePhone: '13234343261',
			// 				Email: 'john@tacos.com',
			// 				Address1: '1234 Main St.',
			// 				Address2: 'Suite 13',
			// 				City: 'Eau Claire',
			// 				State: 'WI',
			// 				PostalCode: '54703',
			// 				Country: 'United Stated',
			// 				Notes: 'Something about this Contact...',
			// 			},
			// 		],
			// 		Notes: 'A Note about this account',
			// 	})
			// );
		}
		//eslint-disable-next-line
	}, [newAccount]);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '87vh',
				overflow: 'auto',
				backgroundColor: 'inherit',
				padding: 8,
				borderRadius: 2,
			}}
		>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = { color: orange[800] };
					const labelProps = { color: orange[800] };
					return (
						<Step
							key={label}
							{...stepProps}
							sx={{
								'& .MuiStepLabel-root .Mui-completed': {
									color: 'secondary.main', // circle color (COMPLETED)
								},
								'& .MuiStepLabel-root .Mui-active': {
									color: 'secondary.dark', // circle color (ACTIVE)
								},
								'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
									fill: blueGrey[50], // circle's number (ACTIVE)
								},
							}}
						>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<Typography sx={{ mt: 2, mb: 4, color: blueGrey[800] }}></Typography>
			{newAccount && (
				<>
					{activeStep === 0 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Account
									id={id}
									type={type}
									newAccount={newAccount}
									user={user}
									users={users}
									handleNext={handleNext}
								/>
							</div>
						</Fade>
					)}
					{activeStep === 1 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Contact
									id={id}
									type={type}
									newAccount={newAccount}
									user={user}
									users={users}
									handleNext={handleNext}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
					{activeStep === 2 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Submit
									dispatch={dispatch}
									newAccount={newAccount}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
				</>
			)}
		</Box>
	);
}
