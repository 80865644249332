export function saleCells(type) {
	return [
		{
			id: 'ContractNumber',
			sortable: true,
			align: 'left',
			disablePadding: true,
			label: 'Contract #',
		},
		{
			id: 'Account',
			sortable: true,
			align: 'center',
			disablePadding: false,
			label: 'Account',
		},
		{
			id: 'Name',
			sortable: true,
			align: 'center',
			disablePadding: false,
			label: 'Name',
		},
		{
			id: 'Status',
			sortable: true,
			align: 'center',
			disablePadding: false,
			label: 'Status',
		},
	];
}
