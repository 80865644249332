import {
	Backdrop,
	Button,
	Grid,
	MenuItem,
	Modal,
	TextField,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import {
	gridItemStyles,
	gridStyles,
	textFieldStyles,
} from '../../../../shared/ComponentStyles';
import { useDispatch } from 'react-redux';
import {
	addProgramItem,
	editProgramItem,
} from '../../../../../redux/programSlice';
import { createAlertAsync } from '../../../../../redux/alertsSlice';

export default function ProgramItemModal({
	program,
	programItem,
	programItemModalStatus,
	handleProgramItemModal,
}) {
	const dispatch = useDispatch();
	const [newProgramItem, setNewProgramItem] = useState({
		_id: programItem ? programItem._id : `new-${crypto.randomUUID()}`,
		IsActive: programItem ? programItem.IsActive : true,
		Program: program._id,
		Name: programItem ? programItem.Name : '',
		Format: program.Format,
		Definition: programItem ? programItem.Definition : '',
		SeasonNumber: programItem ? programItem.SeasonNumber : 1,
		EpisodeNumber: programItem ? programItem.EpisodeNumber : 1,
		Synopsis: programItem ? programItem.Synopsis : '',
		RunningTime: program.RunningTime,
		VimeoLink: programItem ? programItem.VimeoLink : '',
		RightsOut: programItem ? programItem.RightsOut : [],
		Notes: programItem ? programItem.Notes : '',
	});

	function handleChange(e) {
		let value = e.target.value;

		if (e.target.name === 'Name') {
			if (value.indexOf(newProgramItem.Definition) !== 0) {
				value = `${newProgramItem.Definition} - ` + value;
			}
		}

		if (e.target.name === 'Definition') {
			console.log(newProgramItem.Name.indexOf(value));

			let name;

			// Remove current definition from name
			if (newProgramItem.Definition !== '') {
				console.log('Toots');
				name = newProgramItem.Name.replace(
					`${newProgramItem.Definition} - `,
					``
				);
				console.log(name);
			}

			// Add new definition
			name = `${value} - ${name}`;

			setNewProgramItem({
				...newProgramItem,
				Name: name,
				Definition: value,
			});
			return;
		}

		if (e.target.name === 'VimeoLink') {
			if (!value.includes('https://') && value.indexOf('https://') !== 0) {
				if (value.indexOf('https://') !== 0) {
					value = 'https://' + value;
				}
			}
		}

		setNewProgramItem({ ...newProgramItem, [e.target.name]: value });
	}

	function submitProgramItem(e) {
		e.preventDefault();

		function isValidURL(string) {
			var res = string.match(
				/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
			);
			return res !== null;
		}

		if (!isValidURL(newProgramItem.VimeoLink)) {
			dispatch(
				createAlertAsync({
					message: 'VimeoLink is not valid',
					severity: 'error',
					autoDismiss: true,
					timeout: 6000,
				})
			);
			return;
		}

		//TODO: Validate URL before submitting
		if (e.target.name === 'ProgramItemModal') {
			if (programItem) {
				dispatch(editProgramItem(newProgramItem));
			} else {
				dispatch(addProgramItem(newProgramItem));
			}

			handleProgramItemModal();
		}
	}

	return (
		<Modal
			open={programItemModalStatus}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotprops={{
				backdrop: {
					Timeout: 1000,
				},
			}}
		>
			<Box
				component='form'
				name={'ProgramItemModal'}
				onSubmit={submitProgramItem}
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: blueGrey[50],
					boxShadow: 24,
					p: 4,
					width: '90%',
					height: '90%',
					minHeight: '90%',
					maxHeight: '90%',
					overflowY: 'auto',
				}}
			>
				<Box
					sx={{
						margin: 0,
						marginTop: 0,
						display: 'flex',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={12}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
								margin: 8,
								marginTop: 4,
							}}
							item
						>
							{programItem ? 'Program Item' : 'New Program Item'}
							{newProgramItem.Name === '' ? '' : `- ${newProgramItem.Name}`}
						</Grid>
						<Grid
							sm={12}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								required
								autoFocus
								variant='outlined'
								color='secondary'
								label='Name'
								value={newProgramItem.Name}
								name='Name'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
								style={{ width: '90%' }}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								required
								select
								variant='outlined'
								color='secondary'
								label='IsActive'
								value={newProgramItem.IsActive}
								name='IsActive'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
							>
								<MenuItem value={true}>Active</MenuItem>
								<MenuItem value={false}>Inactive</MenuItem>
							</TextField>
						</Grid>
						<Grid
							sm={6}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								required
								disabled
								variant='outlined'
								color='secondary'
								label='Format'
								value={newProgramItem.Format}
								name='Format'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								required
								select
								variant='outlined'
								color='secondary'
								label='Definition'
								value={newProgramItem.Definition}
								name='Definition'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
							>
								{program.Definition.map((definition) => (
									<MenuItem
										key={definition}
										value={definition}
									>
										{definition}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid
							sm={6}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								required
								disabled
								type='number'
								variant='outlined'
								color='secondary'
								label='Running Time'
								value={newProgramItem.RunningTime}
								name='RunningTime'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
							/>
						</Grid>
						{program.Format === 'Series' && (
							<>
								<Grid
									sm={6}
									sx={{
										textAlign: 'center',
										fontWeight: 'bold',
										color: blueGrey[600],
										fontSize: '1.5em',
									}}
									item
								>
									<TextField
										required
										type='number'
										variant='outlined'
										color='secondary'
										label='Season Number'
										value={newProgramItem.SeasonNumber}
										name='SeasonNumber'
										onChange={(e) => handleChange(e)}
										sx={textFieldStyles}
									/>
								</Grid>
								<Grid
									sm={6}
									sx={{
										textAlign: 'center',
										fontWeight: 'bold',
										color: blueGrey[600],
										fontSize: '1.5em',
									}}
									item
								>
									<TextField
										required
										type='number'
										variant='outlined'
										color='secondary'
										label='Episode Number'
										value={newProgramItem.EpisodeNumber}
										name='EpisodeNumber'
										onChange={(e) => handleChange(e)}
										sx={textFieldStyles}
									/>
								</Grid>
							</>
						)}

						<Grid
							sm={12}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								required
								type={'url'}
								variant='outlined'
								color='secondary'
								label='Vimeo Link'
								value={newProgramItem.VimeoLink}
								name='VimeoLink'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
								style={{ width: '90%' }}
							/>
						</Grid>
						<Grid
							sm={12}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								multiline
								rows={4}
								variant='outlined'
								color='secondary'
								label='Synopsis'
								value={newProgramItem.Synopsis}
								name='Synopsis'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
								style={{ width: '90%' }}
							/>
						</Grid>
						<Grid
							sm={12}
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							<TextField
								multiline
								rows={4}
								variant='outlined'
								color='secondary'
								label='Notes'
								value={newProgramItem.Notes}
								name='Notes'
								onChange={(e) => handleChange(e)}
								sx={textFieldStyles}
								style={{ width: '90%' }}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ marginTop: 8 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={handleProgramItemModal}
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								type='submit'
								name='ProgramItemModal'
								//onClick={submitProgramItem}
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								{programItem ? 'Update' : 'Add'}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
}
