import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export default function AccountsTableRowContacts({ open, contacts }) {
	return (
		<TableRow sx={{ color: 'inherit' }}>
			<TableCell
				style={{ paddingBottom: 0, paddingTop: 0 }}
				colSpan={6}
				sx={{ color: 'inherit' }}
			>
				<Collapse
					in={open}
					timeout='auto'
					unmountOnExit
				>
					<Box sx={{ margin: 1, color: 'inherit' }}>
						<Typography gutterBottom>Contacts</Typography>
						<Table size='small'>
							<TableHead>
								<TableRow>
									<TableCell
										align='left'
										sx={{ fontSize: '.75em', color: 'inherit' }}
									>
										Name
									</TableCell>
									<TableCell
										align='center'
										sx={{ fontSize: '.75em', color: 'inherit' }}
									>
										Phone
									</TableCell>
									<TableCell
										align='center'
										sx={{ fontSize: '.75em', color: 'inherit' }}
									>
										Mobile
									</TableCell>
									<TableCell
										align='center'
										sx={{ fontSize: '.75em', color: 'inherit' }}
									>
										Email
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{contacts.map((contact) => (
									<TableRow key={contact._id}>
										<TableCell
											align='left'
											sx={{ fontSize: '.75em', color: 'inherit' }}
										>{`${contact.FirstName}  ${contact.LastName} - ${contact.Department}`}</TableCell>
										<TableCell
											align='center'
											sx={{ fontSize: '.75em', color: 'inherit' }}
										>
											{contact.Phone}
										</TableCell>
										<TableCell
											align='center'
											sx={{ fontSize: '.75em', color: 'inherit' }}
										>
											{contact.MobilePhone}
										</TableCell>
										<TableCell
											align='center'
											sx={{ fontSize: '.75em', color: 'inherit' }}
										>
											{contact.Email}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Box>
				</Collapse>
			</TableCell>
		</TableRow>
	);
}

AccountsTableRowContacts.propTypes = {
	open: PropTypes.bool.isRequired,
	contacts: PropTypes.array.isRequired,
};
