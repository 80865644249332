import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { blueGrey, orange } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import {
	setTab,
	closeTab,
	selectTabs,
	selectCurrentTab,
	reorderTabs,
} from '../../redux/tabsSlice';
import { useLocation } from 'react-router-dom';
import { determineTabStatus } from '../../utils/tabs';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import { handleTabClose } from '../../utils/tabs';

function TabBarMenu({ params }) {
	const dispatch = useDispatch();
	let location = useLocation();
	const navigate = useNavigate();
	const tabs = useSelector(selectTabs);
	let currentTab = useSelector(selectCurrentTab);
	let tab = tabs.find((tab) => tab.link === location.pathname);
	const [modalStatus, setModalStatus] = useState(false);
	const [tabToClose, setTabToClose] = useState(null);
	const handleModalStatus = () => setModalStatus(!modalStatus);

	useEffect(() => {
		if (tab && tab.id !== currentTab.id) {
			dispatch(setTab(tab));
		}

		// eslint-disable-next-line
	}, [currentTab, tab]);

	function handleTab(e, tab) {
		e.preventDefault();

		if (e.target.id === 'close') {
			if (tab.type !== 'dashboard') {
				if (tab.status) {
					const currentIndex = tabs.findIndex((x) => x.id === tab.id);

					dispatch(setTab(tabs[currentIndex - 1]));
					navigate(tabs[currentIndex - 1].link);
					dispatch(closeTab(tab.id));
					handleTabClose(tab, dispatch);
				} else {
					setTabToClose(tab);
					handleModalStatus();
				}
			}
		} else if (e.target.id === 'switch') {
			navigate(tab.link);
		}
	}

	function handleCloseWithUnsavedChanges() {
		const currentIndex = tabs.findIndex((x) => x.id === tabToClose.id);

		dispatch(setTab(tabs[currentIndex - 1]));
		navigate(tabs[currentIndex - 1].link);
		dispatch(closeTab(tabToClose.id));
		handleTabClose(tab, dispatch);
		handleModalStatus();
	}

	useEffect(() => {
		if (
			!tab &&
			currentTab.link !== location.pathname &&
			location.pathname.includes(params.sub)
		) {
			determineTabStatus(tabs, location, params, dispatch);
		}

		// eslint-disable-next-line
	}, [params]);

	let dragging = { begin: null, end: null };

	function allowDrop(e) {
		e.preventDefault();
	}

	function drag(e) {
		dragging.begin = e.target.id;
	}

	function drop(e) {
		e.preventDefault();

		if (e.target.id === 'switch' || e.target.value === 'close') {
			dragging.end = e.target.parentElement.id;
		} else {
			dragging.end = e.target.id;
		}

		const beginTab = tabs.findIndex((tab) => tab.id === dragging.begin);
		const endTab = tabs.findIndex((tab) => tab.id === dragging.end);

		if (beginTab !== -1 && endTab !== -1) {
			dispatch(reorderTabs({ begin: beginTab, end: endTab }));
		}
	}

	return (
		<Box sx={{ width: '100%', marginTop: 2 }}>
			<Box>
				<Tabs
					value={currentTab.id}
					variant='scrollable'
					scrollButtons={true}
					aria-label='sales tabs'
					allowScrollButtonsMobile
					sx={{
						'& .MuiTabs-indicator': { backgroundColor: blueGrey[500] },
						'& .MuiTab-root': {
							margin: 0,
							color: 'white',
							backgroundColor: 'gray',
							marginLeft: '1em',
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
							paddingTop: '0',
							paddingBottom: '0',
							minHeight: '0px',
							top: '10px',
							opacity: 0.5,
							maxWidth: '100em',
							boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
							'&:hover': {
								//backgroundColor: 'white',
								//color: 'black',
								opacity: 0.75,
							},
						},
						'& .Mui-selected': {
							color: 'white !important',
							backgroundColor: blueGrey[500],
							opacity: '1 !important',
							boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
						},
						'& .MuiTabs-scrollButtons': {
							color: 'white',
							'&.Mui-disabled': { opacity: 0, color: 'white' },
						},
					}}
				>
					{tabs &&
						tabs.length > 0 &&
						tabs.map((tab) => (
							<Tab
								key={tab.id}
								id={tab.id}
								value={tab.id}
								iconPosition='end'
								wrapped={false}
								element={'div'}
								onDrop={tab.id !== 'dashboard' ? drop : null}
								onDragOver={tab.id !== 'dashboard' ? allowDrop : null}
								draggable={tab.id !== 'dashboard'}
								onDragStart={drag}
								label={
									<React.Fragment>
										<div
											id='switch'
											style={{
												zIndex: 1,
												marginRight: 6,
												paddingLeft: 6,
												paddingRight: 6,
												paddingTop: 10,
												paddingBottom: 10,
												//color: tab.status ? blueGrey[100] : orange[800],
												//boxShadow: `0px 0px 1px 1px gray`,
											}}
											onClick={(e) => {
												handleTab(e, tab);
											}}
										>
											{tab.title}
											{/* {tab.title.substring(0, 20)} */}
										</div>
										<div style={{ minWidth: 10 }}></div>
										{tab.delete && (
											<div
												id='close'
												style={{
													zIndex: 10,
													paddingLeft: 6,
													paddingRight: 6,
													width: '2em',
													height: '2em',
													lineHeight: '1.75em',
													backgroundColor: 'gray',
													color: tab.status ? blueGrey[100] : orange[800],
													borderRadius: 15,
													border: '2px solid white',
													borderColor: tab.status ? blueGrey[100] : orange[800],
													boxShadow: `0px 0px 1px 1px gray`,
												}}
												onClick={(e) => {
													handleTab(e, tab);
												}}
											>
												X
											</div>
										)}
									</React.Fragment>
								}
							/>
						))}
				</Tabs>
			</Box>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={modalStatus}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Fade in={modalStatus}>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: 500,
							bgcolor: blueGrey[50],
							border: '2px solid #000',
							boxShadow: 24,
							p: 4,
						}}
					>
						<Box
							sx={{
								textAlign: 'center',
								color: blueGrey[900],
								fontSize: '1.25em',
							}}
						>
							<Box>There are unsaved changes in this tab.</Box>
							<Box>How would you like to proceed?</Box>
						</Box>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: '3em' }}
						>
							<Button
								variant='contained'
								onClick={handleModalStatus}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
							<Button
								variant='contained'
								onClick={handleCloseWithUnsavedChanges}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Discard Changes & Close
							</Button>
						</Stack>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
}

export default TabBarMenu;
