import React, { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';
import { grey, blueGrey } from '@mui/material/colors';
import MenuItems from './AppBarMenuItems.json';

function AppBarMenuItems({ category }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuOpen, setMenuOpen] = React.useState(false);

	const handleClick = (event) => {
		setMenuOpen(true);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setMenuOpen(false);
		setAnchorEl(null);
	};

	let location = useLocation();

	let menuItem;

	menuItem = MenuItems.data.filter(
		(menuItem) => menuItem.category === category
	);

	let ref = useRef();

	let activeBackgroundColor = grey[900];
	let activeBackgroundColorHover = grey[800];

	if (
		(menuItem.length > 0 &&
			location.pathname.includes(`${menuItem[0].category.toLowerCase()}`)) ||
		location.pathname === '/'
	) {
		activeBackgroundColor = blueGrey[700];
		activeBackgroundColorHover = blueGrey[700];
	}

	return (
		<React.Fragment>
			{menuItem.length > 0 && (
				<div>
					<Button
						ref={ref}
						id='basic-button'
						aria-controls={menuOpen ? 'basic-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={menuOpen ? 'true' : undefined}
						onClick={handleClick}
						sx={{
							marginTop: '14px',
							marginLeft: '1em',
							paddingLeft: 4,
							paddingRight: 4,
							minHeight: '35px',
							maxHeight: '35px',
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
							color: 'white',

							backgroundColor: `${activeBackgroundColor}`,
							'&:hover': {
								backgroundColor: `${activeBackgroundColorHover}`,
							},
							'& .MuiTouchRipple-child': {
								backgroundColor: grey[900],
							},
						}}
					>
						{menuItem[0].category}
					</Button>
					<Menu
						id='basic-menu'
						anchorEl={anchorEl}
						open={menuOpen}
						onClose={handleClose}
						disableAutoFocus={true}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
							onMouseLeave: handleClose,
						}}
						sx={{
							'& .MuiList-root': {
								backgroundColor: `${activeBackgroundColor}`,
							},
							'& .MuiPaper-root': {
								borderTopLeftRadius: 0,
								borderTopRightRadius: 0,
								boxShadow: '0px 3px 3px black',
								minWidth: `${
									ref && ref.current && ref.current.clientWidth
								}px !important`,
							},
						}}
					>
						{menuItem[0].items.map((item) => (
							<MenuItem
								key={item.order}
								onClick={handleClose}
							>
								<Link
									to={item.link}
									component={RouterLink}
									sx={{ textDecoration: 'none', color: 'white' }}
								>
									{item.title}
								</Link>
							</MenuItem>
						))}
					</Menu>
				</div>
			)}
		</React.Fragment>
	);
}

export default AppBarMenuItems;
