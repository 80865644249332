import { Backdrop, Button, Grid, Modal, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React from 'react';
import { gridItemStyles, gridStyles } from '../../../../shared/ComponentStyles';

export default function DeleteRightsModal({
	deleteRightsModal,
	handleDeleteRightsModal,
	deleteRights,
}) {
	return (
		<Modal
			open={deleteRightsModal}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotprops={{
				backdrop: {
					Timeout: 1000,
				},
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: blueGrey[50],
					boxShadow: 24,
					p: 4,
					width: '40%',
					height: '40%',
					minHeight: '40%',
					maxHeight: '40%',
					overflowY: 'auto',
				}}
			>
				<Box sx={{ marginTop: 8, textAlign: 'center' }}>
					<Typography sx={{ fontSize: 20, margin: 16 }}>
						Are you sure you want to delete these rights?
					</Typography>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={() => handleDeleteRightsModal()}
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={deleteRights}
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
}
