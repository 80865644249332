import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { blueGrey } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
//import IconButton from '@mui/material/IconButton';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//import SalesTableRowItems from './SalesTableRowItems';

const tableCell = {
	color: blueGrey[50],

	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
	},
};

export default function SalesTableRow({ cells, sale }) {
	//const [open, setOpen] = React.useState(false);

	// function renderContractNumber(saleNumber) {
	// 	saleNumber = saleNumber.toString();
	// 	while (saleNumber.length < 6) saleNumber = '0' + saleNumber;

	// 	return 'SCRN' + saleNumber;
	// }

	function renderCell(sale, cellId) {
		if (cellId === 'Account') {
			return sale.Account.Name;
		}

		if (cellId === 'Status') {
			return sale.Status ? sale.Status : 'N/A';
		}

		return sale[cellId];

		//cell.id === 'Account' ? sale.Account.Name : sale[cell.id];
	}

	return (
		<>
			<TableRow sx={{ color: 'inherit' }}>
				<TableCell
					align='left'
					sx={tableCell}
				>
					{/* <IconButton
						aria-label='expand row'
						size='small'
						onClick={() => setOpen(!open)}
						sx={{
							color: 'inherit',
							visibility: `${
								sale.SaleItems && sale.SaleItems.length > 0
									? 'visible'
									: 'hidden'
							}`,
							'& .MuiSvgIcon-root': { color: 'inherit' },
						}}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton> */}
				</TableCell>
				{cells.map((cell) => (
					<TableCell
						key={cell.id}
						align={cell.align}
						sx={tableCell}
						style={{ width: `calc(100% / ${cells.length})` }}
					>
						{cell.id === 'ContractNumber' ? (
							<Link
								to={`/sales/sales/${sale._id}`}
								component={RouterLink}
								sx={{
									color: blueGrey[50],
									textDecoration: 'none',
									'&:hover': { color: blueGrey[100], fontWeight: 'bold' },
								}}
							>
								{sale.ContractNumber}
							</Link>
						) : (
							`${renderCell(sale, cell.id)}`
						)}
					</TableCell>
				))}
			</TableRow>
			{/* {sale.SaleItems && sale.SaleItems.length > 0 && (
				<SalesTableRowItems
					open={open}
					saleItems={sale.SaleItems}
				/>
			)} */}
		</>
	);
}

SalesTableRow.propTypes = {
	cells: PropTypes.array.isRequired,
	sale: PropTypes.object.isRequired,
};
