export const programTableCells = [
	{
		id: 'Name',
		sortable: true,
		align: 'left',
		disablePadding: true,
		label: 'Name',
	},
	{
		id: 'Territory',
		sortable: false,
		align: 'center',
		disablePadding: false,
		label: 'Territory',
	},
	{
		id: 'LicensedMedia',
		sortable: false,
		align: 'center',
		disablePadding: false,
		label: 'LicensedMedia',
	},
	{
		id: 'Format',
		sortable: false,
		align: 'center',
		disablePadding: false,
		label: 'Format',
	},
	{
		id: 'Definition',
		sortable: false,
		align: 'center',
		disablePadding: false,
		label: 'Definition',
	},
	{
		id: 'Genre',
		sortable: false,
		align: 'center',
		disablePadding: false,
		label: 'Genre',
	},
	{
		id: 'YearOfProduction',
		sortable: true,
		align: 'center',
		disablePadding: false,
		label: 'YOP',
	},
	{
		id: 'Exclusive',
		sortable: false,
		align: 'center',
		disablePadding: false,
		label: 'Exclusive',
	},
];
