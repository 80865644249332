import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Checkbox } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';

export default function ProgramsTableRow({
	program,
	selectedPrograms,
	setSelectedPrograms,
}) {
	let checked = false;
	console.log(program);
	console.log(selectedPrograms);

	if (
		selectedPrograms.findIndex(
			(selectedProgram) => selectedProgram.Program._id === program._id
		) !== -1 ||
		(program.Program &&
			selectedPrograms.findIndex(
				(selectedProgram) => selectedProgram.Program._id === program.Program._id
			) !== -1)
	) {
		console.log(true);
		checked = true;
	} else {
		console.log(false);
	}

	const tableCell = {
		color: checked ? orange[500] : blueGrey[50],
		'& .MuiCheckbox-root.Mui-checked': {
			color: checked ? orange[500] : blueGrey[50],
		},
		'& .MuiCheckbox-root.Mui-checked.Mui-disabled': {
			color: blueGrey[500],
		},
	};

	function combineLicensedMedia(rightsIn) {
		let licensedMedia = [];

		if (rightsIn.length > 0) {
			rightsIn.forEach((rights) => {
				licensedMedia = [...licensedMedia, ...rights.LicensedMedia];
			});
		}

		return licensedMedia.join(', ');
	}

	return (
		<>
			<TableRow>
				<TableCell
					align='left'
					sx={tableCell}
					//style={{ width: '1em' }}
				>
					{!program.Program ||
					(program.Program && program.Status === 'Draft') ? (
						<Checkbox
							id={program._id}
							checked={checked}
							onClick={(e) => setSelectedPrograms(e, program)}
							sx={tableCell}
						/>
					) : (
						<Checkbox
							id={program._id}
							disabled
							checked={checked}
							onClick={(e) => setSelectedPrograms(e, program)}
							sx={tableCell}
						/>
					)}
				</TableCell>
				<TableCell
					align='left'
					sx={tableCell}
					//style={{ width: 150 }}
				>
					{program.Name}
				</TableCell>
				<TableCell
					align='center'
					sx={tableCell}
				>
					{program.Program ? program.Program.Territory : program.Territory}
				</TableCell>
				<TableCell
					align='center'
					sx={tableCell}
				>
					{program.RightsIn ? combineLicensedMedia(program.RightsIn) : []}
				</TableCell>
				<TableCell
					align='center'
					sx={tableCell}
				>
					{program.Program ? program.Program.Format : program.Format}
				</TableCell>
				<TableCell
					align='center'
					sx={tableCell}
				>
					{program.Definition && program.Definition.join(', ')}
				</TableCell>
				<TableCell
					align='center'
					sx={tableCell}
				>
					{program.Program ? program.Program.Genre : program.Genre}
				</TableCell>
				<TableCell
					align='center'
					sx={tableCell}
				>
					{program.Program
						? program.Program.YearOfProduction
						: program.YearOfProduction}
				</TableCell>
				<TableCell
					align='center'
					sx={tableCell}
				>
					{program.Exclusive}
				</TableCell>
			</TableRow>
		</>
	);
}
