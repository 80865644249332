import {
	Box,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	NavigateBeforeTwoTone,
	NavigateNextTwoTone,
} from '@mui/icons-material';
import { blueGrey, orange } from '@mui/material/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';

export default function ConvertToSaleStep3({
	newSale,
	handleChange,
	handleCalendarChange,
}) {
	function submit(e) {
		e.preventDefault();
		handleChange({ target: { name: 'stepIndex', value: 3 } });
	}

	return (
		<Box
			component='form'
			onSubmit={submit}
			sx={{ height: '100%' }}
		>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[900],
					height: 64,
					justifyContent: 'center',
					alignItems: 'center',
					alignContent: 'center',
					marginBottom: 6,
				}}
			>
				<Grid
					item
					xs={1}
				>
					<Tooltip title='Back'>
						<IconButton
							onClick={() =>
								handleChange({
									target: { name: 'stepIndex', value: 1 },
								})
							}
							sx={{ padding: 0 }}
						>
							<NavigateBeforeTwoTone
								sx={{
									color: blueGrey[50],
									backgroundColor: blueGrey[800],
									height: 40,
									width: 40,
									borderRadius: 40,
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid
					item
					xs={10}
				>
					<Typography
						variant='h5'
						component='h2'
						sx={{ color: blueGrey[50] }}
					>
						Delivery
					</Typography>
				</Grid>
				<Grid
					item
					xs={1}
				>
					<Tooltip title='Next'>
						<IconButton
							type='submit'
							sx={{ padding: 0 }}
						>
							<NavigateNextTwoTone
								sx={{
									color: blueGrey[50],
									backgroundColor: blueGrey[800],
									height: 40,
									width: 40,
									borderRadius: 40,
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<Box
				sx={{
					height: 'calc(100vh - 450px)',
					overflowY: 'auto',
					paddingBottom: 4,
				}}
			>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='DeliveryContact'
							select
							required
							variant='outlined'
							label='Delivery Contact'
							value={newSale.DeliveryContact}
							name='DeliveryContact'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						>
							{newSale.Account &&
								newSale.Account.Contacts &&
								newSale.Account.Contacts.map((contact) => (
									<MenuItem
										key={contact._id}
										value={contact._id}
									>{`${contact.FirstName} ${contact.LastName} : ${contact.Department}`}</MenuItem>
								))}
						</TextField>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='DeliveryContactEmail'
							disabled
							variant='outlined'
							label='Delivery Contact Email'
							value={
								newSale.Account &&
								newSale.Account.Contacts &&
								newSale.Account.Contacts.find(
									(contact) => contact._id === newSale.DeliveryContact
								).Email
							}
							name='DeliveryContactEmail'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								required
								key='DeliveryDate'
								name='DeliveryDate'
								label='Delivery Date'
								inputFormat='DD/MM/YYYY'
								value={newSale.DeliveryDate}
								onChange={(e) => handleCalendarChange(e, 'DeliveryDate')}
								sx={pickerStyles}
								style={pickerStyles}
								InputProps={{
									sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
								}}
								PopperProps={{ sx: pickerStyles }}
								renderInput={(params) => (
									<TextField
										{...params}
										key='DeliveryDate'
										required
										disabled
										variant='outlined'
										label='Delivery Date'
										value={newSale.DeliveryDate}
										name='DeliveryDate'
										//onChange={handleCalendarChange}
										color='secondary'
										sx={textFields}
									/>
								)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='DeliveryMethod'
							select
							required
							variant='outlined'
							label='Delivery Method'
							value={newSale.DeliveryMethod}
							name='DeliveryMethod'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						>
							<MenuItem
								key={crypto.randomUUID()}
								value={'LGI FTP'}
							>
								LGI FTP
							</MenuItem>
							<MenuItem
								key={crypto.randomUUID()}
								value={'LGI Portal'}
							>
								LGI Portal
							</MenuItem>
							<MenuItem
								key={crypto.randomUUID()}
								value={'Client Portal'}
							>
								Client Portal
							</MenuItem>
							<MenuItem
								key={crypto.randomUUID()}
								value={'Client FTP'}
							>
								Client FTP
							</MenuItem>
							<MenuItem
								key={crypto.randomUUID()}
								value={'Client Aspera'}
							>
								Client Aspera
							</MenuItem>
						</TextField>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='TechSpecs'
							disabled
							variant='outlined'
							label='Tech Specs ????'
							value={''}
							name='TechSpecs'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					></Grid>
				</Grid>
			</Box>
		</Box>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
