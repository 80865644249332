import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { blueGrey } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
//import IconButton from '@mui/material/IconButton';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//import ScreenersTableRowItems from './ScreenersTableRowItems';

const tableCell = {
	color: blueGrey[50],

	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
	},
};

export default function ScreenersTableRow({ cells, screener }) {
	//const [open, setOpen] = React.useState(false);

	function renderScreenerNumber(screenerNumber) {
		screenerNumber = screenerNumber.toString();
		while (screenerNumber.length < 6) screenerNumber = '0' + screenerNumber;

		return 'SCRN' + screenerNumber;
	}

	function renderCell(screener, cellId) {
		if (cellId === 'Account') {
			return screener.Account.Name;
		}

		if (cellId === 'Status') {
			return screener.Status ? screener.Status : 'N/A';
		}

		return screener[cellId];

		//cell.id === 'Account' ? screener.Account.Name : screener[cell.id];
	}

	return (
		<>
			<TableRow
				hover
				sx={{ color: 'inherit' }}
			>
				<TableCell
					align='left'
					sx={tableCell}
				></TableCell>
				{cells.map((cell) => (
					<TableCell
						key={cell.id}
						align={cell.align}
						sx={tableCell}
						style={{ width: `calc(100% / ${cells.length})` }}
					>
						{cell.id === 'ScreenerNumber' ? (
							<Link
								to={`/sales/screeners/${screener._id}`}
								component={RouterLink}
								sx={{
									color: blueGrey[50],
									textDecoration: 'none',
									'&:hover': { color: blueGrey[100], fontWeight: 'bold' },
								}}
							>
								{renderScreenerNumber(screener.ScreenerNumber)}
							</Link>
						) : (
							`${renderCell(screener, cell.id)}`
						)}
					</TableCell>
				))}
			</TableRow>
			{/* {screener.ScreenerItems && screener.ScreenerItems.length > 0 && (
				<ScreenersTableRowItems
					open={open}
					screenerItems={screener.ScreenerItems}
				/>
			)} */}
		</>
	);
}

ScreenersTableRow.propTypes = {
	cells: PropTypes.array.isRequired,
	screener: PropTypes.object.isRequired,
};
