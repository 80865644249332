import axios from 'axios';
//import store from '../redux/store';
//import { LOGOUT } from '../redux/types';
//axios.defaults.proxy = 'http://localhost:5000';
///axios.defaults.proxy.port = 5000;

const api = axios.create({
	baseURL: '/api',
	headers: {
		'Content-Type': 'application/json',
		//'Access-Control-Allow-Headers': 'X-Requested-With',
	},
});
/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 logout the user if the token has expired
**/

// api.interceptors.response.use(
// 	(res) => {
// 		//console.log(res);
// 		return res;
// 	},
// 	(err) => {
// 		throw new Error(err);
// 	}
// );

export default api;
