import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	definitions: null,
	editing: null,
};

export const definitionSlice = createSlice({
	name: 'definition',
	initialState: initialState,
	reducers: {
		createDefinition: (state, action) => {
			state.definitions.push(action.payload);

			state.definitions = state.definitions.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.definitions = state.definitions.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getDefinition: (state, action) => {
			state.editing = action.payload;
		},
		getDefinitions: (state, action) => {
			state.definitions = action.payload;

			state.definitions = state.definitions.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.definitions = state.definitions.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateDefinition: (state, action) => {
			const definitionIndex = state.definitions.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.definitions[definitionIndex] = action.payload;

			state.definitions = state.definitions.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.definitions = state.definitions.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeDefinitions: (state, action) => {
			state.definitions = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeDefinition: (state, action) => {
			state.editing = null;
		},
		resetDefinitions: () => initialState,
	},
});

export const {
	createDefinition,
	getDefinition,
	getDefinitions,
	updateDefinition,
	removeDefinitions,
	setEditing,
	removeDefinition,
	resetDefinitions,
} = definitionSlice.actions;

// Selectors
export const selectDefinitions = (state) => state.definition.definitions;

export const selectDefinition = () => (state) => {
	if (state.definition.definitions && state.definition.editing) {
		return state.definition.definitions.find(
			(definition) => definition._id === state.definition.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.definition.editing;
};

// Create Definitions Async
export const createDefinitionAsync = (definition) => async (dispatch) => {
	await api
		.post(`/admin/definition`, { Name: definition }, { withCredentials: true })
		.then((res) => {
			dispatch(createDefinition(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Definitions Async
export const getDefinitionsAsync = () => async (dispatch) => {
	await api
		.get(`/admin/definition`, { withCredentials: true })
		.then((res) => {
			dispatch(getDefinitions(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Definition Async
export const getDefinitionAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/definition/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getDefinition(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Definition Async
export const updateDefinitionAsync = (definition) => async (dispatch) => {
	await api
		.put(`/admin/definition/${definition._id}`, definition, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateDefinition(res.data));
			dispatch(
				createAlertAsync({
					message: 'Definition Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default definitionSlice.reducer;
