import React, { useState } from 'react';
import {
	Box,
	Checkbox,
	Chip,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	//IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Tooltip,
	//Tooltip,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import {
	//gridItemStyles,
	textFieldStyles,
} from '../../../../shared/ComponentStyles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
	addEditingProgramRights,
	changeEditingProgramRights,
	deleteRightsAsync,
	deleteRightsIn,
} from '../../../../../redux/programSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLicensedMedias } from '../../../../../redux/licensedmediaSlice';
import RightsModal from '../../../../shared/rightsModal/RightsModal';
import { changeEditingTerritory } from '../../../../../redux/programSlice';
import EditSharp from '@mui/icons-material/EditSharp';
import { AddCircle, DeleteForeverRounded } from '@mui/icons-material';
import rightsTree from '../../../../shared/territories.json';
import DeleteRightsModal from './DeleteRightsModal';
import { createAlertAsync } from '../../../../../redux/alertsSlice';

export default function RightsInTab({ programId, rightsIn }) {
	const dispatch = useDispatch();
	const licensedmedias = useSelector(selectLicensedMedias);
	const [rights, setRights] = useState(null);
	const [rightsModal, setRightsModal] = useState(false);
	const [deleteRightsId, setDeleteRightsId] = useState(null);
	const [deleteRightsModal, setDeleteRightsModal] = useState(false);

	function handleRightsModal(rights) {
		setRights(rights);
		setRightsModal(!rightsModal);
	}

	function handleChange(e, id) {
		let value = e.target.value;

		if (e.target.name === 'IsEndDate' || e.target.name === 'Exclusive') {
			value = e.target.checked ? true : false;
		}

		dispatch(
			changeEditingProgramRights({
				rights: true,
				program: programId,
				id: id,
				name: e.target.name,
				value: value,
			})
		);
	}

	function handleArrayChange(e, id) {
		let newValue =
			typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');
		dispatch(
			changeEditingProgramRights({
				rightsIn: true,
				program: programId,
				id: id,
				name: e.target.name,
				value: correctedValue,
			})
		);
	}

	function handleCalendarChange(e, name, id) {
		let newDate = new Date(e.$d);

		dispatch(
			changeEditingProgramRights({
				rights: true,
				program: programId,
				id: id,
				name: name,
				value: newDate.toDateString(),
			})
		);
	}

	function addNewRightsIn() {
		const newRightIn = {
			_id: `new-${crypto.randomUUID()}`,
			IsActive: true,
			Program: programId,
			StartDate: null,
			IsEndDate: false,
			EndDate: null,
			Exclusive: false,
			Holdback: '',
			HoldbackEndDate: null,
			ContinueRollover: false,
			RightsIn: true,
			Territory: 'None',
			RightsTree: rightsTree,
			LicensedMedia: [],
		};

		dispatch(
			addEditingProgramRights({ program: programId, rights: newRightIn })
		);
	}

	function handleDeleteRightsModal(id = null) {
		id ? setDeleteRightsId(id) : setDeleteRightsId(null);

		setDeleteRightsModal(!deleteRightsModal);
	}

	function deleteRights() {
		let rights = rightsIn.find((x) => x._id === deleteRightsId);

		if (rights) {
			if (rights._id.includes('new-')) {
				dispatch(deleteRightsIn({ id: deleteRightsId, program: programId }));
			} else {
				dispatch(deleteRightsAsync(deleteRightsId, programId));
			}
		} else {
			createAlertAsync({
				message: `Error: Can't Find Rights to Delete!`,
				severity: 'error',
				autoDismiss: false,
			});
		}

		handleDeleteRightsModal(!deleteRightsModal);
	}

	return (
		<Box sx={{ height: 'calc(100vh - 265px)', overflowY: 'auto', padding: 0 }}>
			<Grid
				container
				sx={{ display: 'flex', marginTop: 4 }}
			>
				<Grid
					item
					sx={{ flexGrow: 1 }}
				></Grid>
				<Grid
					item
					sx={{ marginRight: 4 }}
				>
					<Tooltip
						title='Add Rights In'
						arrow={true}
					>
						<IconButton
							onClick={addNewRightsIn}
							sx={{ color: blueGrey[600] }}
						>
							<AddCircle
								sx={{
									color: 'inherit',
									fontSize: '1.5em',
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			{rightsIn.map((rights) => (
				<Box
					key={rights._id}
					sx={{
						backgroundColor: blueGrey[50],
						borderRadius: 2,
						color: blueGrey[600],
						margin: 4,

						border: `1px solid ${blueGrey[500]}`,
					}}
				>
					<Grid
						container
						sx={{ display: 'flex', marginTop: 4 }}
					>
						<Grid
							item
							sx={{ flexGrow: 1 }}
						></Grid>
						<Grid
							item
							sx={{ marginRight: 4 }}
						>
							{rightsIn.length > 1 && (
								<Tooltip title='Delete Rights'>
									<span>
										<IconButton
											onClick={() => handleDeleteRightsModal(rights._id)}
											sx={{ border: 'none', marginRight: 4 }}
										>
											<DeleteForeverRounded
												style={{
													color: blueGrey[600],
													fontSize: '2em',
													cursor: 'pointer',
												}}
											/>
										</IconButton>
									</span>
								</Tooltip>
							)}
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							width: '100%',
							marginTop: 4,
							marginBottom: 4,
							alignContent: 'center',
							alignItems: 'center',
							margin: '4 auto',
						}}
					>
						<Grid
							item
							sm={5}
							sx={{
								margin: '0 auto',
							}}
						>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DesktopDatePicker
									color='secondary'
									key='StartDate'
									name='StartDate'
									label='Start Date'
									inputFormat='DD/MM/YYYY'
									value={rights.StartDate}
									onChange={(e) =>
										handleCalendarChange(e, 'StartDate', rights._id)
									}
									//sx={textFieldStyles}
									InputProps={{
										sx: {
											//width: '97.5%',
											'& .MuiSvgIcon-root': { color: blueGrey[700] },
										},
									}}
									PopperProps={{ sx: pickerStyles }}
									renderInput={(params) => (
										<TextField
											color='secondary'
											{...params}
											key='StartDate'
											required
											variant='outlined'
											label='Start Date'
											value={rights.StartDate}
											name='StartDate'
											sx={textFieldStyles}
											style={{ marginLeft: '4%' }}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid
							item
							sm={2}
						>
							<FormControl
								color='secondary'
								sx={textFieldStyles}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									alignContent: 'center',
									width: '80%',
									margin: '0 auto',
								}}
							>
								<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
									End Date?
								</FormLabel>
								<Checkbox
									name='IsEndDate'
									checked={rights.IsEndDate}
									onChange={(e) => handleChange(e, rights._id)}
									sx={{ display: 'inline-flex' }}
								/>
							</FormControl>
						</Grid>
						<Grid
							item
							sm={5}
						>
							{rights.IsEndDate && (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										color='secondary'
										key='EndDate'
										name='EndDate'
										label='End Date'
										inputFormat='DD/MM/YYYY'
										value={rights.EndDate}
										onChange={(e) =>
											handleCalendarChange(e, 'EndDate', rights._id)
										}
										sx={textFieldStyles}
										InputProps={{
											sx: {
												width: '97.5%',
												'& .MuiSvgIcon-root': { color: blueGrey[700] },
											},
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												color='secondary'
												{...params}
												key='EndDate'
												required
												variant='outlined'
												label='End Date'
												value={rights.EndDate}
												name='EndDate'
												sx={textFieldStyles}
											/>
										)}
									/>
								</LocalizationProvider>
							)}
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							width: '100%',
						}}
					>
						<Grid
							item
							sm={6}
						>
							<FormControl
								onClick={() => handleRightsModal(rights)}
								sx={{
									width: '80%',
									minHeight: '100%',
									height: '100%',
								}}
							>
								<InputLabel>Territory</InputLabel>
								<Select
									name='Territory'
									multiple
									multiline
									disabled
									required
									rows={6}
									IconComponent={EditSharp}
									//value={rights.Territory}
									value={
										rights.Territory
											? rights.Territory.split(',').filter((el) => el !== '  ')
											: ''
									}
									input={
										<OutlinedInput
											label='Territory'
											color='secondary'
										>
											Territory
										</OutlinedInput>
									}
									renderValue={(selected) => (
										<Box
											sx={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: 2,
											}}
										>
											{selected &&
												selected.length > 0 &&
												selected.map((value) => (
													<Chip
														key={value}
														label={value}
														sx={{
															backgroundColor: blueGrey[600],
															color: blueGrey[50],
															WebkitTextFillColor: blueGrey[50],
														}}
													/>
												))}
										</Box>
									)}
									style={{ minHeight: '100%', height: '100%' }}
								/>
							</FormControl>
						</Grid>
						<Grid
							item
							sm={6}
						>
							<FormControl
								sx={{
									width: '80%',
									minHeight: '100%',
									height: '100%',
								}}
							>
								<InputLabel>Licensed Media</InputLabel>
								<Select
									name='LicensedMedia'
									multiple
									multiline
									rows={6}
									required
									value={rights.LicensedMedia}
									onChange={(e) => handleArrayChange(e, rights._id)}
									input={
										<OutlinedInput
											label='Licensed Media'
											color='secondary'
										>
											Licensed Media
										</OutlinedInput>
									}
									renderValue={(selected) => (
										<Box
											sx={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: 2,
											}}
										>
											{selected.map((value) => (
												<Chip
													key={value}
													label={value}
													sx={{
														backgroundColor: blueGrey[600],
														color: blueGrey[50],
													}}
												/>
											))}
										</Box>
									)}
									//sx={textFieldStyles}
									style={{ minHeight: '100%', height: '100%' }}
									MenuProps={{
										PaperProps: {
											style: {
												width: '20%',
												maxWidth: '20%',
												marginTop: 36,
											},
										},
									}}
								>
									{licensedmedias.map((media) => (
										<MenuItem
											key={media._id}
											value={media.Name}
										>
											<Checkbox
												checked={rights.LicensedMedia.indexOf(media.Name) > -1}
											/>
											<ListItemText primary={media.Name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							width: '100%',
							marginTop: 4,
						}}
					>
						<Grid
							item
							sm={12}
						>
							<FormControl
								sx={{
									width: '90%',
									minHeight: '100%',
									height: '100%',
								}}
							>
								<InputLabel>Exclusions</InputLabel>
								<Select
									name='Exclusions'
									multiple
									multiline
									disabled
									required
									rows={6}
									//value={rights.Territory}
									value={
										rights.Exclusions
											? rights.Exclusions.split(',').filter((el) => el !== '  ')
											: ''
									}
									input={
										<OutlinedInput
											label='Exclusions'
											color='secondary'
										>
											Exclusions
										</OutlinedInput>
									}
									renderValue={(selected) => (
										<Box
											sx={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: 2,
											}}
										>
											{selected &&
												selected.length > 0 &&
												selected.map((value) => (
													<Chip
														key={value}
														label={value}
														sx={{
															backgroundColor: blueGrey[600],
															color: blueGrey[50],
															WebkitTextFillColor: blueGrey[50],
														}}
													/>
												))}
										</Box>
									)}
									style={{ minHeight: '100%', height: '100%' }}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							width: '100%',
							margin: '0 auto',
							marginTop: 4,
							marginBottom: 4,
							alignContent: 'space-between',
							alignItems: 'stretch',
						}}
					>
						<Grid
							item
							sm={6}
							sx={{
								width: '100%',
								textAlign: 'center',
								alignContent: 'space-between',
								alignItems: 'stretch',
							}}
						>
							<FormControl
								color='secondary'
								sx={textFieldStyles}
								style={{
									//display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									marginTop: 0,
									//marginBottom: 34,
									//width: '80%',
								}}
							>
								<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
									Exclusive
								</FormLabel>
								<Checkbox
									name='Exclusive'
									checked={rights.Exclusive}
									onChange={(e) => handleChange(e, rights._id)}
									sx={{ display: 'inline-flex' }}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							width: '100%',
							margin: '0 auto',
							marginTop: 4,
							marginBottom: 4,
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Grid
							item
							sm={6}
							sx={{
								width: '100%',
								textAlign: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							<TextField
								color='secondary'
								variant='outlined'
								label='Holdback'
								value={rights.Holdback}
								name='Holdback'
								onChange={(e) => handleChange(e, rights._id)}
								sx={textFieldStyles}
								//style={{ width: '95%' }}
							/>
						</Grid>
						<Grid
							item
							sm={6}
							sx={{
								width: '100%',
								textAlign: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							{rights.Holdback && rights.Holdback.length > 0 && (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										color='secondary'
										name='HoldbackEndDate'
										label='Holdback End Date'
										inputFormat='DD/MM/YYYY'
										value={rights.HoldbackEndDate}
										onChange={(e) =>
											handleCalendarChange(e, 'HoldbackEndDate', rights._id)
										}
										sx={textFieldStyles}
										InputProps={{
											sx: {
												'& .MuiSvgIcon-root': { color: blueGrey[700] },
											},
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												color='secondary'
												{...params}
												required
												variant='outlined'
												label='Holdback End Date'
												value={rights.HoldbackEndDate}
												name='HoldbackEndDate'
												sx={textFieldStyles}
											/>
										)}
									/>
								</LocalizationProvider>
							)}
						</Grid>
					</Grid>
				</Box>
			))}
			{rightsModal && (
				<RightsModal
					id={rights._id}
					program={rights.Program}
					rightsIn={true}
					rightsTree={rights.RightsTree}
					rightsModal={rightsModal}
					handleRightsModal={handleRightsModal}
					changeEditingTerritory={changeEditingTerritory}
				/>
			)}

			<DeleteRightsModal
				deleteRightsModal={deleteRightsModal}
				handleDeleteRightsModal={handleDeleteRightsModal}
				deleteRights={deleteRights}
			/>
		</Box>
	);
}

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
