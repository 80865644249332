import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	formats: null,
	editing: null,
};

export const formatSlice = createSlice({
	name: 'format',
	initialState: initialState,
	reducers: {
		createFormat: (state, action) => {
			state.formats.push(action.payload);

			state.formats = state.formats.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.formats = state.formats.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getFormat: (state, action) => {
			state.editing = action.payload;
		},
		getFormats: (state, action) => {
			state.formats = action.payload;

			state.formats = state.formats.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.formats = state.formats.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateFormat: (state, action) => {
			const formatIndex = state.formats.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.formats[formatIndex] = action.payload;

			state.formats = state.formats.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.formats = state.formats.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeFormats: (state, action) => {
			state.formats = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeFormat: (state, action) => {
			state.editing = null;
		},
		resetFormats: () => initialState,
	},
});

export const {
	createFormat,
	getFormat,
	getFormats,
	updateFormat,
	removeFormats,
	setEditing,
	removeFormat,
	resetFormats,
} = formatSlice.actions;

// Selectors
export const selectFormats = (state) => state.format.formats;

export const selectFormat = () => (state) => {
	if (state.format.formats && state.format.editing) {
		return state.format.formats.find(
			(format) => format._id === state.format.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.format.editing;
};

// Create Formats Async
export const createFormatAsync = (format) => async (dispatch) => {
	await api
		.post(`/admin/format`, { Name: format }, { withCredentials: true })
		.then((res) => {
			dispatch(createFormat(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Formats Async
export const getFormatsAsync = () => async (dispatch) => {
	await api
		.get(`/admin/format`, { withCredentials: true })
		.then((res) => {
			dispatch(getFormats(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Format Async
export const getFormatAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/format/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getFormat(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Format Async
export const updateFormatAsync = (format) => async (dispatch) => {
	await api
		.put(`/admin/format/${format._id}`, format, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateFormat(res.data));
			dispatch(
				createAlertAsync({
					message: 'Format Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default formatSlice.reducer;
