import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
	getSalesAsync,
	selectSales,
	selectSalesTableSettings,
	changeSalesTableSettings,
} from '../../../../redux/salesSlice';
import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	CircularProgress,
} from '@mui/material';
import SalesTableSearchBar from './SalesTableSearchBar';
import SalesTableHeader from './SalesTableHeader';
import SalesTablePagination from './SalesTablePagination';
import SalesTableRow from './SalesTableRow';
import { useNavigate } from 'react-router-dom';
import { saleCells } from './salesTableCells';
import { selectUser } from '../../../../redux/authSlice';
import { blueGrey } from '@mui/material/colors';

export default function SalesTable() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const cells = saleCells();
	const sales = useSelector(selectSales);
	const salesTableSettings = useSelector(selectSalesTableSettings);
	let count = 0;
	const user = useSelector(selectUser);

	useEffect(() => {
		if (sales.length === 0 && !loading) {
			setLoading(true);
			dispatch(getSalesAsync());
		}

		if (sales.length > 0 && loading) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [sales, loading]);

	function handleChange(e) {
		if (e.target.name === 'searchTerm') {
			dispatch(
				changeSalesTableSettings({
					name: 'page',
					value: 1,
				})
			);
		}

		dispatch(
			changeSalesTableSettings({
				name: e.target.name,
				value: e.target.value,
				checked: e.target.checked,
			})
		);
	}

	function handleSorting(id) {
		console.log(id);
		if (id === salesTableSettings.sortBy) {
			dispatch(
				changeSalesTableSettings({
					name: 'sortBy',
					sortBy: id,
					sortOrder: salesTableSettings.sortOrder === 'asc' ? 'desc' : 'asc',
				})
			);
		} else {
			dispatch(
				changeSalesTableSettings({
					name: 'sortBy',
					sortBy: id,
					sortOrder: 'asc',
				})
			);
		}
	}

	function handlePageChange(e, page) {
		dispatch(
			changeSalesTableSettings({
				name: 'page',
				value: page + 1,
			})
		);
	}

	function handleRowsPerPageChange(e, name) {
		dispatch(
			changeSalesTableSettings({
				name: name,
				value: e.target.value,
			})
		);
	}

	function renderSales() {
		let salesToDisplay = [...sales];

		if (!salesTableSettings.showAll) {
			salesToDisplay = salesToDisplay.filter((sale) => sale.Owner === user._id);
		}

		const searchTerms = salesTableSettings.searchTerm
			.toLowerCase()
			.split(' ')
			.filter((word) => word !== '');

		if (salesTableSettings.searchTerm.length > 0) {
			salesToDisplay = salesToDisplay.filter((sale, index) => {
				let anyTrue = false;

				if (salesTableSettings.searchEvery) {
					if (
						searchTerms.every((term) =>
							sale.ContractNumber
								? sale.ContractNumber.toString().includes(term)
								: false
						) ||
						searchTerms.every((term) =>
							sale.Account.Name.toLowerCase().includes(term)
						) ||
						searchTerms.every((term) =>
							sale.Name ? sale.Name.toLowerCase().includes(term) : false
						) ||
						searchTerms.every((term) =>
							sale.Status ? sale.Status.toLowerCase().includes(term) : false
						)
					) {
						anyTrue = true;
					}
				} else {
					if (
						searchTerms.some((term) =>
							sale.ContractNumber
								? sale.ContractNumber.toString().includes(term)
								: false
						) ||
						searchTerms.some((term) =>
							sale.Account.Name.toLowerCase().includes(term)
						) ||
						searchTerms.some((term) =>
							sale.Name ? sale.Name.toLowerCase().includes(term) : false
						) ||
						searchTerms.some((term) =>
							sale.Status ? sale.Status.toLowerCase().includes(term) : false
						)
					) {
						anyTrue = true;
					}
				}
				return anyTrue;
			});
		}

		salesToDisplay = salesToDisplay.sort((sale1, sale2) => {
			if (
				!sale1[salesTableSettings.sortBy] ||
				!sale2[salesTableSettings.sortBy]
			) {
				return -1;
			}

			if (salesTableSettings.sortOrder === 'asc') {
				if (
					sale2[salesTableSettings.sortBy] > sale1[salesTableSettings.sortBy]
				) {
					return -1;
				}

				if (
					sale2[salesTableSettings.sortBy] < sale1[salesTableSettings.sortBy]
				) {
					return 1;
				}

				return 0;
			} else {
				if (
					sale2[salesTableSettings.sortBy] < sale1[salesTableSettings.sortBy]
				) {
					return -1;
				}

				if (
					sale2[salesTableSettings.sortBy] > sale1[salesTableSettings.sortBy]
				) {
					return 1;
				}

				return 0;
			}
		});

		count = salesToDisplay.length;

		salesToDisplay = salesToDisplay.slice(
			(salesTableSettings.page - 1) * salesTableSettings.pageSize,
			salesTableSettings.page * salesTableSettings.pageSize
		);

		return salesToDisplay;
	}

	return (
		<Paper
			sx={{
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
				backgroundColor: 'inherit',
				color: 'inherit',
				boxShadow: 'none',
			}}
		>
			<SalesTableSearchBar
				dispatch={dispatch}
				navigate={navigate}
				salesTableSettings={salesTableSettings}
				changeSalesTableSettings={changeSalesTableSettings}
				handleChange={handleChange}
			/>
			<TableContainer>
				<Table>
					<SalesTableHeader
						cells={cells}
						handleSorting={handleSorting}
						handleChange={handleChange}
						salesTableSettings={salesTableSettings}
					/>
				</Table>
			</TableContainer>
			<TableContainer
				sx={{
					minHeight: 'calc(100vh - 280px)',
					maxHeight: 'calc(100vh - 280px)',
					borderTop: 'none',
					paddingBottom: 8,
					borderLeft: `10px solid ${blueGrey[800]}`,
					borderRight: `10px solid ${blueGrey[800]}`,
				}}
			>
				<Table>
					<TableBody
						sx={{
							width: '100%',
							overflowY: 'scroll',
						}}
					>
						{renderSales().map((sale) => (
							<SalesTableRow
								key={sale._id}
								cells={cells}
								sale={sale}
								navigate={navigate}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<SalesTablePagination
				count={count}
				salesTableSettings={salesTableSettings}
				handlePageChange={handlePageChange}
				handleRowsPerPageChange={handleRowsPerPageChange}
			/>
			{loading && (
				<CircularProgress
					color='warning'
					sx={{ position: 'absolute', top: '45%' }}
				/>
			)}
		</Paper>
	);
}

SalesTable.propTypes = {
	sales: PropTypes.array,
};
