import {
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	Modal,
	Typography,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { Box } from '@mui/system';
import React from 'react';
import { gridItemStyles, gridStyles } from '../../../shared/ComponentStyles';

export default function DeleteProgramModal({
	deleting,
	deleteProgram,
	deleteProgramModal,
	setDeleteProgramModal,
}) {
	return (
		<Modal
			open={deleteProgramModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 1000,
			}}
		>
			<Box
				component='form'
				onSubmit={deleteProgram}
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: blueGrey[700],
					color: blueGrey[800],
					boxShadow: 24,
					p: 4,
					width: '40%',
					height: '40%',
					minHeight: '40%',
					maxHeight: '40%',
					overflowY: 'auto',
				}}
			>
				<Box sx={{ margin: 0, marginTop: 16, marginBottom: 16 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={12}
							sx={gridItemStyles}
							item
						>
							{deleting ? (
								<CircularProgress
									size={60}
									color='primary'
									sx={{
										color: `${orange[500]} !important`,
									}}
								/>
							) : (
								<Typography sx={{ fontSize: 20, color: blueGrey[50] }}>
									Are you sure you want to mark this program inactive?
								</Typography>
							)}
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ marginTop: 8 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={() => setDeleteProgramModal(false)}
								sx={{
									backgroundColor: blueGrey[500],

									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								type='submit'
								sx={{
									backgroundColor: blueGrey[500],

									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Mark Inactive
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
}
