import { createSlice } from '@reduxjs/toolkit';
//import api from '../utils/api';
////import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
//import { verifyAuthAsync } from './authSlice';

const initialState = {
	territories: null,
	editing: null,
};

export const territorySlice = createSlice({
	name: 'territory',
	initialState: initialState,
	reducers: {
		getTerritories: (state, action) => {
			state.territories = action.payload;
		},
		resetTerritories: () => initialState,
	},
});

export const { getTerritories, resetTerritories } = territorySlice.actions;

// Selectors
export const selectTopTerritories = () => (state) => {
	if (state.territory.territories.length > 0) {
		return state.territory.territories.filter((territory) => !territory.Parent);
	} else {
		return [];
	}
};

export const selectRegions = (branch, parent) => (state) => {
	if (state.territory.territories.length > 0) {
		return state.territory.territories.filter(
			(territory) =>
				territory.IsRegion &&
				territory.Branch === branch &&
				territory.Parent &&
				territory.Parent === parent
		);
	} else {
		return [];
	}
};

export const selectTerritories = (branch, parent) => (state) => {
	if (state.territory.territories.length > 0) {
		return state.territory.territories.filter(
			(territory) =>
				!territory.IsRegion &&
				territory.Branch === branch &&
				territory.Parent &&
				territory.Parent === parent
		);
	} else {
		return [];
	}
};

export const selectAllTerritories = (branch) => (state) => {
	if (state.territory.territories.length > 0) {
		return state.territory.territories;
	} else {
		return [];
	}
};

// export const selectTerritory = () => (state) => {
// 	if (state.territory.territories && state.territory.editing) {
// 		return state.territory.territories.find(
// 			(territory) => territory._id === state.territory.editing._id
// 		);
// 	} else {
// 		return null;
// 	}
// };

// export const selectEditing = () => (state) => {
// 	return state.territory.editing;
// };

// // Create Territories Async
// export const createTerritoryAsync = (territory) => async (dispatch) => {
// 	await api
// 		.post(`/admin/territory`, { Name: territory }, { withCredentials: true })
// 		.then((res) => {
// 			dispatch(createTerritory(res.data));
// 		})
// 		.catch((error) => {
// 			dispatch(handleErrorAlertAsync(error));
// 		});
// };

// // Get Territories Async
// export const getTerritoriesAsync = () => async (dispatch) => {
// 	await api
// 		.get(`/admin/territory`, { withCredentials: true })
// 		.then((res) => {
// 			dispatch(getTerritories(res.data));
// 		})
// 		.catch((error) => {
// 			dispatch(handleErrorAlertAsync(error));
// 			dispatch(verifyAuthAsync(error.response.data.message));
// 		});
// };

// // Get Territory Async
// export const getTerritoryAsync = (id) => async (dispatch) => {
// 	await api
// 		.get(`/admin/territory/${id}`, { withCredentials: true })
// 		.then((res) => {
// 			dispatch(getTerritory(res.data));
// 		})
// 		.catch((error) => {
// 			dispatch(handleErrorAlertAsync(error));
// 			dispatch(verifyAuthAsync(error.response.data.message));
// 		});
// };

// // Update Territory Async
// export const updateTerritoryAsync = (territory) => async (dispatch) => {
// 	await api
// 		.put(`/admin/territory/${territory._id}`, territory, {
// 			withCredentials: true,
// 		})
// 		.then((res) => {
// 			dispatch(updateTerritory(res.data));
// 			dispatch(
// 				createAlertAsync({
// 					message: 'Territory Updated Successfully',
// 					severity: 'success',
// 					autoDismiss: true,
// 					timeout: 6000,
// 				})
// 			);
// 		})
// 		.catch((error) => {
// 			dispatch(handleErrorAlertAsync(error));
// 		});
// };

export default territorySlice.reducer;
