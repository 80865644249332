import React from 'react';
import {
	Grid,
	IconButton,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { AddCircle, Cancel } from '@mui/icons-material';

const gridStyles = {
	paddingTop: 1,
	paddingLeft: 4,
	paddingRight: 4,
	paddingBottom: 1,
	textAlign: 'center',
	//backgroundColor: blueGrey[50],
	borderRadius: 2,
	fontSize: '.65em',
	display: 'flex',
	alignItems: 'center',
	alignContent: 'center',
};

const gridItemStyles = {
	//color: blueGrey[900],
	padding: 0,

	'& .MuiGridItem-root': {
		padding: 0,
	},
};

export default function SalesTableSearchBar({
	dispatch,
	navigate,
	salesTableSettings,
	changeSalesTableSettings,
	handleChange,
}) {
	function clearSearchTerm() {
		if (salesTableSettings.searchTerm !== '') {
			dispatch(
				changeSalesTableSettings({
					name: 'searchTerm',
					value: '',
				})
			);
		}
	}

	function addNewSale() {
		// dispatch(
		// 	getSale({
		// 		_id: 'new-sale',
		// 		isSaved: false,
		// 		savedId: null,
		// 		IsActive: true,
		// 		Owner: user,
		// 		Account: editingAccount,
		// 		Contact: editingAccount.Contacts[0]._id,
		// 		SaleNumber: '',
		// 		Name: '',
		// 		Status: 'Draft',
		// 		IsClosed: false,
		// 		IsWon: false,
		// 		Currency: editingAccount.Currency,
		// 		Territory: editingAccount.Territory,
		// 		RightsTree: editingAccount.RightsTree,
		// 		DateSent: undefined,
		// 		FollowUpDate: new Date(
		// 			new Date().setDate(new Date().getDate() + 14)
		// 		).setHours(0, 0, 0, 0),
		// 		SaleItems: [],
		// 	})
		// );
		// navigate('/sales/sales/new-sale');
	}

	return (
		<Grid
			container
			sx={gridStyles}
		>
			<Grid
				sm={10}
				sx={gridItemStyles}
				item
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'middle',
						alignText: 'center',
					}}
				>
					<Typography sx={{ marginLeft: 9 }}>Search:</Typography>
					<TextField
						variant='outlined'
						size='small'
						onChange={handleChange}
						value={salesTableSettings.searchTerm}
						name='searchTerm'
						sx={{
							color: 'inherit',
							marginLeft: 2,
							marginRight: 2,
							width: { sm: 200, md: 500, lg: 600 },
							'& .MuiInputBase-root': {
								color: 'inherit',
								height: 30,
							},
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: `${blueGrey[50]} !important`,
								},
								'&.Mui-focused fieldset': {
									borderColor: `${blueGrey[50]} !important`,
								},
							},
						}}
					/>
					<Tooltip
						title='Clear Search'
						arrow={true}
					>
						<IconButton
							onClick={clearSearchTerm}
							sx={{ color: 'inherit' }}
						>
							<Cancel
								sx={{
									color: 'inherit',
									fontSize: '1.5em',
								}}
							/>
						</IconButton>
					</Tooltip>
				</Box>
			</Grid>
			<Grid
				sm={2}
				sx={gridItemStyles}
				item
				style={{ display: 'flex' }}
			>
				<Grid sx={{ flexGrow: 1 }}></Grid>
				<Tooltip
					title='Add New Buyer'
					arrow={true}
				>
					<IconButton
						onClick={addNewSale}
						sx={{ color: 'inherit' }}
					>
						<AddCircle
							sx={{
								color: 'inherit',
								fontSize: '1.5em',
							}}
						/>
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid
				sm={4}
				sx={gridItemStyles}
				item
			>
				<Typography sx={{ display: 'inline' }}>Search Some</Typography>
				<Switch
					checked={salesTableSettings.searchEvery}
					onChange={handleChange}
					name='searchEvery'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Search All</Typography>
			</Grid>
			{/* <Grid
				sm={4}
				sx={gridItemStyles}
				item
			>
				<Switch
					checked={salesTableSettings.includeContacts}
					onChange={handleChange}
					name='includeContacts'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Include Contacts</Typography>
			</Grid> */}
			<Grid
				sm={4}
				sx={gridItemStyles}
				item
			>
				<Typography sx={{ display: 'inline' }}>Show Mine</Typography>
				<Switch
					checked={salesTableSettings.showAll}
					onChange={handleChange}
					name='showAll'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Show All</Typography>
			</Grid>
		</Grid>
	);
}
