import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { blueGrey } from '@mui/material/colors';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Table,
	TableBody,
} from '@mui/material';
import ProgramsTable from './ProgramsTable';
import { ExpandMore, Save } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import ProgramsTableRow from './ProgramsTableRow';

export default function ProgramsModal({
	id,
	rightsTree,
	programsModalOpen,
	handleProgramsModalClose,
	updateFunction,
	selectedPrograms,
	updatePrograms,
	deleteProgram,
}) {
	const dispatch = useDispatch();

	function handleCheckbox(e, program) {
		if (program === 'ALL') {
		} else {
			if (e.target.checked) {
				dispatch(updatePrograms({ id: id, program: program }));
			} else {
				if (program.Program) {
					dispatch(deleteProgram({ id: id, program: program.Program._id }));
				} else {
					dispatch(deleteProgram({ id: id, program: program._id }));
				}
			}
		}
	}

	return (
		<div>
			<Modal
				open={programsModalOpen}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '100%',
						height: '100vh',
						bgcolor: blueGrey[800],
						color: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 0,
					}}
				>
					<Grid container>
						<Grid
							item
							sx={{
								flexGrow: 1,
								paddingTop: 2,
								//paddingBottom: 2,
								paddingLeft: 4,
							}}
						></Grid>
						<Grid
							sx={{
								paddingTop: 2,
								//paddingBottom: 2,
								paddingRight: 4,
							}}
						>
							<Save
								onClick={() => handleProgramsModalClose(false)}
								sx={{ fontSize: '2.5em' }}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid
							item
							sx={{
								flexGrow: 1,
								paddingTop: 2,
								paddingBottom: 2,
								//paddingLeft: 4,
							}}
						>
							{' '}
							<Accordion
								sx={{
									color: blueGrey[100],
									backgroundColor: blueGrey[800],
									boxShadow: 'none',
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMore sx={{ color: blueGrey[100] }} />}
								>
									<Typography
										variant='h6'
										component='h2'
									>{`Select Programs:  ${selectedPrograms.length} Selected`}</Typography>
								</AccordionSummary>
								<AccordionDetails
									sx={{ height: '400px', maxHeight: '400px', overflow: 'auto' }}
								>
									<Table>
										<TableBody>
											{selectedPrograms.map((program) => (
												<ProgramsTableRow
													key={program._id}
													program={program}
													setSelectedPrograms={handleCheckbox}
													selectedPrograms={selectedPrograms}
												/>
											))}
										</TableBody>
									</Table>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
					<ProgramsTable
						rightsTree={rightsTree}
						setSelectedPrograms={handleCheckbox}
						selectedPrograms={selectedPrograms}
					/>
				</Box>
			</Modal>
		</div>
	);
}
