import {
	Box,
	Checkbox,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useDispatch } from 'react-redux';
import {
	changeEditingSaleItem,
	changeEditingSaleItemChecked,
} from '../../../../redux/salesSlice';

export default function ConvertStep4Row({ program, index, newSale }) {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const currentProgram = newSale.SaleItems.filter(
		(saleItem) => saleItem.Program._id === program
	)[0].Program;

	function handleChange(e) {
		dispatch(
			changeEditingSaleItem({
				sale: newSale._id,
				program: program,
				name: e.target.name,
				value: e.target.value,
			})
		);
	}

	function handleCheckboxChange(e) {
		dispatch(
			changeEditingSaleItemChecked({
				sale: newSale._id,
				program: program,
				name: e.target.name,
				value: e.target.value,
				checked: e.target.checked,
			})
		);
	}

	return (
		<>
			<TableRow
				sx={{
					'& > *': { borderBottom: 'none' },
					color: blueGrey[50],
					backgroundColor: index % 2 === 0 ? blueGrey[800] : blueGrey[900],
				}}
			>
				<TableCell sx={{ borderBottom: `1px solid ${blueGrey[500]}` }}>
					<IconButton
						tabIndex={-1}
						aria-label='expand row'
						size='small'
						onClick={() => setOpen(!open)}
						sx={{ color: blueGrey[50] }}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell
					sx={{
						color: blueGrey[50],
						textAlign: 'center',
						borderBottom: `1px solid ${blueGrey[500]}`,
					}}
				>
					<TextField
						key='ProgramName'
						disabled
						variant='standard'
						inputProps={{ min: 0, style: { textAlign: 'center' } }}
						InputProps={{ disableUnderline: true }}
						value={currentProgram.Name}
						name='ProgramName'
						color='secondary'
						InputLabelProps={{ shrink: false }}
						sx={textFields}
					/>
				</TableCell>
				<TableCell
					sx={{
						color: blueGrey[50],
						textAlign: 'center',
						borderBottom: `1px solid ${blueGrey[500]}`,
					}}
				>
					<TextField
						key='UnitPrice'
						required
						type='number'
						variant='outlined'
						inputProps={{
							min: 0,
							inputMode: 'numeric',
							style: { textAlign: 'center' },
						}}
						InputLabelProps={{
							shrink: false,
						}}
						value={
							newSale.SaleItems.filter(
								(saleItem) => saleItem.Program._id === program
							)[0].UnitPrice
						}
						name='UnitPrice'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					/>
				</TableCell>
				<TableCell
					sx={{
						color: blueGrey[50],
						textAlign: 'center',
						borderBottom: `1px solid ${blueGrey[500]}`,
					}}
				>
					<TextField
						key='Hours'
						disabled
						variant='standard'
						inputProps={{ min: 0, style: { textAlign: 'center' } }}
						InputProps={{ disableUnderline: true }}
						InputLabelProps={{ shrink: false }}
						value={
							(newSale.SaleItems.filter(
								(saleItem) => saleItem.Program._id === program
							)[0].Hours *
								newSale.SaleItems.filter(
									(saleItem) =>
										saleItem.Program._id === program && saleItem.Checked
								).length) /
							60
						}
						name='Hours'
						color='secondary'
						sx={textFields}
					/>
				</TableCell>
				<TableCell
					sx={{
						color: blueGrey[50],
						textAlign: 'center',
						borderBottom: `1px solid ${blueGrey[500]}`,
					}}
				>
					<TextField
						key='TotalFee'
						disabled
						variant='standard'
						inputProps={{ min: 0, style: { textAlign: 'center' } }}
						InputProps={{ disableUnderline: true }}
						InputLabelProps={{ shrink: false }}
						value={`$ ${(
							newSale.SaleItems.filter(
								(saleItem) => saleItem.Program._id === program
							)[0].UnitPrice *
							newSale.SaleItems.filter(
								(saleItem) =>
									saleItem.Program._id === program && saleItem.Checked
							).length
						).toFixed(2)}`}
						name='TotalFee'
						color='secondary'
						sx={textFields}
					/>
				</TableCell>
				<TableCell
					sx={{
						color: blueGrey[50],
						textAlign: 'center',
						borderBottom: `1px solid ${blueGrey[500]}`,
					}}
				>
					<TextField
						key='ProgramsSelected'
						disabled
						variant='standard'
						inputProps={{ min: 0, style: { textAlign: 'center' } }}
						InputProps={{ disableUnderline: true }}
						InputLabelProps={{ shrink: false }}
						label={null}
						value={`${
							newSale.SaleItems.filter(
								(saleItem) =>
									saleItem.Program._id === program && saleItem.Checked
							).length
						} / ${
							newSale.SaleItems.filter(
								(saleItem) => saleItem.Program._id === program
							).length
						} Selected`}
						name='ProgramsSelected'
						color='secondary'
						sx={textFields}
					/>
				</TableCell>
			</TableRow>
			<TableRow
				sx={{
					margin: 4,
					//backgroundColor: blueGrey[700],
					backgroundColor: index % 2 === 0 ? blueGrey[800] : blueGrey[900],
				}}
			>
				<TableCell
					style={{
						paddingBottom: 0,
						paddingTop: 0,
						borderBottom: `1px solid ${blueGrey[500]}`,
					}}
					colSpan={6}
				>
					<Collapse
						in={open}
						timeout='auto'
						unmountOnExit
					>
						<Box sx={{ marginTop: 0 }}>
							<Table size='medium'>
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												color: blueGrey[50],
												borderBottom: `1px solid ${blueGrey[500]}`,
											}}
										>
											<Checkbox
												name='Select All'
												onChange={handleCheckboxChange}
												checked={newSale.SaleItems.filter(
													(item) => item.Program._id === program
												).every((item) => item.Checked)}
												color='secondary'
												sx={{
													color: blueGrey[50],
													'& .MuiCheckbox-colorSecondary': {
														color: blueGrey[50],
													},
													'& .MuiCheckbox-root': {
														color: blueGrey[50],
													},
												}}
											/>
										</TableCell>
										<TableCell
											sx={{
												color: blueGrey[50],
												textAlign: 'left',
												borderBottom: `1px solid ${blueGrey[500]}`,
											}}
										>
											Name
										</TableCell>
										<TableCell
											sx={{
												color: blueGrey[50],
												textAlign: 'center',
												borderBottom: `1px solid ${blueGrey[500]}`,
											}}
										>
											Season
										</TableCell>
										<TableCell
											sx={{
												color: blueGrey[50],
												textAlign: 'center',
												borderBottom: `1px solid ${blueGrey[500]}`,
											}}
										>
											Episode
										</TableCell>
										<TableCell
											sx={{
												color: blueGrey[50],
												textAlign: 'center',
												borderBottom: `1px solid ${blueGrey[500]}`,
											}}
										>
											Running Time
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{newSale.SaleItems.filter(
										(item) => item.Program._id === program
									).map((saleItem) => (
										<TableRow key={saleItem._id}>
											<TableCell
												sx={{ color: blueGrey[50], borderBottom: 'none' }}
											>
												<Checkbox
													name={saleItem._id}
													onChange={handleCheckboxChange}
													checked={saleItem.Checked}
													color='secondary'
													sx={{
														color: blueGrey[50],
														'& .MuiCheckbox-colorSecondary': {
															color: blueGrey[50],
														},
														'& .MuiCheckbox-root': {
															color: blueGrey[50],
														},
													}}
												/>
											</TableCell>
											<TableCell
												sx={{
													color: blueGrey[50],
													textAlign: 'left',
													borderBottom: 'none',
												}}
											>
												{saleItem.ProgramItem.Name}
											</TableCell>
											<TableCell
												sx={{
													color: blueGrey[50],
													textAlign: 'center',
													borderBottom: 'none',
												}}
											>
												{saleItem.ProgramItem.SeasonNumber}
											</TableCell>
											<TableCell
												sx={{
													color: blueGrey[50],
													textAlign: 'center',
													borderBottom: 'none',
												}}
											>
												{saleItem.ProgramItem.EpisodeNumber}
											</TableCell>
											<TableCell
												sx={{
													color: blueGrey[50],
													textAlign: 'center',
													borderBottom: 'none',
												}}
											>
												{saleItem.ProgramItem.RunningTime}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'center',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	boxShadow: 'none',
	border: 'none',
	'& .MuiInputBase-input': {
		color: blueGrey[50],
		backgroundColor: 'transparent',
	},
	'& .MuiInputBase-input.MuiInput-input': {
		backgroundColor: 'transparent',
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
		backgroundColor: 'transparent',
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: 'transparent',
		'& fieldset': {
			borderColor: blueGrey[50],
		},
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `2px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		borderColor: 'transparent',
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[500]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};
