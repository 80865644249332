import React from 'react';
import {
	Grid,
	IconButton,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { AddCircle, Cancel, Refresh } from '@mui/icons-material';
import { removeAccounts } from '../../../redux/accountSlice';
const gridStyles = {
	paddingTop: 1,
	paddingLeft: 4,
	paddingRight: 4,
	paddingBottom: 1,
	textAlign: 'center',
	borderRadius: 2,
	fontSize: '.65em',
	display: 'flex',
	alignItems: 'center',
	alignContent: 'center',
};

const gridItemStyles = {
	//color: blueGrey[900],
	padding: 0,

	'& .MuiGridItem-root': {
		padding: 0,
	},
};

export default function AccountsTableSearchBar({
	type,
	dispatch,
	navigate,
	accountsTableSettings,
	changeAccountTableSettings,
	handleChange,
}) {
	function clearSearchTerm() {
		if (accountsTableSettings.searchTerm !== '') {
			dispatch(
				changeAccountTableSettings({
					type: type,
					name: 'searchTerm',
					value: '',
				})
			);
		}
	}

	function refreshAccounts() {
		dispatch(removeAccounts(type));
	}

	function addNewAccount() {
		switch (type) {
			case 'Buyer':
				navigate('/sales/buyers/new-buyer');
				break;
			case 'Producer':
				navigate('/acquisitions/producers/new-producer');
				break;
			case 'Other':
				navigate('/finance/others/new-other');
				break;
			default:
				break;
		}
	}

	return (
		<Grid
			container
			sx={gridStyles}
		>
			<Grid
				sm={10}
				sx={gridItemStyles}
				item
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'middle',
						alignText: 'center',
					}}
				>
					<Typography sx={{ marginLeft: 9 }}>Search:</Typography>
					<TextField
						variant='outlined'
						size='small'
						onChange={handleChange}
						value={accountsTableSettings.searchTerm}
						name='searchTerm'
						sx={{
							color: 'inherit',
							marginLeft: 2,
							marginRight: 2,
							width: { sm: 200, md: 500, lg: 600 },
							'& .MuiInputBase-root': {
								color: 'inherit',
								height: 30,
							},
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: `${blueGrey[50]} !important`,
								},
								'&.Mui-focused fieldset': {
									borderColor: `${blueGrey[50]} !important`,
								},
							},
						}}
					/>
					<Tooltip
						title='Clear Search'
						arrow={true}
					>
						<IconButton
							onClick={clearSearchTerm}
							sx={{ color: 'inherit' }}
						>
							<Cancel
								sx={{
									color: 'inherit',
									fontSize: '1.5em',
								}}
							/>
						</IconButton>
					</Tooltip>
				</Box>
			</Grid>
			<Grid
				sm={2}
				sx={gridItemStyles}
				item
				style={{ display: 'flex' }}
			>
				<Grid sx={{ flexGrow: 1 }}></Grid>
				<Tooltip
					title={`Refresh ${type} Accounts`}
					arrow={true}
				>
					<IconButton
						onClick={refreshAccounts}
						sx={{ color: 'inherit' }}
					>
						<Refresh
							sx={{
								color: 'inherit',
								fontSize: '1.5em',
							}}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip
					title='Add New Buyer Account'
					arrow={true}
				>
					<IconButton
						onClick={addNewAccount}
						sx={{ color: 'inherit' }}
					>
						<AddCircle
							sx={{
								color: 'inherit',
								fontSize: '1.5em',
							}}
						/>
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid
				sm={3}
				sx={gridItemStyles}
				item
			>
				<Typography sx={{ display: 'inline' }}>Search Some</Typography>
				<Switch
					checked={accountsTableSettings.searchEvery}
					onChange={handleChange}
					name='searchEvery'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Search All</Typography>
			</Grid>
			<Grid
				sm={3}
				sx={gridItemStyles}
				item
			>
				<Switch
					checked={accountsTableSettings.includeContacts}
					onChange={handleChange}
					name='includeContacts'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Include Contacts</Typography>
			</Grid>
			<Grid
				sm={3}
				sx={gridItemStyles}
				item
			>
				<Typography sx={{ display: 'inline' }}>Show All</Typography>
				<Switch
					checked={accountsTableSettings.showActive}
					onChange={handleChange}
					name='showActive'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Show Active</Typography>
			</Grid>
			<Grid
				sm={3}
				sx={gridItemStyles}
				item
			>
				<Typography sx={{ display: 'inline' }}>Show Mine</Typography>
				<Switch
					checked={accountsTableSettings.showAll}
					onChange={handleChange}
					name='showAll'
					sx={{
						'& .Mui-checked': {
							color: `${blueGrey[800]} !important`,
						},
						'& .MuiSwitch-input': {
							color: blueGrey[800],
							backgroundColor: blueGrey[800],
						},
						'& .MuiSwitch-track': {
							color: blueGrey[800],
							backgroundColor: `${blueGrey[800]} !important`,
						},
					}}
				/>
				<Typography sx={{ display: 'inline' }}>Show All</Typography>
			</Grid>
		</Grid>
	);
}
