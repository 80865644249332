import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export default function RightsOutTab({ rightsOut }) {
	return (
		<Box sx={{ height: 'calc(100vh - 265px)', overflowY: 'auto', padding: 0 }}>
			{rightsOut && rightsOut.length > 0 ? (
				rightsOut.map((rights) => {
					return <p>{rights._id}</p>;
				})
			) : (
				<Grid
					container
					sx={{
						//display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						minHeight: '25vh',
						width: '100%',
					}}
				>
					<Grid
						item
						sm={12}
					>
						No Rights Out
					</Grid>
				</Grid>
			)}
		</Box>
	);
}
