import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { loginAsync } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { selectIsAuthenticated, selectUser } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { blueGrey } from '@mui/material/colors';

export const Login = () => {
	const navigate = useNavigate();
	let dispatch = useDispatch();

	let isAuthenticated = useSelector(selectIsAuthenticated);
	let user = useSelector(selectUser);

	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});

	const { email, password } = formData;

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		dispatch(loginAsync(email, password));
	};

	useEffect(() => {
		if (isAuthenticated && user) {
			navigate('/');
		}
	}, [isAuthenticated, user, navigate]);

	return (
		<Container
			sx={{
				textAlign: 'center',
				padding: '2em',
				maxWidth: '100% !important',
				color: 'white',
			}}
		>
			<form onSubmit={onSubmit}>
				<TextField
					label='Email'
					variant='outlined'
					color='secondary'
					type='email'
					name='email'
					value={email}
					onChange={onChange}
					autoComplete='on'
					required
					sx={{
						margin: '2em 2em',
						'& .MuiInputBase-root': {
							color: 'white',
							borderColor: 'white',
						},
						'& .MuiFormLabel-root': {
							color: 'white',
						},
						'& .MuiOutlinedInput-root': {
							color: 'white',
							backgroundColor: blueGrey[800],
							'& fieldset': {
								borderColor: 'white',
							},
							'&:hover fieldset': {
								borderColor: 'white',
								color: 'white',
							},
							'&.Mui-focused fieldset': {
								borderColor: 'white',
								color: 'white',
							},
						},
					}}
				/>
				<TextField
					label='Password'
					variant='outlined'
					color='secondary'
					type='password'
					name='password'
					value={password}
					onChange={onChange}
					autoComplete='on'
					required
					sx={{
						margin: '2em 2em',
						'& .MuiInputBase-root': {
							color: 'white',
							borderColor: 'white',
						},
						'& .MuiFormLabel-root': {
							color: 'white',
						},
						'& .MuiOutlinedInput-root': {
							color: 'white',
							backgroundColor: blueGrey[800],
							'& fieldset': {
								borderColor: 'white',
							},
							'&:hover fieldset': {
								borderColor: 'white',
								color: 'white',
							},
							'&.Mui-focused fieldset': {
								borderColor: 'white',
								color: 'white',
							},
						},
					}}
				/>
				<br />
				<Button
					variant='contained'
					type='submit'
					sx={{
						backgroundColor: blueGrey[900],
						'&:hover': {
							backgroundColor: blueGrey[800],
						},
						'& .MuiTouchRipple-child': {
							backgroundColor: blueGrey[900],
						},
					}}
				>
					Submit
				</Button>
			</form>
		</Container>
	);
};

export default Login;
