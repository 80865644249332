import { useSelector } from 'react-redux';
import {
	Box,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { NavigateNextTwoTone } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { selectLanguages } from '../../../../redux/languageSlice';

export default function ConvertToSaleStep1({
	newSale,
	handleChange,
	handleCalendarChange,
}) {
	const languages = useSelector(selectLanguages);

	function submit(e) {
		e.preventDefault();
		handleChange({ target: { name: 'stepIndex', value: 1 } });
	}

	return (
		<Box
			component='form'
			onSubmit={submit}
		>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[900],
					height: 64,
					justifyContent: 'center',
					alignItems: 'center',
					alignContent: 'center',
					marginBottom: 6,
				}}
			>
				<Grid
					item
					xs={1}
				></Grid>
				<Grid
					item
					xs={10}
				>
					<Typography
						variant='h5'
						component='h2'
						sx={{ color: blueGrey[50] }}
					>
						General Info
					</Typography>
				</Grid>
				<Grid
					item
					xs={1}
				>
					<Tooltip title='Next'>
						<IconButton
							type='submit'
							name='stepIndex'
							value={1}
							sx={{ padding: 0 }}
						>
							<NavigateNextTwoTone
								sx={{
									color: blueGrey[50],
									backgroundColor: blueGrey[800],
									height: 40,
									width: 40,
									borderRadius: 40,
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<Box
				sx={{
					height: 'calc(100vh - 400px)',
					overflowY: 'auto',
					paddingBottom: 4,
				}}
			>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='Account'
							required
							disabled
							variant='outlined'
							label='Account Name'
							value={newSale.Account ? newSale.Account.Name : ''}
							name='Account'
							color='secondary'
							sx={textFields}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='Contract Contact'
							select
							required
							variant='outlined'
							label='Contract Contact'
							value={newSale.ContractContact}
							name='ContractContact'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						>
							{newSale.Account &&
								newSale.Account.Contacts &&
								newSale.Account.Contacts.map((contact) => (
									<MenuItem
										key={contact._id}
										value={contact._id}
									>{`${contact.FirstName} ${contact.LastName} : ${contact.Department}`}</MenuItem>
								))}
						</TextField>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								required
								key='StartDate'
								name='StartDate'
								type='date'
								label='Date desktop'
								inputFormat='DD/MM/YYYY'
								value={newSale.StartDate}
								onChange={(e) => handleCalendarChange(e, 'StartDate')}
								sx={pickerStyles}
								style={pickerStyles}
								InputProps={{
									sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
								}}
								PopperProps={{ sx: pickerStyles }}
								renderInput={(params) => (
									<TextField
										{...params}
										key='StartDate'
										type='date'
										required
										disabled
										variant='outlined'
										label='Start Date'
										value={newSale.StartDate}
										name='StartDate'
										//onChange={handleCalendarChange}
										color='secondary'
										sx={textFields}
									/>
								)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								required
								key='EndDate'
								name='EndDate'
								type='date'
								label='Date desktop'
								inputFormat='DD/MM/YYYY'
								value={newSale.EndDate}
								onChange={(e) => handleCalendarChange(e, 'EndDate')}
								sx={pickerStyles}
								style={pickerStyles}
								InputProps={{
									sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
								}}
								PopperProps={{ sx: pickerStyles }}
								renderInput={(params) => (
									<TextField
										{...params}
										key='EndDate'
										type='date'
										required
										disabled
										variant='outlined'
										label='End Date'
										value={newSale.EndDate}
										name='EndDate'
										onChange={handleCalendarChange}
										color='secondary'
										sx={textFields}
									/>
								)}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='Territory'
							required
							disabled
							variant='outlined'
							label='Territory'
							value={newSale.Territory ? newSale.Territory : ''}
							name='Territory'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='Exclusivity'
							required
							variant='outlined'
							label='Exclusivity'
							value={newSale.Exclusivity ? newSale.Exclusivity : ''}
							name='Exclusivity'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<TextField
							select
							required
							variant='outlined'
							label='Language'
							value={newSale.Language}
							name='Language'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						>
							{languages.map((language) => (
								<MenuItem
									key={language._id}
									value={language.Name}
								>
									{language.Name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='LicensedChannel'
							variant='outlined'
							label='Licensed Channel'
							value={newSale.LicensedChannel ? newSale.LicensedChannel : ''}
							name='LicensedChannel'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='NumberOfExhibitions'
							required
							variant='outlined'
							label='Number Of Exhibitions'
							value={
								newSale.NumberOfExhibitions ? newSale.NumberOfExhibitions : ''
							}
							name='NumberOfExhibitions'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='Holdback'
							required
							variant='outlined'
							label='Holdback'
							value={newSale.Holdback ? newSale.Holdback : ''}
							name='Holdback'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<TextField
							key='SpecialConditions'
							required
							variant='outlined'
							label='Special Conditions'
							value={newSale.SpecialConditions ? newSale.SpecialConditions : ''}
							name='SpecialConditions'
							onChange={handleChange}
							color='secondary'
							sx={textFields}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					></Grid>
				</Grid>
			</Box>
		</Box>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
