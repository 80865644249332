import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrencies } from '../../../../redux/currencySlice';
import {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
	validatePhoneNumberLength,
} from 'libphonenumber-js';
import {
	changeEditingTerritory,
	updateAccountAsync,
} from '../../../../redux/accountSlice';
import { createAlertAsync } from '../../../../redux/alertsSlice';
import { Box } from '@mui/system';
import {
	CircularProgress,
	Grid,
	IconButton,
	Link,
	MenuItem,
	TextField,
	Tooltip,
} from '@mui/material';
import {
	gridItemStyles,
	gridStyles,
	textFieldStyles,
} from '../../ComponentStyles';
import { EditSharp, Save } from '@mui/icons-material';
import { blueGrey, orange } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { MuiTelInput } from 'mui-tel-input';
import CreatedModified from '../../CreatedModified';
import AccountParentModal from '../../AccountParentModal';
import RightsModal from '../../rightsModal/RightsModal';

export default function CompanyTab({
	user,
	users,
	originalAccount,
	editingAccount,
	handleChange,
	renderAccountName,
}) {
	const dispatch = useDispatch();
	const currencies = useSelector(selectCurrencies);
	const [parentAccountModal, setParentAccountModal] = useState(false);
	const [saving, setSaving] = useState(false);

	function handleParentAccountModal() {
		setParentAccountModal(!parentAccountModal);
	}

	function renderAccountParentName(account) {
		let number = account.AccountNumber.toString();
		while (number.length < 6) number = '0' + number;

		return `${number} - ${account.Name}`;
	}

	function renderAccountChildName(account) {
		let number = account.AccountNumber.toString();
		while (number.length < 6) number = '0' + number;

		return `2${number} - ${account.Name}`;
	}

	function submit(e) {
		e.preventDefault();

		if (
			isPossiblePhoneNumber(editingAccount.Phone) &&
			isValidPhoneNumber(editingAccount.Phone) &&
			validatePhoneNumberLength(editingAccount.Phone) !== 'TOO_SHORT' &&
			validatePhoneNumberLength(editingAccount.Phone) !== 'TOO_LONG'
		) {
			setSaving(true);
			dispatch(updateAccountAsync(editingAccount));
		} else {
			dispatch(
				createAlertAsync({
					message: 'Phone Number Is Not Valid',
					severity: 'error',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		}
	}

	const [rightsModal, setRightsModal] = useState(false);

	function handleRightsModal() {
		setRightsModal(!rightsModal);
	}

	useEffect(() => {}, [originalAccount, editingAccount]);

	useEffect(() => {
		setSaving(false);
	}, [originalAccount]);

	return (
		<Box
			sx={{ margin: 0, marginTop: 8 }}
			component='form'
			onSubmit={submit}
		>
			<Grid
				container
				sx={gridStyles}
			>
				<Grid
					sm={10}
					sx={gridItemStyles}
					item
				></Grid>
				<Grid
					sm={2}
					sx={gridItemStyles}
					item
				>
					<Tooltip title='Save Account'>
						<IconButton
							type='submit'
							style={{ border: 'none' }}
						>
							{saving ? (
								<CircularProgress color='warning' />
							) : (
								<Save
									style={{
										color: `${
											_.isEqual(originalAccount, editingAccount)
												? blueGrey[600]
												: orange[800]
										}`,

										fontSize: '1.5em',
										cursor: 'pointer',
									}}
								/>
							)}
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='Owner'
						select
						required
						disabled={user.Role !== 'admin'}
						variant='standard'
						label='Status:'
						value={editingAccount.IsActive}
						name='IsActive'
						onChange={handleChange}
						color='secondary'
						sx={textFieldStyles}
					>
						<MenuItem
							value={true}
							//disabled={user.Role !== 'admin'}
						>
							Active
						</MenuItem>
						<MenuItem
							value={false}
							//disabled={user.Role !== 'admin'}
						>
							Inactive
						</MenuItem>
					</TextField>
				</Grid>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='Owner'
						select
						required
						variant='standard'
						label='Owner'
						value={editingAccount.Owner}
						name='Owner'
						onChange={handleChange}
						color='secondary'
						sx={textFieldStyles}
					>
						{users &&
							users.map((user) => (
								<MenuItem
									key={user._id}
									value={user._id}
									disabled={!user.IsActive}
									sx={{
										display: `${
											user.IsActive || editingAccount.Owner === user._id
												? 'block'
												: 'none'
										}`,
									}}
								>{`${user.FirstName} ${user.LastName}`}</MenuItem>
							))}
					</TextField>
				</Grid>
			</Grid>
			<Grid
				container
				sx={gridStyles}
			>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='Name'
						required
						variant='standard'
						label='Name'
						value={editingAccount.Name}
						name='Name'
						onChange={handleChange}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					{editingAccount.Parent ? (
						<div>
							<TextField
								key='Parent'
								component={RouterLink}
								to={`/sales/buyers/${editingAccount.Parent._id}`}
								variant='standard'
								label='Parent Account:'
								value={renderAccountParentName(editingAccount.Parent)}
								name='Parent'
								sx={{
									minWidth: '70%',
									textAlign: 'left',
									color: blueGrey[900],
									'& .MuiInput-input': {
										color: blueGrey[900],
										cursor: 'pointer !important',
									},
									'& .MuiInput-root': {
										color: blueGrey[900],
									},
									'& .MuiInputLabel-root': {
										color: blueGrey[900],
									},
									'& .MuiInput-root:after': {
										borderColor: blueGrey[900],
									},
								}}
							/>
							<div
								style={{
									display: 'inline-flex',
									minWidth: '10%',
									maxWidth: '10%',
									paddingLeft: '5%',
								}}
							>
								<Tooltip title='Edit/Delete Parent'>
									<IconButton onClick={handleParentAccountModal}>
										<EditSharp
											sx={{
												color: blueGrey[600],
												backgroundColor: 'transparent',
												borderRadius: 2,
											}}
										/>
									</IconButton>
								</Tooltip>
							</div>
						</div>
					) : (
						<div>
							<TextField
								key='Parent'
								variant='standard'
								disabled={true}
								label='Parent Account:'
								value={''}
								name='Parent'
								sx={{
									minWidth: '70%',
									textAlign: 'left',
									color: blueGrey[900],
									'& .MuiInput-input': {
										color: blueGrey[900],
									},
									'& .MuiInput-root': {
										color: blueGrey[900],
									},
									'& .MuiInputLabel-root': {
										color: blueGrey[900],
									},
									'& .MuiInput-root:after': {
										borderColor: blueGrey[900],
									},
								}}
							/>
							<div
								style={{
									display: 'inline-flex',
									minWidth: '10%',
									maxWidth: '10%',
									paddingLeft: '5%',
								}}
							>
								<Tooltip title='Add Parent Account'>
									<IconButton onClick={handleParentAccountModal}>
										<EditSharp
											sx={{
												color: blueGrey[600],
												backgroundColor: 'transparent',
												borderRadius: 2,
											}}
										/>
									</IconButton>
								</Tooltip>
							</div>
						</div>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				sx={gridStyles}
			>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<MuiTelInput
						key='Phone'
						type='phone'
						required
						variant='standard'
						label='Phone'
						value={editingAccount.Phone && editingAccount.Phone}
						name='Phone'
						onChange={handleChange}
						forceCallingCode={true}
						error={!isValidPhoneNumber}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						required
						variant='standard'
						label='Website'
						type='url'
						value={editingAccount.Website}
						name='Website'
						onChange={handleChange}
						sx={textFieldStyles}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={gridStyles}
			>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<TextField
						select
						variant='standard'
						label='Currency'
						value={editingAccount.Currency}
						name='Currency'
						onChange={handleChange}
						sx={textFieldStyles}
					>
						{currencies.map((currency) => (
							<MenuItem
								key={currency._id}
								value={currency.Name}
							>
								{currency.Name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid
					sm={6}
					sx={gridItemStyles}
					item
				>
					<div>
						<TextField
							key='Territory'
							variant='standard'
							disabled={true}
							label='Territory'
							value={editingAccount.Territory}
							name='Territory'
							multiline
							sx={{
								minWidth: '70%',
								textAlign: 'left',
								color: blueGrey[900],
								pointer: 'none !important',
								'& .MuiInput-input': {
									color: blueGrey[900],
									//cursor: 'pointer !important',
									//cursor: 'none !important',
								},
								'& .MuiInput-root': {
									color: blueGrey[900],
								},
								'& .MuiInputLabel-root': {
									color: blueGrey[900],
								},
								'& .MuiInput-root:after': {
									borderColor: blueGrey[900],
								},
								'& .MuiDisabled:': {
									color: blueGrey[900],
									opacity: '1 !important',
								},
								'& .MuiInputBase-input.Mui-disabled': {
									WebkitTextFillColor: blueGrey[900],
									//opacity: '1 !important',
									//cursor: 'none !important',
								},
							}}
						/>
						<div
							style={{
								display: 'inline-flex',
								minWidth: '10%',
								maxWidth: '10%',
								paddingLeft: '5%',
							}}
						>
							<Tooltip title='Edit Rights Tree'>
								<IconButton onClick={handleRightsModal}>
									<EditSharp
										sx={{
											color: blueGrey[600],
											backgroundColor: 'transparent',
											borderRadius: 2,
										}}
									/>
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</Grid>
			</Grid>

			<Grid
				container
				spacing={2}
				sx={gridStyles}
			>
				<Grid
					sm={6}
					item
				>
					Summary goes here...
				</Grid>
				<Grid
					sm={6}
					item
				>
					{editingAccount.Children && editingAccount.Children.length > 0 && (
						<>
							<div>Child Accounts:</div>
							{editingAccount.Children.map((child) => (
								<Link
									key={child._id}
									component={RouterLink}
									to={`/sales/buyers/${child._id}`}
									sx={{ display: 'block', marginTop: 2, marginBottom: 2 }}
								>
									{renderAccountChildName(child)}
								</Link>
							))}
						</>
					)}
				</Grid>
			</Grid>
			<CreatedModified account={editingAccount} />
			<AccountParentModal
				type={editingAccount.Type}
				account={editingAccount}
				parentAccountModal={parentAccountModal}
				handleParentAccountModal={handleParentAccountModal}
			/>
			{rightsModal && (
				<RightsModal
					id={editingAccount._id}
					rightsTree={editingAccount.RightsTree}
					rightsModal={rightsModal}
					handleRightsModal={handleRightsModal}
					changeEditingTerritory={changeEditingTerritory}
				/>
			)}
		</Box>
	);
}
