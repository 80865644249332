import { addTab } from '../redux/tabsSlice';
import { removeAccount } from '../redux/accountSlice';
import { removeDepartment } from '../redux/departmentSlice';
import { removeCurrency } from '../redux/currencySlice';
import { removeUser } from '../redux/userSlice';
import { removeScreener } from '../redux/screenerSlice';
import { removeSale } from '../redux/salesSlice';
import { removeProgram } from '../redux/programSlice';

export function determineTabStatus(tabs, location, params, dispatch) {
	let paths = location.pathname.slice(1).split('/');

	let newTab = {};

	if (params.id && params.id === 'new') {
		newTab.id = `${params.id} ${paths[1].slice(0, -1)}`;
		newTab.title = `${params.id} ${paths[1].slice(0, -1)}`;
		newTab.link = location.pathname;
		newTab.type = paths[1]; //paths[1].slice(0, -1);
		newTab.tertiary = 0;
		newTab.status = true;
		newTab.delete = true;

		dispatch(addTab(newTab));
		return;
	}

	if (params.id) {
		newTab.id = params.id;
		newTab.title = params.id;
		newTab.link = location.pathname;
		newTab.type = paths[1]; //paths[1].slice(0, -1);
		newTab.tertiary = 0;
		newTab.status = true;
		newTab.delete = true;

		dispatch(addTab(newTab));
		return;
	}

	if (params.sub && !params.id) {
		let pathname = '';
		console.log(location);
		if (paths[1].includes('_')) {
			pathname = paths[1].replace('_', ' ');
		}
		console.log(pathname);
		newTab.id = crypto.randomUUID();
		newTab.title = pathname !== '' ? pathname : paths[1];
		newTab.link = location.pathname;
		newTab.type = pathname !== '' ? paths[0] : paths[1];
		newTab.tertiary = 0;
		newTab.status = true;
		newTab.delete = true;

		dispatch(addTab(newTab));
		return;
	}
}

export function handleTabClose(tab, dispatch) {
	if (
		tab.type === 'buyers' ||
		tab.type === 'producers' ||
		tab.type === 'others'
	) {
		dispatch(removeAccount(tab.id));
	}

	if (tab.type === 'screeners') {
		dispatch(removeScreener(tab.id));
	}

	if (tab.type === 'sales' || tab.type === 'convert') {
		dispatch(removeSale(tab.id));
	}

	if (tab.type === 'programs') {
		dispatch(removeProgram(tab.id));
	}

	if (tab.type === 'departments') {
		dispatch(removeDepartment());
	}

	if (tab.type === 'currencies') {
		dispatch(removeCurrency());
	}

	if (tab.type === 'users') {
		dispatch(removeUser());
	}
}
