import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	tabs: [
		{
			id: 'dashboard',
			type: 'dashboard',
			title: 'Dashboard',
			link: '/',
			delete: false,
			status: true,
			tertiary: 0,
			quaternary: 0,
		},
	],
	currentTab: {
		id: 'dashboard',
		type: 'dashboard',
		title: 'Dashboard',
		link: '/',
		delete: false,
		status: true,
		tertiary: 0,
		quaternary: 0,
	},
};

export const tabsSlice = createSlice({
	name: 'tabs',
	initialState: initialState,
	reducers: {
		setTab: (state, action) => {
			state.currentTab = action.payload;
		},
		updateTab: (state, action) => {
			const index = state.tabs.findIndex(
				(tab) => tab.id === state.currentTab.id
			);

			state.currentTab = action.payload;
			state.tabs[index] = action.payload;
		},
		updateTertiaryTab: (state, action) => {
			state.currentTab.tertiary = action.payload.newValue;

			const tab = state.tabs.find((tab) => tab.id === action.payload.id);

			tab.tertiary = action.payload.newValue;
		},
		updateQuaternaryTab: (state, action) => {
			state.currentTab.quaternary = action.payload.newValue;

			const tab = state.tabs.find((tab) => tab.id === action.payload.id);

			tab.quaternary = action.payload.newValue;
		},
		addTab: (state, action) => {
			state.tabs.push(action.payload);
			state.currentTab = action.payload;
		},
		closeTab: (state, action) => {
			if (state.currentTab.id === action.payload) {
				state.currentTab = state.tabs[state.tabs.length - 1];
			}

			state.tabs = state.tabs.filter((tab) => tab.id !== action.payload);
		},
		updateTabTitle: (state, action) => {
			let tabIndex = state.tabs.findIndex(
				(tab) => tab.id === action.payload.id
			);

			state.tabs[tabIndex].title = action.payload.title;
			state.currentTab.title = action.payload.title;
		},
		updateTabStatus: (state, action) => {
			let tabIndex = state.tabs.findIndex(
				(tab) => tab.id === action.payload.id
			);

			state.tabs[tabIndex].status = action.payload.status;
		},
		reorderTabs: (state, action) => {
			const beginTab = state.tabs[action.payload.begin];

			state.tabs.splice(action.payload.begin, 1);
			state.tabs.splice(action.payload.end, 0, beginTab);
		},
		resetTabs: () => initialState,
	},
});

export const {
	setTab,
	updateTab,
	updateTertiaryTab,
	updateQuaternaryTab,
	addTab,
	closeTab,
	updateTabTitle,
	updateTabStatus,
	reorderTabs,
	resetTabs,
} = tabsSlice.actions;

export const selectTabs = (state) => state.tabs.tabs;
export const selectCurrentTab = (state) => state.tabs.currentTab;

export default tabsSlice.reducer;
