import React, { useEffect, useState } from 'react';
import { blueGrey, green, orange, red } from '@mui/material/colors';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Box,
	TextField,
	Grid,
	MenuItem,
	Tooltip,
	IconButton,
	CircularProgress,
	ListItemText,
	FormControl,
	Select,
	InputLabel,
	Checkbox,
	Input,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { gridStyles, gridItemStyles } from '../../../ComponentStyles';
import {
	changeEditingAccountContact,
	updateAccountContactAsync,
} from '../../../../../redux/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import _ from 'lodash';
import { MuiTelInput } from 'mui-tel-input';
import {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
	validatePhoneNumberLength,
} from 'libphonenumber-js';
import { createAlertAsync } from '../../../../../redux/alertsSlice';
import ContactModal from '../../../ContactModal';
import CreatedModified from '../../../CreatedModified';
import { selectDepartments } from '../../../../../redux/departmentSlice';

const textFieldStyles = {
	minWidth: '80%',
	textAlign: 'left',
	color: blueGrey[900],
	marginTop: '1em',
	marginBottom: '1em',

	'& .MuiInput-root': {
		color: blueGrey[900],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[900],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[900],
	},
};

export default function ContactAccordion({
	originalAccount,
	editingAccount,
	contact,
	index,
	submit,
	saving,
	handleContactChange,
	users,
	handleDepartmentChange,
	departments,
}) {
	const accordionGridHeaderStyles = (index) => {
		// let color = blueGrey[50];

		// if (
		// 	_.isEqual(originalAccount.Contacts[index], editingAccount.Contacts[index])
		// ) {
		// 	if (contact.IsActive) {
		// 		color = blueGrey[50];
		// 	} else {
		// 		color = blueGrey[800];
		// 	}
		// } else {
		// 	color = orange[800];
		// }

		return {
			//width: '20%',
			//flexShrink: 0,
			fontWeight: 'bold',
			//color: color,
			textAlign: 'center',
			color: `${
				_.isEqual(
					originalAccount.Contacts[index],
					editingAccount.Contacts[index]
				)
					? blueGrey[800]
					: orange[800]
			}`,
		};
	};

	return (
		<Accordion
			key={contact._id}
			disableGutters={true}
			sx={{
				backgroundColor: blueGrey[50],
				color: contact.IsActive ? blueGrey[50] : blueGrey[50],
				padding: 0,
				boxShadow: `0px 0px 2px .5px ${blueGrey[600]}`,
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Grid
					container
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						sm={3}
						item
						sx={
							{
								//display: 'flex',
								//flexDirection: 'row',
								//alignItems: 'center',
								//alignContent: 'end',
								//textAlign: 'right',
							}
						}
					>
						<Typography
							sx={accordionGridHeaderStyles(index)}
						>{`${contact.FirstName} ${contact.LastName}`}</Typography>
					</Grid>
					<Grid
						sm={2}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						<Typography sx={accordionGridHeaderStyles(index)}>
							{contact.Title}
						</Typography>
					</Grid>
					<Grid
						sm={2}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						<Typography sx={accordionGridHeaderStyles(index)}>
							{contact.Department.join(', ')}
						</Typography>
					</Grid>
					<Grid
						sm={3}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						<Typography sx={accordionGridHeaderStyles(index)}>
							{contact.Email}
						</Typography>
					</Grid>
					<Grid
						sm={2}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						{contact.IsActive ? (
							<Box
								sx={{
									marginLeft: 8,
									width: '60%',
									height: 25,
									textAlign: 'center',
									backgroundColor: green[500],
								}}
							>
								Active
							</Box>
						) : (
							<Box
								sx={{
									marginLeft: 8,
									width: '60%',
									height: 25,
									textAlign: 'center',
									backgroundColor: red[500],
								}}
							>
								Inactive
							</Box>
						)}
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					component='form'
					onSubmit={(e) => submit(e, contact)}
					sx={{
						margin: 0,
						marginTop: 0,
						//backgroundColor: blueGrey[50],
					}}
				>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={10}
							sx={gridItemStyles}
							item
						></Grid>
						<Grid
							sm={2}
							sx={gridItemStyles}
							item
						>
							{saving ? (
								<CircularProgress color='warning' />
							) : (
								<IconButton
									type='submit'
									style={{ border: 'none' }}
								>
									<SaveIcon
										style={{
											color: `${
												_.isEqual(
													originalAccount.Contacts[index],
													editingAccount.Contacts[index]
												)
													? blueGrey[600]
													: orange[800]
											}`,
											fontSize: '1.5em',
											cursor: 'pointer',
										}}
									/>
								</IconButton>
							)}
						</Grid>
					</Grid>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='IsActive'
								select
								variant='standard'
								label='Contact Status'
								value={contact.IsActive}
								name='IsActive'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							>
								<MenuItem
									key={'IsActive'}
									value={true}
								>
									Active
								</MenuItem>
								<MenuItem
									key={'InActive'}
									value={false}
								>
									InActive
								</MenuItem>
							</TextField>
							<TextField
								key='Salutation'
								variant='standard'
								label='Salutation'
								value={contact.Salutation}
								name='Salutation'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='FirstName'
								required
								variant='standard'
								label='FirstName'
								value={contact.FirstName}
								name='FirstName'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='LastName'
								required
								variant='standard'
								label='LastName'
								value={contact.LastName}
								name='LastName'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Title'
								required
								variant='standard'
								label='Title'
								value={contact.Title}
								name='Title'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Territory'
								variant='standard'
								label='Territory'
								value={contact.Territory}
								name='Territory'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Owner'
								select
								required
								variant='standard'
								label='Owner'
								value={contact.Owner}
								name='Owner'
								onChange={(e) => handleContactChange(e, contact._id)}
								color='secondary'
								sx={textFieldStyles}
							>
								{users &&
									users.map((user) => (
										<MenuItem
											key={user._id}
											value={user._id}
											disabled={!user.IsActive}
											sx={{
												display: `${
													user.IsActive || contact.Owner === user._id
														? 'block'
														: 'none'
												}`,
											}}
										>{`${user.FirstName} ${user.LastName}`}</MenuItem>
									))}
							</TextField>
							<MuiTelInput
								key='Phone'
								type='phone'
								variant='standard'
								label='Phone'
								value={contact.Phone}
								name='Phone'
								onChange={(e) => handleContactChange(e, contact._id, 'Phone')}
								forceCallingCode={true}
								error={!isValidPhoneNumber}
								sx={textFieldStyles}
							/>
							<MuiTelInput
								key='MobilePhone'
								type='phone'
								required
								variant='standard'
								label='Mobile Phone'
								value={contact.MobilePhone}
								name='MobilePhone'
								onChange={(e) =>
									handleContactChange(e, contact._id, 'MobilePhone')
								}
								forceCallingCode={true}
								error={!isValidPhoneNumber}
								sx={textFieldStyles}
							/>
							<TextField
								key='Email'
								required
								variant='standard'
								type='email'
								label='Email'
								value={contact.Email}
								name='Email'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<FormControl sx={textFieldStyles}>
								<InputLabel sx={{ left: -14 }}>Department</InputLabel>
								<Select
									name='Department'
									multiple
									value={contact.Department}
									onChange={(e) => handleDepartmentChange(e, contact._id)}
									input={<Input label='Department'>Department</Input>}
									renderValue={(selected) => selected.join(', ')}
									MenuProps={{
										PaperProps: {
											style: {
												width: '20%',
												maxWidth: '20%',
											},
										},
									}}
									sx={textFieldStyles}
								>
									{departments.map((department) => (
										<MenuItem
											key={department._id}
											value={department.Name}
										>
											<Checkbox
												checked={
													contact.Department.indexOf(department.Name) > -1
												}
											/>
											<ListItemText primary={department.Name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{/* <TextField
											key='Department'
											required
											select
											multiple
											variant='standard'
											label='Department'
											value={contact.Department}
											name='Department'
											onChange={(e) => handleContactChange(e, contact._id)}
											sx={textFieldStyles}
										>
											<MenuItem
												key={'Sales'}
												value={'Sales'}
											>
												<CheckBox
													checked={contact.Department.indexOf('Sales') > -1}
												/>
												<ListItemText primary={'Sales'} />
											</MenuItem>
											<MenuItem
												key={'Finance'}
												value={'Finance'}
											>
												<CheckBox
													checked={contact.Department.indexOf('Finance') > -1}
												/>
												<ListItemText primary={'Finance'} />
											</MenuItem>
										</TextField> */}
						</Grid>
					</Grid>
				</Box>
				<Box
					sx={{
						margin: 0,
						marginTop: 6,
						textAlign: 'left',
						marginLeft: 7,
						fontWeight: 'bold',
						//backgroundColor: blueGrey[50],
					}}
				>
					Address:
				</Box>
				<Box sx={{ margin: 0, marginTop: 0 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Address1'
								variant='standard'
								label='Address1'
								value={contact.Address1}
								name='Address1'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Address2'
								variant='standard'
								label='Address2'
								value={contact.Address2}
								name='Address2'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='City'
								variant='standard'
								label='City'
								value={contact.City}
								name='City'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='State'
								variant='standard'
								label='State'
								value={contact.State}
								name='State'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='PostalCode'
								variant='standard'
								label='PostalCode'
								value={contact.PostalCode}
								name='PostalCode'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Country'
								variant='standard'
								label='Country'
								value={contact.Country}
								name='Country'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Notes'
								variant='outlined'
								label='Notes'
								value={contact.Notes}
								name='Notes'
								multiline
								rows={18}
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
						</Grid>
					</Grid>
				</Box>
				<CreatedModified account={contact} />
			</AccordionDetails>
		</Accordion>
	);
}
