import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	alerts: [],
};

export const alertsSlice = createSlice({
	name: 'alerts',
	initialState: initialState,
	reducers: {
		createAlert: (state, action) => {
			state.alerts.push(action.payload);
		},
		deleteAlert: (state, action) => {
			state.alerts = state.alerts.filter(
				(alert) => alert.id !== action.payload
			);
		},
		resetAlerts: () => initialState,
	},
});

export const { createAlert, deleteAlert, resetAlerts } = alertsSlice.actions;

export const selectAlerts = (state) => state.alerts.alerts;

export const createAlertAsync = (alert) => async (dispatch) => {
	try {
		alert.id = crypto.randomUUID();
		dispatch(createAlert(alert));

		if (alert.autoDismiss) {
			setTimeout(() => dispatch(deleteAlert(alert.id)), alert.timeout || 5000);
		}
	} catch (error) {
		dispatch(handleErrorAlertAsync(error));
	}
};

export const handleErrorAlertAsync = (error) => async (dispatch) => {
	try {
		let errorCreated = false;

		if (error.message) {
			const alert = {
				id: crypto.randomUUID(),
				message: error.message,
				severity: 'error',
				autoDismiss: false,
			};

			dispatch(createAlert(alert));
			errorCreated = true;
		}

		if (error.response.data.message) {
			const alert = {
				id: crypto.randomUUID(),
				message: error.response.data.message,
				severity: 'error',
				autoDismiss: false,
			};

			dispatch(createAlert(alert));
			errorCreated = true;
		}

		if (error.response.data.error) {
			//TODO:  Not sure if this is right...
			if (typeof error.response.data.error === String) {
				const alert = {
					id: crypto.randomUUID(),
					message: error.response.data.error,
					severity: 'error',
					autoDismiss: false,
				};

				dispatch(createAlert(alert));
				errorCreated = true;
			}
		}

		if (error.messages) {
			error.messages.forEach((message) => {
				const alert = {
					id: crypto.randomUUID(),
					message: message,
					severity: 'error',
					autoDismiss: false,
				};

				dispatch(createAlert(alert));
			});
			errorCreated = true;
		}

		if (error.response.data.errors) {
			error.response.data.errors.forEach((error) => {
				const alert = {
					id: crypto.randomUUID(),
					message: error,
					severity: 'error',
					autoDismiss: false,
				};

				dispatch(createAlert(alert));
			});
			errorCreated = true;
		}

		if (!errorCreated) {
			const alert = {
				id: crypto.randomUUID(),
				message: 'Unknown Error Occurred',
				severity: 'error',
				autoDismiss: false,
			};

			dispatch(createAlert(alert));
		}
	} catch (error) {
		console.log(`Unknown Error Occurred: ${error}`);
	}
};

export default alertsSlice.reducer;
