export function getAccountTabs(type) {
	switch (type) {
		case 'Buyer':
			return buyerTabs;
		case 'Producer':
			return producerTabs;
		case 'Other':
			return otherTabs;
		default:
			break;
	}
}

const buyerTabs = [
	{
		tab: 0,
		name: 'Company',
	},
	{
		tab: 1,
		name: 'Contacts',
	},
	{
		tab: 2,
		name: 'Screeners',
	},
	{
		tab: 3,
		name: 'Sales',
	},
	{
		tab: 4,
		name: 'Finance',
	},
	{
		tab: 5,
		name: 'Deliveries',
	},
	{
		tab: 6,
		name: 'Tech Specs',
	},
];

const producerTabs = [
	{
		tab: 0,
		name: 'Company',
	},
	{
		tab: 1,
		name: 'Contacts',
	},
	{
		tab: 2,
		name: 'Programs',
	},
	{
		tab: 3,
		name: 'Royalties',
	},
];

const otherTabs = [
	{
		tab: 0,
		name: 'Company',
	},
	{
		tab: 1,
		name: 'Contacts',
	},
];
