import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getSaleAsync,
	selectEditingSale,
	selectOriginalSale,
} from '../../../../redux/salesSlice';
import {
	closeTab,
	selectTabs,
	updateTabStatus,
	//updateTertiaryTab,
} from '../../../../redux/tabsSlice';
import _ from 'lodash';
import {
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import Notes from '../../../shared/Notes';
import { Box } from '@mui/system';
import { selectUsers } from '../../../../redux/userSlice';
import { gridItemStyles } from '../../../shared/ComponentStyles';
import { Save } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function SaleDetails({ id, currentTab }) {
	const dispatch = useDispatch();
	let users = useSelector(selectUsers);
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const originalSale = useSelector(selectOriginalSale(id));
	const editingSale = useSelector(selectEditingSale(id));
	const tabs = useSelector(selectTabs);
	let tab = null;

	if (editingSale) {
		tab = tabs.find(
			(tab) => tab.id === `convert-to-sale-${editingSale.Screener._id}`
		);
	}

	//console.log(id, editingSale);

	useEffect(() => {
		if (tab) {
			dispatch(closeTab(tab.id));
		}

		//eslint-disable-next-line
	}, [tab]);

	useEffect(() => {
		if (!editingSale && !loading && !id.includes('new-sale')) {
			setLoading(true);
			dispatch(getSaleAsync(id));
		}

		if (editingSale && loading) {
			setLoading(false);
		}

		//eslint-disable-next-line
	}, [editingSale, loading]);

	useEffect(() => {
		if (_.isEqual(originalSale, editingSale)) {
			dispatch(updateTabStatus({ id: id, status: true }));
		} else {
			dispatch(updateTabStatus({ id: id, status: false }));
		}
	}, [id, originalSale, editingSale, dispatch]);

	// const tertiaryTabChange = (event, newValue) => {
	// 	dispatch(updateTertiaryTab({ id: id, newValue: newValue }));
	// };

	function renderSaleName() {
		let number = editingSale.ContractNumber
			? editingSale.ContractNumber.toString()
			: '000000';

		while (number.length < 6) number = '0' + number;

		return `${number}`;
	}

	function handleChange(event) {
		console.log(event);
		setSaving(true);
	}

	//console.log(editingSale);

	return (
		<>
			{editingSale ? (
				<Box
					sx={{ width: '100%', padding: 2, height: '90vh', overflowY: 'auto' }}
				>
					<Grid container>
						<Grid
							item
							sx={{ flexGrow: 1 }}
						>
							<Typography
								style={{
									textAlign: 'left',
									marginLeft: 16,
									fontSize: '1.5em',
								}}
							>
								{currentTab.type[0].toUpperCase()}
								{currentTab.type.slice(1)} - {renderSaleName(editingSale)}
							</Typography>
						</Grid>

						<Grid
							sm={2}
							sx={gridItemStyles}
							item
						>
							<IconButton
								type='submit'
								style={{ border: 'none' }}
							>
								{saving ? (
									<CircularProgress color='warning' />
								) : (
									<Save
										style={{
											color: `${
												_.isEqual(originalSale, editingSale)
													? blueGrey[50]
													: orange[800]
											}`,

											fontSize: '2em',
											cursor: 'pointer',
										}}
									/>
								)}
							</IconButton>
						</Grid>
					</Grid>
					<Box
						sx={{
							//backgroundColor: blueGrey[50],
							borderRadius: 2,
							color: blueGrey[900],
							paddingTop: 4,
							marginBottom: 4,
						}}
					>
						<Grid container>
							<Grid
								item
								sm={6}
							></Grid>
							<Grid
								item
								sm={6}
							>
								<FormControl sx={textFields}>
									<InputLabel>Status</InputLabel>
									<Select
										name='Status'
										color='secondary'
										value={editingSale.Status}
										label='Status'
										onChange={handleChange}
									>
										<MenuItem value={'Draft'}>Draft</MenuItem>
										<MenuItem value={'Presented'}>Presented</MenuItem>
										<MenuItem value={'Rejected'}>Rejected</MenuItem>
										<MenuItem value={'Accepted'}>Accepted</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									required
									variant='outlined'
									color='secondary'
									label='Contract Number'
									value={editingSale.ContractNumber}
									name='ContractNumber'
									onChange={handleChange}
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									key='Owner'
									select
									required
									variant='outlined'
									label='Owner'
									value={editingSale.Owner}
									name='Owner'
									onChange={handleChange}
									color='secondary'
									sx={textFields}
								>
									{users &&
										users.map((user) => (
											<MenuItem
												key={user._id}
												value={user._id}
												disabled={!user.IsActive}
												sx={{
													display: `${
														user.IsActive || editingSale.Owner === user._id
															? 'block'
															: 'none'
													}`,
												}}
											>{`${user.FirstName} ${user.LastName}`}</MenuItem>
										))}
								</TextField>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Account'
									value={editingSale.Account.Name}
									name='Account'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Screener'
									value={editingSale.Screener && editingSale.Screener.Name}
									name='Screener'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='ContractSigned'
									value={editingSale.ContractSigned}
									name='ContractSigned'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='DateOfAgreement'
									value={editingSale.DateOfAgreement || ''}
									name='DateOfAgreement'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Contact'
									value={editingSale.Contact}
									name='Contact'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Exclusivity'
									value={editingSale.Exclusivity}
									name='Exclusivity'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										required
										name='StartDate'
										label='Start Date'
										inputFormat='DD/MM/YYYY'
										value={editingSale.StartDate}
										onChange={handleChange}
										sx={pickerStyles}
										style={pickerStyles}
										InputProps={{
											sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												{...params}
												required
												disabled
												variant='outlined'
												label='Start Date'
												value={editingSale.StartDate}
												name='StartDate'
												color='secondary'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										required
										name='EndDate'
										label='End Date'
										inputFormat='DD/MM/YYYY'
										value={editingSale.EndDate}
										onChange={handleChange}
										sx={pickerStyles}
										style={pickerStyles}
										InputProps={{
											sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												{...params}
												required
												disabled
												variant='outlined'
												label='End Date'
												value={editingSale.EndDate}
												name='EndDate'
												color='secondary'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Language'
									value={editingSale.Language || ''}
									name='Language'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Territory'
									value={editingSale.Territory || ''}
									name='Territory'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='WithholdingTaxRate'
									value={editingSale.WithholdingTaxRate}
									name='WithholdingTaxRate'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='NoWithholdingTaxRate'
									value={editingSale.NoWithholdingTaxRate}
									name='NoWithholdingTaxRate'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Hourly Materials Fee'
									value={'What is this? Please Explain!'}
									sx={{
										width: '95%',
										color: 'red',
										marginTop: 2,
										marginBottom: 2,
										borderRadius: 8,
										'& .MuiInputBase-input.MuiOutlinedInput-input': {
											color: 'red !important',
										},
										'& .MuiInputBaseInput.MuiOutlinedInputInput': {
											color: 'red !important',
										},
									}}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Included In Total Price'
									value={editingSale.IncludedInTotalPrice}
									name='IncludedInTotalPrice'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Total Materials Fee'
									value={editingSale.MaterialsFee}
									name='MaterialsFee'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										name='MaterialsFeeReceived'
										label='Materials Fee Received'
										inputFormat='DD/MM/YYYY'
										value={editingSale.MaterialsFeeReceived}
										onChange={handleChange}
										sx={pickerStyles}
										style={pickerStyles}
										InputProps={{
											sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												{...params}
												disabled
												variant='outlined'
												label='Materials Fee Received'
												value={editingSale.MaterialsFeeReceived}
												name='MaterialsFeeReceived'
												color='secondary'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										name='MaterialsFeeDueBy'
										label='Materials Fee Due By'
										inputFormat='DD/MM/YYYY'
										value={editingSale.MaterialsFeeDueBy || ''}
										onChange={handleChange}
										sx={pickerStyles}
										style={pickerStyles}
										InputProps={{
											sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												{...params}
												disabled
												variant='outlined'
												label='Materials Fee Due By'
												value={editingSale.MaterialsFeeDueBy || ''}
												name='MaterialsFeeDueBy'
												color='secondary'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Total Hours'
									value={editingSale.TotalHours}
									name='TotalHours'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Total Licence Fee'
									value={editingSale.TotalLicenceFee}
									name='TotalLicenceFee'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										name='DeliveryDate'
										label='Delivery Required By'
										inputFormat='DD/MM/YYYY'
										value={editingSale.DeliveryDate || ''}
										onChange={handleChange}
										sx={pickerStyles}
										style={pickerStyles}
										InputProps={{
											sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												{...params}
												disabled
												variant='outlined'
												label='Delivery Required By'
												value={editingSale.DeliveryDate || ''}
												name='DeliveryDate'
												color='secondary'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Delivery Method'
									value={'What are the options to choose from?'}
									name='DeliveryMethod'
									sx={{
										width: '95%',
										color: 'red',
										marginTop: 2,
										marginBottom: 2,
										borderRadius: 8,
										'& .MuiInputBase-input.MuiOutlinedInput-input': {
											color: 'red !important',
										},
										'& .MuiInputBaseInput.MuiOutlinedInputInput': {
											color: 'red !important',
										},
									}}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										name='DeliveryCompleted'
										label='Delivery Completed'
										inputFormat='DD/MM/YYYY'
										value={editingSale.DeliveryCompleted}
										onChange={handleChange}
										sx={pickerStyles}
										style={pickerStyles}
										InputProps={{
											sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												{...params}
												disabled
												variant='outlined'
												label='Delivery Completed'
												value={editingSale.DeliveryCompleted}
												name='DeliveryCompleted'
												color='secondary'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Copyright Letter Required'
									value={editingSale.CopyRightLetterRequired}
									name='CopyRightLetterRequired'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Certificate Of Origin Required'
									value={editingSale.CertificateOfOriginRequired}
									name='CertificateOfOriginRequired'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Number Of Exhibitions'
									value={editingSale.NumberOfExhibitions}
									name='NumberOfExhibitions'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Holdback'
									value={editingSale.Holdback}
									name='Holdback'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Licensed Channel'
									value={editingSale.LicensedChannel}
									name='LicensedChannel'
									sx={textFields}
								/>
							</Grid>
							<Grid
								item
								sm={6}
							>
								<TextField
									variant='outlined'
									color='secondary'
									label='Holdback'
									value={editingSale.Holdback}
									name='Holdback'
									sx={textFields}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={6}
							></Grid>
							<Grid
								item
								sm={6}
							></Grid>
						</Grid>
						<Grid container>
							<Grid
								item
								sm={12}
								sx={{ color: 'red' }}
							>
								View Selected Sales
							</Grid>
						</Grid>
					</Box>
					<Box
						sx={{
							backgroundColor: blueGrey[700],
							borderRadius: 2,
							color: blueGrey[50],
						}}
					>
						<Notes
							id={id}
							type={'Account'}
							notes={editingSale && editingSale.Notes}
						/>
					</Box>
				</Box>
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '70vh',
						top: '30%',
					}}
				>
					<CircularProgress
						size={60}
						color='primary'
						sx={{
							color: `${blueGrey[100]} !important`,
						}}
					/>
				</div>
			)}
		</>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
