import React, { useState, useEffect } from 'react';
import {
	Box,
	TextField,
	Grid,
	MenuItem,
	IconButton,
	Button,
	Modal,
	Backdrop,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	Input,
	Checkbox,
	ListItemText,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { addAccountContactAsync } from '../../redux/accountSlice';
import { selectUser } from '../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { gridStyles, gridItemStyles, textFieldStyles } from './ComponentStyles';
import SaveIcon from '@mui/icons-material/Save';
import { MuiTelInput } from 'mui-tel-input';
import {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
	validatePhoneNumberLength,
} from 'libphonenumber-js';
import { createAlertAsync } from '../../redux/alertsSlice';
import { selectDepartments } from '../../redux/departmentSlice';

export default function ContactModal({
	originalAccount,
	editingAccount,
	newContactModal,
	newContactModalClose,
	users,
}) {
	const dispatch = useDispatch();
	const currentUser = useSelector(selectUser);
	const [saving, setSaving] = useState(false);

	const initialState = {
		Type: originalAccount.Type,
		Account: originalAccount._id,
		Owner: currentUser._id,
		Salutation: '',
		FirstName: '',
		LastName: '',
		Title: '',
		Territory: '',
		Department: [],
		Phone: '',
		MobilePhone: '',
		Email: '',
		Address1: '',
		Address2: '',
		City: '',
		State: '',
		PostalCode: '',
		Country: '',
		Notes: '',
	};
	const departments = useSelector(selectDepartments);
	const [newContact, setNewContact] = useState(initialState);

	function handleChange(e, name) {
		if (!e.target) {
			if (name === 'Phone') {
				setNewContact({ ...newContact, Phone: e });
			} else {
				setNewContact({ ...newContact, MobilePhone: e });
			}
		} else {
			setNewContact({ ...newContact, [e.target.name]: e.target.value });
		}
	}

	function handleDepartmentChange(e, contactId) {
		let newValue =
			typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');
		setNewContact({ ...newContact, [e.target.name]: correctedValue });
	}

	function addContact(event) {
		event.preventDefault();

		let phoneNumbersValid = true;

		if (
			!isPossiblePhoneNumber(newContact.MobilePhone) &&
			!isValidPhoneNumber(newContact.MobilePhone) &&
			validatePhoneNumberLength(newContact.MobilePhone) === 'TOO_SHORT' &&
			validatePhoneNumberLength(newContact.MobilePhone) === 'TOO_LONG'
		) {
			phoneNumbersValid = false;
			dispatch(
				createAlertAsync({
					message: 'Mobile Phone Number Is Not Valid',
					severity: 'error',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		}

		if (newContact.Phone !== '') {
			if (
				!isPossiblePhoneNumber(newContact.Phone) &&
				!isValidPhoneNumber(newContact.Phone) &&
				validatePhoneNumberLength(newContact.Phone) === 'TOO_SHORT' &&
				validatePhoneNumberLength(newContact.Phone) === 'TOO_LONG'
			) {
				phoneNumbersValid = false;
				dispatch(
					createAlertAsync({
						message: 'Phone Number Is Not Valid',
						severity: 'error',
						autoDismiss: true,
						timeout: 5000,
					})
				);
			}
		}

		if (phoneNumbersValid) {
			setSaving(true);
			dispatch(addAccountContactAsync(newContact, originalAccount.Type));
		}
	}

	useEffect(() => {
		setSaving(false);
		newContactModalClose();
		setNewContact(initialState);

		// eslint-disable-next-line
	}, [originalAccount.Contacts]);

	return (
		<Modal
			open={newContactModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 1000,
			}}
		>
			<Box
				component='form'
				onSubmit={addContact}
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: blueGrey[50],
					//border: '2px solid #000',
					boxShadow: 24,
					p: 4,
					width: '80%',
					height: '80%',
					minHeight: '80%',
					maxHeight: '80%',
					overflowY: 'auto',
				}}
			>
				<Box sx={{ margin: 0, marginTop: 0 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={10}
							sx={{
								textAlign: 'left',
								paddingLeft: 6,
								fontWeight: 'bold',
								color: blueGrey[600],
								fontSize: '1.5em',
							}}
							item
						>
							New Contact
						</Grid>
						<Grid
							sm={2}
							sx={gridItemStyles}
							item
						>
							{saving ? (
								<CircularProgress color='warning' />
							) : (
								<IconButton
									type='submit'
									style={{ border: 'none' }}
								>
									<SaveIcon
										style={{
											color: blueGrey[500],
											fontSize: '1.5em',
											cursor: 'pointer',
											'&:hover': { color: blueGrey[900] },
										}}
									/>
								</IconButton>
							)}
						</Grid>
					</Grid>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Salutation'
								variant='standard'
								label='Salutation'
								value={newContact.Salutation}
								name='Salutation'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='FirstName'
								required
								variant='standard'
								label='FirstName'
								value={newContact.FirstName}
								name='FirstName'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='LastName'
								required
								variant='standard'
								label='LastName'
								value={newContact.LastName}
								name='LastName'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='Title'
								required
								variant='standard'
								label='Title'
								value={newContact.Title}
								name='Title'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='Territory'
								variant='standard'
								label='Territory'
								value={newContact.Territory}
								name='Territory'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Owner'
								select
								required
								variant='standard'
								label='Owner'
								value={newContact.Owner}
								name='Owner'
								onChange={handleChange}
								color='primary'
								sx={textFieldStyles}
							>
								{users &&
									users.map((user) => (
										<MenuItem
											key={user._id}
											value={user._id}
											disabled={!user.IsActive}
											sx={{
												display: `${
													user.IsActive || editingAccount.Owner === user._id
														? 'block'
														: 'none'
												}`,
											}}
										>{`${user.FirstName} ${user.LastName}`}</MenuItem>
									))}
							</TextField>
							<MuiTelInput
								key='Phone'
								type='phone'
								variant='standard'
								label='Phone'
								value={newContact.Phone}
								name='Phone'
								onChange={(e) => handleChange(e, 'Phone')}
								forceCallingCode={true}
								error={!isValidPhoneNumber}
								sx={textFieldStyles}
							/>
							<MuiTelInput
								key='MobilePhone'
								type='phone'
								required
								variant='standard'
								label='Mobile Phone'
								value={newContact.MobilePhone}
								name='MobilePhone'
								onChange={(e) => handleChange(e, 'MobilePhone')}
								forceCallingCode={true}
								error={!isValidPhoneNumber}
								sx={textFieldStyles}
							/>
							<TextField
								key='Email'
								required
								variant='standard'
								type='email'
								label='Email'
								value={newContact.Email}
								name='Email'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<FormControl sx={textFieldStyles}>
								<InputLabel sx={{ left: -14 }}>Department</InputLabel>
								<Select
									name='Department'
									multiple
									value={newContact.Department}
									onChange={(e) => handleDepartmentChange(e, newContact._id)}
									input={<Input label='Department'>Department</Input>}
									renderValue={(selected) => selected.join(', ')}
									MenuProps={{
										PaperProps: {
											style: {
												width: '20%',
												maxWidth: '20%',
											},
										},
									}}
									sx={textFieldStyles}
								>
									{departments.map((department) => (
										<MenuItem
											key={department._id}
											value={department.Name}
										>
											<Checkbox
												checked={
													newContact.Department.indexOf(department.Name) > -1
												}
											/>
											<ListItemText primary={department.Name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Box>
				<Box
					sx={{
						margin: 0,
						marginTop: 6,
						textAlign: 'left',
						marginLeft: 7,
						fontWeight: 'bold',
					}}
				>
					Address:
				</Box>
				<Box sx={{ margin: 0, marginTop: 0 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Address1'
								variant='standard'
								label='Address1'
								value={newContact.Address1}
								name='Address1'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='Address2'
								variant='standard'
								label='Address2'
								value={newContact.Address2}
								name='Address2'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='City'
								variant='standard'
								label='City'
								value={newContact.City}
								name='City'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='State'
								variant='standard'
								label='State'
								value={newContact.State}
								name='State'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='PostalCode'
								variant='standard'
								label='PostalCode'
								value={newContact.PostalCode}
								name='PostalCode'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
							<TextField
								key='Country'
								variant='standard'
								label='Country'
								value={newContact.Country}
								name='Country'
								onChange={handleChange}
								sx={textFieldStyles}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Notes'
								variant='outlined'
								label='Notes'
								value={newContact.Notes}
								name='Notes'
								multiline
								rows={11}
								onChange={handleChange}
								sx={textFieldStyles}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ marginTop: 8 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={newContactModalClose}
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								type='submit'
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
}
