import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	tasks: null,
	fetched: null,
};

export const taskSlice = createSlice({
	name: 'task',
	initialState: initialState,
	reducers: {
		getTasks: (state, action) => {
			state.tasks = action.payload;
			state.fetched = Date.now();
		},
		updateTask: (state, action) => {
			const taskIndex = state.tasks.findIndex(
				(task) => task._id === action.payload._id
			);

			if (taskIndex) {
				state.tasks[taskIndex] = action.payload;
			}
		},
		deleteTask: (state, action) => {
			state.tasks = state.tasks.filter((task) => task._id !== action.payload);
		},

		resetTasks: () => initialState,
	},
});

export const { getTasks, updateTask, deleteTask, resetTasks } =
	taskSlice.actions;

// Selectors
export const selectTasks = (state) => state.task.tasks;
export const selectTasksFetched = (state) => state.task.fetched;

// Get Tasks Async
export const getTasksAsync = () => async (dispatch) => {
	await api
		.get(`/task`, { withCredentials: true })
		.then((res) => {
			dispatch(getTasks(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Task Async
export const updateTaskAsync = (task) => async (dispatch) => {
	await api
		.put(`/task/${task._id}`, task, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateTask(res.data));
			dispatch(
				createAlertAsync({
					message: 'Task Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Delete Tasks Async
export const deleteTaskAsync = (taskId) => async (dispatch) => {
	await api
		.delete(`/task/${taskId}`, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(deleteTask(res.data));

			dispatch(
				createAlertAsync({
					message: 'Task Deleted Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

export default taskSlice.reducer;
