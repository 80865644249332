import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { blueGrey, orange } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountsTableRowContacts from './AccountsTableRowContacts';

const tableCell = {
	color: blueGrey[50],

	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
	},
};

export default function AccountsTableRow({ cells, type, account }) {
	const [open, setOpen] = React.useState(false);

	function renderLink() {
		let link;

		switch (type) {
			case 'Buyer':
				link = `/sales/buyers/${account._id}`;
				break;
			case 'Producer':
				link = `/acquisitions/producers/${account._id}`;
				break;
			case 'Other':
				link = `/finance/others/${account._id}`;
				break;
			default:
				break;
		}

		return link;
	}

	return (
		<>
			<TableRow
				hover
				sx={{
					color: account.IsActive ? 'inherit' : blueGrey[500],
				}}
			>
				<TableCell
					align='left'
					sx={tableCell}
				>
					<IconButton
						aria-label='expand row'
						size='small'
						onClick={() => setOpen(!open)}
						sx={{
							color: 'inherit',
							visibility: `${
								account.Contacts.length > 0 ? 'visible' : 'hidden'
							}`,
							'& .MuiSvgIcon-root': { color: 'inherit' },
						}}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				{cells.map((cell) => (
					<TableCell
						key={cell.id}
						align={cell.align}
						sx={{
							color: account.IsActive ? blueGrey[50] : orange[500],

							'& .MuiCheckbox-root.Mui-checked': {
								color: blueGrey[50],
							},
						}}
						style={{ width: `calc(100% / ${cells.length})` }}
					>
						{cell.id === 'AccountNumber' ? (
							<Link
								to={renderLink()}
								component={RouterLink}
								sx={{
									color: account.IsActive ? blueGrey[50] : orange[500],
									textDecoration: 'none',
									'&:hover': { color: blueGrey[100], fontWeight: 'bold' },
								}}
							>
								{account.AccountNumber}
							</Link>
						) : (
							`${account[cell.id]}`
						)}
					</TableCell>
				))}
			</TableRow>
			{account.Contacts && account.Contacts.length > 0 && (
				<AccountsTableRowContacts
					open={open}
					contacts={account.Contacts}
				/>
			)}
		</>
	);
}

AccountsTableRow.propTypes = {
	cells: PropTypes.array.isRequired,
	type: PropTypes.string.isRequired,
	account: PropTypes.object.isRequired,
};
