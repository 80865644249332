import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	roles: null,
	editing: null,
};

export const roleSlice = createSlice({
	name: 'role',
	initialState: initialState,
	reducers: {
		createRole: (state, action) => {
			state.roles.push(action.payload);

			state.roles = state.roles.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.roles = state.roles.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getRole: (state, action) => {
			state.editing = action.payload;
		},
		getRoles: (state, action) => {
			state.roles = action.payload;

			state.roles = state.roles.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.roles = state.roles.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateRole: (state, action) => {
			const roleIndex = state.roles.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.roles[roleIndex] = action.payload;

			state.roles = state.roles.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.roles = state.roles.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeRoles: (state, action) => {
			state.roles = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeRole: (state, action) => {
			state.editing = null;
		},
		resetRoles: () => initialState,
	},
});

export const {
	createRole,
	getRole,
	getRoles,
	updateRole,
	removeRoles,
	setEditing,
	removeRole,
	resetRoles,
} = roleSlice.actions;

// Selectors
export const selectRoles = (state) => state.role.roles;

export const selectRole = () => (state) => {
	if (state.role.roles && state.role.editing) {
		return state.role.roles.find((role) => role._id === state.role.editing._id);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.role.editing;
};

// Create Roles Async
export const createRoleAsync = (role) => async (dispatch) => {
	console.log(role);
	await api
		.post(`/admin/role`, { Name: role }, { withCredentials: true })
		.then((res) => {
			dispatch(createRole(res.data));
		})
		.catch((error) => {
			console.log(error);
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Roles Async
export const getRolesAsync = () => async (dispatch) => {
	await api
		.get(`/admin/role`, { withCredentials: true })
		.then((res) => {
			dispatch(getRoles(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Role Async
export const getRoleAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/role/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getRole(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Role Async
export const updateRoleAsync = (role) => async (dispatch) => {
	await api
		.put(`/admin/role/${role._id}`, role, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateRole(res.data));
			dispatch(
				createAlertAsync({
					message: 'Role Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default roleSlice.reducer;
