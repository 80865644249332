import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { blueGrey, orange } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
//import IconButton from '@mui/material/IconButton';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//import ProgramsTableRowItems from './ProgramsTableRowItems';

const tableCell = {
	color: blueGrey[50],

	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
	},
};

export default function ProgramsTableRow({ cells, program }) {
	//const [open, setOpen] = React.useState(false);

	// function renderProgramNumber(programNumber) {
	// 	programNumber = programNumber.toString();
	// 	while (programNumber.length < 6) programNumber = '0' + programNumber;

	// 	return 'SCRN' + programNumber;
	// }

	function renderCell(program, cellId) {
		if (cellId === 'Account') {
			return program.Account.Name;
		}

		if (cellId === 'Status') {
			return program.Status ? program.Status : 'N/A';
		}

		return program[cellId];

		//cell.id === 'Account' ? program.Account.Name : program[cell.id];
	}

	return (
		<>
			<TableRow
				hover
				//sx={{ color: program.IsActive ? 'inherit' : blueGrey[500] }}
			>
				<TableCell
					align='left'
					sx={tableCell}
				></TableCell>
				{cells.map((cell) => (
					<TableCell
						key={cell.id}
						align={cell.align}
						//sx={tableCell}
						style={{
							width: `calc(100% / ${cells.length})`,
							color: program.IsActive ? blueGrey[50] : orange[500],
						}}
					>
						{cell.id === 'Name' ? (
							<Link
								to={`/acquisitions/programs/${program._id}`}
								component={RouterLink}
								sx={{
									color: program.IsActive ? blueGrey[50] : orange[500],
									textDecoration: 'none',
									'&:hover': { color: blueGrey[100], fontWeight: 'bold' },
								}}
							>
								{program.Name}
							</Link>
						) : (
							`${renderCell(program, cell.id)}`
						)}
					</TableCell>
				))}
			</TableRow>
			{/* {program.ProgramItems && program.ProgramItems.length > 0 && (
				<ProgramsTableRowItems
					open={open}
					programItems={program.ProgramItems}
				/>
			)} */}
		</>
	);
}

ProgramsTableRow.propTypes = {
	cells: PropTypes.array.isRequired,
	program: PropTypes.object.isRequired,
};
