import * as React from 'react';
import {
	Tooltip,
	IconButton,
	Paper,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { AddCircle } from '@mui/icons-material';
import ScreenersTabRow from './screenersTab/ScreenersTabRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getScreener } from '../../../../redux/screenerSlice';

export default function ScreenersTab({ editingAccount, user }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [checked, setChecked] = React.useState(false);

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	let screenersToDisplay = editingAccount.Screeners;

	if (screenersToDisplay && checked) {
		screenersToDisplay = screenersToDisplay.filter(
			(screener) => screener.IsActive
		);
	}

	function handleNewScreener() {
		dispatch(
			getScreener({
				_id: 'new-screener',
				isSaved: false,
				savedId: null,
				IsActive: true,
				Owner: user,
				Account: editingAccount,
				Contact: editingAccount.Contacts[0]._id,
				ScreenerNumber: '',
				Name: '',
				Status: 'Draft',
				IsClosed: false,
				IsWon: false,
				Currency: editingAccount.Currency,
				Territory: editingAccount.Territory,
				RightsTree: editingAccount.RightsTree,
				DateSent: undefined,
				FollowUpDate: new Date(
					new Date().setDate(new Date().getDate() + 14)
				).setHours(0, 0, 0, 0),
				ScreenerItems: [],
			})
		);
		navigate('/sales/screeners/new-screener');
	}

	return (
		<>
			{editingAccount.Contacts.length > 0 && (
				<Grid
					container
					sx={{
						backgroundColor: blueGrey[50],
						marginBottom: 2,
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10,
						color: blueGrey[900],
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Grid
						item
						sx={{ flexGrow: 1, fontWeight: 'bold', color: blueGrey[700] }}
					></Grid>
					<Grid
						item
						sx={{ flexGrow: 0 }}
					>
						{editingAccount.IsActive && (
							<Tooltip
								title='Add New Screener'
								arrow={true}
							>
								<IconButton onClick={handleNewScreener}>
									<AddCircle
										sx={{
											color: blueGrey[600],
											backgroundColor: 'transparent',
											borderBottomRadius: 10,
											borderTopRadius: 10,
											fontSize: '1.5em',
										}}
									/>
								</IconButton>
							</Tooltip>
						)}
					</Grid>
				</Grid>
			)}

			<Grid
				//container
				sx={{ display: 'flex', marginTop: 4 }}
			>
				<Grid
					//item
					sx={{ flexGrow: 1 }}
				></Grid>
				<Grid
					//item
					sx={{ flexGrow: 0 }}
				>
					<Typography sx={{ display: 'inline' }}>Show All</Typography>
					<Switch
						checked={checked}
						onChange={handleChange}
						sx={{
							'& .Mui-checked': {
								color: `${blueGrey[800]} !important`,
							},
							'& .MuiSwitch-input': {
								color: blueGrey[800],
								backgroundColor: blueGrey[800],
							},
							'& .MuiSwitch-track': {
								color: blueGrey[800],
								backgroundColor: `${blueGrey[800]} !important`,
							},
						}}
					/>
					<Typography sx={{ display: 'inline' }}>Show Active</Typography>
				</Grid>
			</Grid>
			{screenersToDisplay && screenersToDisplay.length > 0 ? (
				<TableContainer
					component={Paper}
					sx={{ backgroundColor: blueGrey[50], boxShadow: 'none' }}
				>
					<Table aria-label='collapsible table'>
						<TableHead>
							<TableRow>
								<TableCell />
								<TableCell align='center'>Screener #</TableCell>
								<TableCell align='center'>Name</TableCell>
								<TableCell align='center'>Date Sent</TableCell>
								<TableCell align='center'># of Programs</TableCell>
								<TableCell align='center'>Status</TableCell>
								<TableCell align='center'>Owner</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{screenersToDisplay.map((screener) => (
								<ScreenersTabRow
									key={screener._id}
									screener={screener}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<div>No Screeners</div>
			)}
		</>
	);
}
