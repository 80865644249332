import React from 'react';
import { useSelector } from 'react-redux';
import { blueGrey } from '@mui/material/colors';
import { selectIsAuthenticated, selectUser } from '../redux/authSlice';
import Tasks from '../components/dashboard/Tasks';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

function Dashboard({ setParams }) {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const user = useSelector(selectUser);

	return (
		<Box
			style={{
				padding: 20,
				paddingTop: 30,
				textAlign: 'center',
				backgroundColor: blueGrey[500],
				color: 'white',
				height: 'calc(100vh - 140px)',
				borderRadius: '8px',
				overflowY: 'auto',
				marginBottom: 16,
			}}
		>
			<Grid
				container
				spacing={2}
				sx={{ height: `100%` }}
			>
				<Grid
					item
					xs={6}
				></Grid>
				<Grid
					item
					xs={6}
				>
					<Tasks
						isAuthenticated={isAuthenticated}
						user={user}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Dashboard;
