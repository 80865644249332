import {
	Button,
	Checkbox,
	FormControl,
	FormLabel,
	Grid,
	TextField,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { Box } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeEditingProgram } from '../../../../redux/programSlice';

export default function ProgramDetails2({
	newProgram,
	handleNext,
	handleBack,
}) {
	const dispatch = useDispatch();

	function handleChange(event) {
		let value;
		switch (event.target.name) {
			case 'CrossCollateralize':
			case 'PerformanceGuarantee':
			case 'DistributionAdvance':
				value = event.target.checked ? true : false;
				break;
			default:
				value = event.target.value;
				break;
		}

		if (event.target.name === 'VimeoLink') {
			if (value.indexOf('http://') !== 0) {
				value = 'http://' + value;
			}
		}

		dispatch(
			changeEditingProgram({
				id: newProgram._id,
				name: event.target.name,
				value: value,
			})
		);
	}

	function handleCalendarChange(e, name) {
		let date = null;

		if (e && e.$d) date = new Date(e.$d).toDateString();

		if (date === 'Invalid Date') {
			date = null;
		}

		console.log(date);

		dispatch(
			changeEditingProgram({
				id: newProgram._id,
				name: name,
				value: date,
			})
		);
	}

	function submit(e) {
		e.preventDefault();

		handleNext();
	}

	return (
		<Box
			component={'form'}
			onSubmit={submit}
		>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DesktopDatePicker
							color='secondary'
							key='DeliveryDate'
							name='DeliveryDate'
							label='Delivery Date'
							inputFormat='DD/MM/YYYY'
							value={newProgram.DeliveryDate}
							onChange={(e) => handleCalendarChange(e, 'DeliveryDate')}
							sx={textFields}
							InputProps={{
								sx: {
									'& .MuiSvgIcon-root': { color: blueGrey[50] },
								},
							}}
							PopperProps={{ sx: pickerStyles }}
							renderInput={(params) => (
								<TextField
									color='secondary'
									{...params}
									key='DeliveryDate'
									variant='outlined'
									label='Delivery Date'
									value={newProgram.DeliveryDate}
									name='DeliveryDate'
									sx={textFields}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						variant='outlined'
						label='Distribution Expense'
						value={newProgram.DistributionExpense}
						name='DistributionExpense'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFields}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						variant='outlined'
						label='LaunchBudget'
						value={newProgram.LaunchBudget}
						name='LaunchBudget'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						variant='outlined'
						label='Expenses Cap'
						value={newProgram.ExpensesCap}
						name='ExpensesCap'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFields}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
			>
				<Grid
					item
					sm={6}
				>
					<FormControl
						color='secondary'
						sx={textFields}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							alignContent: 'center',
							width: '80%',
							marginLeft: 20,
						}}
					>
						<Box>
							<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
								Distribution Advance
							</FormLabel>
							<Checkbox
								name='DistributionAdvance'
								checked={newProgram.DistributionAdvance}
								onChange={(e) => handleChange(e)}
								sx={{ display: 'inline-flex' }}
							/>
						</Box>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{newProgram.DistributionAdvance && (
						<TextField
							color='secondary'
							variant='outlined'
							label='Distribution Advance Value'
							value={newProgram.DistributionAdvanceValue}
							name='DistributionAdvanceValue'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={textFields}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
					sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
				>
					<FormControl
						color='secondary'
						sx={textFields}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							alignContent: 'center',
							width: '80%',
							marginLeft: 20,
						}}
					>
						<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
							Performance Guarantee
						</FormLabel>
						<Checkbox
							name='PerformanceGuarantee'
							checked={newProgram.PerformanceGuarantee}
							onChange={(e) => handleChange(e)}
							sx={{ display: 'inline-flex' }}
						/>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{newProgram.PerformanceGuarantee && (
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								key='PerformanceGuaranteeDate'
								name='PerformanceGuaranteeDate'
								label='Performance Guarantee Date'
								inputFormat='DD/MM/YYYY'
								value={newProgram.PerformanceGuaranteeDate}
								onChange={(e) =>
									handleCalendarChange(e, 'PerformanceGuaranteeDate')
								}
								//sx={pickerStyles}
								InputProps={{
									sx: { '& .MuiSvgIcon-root': { color: blueGrey[700] } },
								}}
								PopperProps={{ sx: pickerStyles }}
								renderInput={(params) => (
									<TextField
										color='secondary'
										{...params}
										key='PerformanceGuaranteeDate'
										required
										variant='outlined'
										label='Performance Guarantee Date'
										value={newProgram.PerformanceGuaranteeDate}
										name='PerformanceGuaranteeDate'
										sx={textFields}
									/>
								)}
							/>
						</LocalizationProvider>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				></Grid>
				<Grid
					item
					sm={6}
				>
					{newProgram.PerformanceGuarantee && (
						<TextField
							color='secondary'
							variant='outlined'
							label='Performance Guarantee Value'
							value={newProgram.PerformanceGuaranteeValue}
							name='PerformanceGuaranteeValue'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={textFields}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
					sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
				>
					<FormControl
						color='secondary'
						sx={textFields}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							alignContent: 'center',
							width: '80%',
							marginLeft: 20,
						}}
					>
						<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
							Cross Collateralise
						</FormLabel>
						<Checkbox
							name='CrossCollateralize'
							checked={newProgram.CrossCollateralize}
							onChange={(e) => handleChange(e)}
							sx={{ display: 'inline-flex' }}
						/>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{newProgram.CrossCollateralize && (
						<TextField
							color='secondary'
							variant='outlined'
							multiline
							label='Programs For Cross Collateralise'
							value={newProgram.ProgramsForCrossCollateralize}
							name='ProgramsForCrossCollateralize'
							onChange={(e) => handleChange(e)}
							sx={textFields}
							style={{ width: '88%' }}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={12}
				>
					<TextField
						color='secondary'
						variant='outlined'
						multiline
						label='Awards'
						value={newProgram.Awards}
						name='Awards'
						onChange={(e) => handleChange(e)}
						sx={textFields}
						style={{ width: '99%' }}
					/>
				</Grid>
			</Grid>
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					//disabled={activeStep === 0}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
						//display: `${activeStep === 0 ? 'none' : 'block'}`,
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
}

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-root.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
		color: orange[500],
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

export const textFieldsWithEdit = {
	minWidth: '85%',
	textAlign: 'left',
	color: blueGrey[50],
	backgroundColor: blueGrey[700],
	marginTop: 2,
	marginBottom: 2,
	pointer: 'none !important',
	'& .MuiInput-input': {
		color: blueGrey[50],
		//cursor: 'pointer !important',
		//cursor: 'none !important',
	},
	'& .MuiInput-root': {
		color: blueGrey[50],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiInputRoot:after': {
		borderColor: blueGrey[900],
	},
	'& .MuiDisabled:': {
		color: blueGrey[50],
		opacity: '1 !important',
	},
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: blueGrey[50],
		//opacity: '1 !important',
		//cursor: 'none !important',
	},
};
