import {
	AddCircleRounded,
	DeleteForeverRounded,
	EditSharp,
} from '@mui/icons-material';
import {
	Button,
	Checkbox,
	Chip,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Tooltip,
} from '@mui/material';
import { blueGrey, orange, red } from '@mui/material/colors';
import { Box } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLicensedMedias } from '../../../../redux/licensedmediaSlice';
import {
	addEditingProgramRights,
	changeEditingProgramRights,
	changeEditingTerritory,
	deleteRightsIn,
} from '../../../../redux/programSlice';
import RightsModal from '../../../shared/rightsModal/RightsModal';
import rightsTree from '../../../shared/territories.json';

export default function RightsIn({ newProgram, handleNext, handleBack }) {
	const dispatch = useDispatch();
	const licensedmedias = useSelector(selectLicensedMedias);
	const [rights, setRights] = useState(null);
	const [rightsModal, setRightsModal] = useState(false);

	function handleRightsModal(rights) {
		setRights(rights);
		setRightsModal(!rightsModal);
	}

	function handleChange(e, id) {
		let value = e.target.value;

		if (e.target.name === 'IsEndDate' || e.target.name === 'Exclusive') {
			value = e.target.checked ? true : false;
		}

		dispatch(
			changeEditingProgramRights({
				rights: true,
				program: newProgram._id,
				id: id,
				name: e.target.name,
				value: value,
			})
		);
	}

	function handleArrayChange(e, id) {
		let newValue =
			typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');
		dispatch(
			changeEditingProgramRights({
				rightsIn: true,
				program: newProgram._id,
				id: id,
				name: e.target.name,
				value: correctedValue,
			})
		);
	}

	function handleCalendarChange(e, name, id) {
		let newDate = new Date(e.$d);

		dispatch(
			changeEditingProgramRights({
				rights: true,
				program: newProgram._id,
				id: id,
				name: name,
				value: newDate.toDateString(),
			})
		);
	}

	function addRights() {
		const newRights = {
			_id: `new-rights-${crypto.randomUUID()}`,
			IsActive: true,
			Program: newProgram._id,
			StartDate: '',
			IsEndDate: false,
			EndDate: '',
			Exclusive: false,
			Holdback: '',
			HoldbackEndDate: '',
			ContinueRollover: false,
			RightsIn: true,
			Territory: '',
			RightsTree: rightsTree,
			LicensedMedia: [],
		};

		dispatch(
			addEditingProgramRights({ program: newProgram._id, rights: newRights })
		);
	}

	function removeRights(deleteRightsId) {
		dispatch(deleteRightsIn({ id: deleteRightsId, program: newProgram._id }));
	}

	function submit(e) {
		e.preventDefault();
		handleNext();
	}

	return (
		<Box
			component={'form'}
			onSubmit={submit}
		>
			<Grid container>
				<Grid
					item
					sx={{ flexGrow: 1 }}
				></Grid>
				<Grid item>
					<Tooltip title={'Add Rights'}>
						<span>
							<IconButton
								onClick={addRights}
								//sx={{ marginRight: 2 }}
							>
								<AddCircleRounded
									sx={{
										color: blueGrey[50],
										fontSize: '1.5em',
										cursor: 'pointer',
									}}
								/>
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
			{newProgram.RightsIn.map((rights, index) => (
				<Box
					key={rights._id}
					sx={{
						backgroundColor: blueGrey[600],
						borderRadius: 2,
						padding: 2,
						marginBottom: 4,
					}}
				>
					<Grid container>
						<Grid
							item
							sx={{ flexGrow: 1, marginBottom: 4 }}
						>
							<Box
								sx={{
									backgroundColor: orange[700],
									color: blueGrey[50],
									borderRadius: 48,
									width: 24,
									height: 24,
								}}
							>
								{index + 1}
							</Box>
						</Grid>
						<Grid item>
							{index > 0 && (
								<Tooltip title={'Remove Rights'}>
									<IconButton onClick={() => removeRights(rights._id)}>
										<DeleteForeverRounded
											sx={{
												color: blueGrey[50],
												fontSize: '1.5em',
												cursor: 'pointer',
											}}
										/>
									</IconButton>
								</Tooltip>
							)}
						</Grid>
					</Grid>
					<Grid container>
						<Grid
							item
							sm={6}
							sx={{
								margin: '0 auto',
							}}
						>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DesktopDatePicker
									color='secondary'
									key='StartDate'
									name='StartDate'
									label='Start Date'
									inputFormat='DD/MM/YYYY'
									value={rights.StartDate}
									onChange={(e) =>
										handleCalendarChange(e, 'StartDate', rights._id)
									}
									//sx={textFields}
									InputProps={{
										sx: {
											//width: '97.5%',
											'& .MuiSvgIcon-root': { color: blueGrey[50] },
										},
									}}
									PopperProps={{ sx: pickerStyles }}
									renderInput={(params) => (
										<TextField
											color='secondary'
											{...params}
											key='StartDate'
											required
											variant='outlined'
											label='Start Date'
											value={rights.StartDate}
											name='StartDate'
											sx={textFields}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid
							item
							sm={6}
						></Grid>
					</Grid>
					<Grid
						container
						sx={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Grid
							item
							sm={6}
							sx={{ height: 56, minHeight: 56, marginTop: 2, marginBottom: 2 }}
						>
							<FormControl
								color='secondary'
								sx={textFields}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									alignContent: 'center',
									width: '80%',
									margin: '0 auto',
								}}
							>
								<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
									End Date?
								</FormLabel>
								<Checkbox
									name='IsEndDate'
									checked={rights.IsEndDate}
									onChange={(e) => handleChange(e, rights._id)}
									sx={{ display: 'inline-flex' }}
								/>
							</FormControl>
						</Grid>
						<Grid
							item
							sm={6}
						>
							{rights.IsEndDate && (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										color='secondary'
										key='EndDate'
										name='EndDate'
										label='End Date'
										inputFormat='DD/MM/YYYY'
										value={rights.EndDate}
										onChange={(e) =>
											handleCalendarChange(e, 'EndDate', rights._id)
										}
										sx={textFields}
										InputProps={{
											sx: {
												'& .MuiSvgIcon-root': { color: blueGrey[50] },
											},
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												color='secondary'
												{...params}
												key='EndDate'
												required
												variant='outlined'
												label='End Date'
												value={rights.EndDate}
												name='EndDate'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							)}
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							width: '100%',
						}}
					>
						<Grid
							item
							sm={6}
						>
							<FormControl
								onClick={() => handleRightsModal(rights)}
								sx={textFields}
								style={{
									width: '80%',
									minHeight: '100%',
									height: '100%',
								}}
							>
								<InputLabel>Territory</InputLabel>
								<Select
									name='Territory'
									multiple
									multiline
									disabled
									required
									rows={6}
									IconComponent={EditSharp}
									value={
										rights.Territory &&
										rights.Territory.split(',').filter((el) => el !== '  ')
									}
									input={
										<OutlinedInput
											label='Territory'
											color='secondary'
										>
											Territory
										</OutlinedInput>
									}
									renderValue={(selected) => (
										<Box
											sx={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: 2,
											}}
										>
											{selected.map((value) => (
												<Chip
													key={value}
													label={value}
													sx={{
														backgroundColor: orange[800],
														color: 'white',
														WebkitTextFillColor: 'white',
													}}
												/>
											))}
										</Box>
									)}
									style={{ minHeight: '100%', height: '100%' }}
								/>
							</FormControl>
						</Grid>
						<Grid
							item
							sm={6}
						>
							<FormControl
								sx={textFields}
								style={{
									width: '80%',
									minHeight: '100%',
									height: '100%',
								}}
							>
								<InputLabel>Licensed Media</InputLabel>
								<Select
									name='LicensedMedia'
									multiple
									multiline
									rows={6}
									required
									value={rights.LicensedMedia}
									onChange={(e) => handleArrayChange(e, rights._id)}
									input={
										<OutlinedInput
											label='Licensed Media'
											color='secondary'
										>
											Licensed Media
										</OutlinedInput>
									}
									renderValue={(selected) => (
										<Box
											sx={textFields}
											style={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: 16,
											}}
										>
											{selected.map((value) => (
												<Chip
													key={value}
													label={value}
													sx={{
														backgroundColor: orange[800],
														color: 'white',
														WebkitTextFillColor: 'white',
													}}
												/>
											))}
										</Box>
									)}
									//sx={textFields}
									style={{ minHeight: '100%', height: '100%' }}
									// MenuProps={{
									// 	PaperProps: {
									// 		style: {
									// 			width: '20%',
									// 			maxWidth: '20%',
									// 			marginTop: 36,
									// 		},
									// 	},
									// }}
								>
									{licensedmedias.map((media) => (
										<MenuItem
											key={media._id}
											value={media.Name}
										>
											<Checkbox
												checked={rights.LicensedMedia.indexOf(media.Name) > -1}
											/>
											<ListItemText primary={media.Name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Grid
							item
							sm={6}
							sx={{ height: 56, minHeight: 56, marginTop: 8 }}
						>
							<FormControl
								color='secondary'
								sx={textFields}
								style={{
									//display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									marginTop: 0,
									//marginBottom: 34,
									//width: '80%',
								}}
							>
								<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
									Exclusive
								</FormLabel>
								<Checkbox
									name='Exclusive'
									checked={rights.Exclusive}
									onChange={(e) => handleChange(e, rights._id)}
									sx={{ display: 'inline-flex' }}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							width: '100%',
							margin: '0 auto',
							marginTop: 4,
							marginBottom: 4,
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Grid
							item
							sm={6}
							sx={{
								width: '100%',
								textAlign: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							<TextField
								color='secondary'
								variant='outlined'
								label='Holdback'
								value={rights.Holdback}
								name='Holdback'
								onChange={(e) => handleChange(e, rights._id)}
								sx={textFields}
								//style={{ width: '95%' }}
							/>
						</Grid>
						<Grid
							item
							sm={6}
							sx={{
								width: '100%',
								textAlign: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							{rights.Holdback.length > 0 && (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										color='secondary'
										name='HoldbackEndDate'
										label='Holdback End Date'
										inputFormat='DD/MM/YYYY'
										value={rights.HoldbackEndDate}
										onChange={(e) =>
											handleCalendarChange(e, 'HoldbackEndDate', rights._id)
										}
										sx={textFields}
										InputProps={{
											sx: {
												'& .MuiSvgIcon-root': { color: blueGrey[50] },
											},
										}}
										PopperProps={{ sx: pickerStyles }}
										renderInput={(params) => (
											<TextField
												color='secondary'
												{...params}
												required
												variant='outlined'
												label='Holdback End Date'
												value={rights.HoldbackEndDate}
												name='HoldbackEndDate'
												sx={textFields}
											/>
										)}
									/>
								</LocalizationProvider>
							)}
						</Grid>
					</Grid>
				</Box>
			))}

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					//disabled={activeStep === 0}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
						//display: `${activeStep === 0 ? 'none' : 'block'}`,
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box>
			{rightsModal && (
				<RightsModal
					id={rights._id}
					program={newProgram._id}
					rightsIn={true}
					rightsTree={rights.RightsTree}
					rightsModal={rightsModal}
					handleRightsModal={handleRightsModal}
					changeEditingTerritory={changeEditingTerritory}
				/>
			)}
		</Box>
	);
}

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused.Mui-error': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${red[800]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-root.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
		color: orange[500],
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiCheckbox-root': {
		color: blueGrey[50],
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

export const textFieldsWithEdit = {
	minWidth: '85%',
	textAlign: 'left',
	color: blueGrey[50],
	backgroundColor: blueGrey[700],
	marginTop: 2,
	marginBottom: 2,
	pointer: 'none !important',
	'& .MuiInput-input': {
		color: blueGrey[50],
		//cursor: 'pointer !important',
		//cursor: 'none !important',
	},
	'& .MuiInput-root': {
		color: blueGrey[50],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiInputRoot:after': {
		borderColor: blueGrey[900],
	},
	'& .MuiDisabled:': {
		color: blueGrey[50],
		opacity: '1 !important',
	},
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: blueGrey[50],
		//opacity: '1 !important',
		//cursor: 'none !important',
	},
};
