import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { blueGrey } from '@mui/material/colors';
import { Checkbox } from '@mui/material';

const styles = {
	color: blueGrey[50],
	borderBottom: 'none',
	'& .MuiSvgIcon-root': { color: blueGrey[50] },
};

export default function ScreenerItemsRow({
	screenerItem,
	selectedItems,
	setSelectedItems,
}) {
	function onChange(e) {
		if (e.target.checked) {
			setSelectedItems([...selectedItems, e.target.name]);
		} else {
			setSelectedItems(selectedItems.filter((x) => x !== e.target.name));
		}
	}

	return (
		<>
			<TableRow
				sx={{ borderBottom: 'none', '& > *': { borderBottom: 'none' } }}
			>
				<TableCell sx={styles}>
					<Checkbox
						name={screenerItem._id}
						color='primary'
						checked={selectedItems.includes(screenerItem._id)}
						onChange={onChange}
					/>
				</TableCell>
				<TableCell
					align='center'
					sx={styles}
				>
					{screenerItem.Name}
				</TableCell>
				<TableCell
					align='center'
					sx={styles}
				>
					{screenerItem.Program && screenerItem.Program.Name}
				</TableCell>
				<TableCell
					align='center'
					sx={styles}
				>
					{screenerItem.Status}
				</TableCell>
			</TableRow>
		</>
	);
}
