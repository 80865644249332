import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Modal,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeEditingAccount,
	deleteAccountAsync,
	getAccountAsync,
	getAccountsAsync,
	selectEditingAccount,
	selectOriginalAccount,
} from '../../../redux/accountSlice';
import { selectUser } from '../../../redux/authSlice';
import { selectUsers } from '../../../redux/userSlice';
import { getAccountTabs } from './accountTabs/accountTabs';
import {
	updateTertiaryTab,
	updateTabStatus,
	closeTab,
	selectTabs,
} from '../../../redux/tabsSlice';
import { blueGrey, orange } from '@mui/material/colors';
import _ from 'lodash';
import Notes from '../Notes';
import TabSelector from './accountTabs/TabSelector';
import { useNavigate } from 'react-router-dom';
export default function AccountDetails({ id, type, currentTab }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tabs = useSelector(selectTabs);
	const menuTabs = getAccountTabs(type);
	const user = useSelector(selectUser);
	const users = useSelector(selectUsers);
	const originalAccount = useSelector(selectOriginalAccount(id));
	const editingAccount = useSelector(selectEditingAccount(id));
	const [deleteAccountModal, setDeleteAccountModal] = useState(false);

	const accounts = useSelector((state) => {
		if (type === 'Buyer') {
			return state.accounts.buyers;
		}
		if (type === 'Producer') {
			return state.accounts.producers;
		}
		if (type === 'Other') {
			return state.accounts.others;
		}
		return null;
	});

	useEffect(() => {
		if (!accounts) {
			dispatch(getAccountsAsync(type));
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!editingAccount) {
			dispatch(getAccountAsync(id));
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (_.isEqual(originalAccount, editingAccount)) {
			dispatch(updateTabStatus({ id: id, status: true }));
		} else {
			dispatch(updateTabStatus({ id: id, status: false }));
		}
	}, [id, editingAccount, originalAccount, dispatch]);

	const tertiaryTabChange = (event, newValue) => {
		dispatch(updateTertiaryTab({ id: id, newValue: newValue }));
	};

	const handleChange = (event) => {
		if (!event.target) {
			dispatch(
				changeEditingAccount({
					id: id,
					name: 'Phone',
					value: event,
				})
			);
		} else {
			let string = event.target.value;

			if (event.target.name === 'Website') {
				if (string.indexOf('http://') !== 0) {
					string = 'http://' + string;
				}
			}

			dispatch(
				changeEditingAccount({
					id: id,
					name: event.target.name,
					value: string,
				})
			);
		}
	};

	async function deleteAccount() {
		dispatch(deleteAccountAsync(id, type)).then(() => {
			const tabIndex = tabs.findIndex((tab) => tab.id === currentTab.id);

			if (tabIndex !== -1) {
				navigate(tabs[tabIndex - 1].link);

				setTimeout(() => {
					dispatch(closeTab(id));
				}, 100);
			} else {
				navigate('/');

				setTimeout(() => {
					dispatch(closeTab(id));
				}, 100);
			}
		});
	}

	return (
		<>
			{editingAccount ? (
				<Box
					sx={{
						width: '100%',
						padding: 2,
						height: '90vh',
						overflowY: 'auto',
					}}
				>
					<Box style={{ display: 'flex' }}>
						<Box
							style={{
								flexGrow: 1,
							}}
						>
							<Typography
								style={{
									flexGrow: 1,
									textAlign: 'left',
									marginLeft: 16,
									fontSize: '1.5em',
								}}
							>
								{currentTab.type[0].toUpperCase()}
								{currentTab.type.slice(1)} -{' '}
								{editingAccount &&
									`${editingAccount.AccountNumber} - ${editingAccount.Name}`}
							</Typography>
						</Box>
						{/* <Box sx={{ color: 'inherit', marginRight: 4 }}>
							<Tooltip title='Delete Account'>
								<IconButton
									onClick={() => setDeleteAccountModal(true)}
									sx={{ border: 'none', color: 'inerit' }}
								>
									<DeleteForever
										sx={{
											color: blueGrey[50],
											fontSize: '1.5em',
											cursor: 'pointer',
										}}
									/>
								</IconButton>
							</Tooltip>
						</Box> */}
					</Box>
					<Tabs
						value={currentTab.tertiary || 0}
						onChange={tertiaryTabChange}
						variant='scrollable'
						scrollButtons={true}
						allowScrollButtonsMobile
						sx={{
							'& .MuiTabs-indicator': { backgroundColor: blueGrey[50] },
							'& .MuiTab-root': {
								margin: 0,
								color: blueGrey[50],
								backgroundColor: blueGrey[800],
								marginLeft: '1em',
								borderTopLeftRadius: '4px',
								borderTopRightRadius: '4px',
								paddingTop: '.5em',
								paddingBottom: '.5em',
								minHeight: '0px',
								top: '16px',
								opacity: 0.75,
								boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
								'&:hover': {
									//backgroundColor: 'white',
									//color: 'black',
									opacity: 0.75,
								},
							},
							'& .Mui-selected': {
								color: `${blueGrey[800]}`,
								backgroundColor: blueGrey[50],
								opacity: '1 !important',
								boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
							},
							'& .MuiTabs-scrollButtons': {
								color: 'white',
								'&.Mui-disabled': { opacity: 0, color: 'white' },
							},
						}}
					>
						{menuTabs.length > 0 &&
							menuTabs.map((tab) => {
								switch (tab.name) {
									case 'Company':
										return (
											<Tab
												key={tab.tab}
												label={tab.name}
												sx={companyTabColor(originalAccount, editingAccount)}
											/>
										);
									case 'Contacts':
										return (
											<Tab
												key={tab.tab}
												label={tab.name}
												sx={contactTabColor(originalAccount, editingAccount)}
											/>
										);
									default:
										return (
											<Tab
												key={tab.tab}
												label={tab.name}
											/>
										);
								}
							})}
					</Tabs>
					{menuTabs.length > 0 &&
						menuTabs.map((tab) => (
							<div
								key={tab.tab}
								role='tabpanel'
								hidden={currentTab.tertiary !== tab.tab}
								style={{
									backgroundColor: blueGrey[50],
									padding: 16,
									paddingTop: 4,
									margin: 0,
									borderRadius: 5,
									color: blueGrey[900],
								}}
							>
								<TabSelector
									tab={tab}
									currentTab={currentTab}
									accounts={accounts}
									user={user}
									users={users}
									originalAccount={originalAccount}
									editingAccount={editingAccount}
									handleChange={handleChange}
									renderAccountName={renderAccountName}
								/>
							</div>
						))}
					<hr style={{ color: blueGrey[50], marginTop: 8, marginBottom: 8 }} />
					<Box
						sx={{
							backgroundColor: blueGrey[50],
							borderRadius: 2,
							color: blueGrey[900],
						}}
					>
						<Notes
							id={id}
							type={'Account'}
							notes={editingAccount && editingAccount.Notes}
						/>
					</Box>
					<Modal
						open={deleteAccountModal}
						onClose={() => setDeleteAccountModal(false)}
						aria-labelledby='modal-modal-title'
						aria-describedby='modal-modal-description'
					>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '50%',
								bgcolor: 'background.paper',
								border: '2px solid #000',
								boxShadow: 24,
								p: 4,
								textAlign: 'center',
							}}
						>
							<Typography
								id='modal-modal-title'
								variant='h6'
								component='h2'
								sx={{ marginTop: 8 }}
							>
								Are you sure you want to delete this account?
							</Typography>
							<Grid
								container
								sx={{ marginTop: 8, marginBottom: 8 }}
							>
								<Grid
									item
									sm={6}
									sx={{
										paddingTop: 2,
										paddingBottom: 2,
										paddingLeft: 4,
									}}
								>
									<Button
										onClick={() => setDeleteAccountModal(false)}
										sx={{
											color: blueGrey[50],
											backgroundColor: blueGrey[600],
											'&:hover': {
												backgroundColor: blueGrey[500],
											},
										}}
									>
										Cancel
									</Button>
								</Grid>
								<Grid
									item
									sm={6}
									sx={{
										paddingTop: 2,
										paddingBottom: 2,
										paddingRight: 4,
									}}
								>
									<Button
										onClick={deleteAccount}
										sx={{
											color: blueGrey[50],
											backgroundColor: blueGrey[600],
											'&:hover': {
												backgroundColor: orange[500],
											},
										}}
									>
										Confirm
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Modal>
				</Box>
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '70vh',
						top: '30%',
					}}
				>
					<CircularProgress
						size={60}
						color='primary'
						sx={{
							color: `${blueGrey[100]} !important`,
						}}
					/>
				</div>
			)}
		</>
	);
}

function renderAccountName(editingAccount) {
	let number = editingAccount.AccountNumber.toString();

	while (number.length < 6) number = '0' + number;

	if (editingAccount.Type === 'Buyer') {
		if (editingAccount.Children && editingAccount.Children.length > 0) {
			number = 1 + number;
		} else if (editingAccount.Parent) {
			number = 2 + number;
		}
	}

	if (editingAccount.Type === 'Producer') {
		number = 3 + number;
	}

	if (editingAccount.Type === 'Other') {
		number = 4 + number;
	}

	return `${number} - ${editingAccount.Name}`;
}

function companyTabColor(originalAccount, editingAccount) {
	if (
		_.isEqual(originalAccount.Name, editingAccount.Name) &&
		_.isEqual(originalAccount.Phone, editingAccount.Phone) &&
		_.isEqual(originalAccount.Currency, editingAccount.Currency) &&
		_.isEqual(originalAccount.Owner, editingAccount.Owner) &&
		_.isEqual(originalAccount.Parent, editingAccount.Parent) &&
		_.isEqual(originalAccount.Website, editingAccount.Website) &&
		_.isEqual(originalAccount.Territory, editingAccount.Territory)
	) {
		return {
			color: blueGrey[50],
			'& .Mui-selected': {
				color: `${blueGrey[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	} else {
		return {
			color: `${orange[800]} !important`,
			'& .Mui-selected': {
				color: `${orange[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	}
}

function contactTabColor(originalAccount, editingAccount) {
	if (_.isEqual(originalAccount.Contacts, editingAccount.Contacts)) {
		return {
			color: blueGrey[50],
			'& .Mui-selected': {
				color: `${blueGrey[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	} else {
		return {
			color: `${orange[800]} !important`,
			'& .Mui-selected': {
				color: `${orange[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	}
}
