import React from 'react';
import Box from '@mui/material/Box';
//import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading() {
	return (
		<Box
			sx={{
				paddingTop: '20%',
				width: '90%',
				textAlign: 'center',
				margin: '0 auto',
			}}
		>
			{/* <LinearProgress

			/> */}
			<CircularProgress sx={{ width: '25%', height: '25%' }} />
		</Box>
	);
}
