import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';
import { addTab, updateTabTitle } from './tabsSlice';

const initialState = {
	programs: null,
	original: [],
	editing: [],
	programsTableSettings: {
		page: 1,
		pageSize: 25,
		searchTerm: '',
		showActive: true,
		searchEvery: false,
		showAll: true,
		sortBy: 'Name',
		sortOrder: 'asc',
	},
};

export const programSlice = createSlice({
	name: 'programs',
	initialState,
	reducers: {
		setSaving: (state, action) => {
			let index = state.editing.findIndex((x) => x._id === 'new-program');

			if (index !== -1) {
				state.editing[index] = {
					...state.editing[index],
					saving: false,
				};
			}
		},
		setSaved: (state, action) => {
			let index = state.editing.findIndex((x) => x._id === 'new-program');
			console.log('boobs');
			if (index !== -1) {
				console.log('boobs');
				state.editing[index] = {
					...state.editing[index],
					saving: false,
					saved: true,
					newId: action.payload,
				};
			}
		},
		getPrograms: (state, action) => {
			state.programs = action.payload;
		},
		getProgram: (state, action) => {
			//Populate Original Program
			if (state.original.length > 0) {
				let indexOfOriginal = state.original.findIndex(
					(program) => program._id === action.payload._id
				);

				if (indexOfOriginal !== -1) {
					state.original[indexOfOriginal] = action.payload;
				} else {
					state.original.push(action.payload);
				}
			} else {
				state.original.push(action.payload);
			}

			//Populate Editing Program
			if (state.editing.length > 0) {
				let indexOfEditing = state.editing.findIndex(
					(edit) => edit._id === action.payload._id
				);

				if (indexOfEditing !== -1) {
					state.editing[indexOfEditing] = action.payload;
				} else {
					state.editing.push(action.payload);
				}
			} else {
				state.editing.push(action.payload);
			}

			//Populate Programs List
			if (state.programs) {
				let indexOfPrograms = state.programs.findIndex(
					(program) => program._id === action.payload._id
				);

				if (indexOfPrograms !== -1) {
					state.programs[indexOfPrograms] = action.payload;
				} else {
					state.programs.push({
						_id: action.payload._id,
						Name: action.payload.Name,
						Genre: action.payload.Genre,
						Format: action.payload.Format,
						Definition: action.payload.Definition,
						YearOfProduction: action.payload.YearOfProduction,
						Territory: action.payload.Territory,
					});
				}
			}
		},
		addEditingProgram: (state, action) => {
			state.editing.push(action.payload);
		},
		changeEditingProgram: (state, action) => {
			let editingProgramIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (editingProgramIndex !== -1) {
				if (action.payload.name === 'Phone') {
					state.editing[editingProgramIndex] = {
						...state.editing[editingProgramIndex],
						Phone: action.payload.value,
					};
				} else {
					state.editing[editingProgramIndex] = {
						...state.editing[editingProgramIndex],
						[action.payload.name]: action.payload.value,
					};
				}
			}
		},
		addProgramItem: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Program
			);

			if (programIndex !== -1) {
				state.editing[programIndex].ProgramItems.push(action.payload);
			}
		},
		editProgramItem: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Program
			);

			if (programIndex !== -1) {
				let itemIndex = state.editing[programIndex].ProgramItems.findIndex(
					(x) => x._id === action.payload._id
				);

				if (itemIndex !== -1) {
					state.editing[programIndex].ProgramItems[itemIndex] = action.payload;
				}
			}
		},
		deleteProgramItem: (state, action) => {
			let originalProgramIndex = state.original.findIndex(
				(x) => x._id === action.payload.Program
			);

			if (originalProgramIndex !== -1) {
				state.editing[originalProgramIndex].ProgramItems = state.editing[
					originalProgramIndex
				].ProgramItems.filter((x) => x._id !== action.payload._id);
			}

			let editingProgramIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Program
			);

			if (editingProgramIndex !== -1) {
				state.editing[editingProgramIndex].ProgramItems = state.editing[
					editingProgramIndex
				].ProgramItems.filter((x) => x._id !== action.payload._id);
			}
		},
		addEditingProgramRights: (state, action) => {
			console.log(action.payload);
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			state.editing[programIndex].RightsIn.push(action.payload.rights);
		},
		changeEditingProgramRights: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (programIndex !== -1) {
				let rightsIndex = state.editing[programIndex].RightsIn.findIndex(
					(x) => x._id === action.payload.id
				);

				if (rightsIndex !== -1) {
					if (
						action.payload.name === 'IsEndDate' &&
						action.payload.value === false
					) {
						state.editing[programIndex].RightsIn[rightsIndex] = {
							...state.editing[programIndex].RightsIn[rightsIndex],
							IsEndDate: action.payload.value,
							EndDate: null,
						};
					} else if (
						action.payload.name === 'Holdback' &&
						action.payload.value === false
					) {
						state.editing[programIndex].RightsIn[rightsIndex] = {
							...state.editing[programIndex].RightsIn[rightsIndex],
							Holdback: action.payload.value,
							HoldbackEndDate: null,
						};
					} else {
						state.editing[programIndex].RightsIn[rightsIndex] = {
							...state.editing[programIndex].RightsIn[rightsIndex],
							[action.payload.name]: action.payload.value,
						};
					}
				}
			}
		},
		deleteRightsIn: (state, action) => {
			let originalProgramIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (originalProgramIndex !== -1) {
				state.editing[originalProgramIndex].RightsIn = state.editing[
					originalProgramIndex
				].RightsIn.filter((rights) => rights._id !== action.payload.id);
			}

			let editingProgramIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (editingProgramIndex !== -1) {
				state.editing[editingProgramIndex].RightsIn = state.editing[
					editingProgramIndex
				].RightsIn.filter((rights) => rights._id !== action.payload.id);
			}
		},
		changeEditingTerritory: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (programIndex !== -1) {
				let rightsIndex = state.editing[programIndex].RightsIn.findIndex(
					(x) => x._id === action.payload.id
				);

				if (rightsIndex !== -1) {
					state.editing[programIndex].RightsIn[rightsIndex].Territory =
						action.payload.territory;
					state.editing[programIndex].RightsIn[rightsIndex].Exclusions =
						action.payload.exclusions;
					state.editing[programIndex].RightsIn[rightsIndex].RightsTree =
						action.payload.rights;
				}
			}
		},
		changeEditingProgramLicensedMedia: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			let licensedMedia = state.editing[programIndex].LicensedMedia;

			state.editing[programIndex].LicensedMedia = {
				...licensedMedia,
				[action.payload.name]: action.payload.value,
			};
		},
		changeCrossCollateralizePrograms: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (programIndex !== -1) {
				if (state.editing[programIndex].CrossCollateralizePrograms) {
					state.editing[programIndex].CrossCollateralizePrograms.push({
						_id: action.payload.program._id,
						Name: action.payload.program.Name,
					});
				} else {
					state.editing[programIndex].CrossCollateralizePrograms = [
						{
							_id: action.payload.program._id,
							Name: action.payload.program.Name,
						},
					];
				}
			}
		},
		deleteCrossCollateralizePrograms: (state, action) => {
			console.log(action.payload);
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);
			console.log(programIndex);
			if (programIndex !== -1) {
				state.editing[programIndex].CrossCollateralizePrograms = state.editing[
					programIndex
				].CrossCollateralizePrograms.filter(
					(program) => program._id !== action.payload.program
				);
			}
		},
		changeProgramsTableSettings: (state, action) => {
			if (
				action.payload.name === 'searchTerm' ||
				action.payload.name === 'pageSize' ||
				action.payload.name === 'page'
			) {
				state.programsTableSettings = {
					...state.programsTableSettings,
					[action.payload.name]: action.payload.value,
				};
			} else if (action.payload.name === 'sortBy') {
				state.programsTableSettings = {
					...state.programsTableSettings,
					[action.payload.name]: action.payload.value,
					sortBy: action.payload.sortBy,
					sortOrder: action.payload.sortOrder,
				};
			} else {
				state.programsTableSettings = {
					...state.programsTableSettings,
					[action.payload.name]: action.payload.checked,
				};
			}
		},
		addProgramCommission: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (programIndex !== -1) {
				state.editing[programIndex].Commissions.push(action.payload.commission);
			}
		},
		editProgramCommission: (state, action) => {
			let programIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (programIndex !== -1) {
				let commissionIndex = state.editing[programIndex].Commissions.findIndex(
					(x) => x._id === action.payload.id
				);

				if (commissionIndex !== -1) {
					state.editing[programIndex].Commissions[commissionIndex] = {
						...state.editing[programIndex].Commissions[commissionIndex],
						[action.payload.name]: action.payload.value,
					};
				}
			}
		},
		removeProgramCommission: (state, action) => {
			let originalProgramIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (originalProgramIndex !== -1) {
				state.editing[originalProgramIndex].Commissions = state.editing[
					originalProgramIndex
				].Commissions.filter(
					(rights) => rights._id !== action.payload.commission
				);
			}

			let editingProgramIndex = state.editing.findIndex(
				(x) => x._id === action.payload.program
			);

			if (editingProgramIndex !== -1) {
				state.editing[editingProgramIndex].Commissions = state.editing[
					editingProgramIndex
				].Commissions.filter(
					(rights) => rights._id !== action.payload.commission
				);
			}
		},
		addProgramNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (originalIndex !== -1) {
				state.original[originalIndex].Notes.unshift(action.payload);
			}

			let editingIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (editingIndex !== -1) {
				state.editing[editingIndex].Notes.unshift(action.payload);
			}
		},
		updateProgramNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (originalIndex !== -1) {
				let orginalNoteIndex = state.original[originalIndex].Notes.findIndex(
					(x) => x._id === action.payload._id
				);

				if (orginalNoteIndex !== -1) {
					state.original[originalIndex].Notes[orginalNoteIndex] =
						action.payload;
				}
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (editingAccountIndex !== -1) {
				let editingNoteIndex = state.editing[
					editingAccountIndex
				].Notes.findIndex((x) => x._id === action.payload._id);

				if (editingNoteIndex !== -1) {
					state.editing[editingAccountIndex].Notes[editingNoteIndex] =
						action.payload;
				}
			}
		},
		deleteProgramNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.id
			);

			if (originalIndex !== -1) {
				state.original[originalIndex].Notes = state.original[
					originalIndex
				].Notes.filter((note) => note._id !== action.payload.noteId);
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (editingAccountIndex !== -1) {
				state.editing[editingAccountIndex].Notes = state.editing[
					editingAccountIndex
				].Notes.filter((note) => note._id !== action.payload.noteId);
			}
		},
		deleteProgram: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload
			);

			if (originalIndex !== -1) {
				state.original[originalIndex] = {
					...state.original[originalIndex],
					IsActive: false,
				};
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload
			);

			if (editingAccountIndex !== -1) {
				state.editing[originalIndex] = {
					...state.editing[originalIndex],
					IsActive: false,
				};
			}

			state.programs = state.programs.filter((x) => x._id !== action.payload);
		},
		removeProgram: (state, action) => {
			state.original = state.original.filter((x) => x._id !== action.payload);
			state.editing = state.editing.filter((x) => x._id !== action.payload);
		},
		removePrograms: (state, action) => {
			state.programs = null;
		},
		resetPrograms: () => initialState,
	},
});

export const {
	setSaving,
	setSaved,
	getPrograms,
	getProgram,
	changeProgramsTableSettings,
	addEditingProgram,
	changeEditingProgram,
	addProgramItem,
	editProgramItem,
	deleteProgramItem,
	addEditingProgramRights,
	changeEditingProgramRights,
	deleteRightsIn,
	changeEditingProgramLicensedMedia,
	changeCrossCollateralizePrograms,
	deleteCrossCollateralizePrograms,
	changeEditingTerritory,
	addProgramCommission,
	editProgramCommission,
	removeProgramCommission,
	addProgramNote,
	updateProgramNote,
	deleteProgramNote,
	deleteProgram,
	removeProgram,
	removePrograms,
	resetPrograms,
} = programSlice.actions;

export const selectPrograms = (state) => state.programs.programs;
export const selectProgramsTableSettings = (state) =>
	state.programs.programsTableSettings;

export const selectOriginalProgram = (id) => (state) => {
	if (state.programs.original.length > 0) {
		return state.programs.original.find((program) => program._id === id);
	} else {
		return null;
	}
};

export const selectEditingProgram = (id) => (state) => {
	if (state.programs.editing.length > 0) {
		return state.programs.editing.find((program) => program._id === id);
	} else {
		return null;
	}
};

// Load Programs Async
export const getProgramsAsync =
	(status = 'active') =>
	async (dispatch) => {
		await api
			.get(`/program/status/${status}`, { withCredentials: true })
			.then((res) => {
				dispatch(getPrograms(res.data));
				console.log(res.data[0]);
			})
			.catch((error) => {
				dispatch(handleErrorAlertAsync(error));
				dispatch(verifyAuthAsync(error.response.data.message));
			});
	};

// Get Program Async
export const getProgramAsync = (id) => async (dispatch) => {
	await api
		.get(`/program/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getProgram(res.data));

			dispatch(
				updateTabTitle({
					id: res.data._id,
					title: `${res.data.Name}`,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Create Program Async
export const createProgramAsync = (program) => async (dispatch) => {
	await api
		.post(`/program/`, program, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(getProgram(res.data));
			dispatch(setSaved(res.data._id));

			dispatch(
				addTab({
					id: res.data._id,
					title: res.data.Name,
					link: `/acquisitions/programs/${res.data._id}`,
					type: 'program',
					tertiary: 0,
					status: true,
					delete: true,
				})
			);

			dispatch(
				createAlertAsync({
					message: 'Program Created Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(setSaving(program._id));
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Program Async
export const updateProgramAsync = (program) => async (dispatch) => {
	await api
		.put(`/program/${program._id}`, program, { withCredentials: true })
		.then((res) => {
			dispatch(getProgram(res.data));

			dispatch(
				createAlertAsync({
					message: 'Programs Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Delete Program Async
export const deleteProgramAsync = (id, program) => async (dispatch) => {
	await api
		.delete(`/program/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(deleteProgram(id));

			dispatch(
				createAlertAsync({
					message: 'Program Marked Inactive',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Delete Rights Async
export const deleteRightsAsync = (id, program) => async (dispatch) => {
	await api
		.delete(`/rights/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(deleteRightsIn({ id: id, program: program }));
			dispatch(
				createAlertAsync({
					message: 'Rights Deleted Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

export default programSlice.reducer;
