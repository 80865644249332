import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import AppBarMenu from './components/layout/AppBarMenu';
import DrawerMenu from './components/layout/DrawerMenu';
import MainContent from './components/layout/MainContent';
import {
	openDrawer,
	closeDrawer,
	selectDrawerStatus,
} from './redux/drawerSlice';
import Alerts from './components/layout/Alerts';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { blueGrey, orange } from '@mui/material/colors';
import './app.css';
import ErrorBoundary from './ErrorBoundary';
//import NewProgramModal from './components/acquisitions/programs/newProgram/NewProgramModal';

const theme = createTheme({
	palette: {
		//mode: 'dark',
		background: {
			default: blueGrey[700],
		},
		// text: {
		// 	primary: '#ffffff',
		// 	light: '#000000',
		// 	secondary: '#000000',
		// },
		primary: {
			light: blueGrey[300],
			main: blueGrey[500],
			dark: blueGrey[800],
			contrastText: '#ffffff',
		},
		secondary: {
			light: orange[300],
			main: orange[500],
			dark: orange[800],
			contrastText: '#ffffff',
		},
	},
});

const drawerWidth = 240;

function App() {
	const dispatch = useDispatch();
	const open = useSelector(selectDrawerStatus);

	const handleDrawerOpen = () => {
		dispatch(openDrawer());
	};

	const handleDrawerClose = () => {
		dispatch(closeDrawer());
	};

	return (
		<ThemeProvider theme={theme}>
			<ErrorBoundary>
				<Box sx={{ display: 'flex', height: '100%' }}>
					<CssBaseline />
					<AppBarMenu
						open={open}
						handleDrawerOpen={handleDrawerOpen}
						handleDrawerClose={handleDrawerClose}
						drawerwidth={drawerWidth}
					/>
					<DrawerMenu
						open={open}
						handleDrawerClose={handleDrawerClose}
						drawerwidth={drawerWidth}
					/>
					<MainContent
						open={open}
						drawerwidth={drawerWidth}
					/>
					<Alerts />
				</Box>
				{/* <NewProgramModal open={open} /> */}
			</ErrorBoundary>
		</ThemeProvider>
	);
}

export default App;
