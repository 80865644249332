import React, { useEffect } from 'react';
import {
	Box,
	Button,
	TextField,
	Grid,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	Checkbox,
	ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { blueGrey, orange } from '@mui/material/colors';
import { selectCurrencies } from '../../../../redux/currencySlice';
////import RightsModal from '../../../shared/rightsModal/RightsModal';
//import AccountParentModal from '../../../shared/AccountParentModal';
import { changeEditingProgram } from '../../../../redux/programSlice';
//import { changeEditingTerritory } from '../../../../redux/programSlice';
import { selectGenres } from '../../../../redux/genreSlice';
import { selectLanguages } from '../../../../redux/languageSlice';
import { selectFormats } from '../../../../redux/formatSlice';
import { selectDefinitions } from '../../../../redux/definitionSlice';
import {
	getAccountsAsync,
	selectProducers,
} from '../../../../redux/accountSlice';

export default function ProgramDetails1({
	id,
	type,
	newProgram,
	user,
	users,
	handleNext,
}) {
	const dispatch = useDispatch();
	const currencies = useSelector(selectCurrencies);
	const languages = useSelector(selectLanguages);
	const genres = useSelector(selectGenres);
	const formats = useSelector(selectFormats);
	const definitions = useSelector(selectDefinitions);
	const producers = useSelector(selectProducers);
	//const [parentAccountModal, setParentAccountModal] = useState(false);

	useEffect(() => {
		if (!producers) {
			dispatch(getAccountsAsync('Producer'));
		}
		//eslint-disable-next-line
	}, [producers]);

	// function handleParentAccountModal() {
	// 	setParentAccountModal(!parentAccountModal);
	// }

	//const [rightsModal, setRightsModal] = useState(false);

	// function handleRightsModal() {
	// 	setRightsModal(!rightsModal);
	// }

	function handleChange(event) {
		if (!event.target) {
			dispatch(
				changeEditingProgram({
					id: id,
					name: 'Phone',
					value: event,
				})
			);
		} else {
			let string = event.target.value;

			if (event.target.name === 'Website') {
				if (string.indexOf('http') !== 0) {
					string = 'http://' + string;
				}
			}

			dispatch(
				changeEditingProgram({
					id: id,
					name: event.target.name,
					value: string,
				})
			);
		}
	}

	function handleArrayChange(e) {
		let newValue =
			typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');
		dispatch(
			changeEditingProgram({
				id: newProgram._id,
				name: e.target.name,
				value: correctedValue,
			})
		);
	}

	function submit(e) {
		e.preventDefault();

		handleNext();
	}

	return (
		<Box
			sx={{ backgroundColor: 'inherit', color: 'inherit' }}
			component={'form'}
			onSubmit={submit}
		>
			<Grid container>
				<Grid
					item
					sm={12}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Name/Title'
						value={newProgram.Name}
						name='Name'
						onChange={(e) => handleChange(e)}
						sx={textFields}
						style={{ width: '80%' }}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						select
						variant='outlined'
						label='Production Company'
						value={newProgram.ProductionCompany}
						name='ProductionCompany'
						onChange={handleChange}
						sx={textFields}
					>
						<MenuItem value={''}>{'SELECT Production Company'}</MenuItem>
						{producers &&
							producers.map((producer) => (
								<MenuItem
									key={producer._id}
									value={producer._id}
								>
									{producer.Name}
								</MenuItem>
							))}
					</TextField>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						select
						variant='outlined'
						label='ProgramStatus'
						value={newProgram.ProgramStatus}
						name='ProgramStatus'
						onChange={(e) => handleChange(e)}
						sx={textFields}
						style={{ width: '80%' }}
					>
						<MenuItem value={'Co-Production'}>Co-Production</MenuItem>
						<MenuItem value={'Pre-Sale'}>Pre-Sale</MenuItem>
						<MenuItem value={'Finished'}>Finished</MenuItem>
					</TextField>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Running Time'
						value={newProgram.RunningTime}
						name='RunningTime'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Country of Origin'
						value={newProgram.CountryOfOrigin}
						name='CountryOfOrigin'
						onChange={(e) => handleChange(e)}
						sx={textFields}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFields}>
						<InputLabel>Genre*</InputLabel>
						<Select
							name='Genre'
							multiple
							multiline
							required
							color='secondary'
							value={newProgram.Genre}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									label='Genre'
									color='secondary'
								>
									Genre
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{genres.map((genre) => (
								<MenuItem
									key={genre._id}
									value={genre.Name}
								>
									<Checkbox
										checked={newProgram.Genre.indexOf(genre.Name) > -1}
									/>
									<ListItemText primary={genre.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Year of Production'
						type='number'
						value={newProgram.YearOfProduction}
						name='YearOfProduction'
						onChange={(e) => handleChange(e)}
						sx={textFields}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl
						variant='outlined'
						sx={textFields}
					>
						<InputLabel>Currency*</InputLabel>
						<Select
							name='Currency'
							multiple
							required
							variant='outlined'
							value={newProgram.Currency}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Currency'
								>
									Currency
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
							//sx={textFieldStyles}
						>
							{currencies.map((currency) => (
								<MenuItem
									key={currency._id}
									value={currency.Name}
								>
									<Checkbox
										checked={newProgram.Currency.indexOf(currency.Name) > -1}
									/>
									<ListItemText primary={currency.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFields}>
						<InputLabel>Language*</InputLabel>
						<Select
							name='Language'
							multiple
							required
							value={newProgram.Language}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Language'
								>
									Language
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{languages.map((language) => (
								<MenuItem
									key={language._id}
									value={language.Name}
								>
									<Checkbox
										checked={newProgram.Language.indexOf(language.Name) > -1}
									/>
									<ListItemText primary={language.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFields}>
						<InputLabel>Format*</InputLabel>
						<Select
							name='Format'
							required
							value={newProgram.Format}
							onChange={(e) => handleChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Format'
								>
									Format
								</OutlinedInput>
							}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{formats.map((format) => (
								<MenuItem
									key={format._id}
									value={format.Name}
								>
									{format.Name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFields}>
						<InputLabel>Definition*</InputLabel>
						<Select
							name='Definition'
							multiple
							required
							value={newProgram.Definition}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Definition'
								>
									Definition
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{definitions.map((definition) => (
								<MenuItem
									key={definition._id}
									value={definition.Name}
								>
									<Checkbox
										checked={
											newProgram.Definition.indexOf(definition.Name) > -1
										}
									/>
									<ListItemText primary={definition.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			{newProgram.Format === 'Series' && (
				<Grid container>
					<Grid
						item
						sm={6}
					>
						<TextField
							required
							color='secondary'
							variant='outlined'
							label='Season'
							value={newProgram.Season}
							name='Season'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={textFields}
						/>
					</Grid>
					<Grid
						item
						sm={6}
					>
						<TextField
							required
							color='secondary'
							variant='outlined'
							label='Number Of Episodes'
							value={newProgram.Episodes}
							name='Episodes'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={textFields}
						/>
					</Grid>
				</Grid>
			)}
			<Grid container>
				<Grid
					item
					sm={12}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Vimeo Link'
						value={newProgram.VimeoLink}
						name='VimeoLink'
						onChange={(e) => handleChange(e)}
						sx={textFields}
						style={{ width: '99%' }}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={12}
				>
					<TextField
						color='secondary'
						required
						multiline
						variant='outlined'
						label='Synopsis'
						value={newProgram.Synopsis}
						name='Synopsis'
						onChange={(e) => handleChange(e)}
						sx={textFields}
						style={{ width: '99%' }}
					/>
				</Grid>
			</Grid>
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
}

const textFields = {
	minWidth: '98%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-root.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
		color: orange[500],
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

export const textFieldStylesWithEdit = {
	minWidth: '85%',
	textAlign: 'left',
	color: blueGrey[50],
	backgroundColor: blueGrey[700],
	marginTop: 2,
	marginBottom: 2,
	pointer: 'none !important',
	'& .MuiInput-input': {
		color: blueGrey[50],
		//cursor: 'pointer !important',
		//cursor: 'none !important',
	},
	'& .MuiInput-root': {
		color: blueGrey[50],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiInputRoot:after': {
		borderColor: blueGrey[900],
	},
	'& .MuiDisabled:': {
		color: blueGrey[50],
		opacity: '1 !important',
	},
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: blueGrey[50],
		//opacity: '1 !important',
		//cursor: 'none !important',
	},
};
