import { blueGrey } from '@mui/material/colors';

export const gridStyles = {
	marginTop: '1em',
	marginBottom: '2em',
	textAlign: 'center !important',
};

export const gridItemStyles = {
	textAlign: 'center !important',
	//display: 'flex',
	//alignItems: 'end !important',
	color: blueGrey[900],
	padding: 0,
	'& .MuiGridItem-root': {
		padding: 0,
	},
};

export const textFieldStyles = {
	minWidth: '80%',
	textAlign: 'left',
	color: blueGrey[900],
	backgroundColor: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	'& .MuiInput-root': {
		color: blueGrey[900],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[900],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[900],
	},
};

export const textFieldStylesWithEdit = {
	minWidth: '70%',
	textAlign: 'left',
	color: blueGrey[900],
	marginTop: 2,
	marginBottom: 2,
	pointer: 'none !important',
	'& .MuiInput-input': {
		color: blueGrey[900],
		//cursor: 'pointer !important',
		//cursor: 'none !important',
	},
	'& .MuiInput-root': {
		color: blueGrey[900],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[900],
	},
	'& .MuiInputRoot:after': {
		borderColor: blueGrey[900],
	},
	'& .MuiDisabled:': {
		color: blueGrey[900],
		opacity: '1 !important',
	},
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: blueGrey[900],
		//opacity: '1 !important',
		//cursor: 'none !important',
	},
};

export const inputStyles = {
	minWidth: '80%',
	textAlign: 'left',
	// '& .MuiInputLabel-root': {
	// 	textAlign: 'left',
	// },
};
