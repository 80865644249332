import { useDispatch } from 'react-redux';

import {
	Box,
	Button,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	Cancel,
	NavigateBeforeTwoTone,
	NavigateNextTwoTone,
} from '@mui/icons-material';
import { blueGrey, orange } from '@mui/material/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import {
	addEditingPaymentTerm,
	changeEditingPaymentTerm,
	deleteEditingPaymentTerm,
} from '../../../../redux/salesSlice';
import { createAlertAsync } from '../../../../redux/alertsSlice';

export default function ConvertToSaleStep2({ newSale, handleChange }) {
	const dispatch = useDispatch();

	function handleAddNewPaymentTerm() {
		dispatch(
			addEditingPaymentTerm({
				_id: newSale._id,
				paymentTerm: {
					_id: crypto.randomUUID(),
					IsActve: true,
					Percent: '',
					Condition: '',
					Date: null,
					BankFee: 0,
					PaymentReceived: 0,
					PaymentReceivedDate: null,
					PayableAfterTax: 0,
				},
			})
		);
	}

	function handlePaymentTermChange(e, id) {
		dispatch(
			changeEditingPaymentTerm({
				_id: newSale._id,
				paymentTermId: id,
				name: e.target.name,
				value: e.target.value,
			})
		);
	}

	function handlePaymentTermCalendarChange(e, id, name) {
		dispatch(
			changeEditingPaymentTerm({
				_id: newSale._id,
				paymentTermId: id,
				name: name,
				value: new Date(e.$d).toDateString(),
			})
		);
	}

	function handleDeletePaymentTerm(id) {
		dispatch(deleteEditingPaymentTerm({ _id: newSale._id, paymentTermId: id }));
	}

	function submit(e) {
		e.preventDefault();

		if (newSale.PaymentTerms.length > 0) {
			handleChange({ target: { name: 'stepIndex', value: 2 } });
		} else {
			dispatch(
				createAlertAsync({
					message: 'Must Add At Least One Payment Term',
					severity: 'warning',
					autoDismiss: true,
				})
			);
		}
	}

	return (
		<Box
			component='form'
			onSubmit={submit}
			//sx={{ height: '100%', overflowY: 'hidden' }}
		>
			<Box>
				<Grid
					container
					sx={{
						backgroundColor: blueGrey[900],
						height: 64,
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						item
						xs={1}
					>
						<Tooltip title='Back'>
							<IconButton
								onClick={() =>
									handleChange({
										target: { name: 'stepIndex', value: 0 },
									})
								}
								sx={{ padding: 0 }}
							>
								<NavigateBeforeTwoTone
									sx={{
										color: blueGrey[50],
										backgroundColor: blueGrey[800],
										height: 40,
										width: 40,
										borderRadius: 40,
									}}
								/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid
						item
						xs={10}
					>
						<Typography
							variant='h5'
							component='h2'
							sx={{ color: blueGrey[50] }}
						>
							Payment Terms
						</Typography>
					</Grid>
					<Grid
						item
						xs={1}
					>
						<Tooltip title='Next'>
							<IconButton
								type='submit'
								sx={{ padding: 0 }}
							>
								<NavigateNextTwoTone
									sx={{
										color: blueGrey[50],
										backgroundColor: blueGrey[800],
										height: 40,
										width: 40,
										borderRadius: 40,
									}}
								/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				<Box
					sx={{
						height: 'calc(100vh - 450px)',
						overflowY: 'auto',
						paddingBottom: 4,
					}}
				>
					{newSale.PaymentTerms &&
						newSale.PaymentTerms.map((paymentTerm, index) => (
							<Grid
								key={paymentTerm._id}
								container
								sx={{
									backgroundColor:
										index % 2 === 0 ? blueGrey[500] : blueGrey[600],
									padding: 2,
									justifyContent: 'center',
									alignItems: 'center',
									alignContent: 'center',
								}}
							>
								<Grid
									item
									xs={3}
								>
									<TextField
										key='Percent'
										required
										type='number'
										variant='outlined'
										label='Percent'
										value={newSale.PaymentTerms[index].Percent}
										name='Percent'
										onChange={(e) =>
											handlePaymentTermChange(e, paymentTerm._id)
										}
										color='secondary'
										sx={textFields}
									/>
								</Grid>
								<Grid
									item
									xs={3}
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DesktopDatePicker
											required
											key='Date'
											name='Date'
											label='Due Date'
											inputFormat='DD/MM/YYYY'
											value={newSale.PaymentTerms[index].Date}
											onChange={(e) =>
												handlePaymentTermCalendarChange(
													e,
													paymentTerm._id,
													'Date'
												)
											}
											sx={pickerStyles}
											style={pickerStyles}
											InputProps={{
												sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
											}}
											PopperProps={{ sx: pickerStyles }}
											renderInput={(params) => (
												<TextField
													{...params}
													key='Date'
													required={
														newSale.PaymentTerms[index].Condition === ''
													}
													disabled
													variant='outlined'
													label='Due Date'
													value={newSale.PaymentTerms[index].Date}
													name='Date'
													//onChange={handleCalendarChange}
													color='secondary'
													sx={textFields}
												/>
											)}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid
									item
									xs={5}
								>
									<TextField
										key='Condition'
										required={newSale.PaymentTerms[index].DueDate === null}
										variant='outlined'
										label='Condition'
										value={newSale.PaymentTerms[index].Condition}
										name='Condition'
										onChange={(e) =>
											handlePaymentTermChange(e, paymentTerm._id)
										}
										color='secondary'
										sx={textFields}
									/>
								</Grid>
								<Grid
									item
									xs={1}
								>
									<Tooltip
										title='Delete Payment Term'
										arrow={true}
									>
										<IconButton
											onClick={() => handleDeletePaymentTerm(paymentTerm._id)}
											sx={{
												padding: 0,
												backgroundColor: orange[500],
												borderBottomRadius: 20,
												borderTopRadius: 20,
												width: 28,
												height: 28,
											}}
										>
											<Cancel
												sx={{
													color: blueGrey[900],
													//backgroundColor: blueGrey[50],
													//fill: blueGrey[50],
													//borderBottomRadius: 10,
													//borderTopRadius: 10,
													fontSize: '1.5em',
													//height: 30,
													//paddingLeft: 2,
												}}
											/>
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						))}
				</Box>
			</Box>
			<Box>
				<Grid
					container
					sx={{ marginTop: 4 }}
				>
					<Grid
						item
						xs={10}
					></Grid>
					<Grid
						item
						xs={2}
					>
						<Button
							variant='contained'
							onClick={handleAddNewPaymentTerm}
							sx={{ backgroundColor: blueGrey[800] }}
						>
							Add Payment Term
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
