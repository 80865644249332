import React, { useState } from 'react';
import {
	Box,
	Grid,
	TextField,
	Modal,
	Stack,
	Button,
	Backdrop,
	IconButton,
	Tooltip,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@mui/material';
import { AddCircle, EditSharp, ExpandMore } from '@mui/icons-material';
import { blueGrey, yellow, red, green } from '@mui/material/colors';
import {
	addNoteAsync,
	updateNoteAsync,
	deleteNoteAsync,
} from '../../redux/noteSlice';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

export default function Notes({ id, type, notes, backgroundColor, color }) {
	const [addText, setAddText] = useState('');
	const [addModalOpen, setAddModalOpen] = useState(false);
	const dispatch = useDispatch();

	function handleAddModal() {
		setAddText('');
		setAddModalOpen(!addModalOpen);
	}

	function handleAddChange(event) {
		setAddText(event.target.value);
	}

	function addNote(event) {
		event.preventDefault();

		dispatch(addNoteAsync(id, type, addText));

		setAddText('');
		setAddModalOpen(false);
	}

	const [editId, setEditId] = useState('');
	const [editText, setEditText] = useState('');
	const [editModalOpen, setEditModalOpen] = useState(false);

	function handleEditModalOpen(note) {
		setEditId(note._id);
		setEditText(note.Text);
		setEditModalOpen(true);
	}

	function handleEditModalClose(text) {
		setEditText('');
		setEditModalOpen(false);
	}

	function handleEditChange(event) {
		setEditText(event.target.value);
	}

	function editNote(event) {
		event.preventDefault();

		dispatch(updateNoteAsync(editId, editText));

		setAddText('');
		setEditModalOpen(false);
	}

	function deleteNote() {
		dispatch(deleteNoteAsync(id, editId, type));

		setEditText('');
		setEditModalOpen(false);
	}

	return (
		<Box
			sx={{
				margin: '0 auto',
				color: color,
				backgroundColor: backgroundColor,
				width: '100%',
				borderRadius: 2,
			}}
		>
			<Grid
				container
				sx={{
					backgroundColor: 'inherit',
					borderRadius: 2,
					color: 'inherit',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Grid
					item
					sx={{ flexGrow: 1, fontWeight: 'bold', marginLeft: 5 }}
				>
					{type.toUpperCase()} NOTES:
				</Grid>
				<Grid
					item
					sx={{ flexGrow: 0, color: 'inherit', marginRight: 2 }}
				>
					<Tooltip
						title='Add Note'
						arrow={true}
					>
						<IconButton
							onClick={handleAddModal}
							sx={{
								color: 'inherit',
							}}
						>
							<AddCircle
								sx={{
									color: 'inherit',
									backgroundColor: 'transparent',
									borderBottomRadius: 10,
									borderTopRadius: 10,
									fontSize: '1.5em',
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					padding: 4,
					paddingTop: 0,
					borderBottomLeftRadius: 10,
					borderBottomRightRadius: 10,
					color: 'inherit !important',
					backgroundColor: 'inherit',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Grid
					item
					sx={{
						flexGrow: 1,
						color: 'inherit !important',
						backgroundColor: 'inherit',
					}}
				>
					{notes &&
						notes.map((note) => (
							<Grid
								key={note._id}
								sx={{
									color: 'inherit !important',
									backgroundColor: 'inherit',
									borderRadius: 0,
									marginTop: 1,
									textAlign: 'center',
									boxShadow: `0px .25px 1.5px 1px${color}`,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Accordion
									disableGutters={true}
									sx={{
										width: '100%',
										color: 'inherit !important',
										backgroundColor: 'inherit',
										'& .Mui-expanded': {
											margin: '16px 0',
										},
									}}
								>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										sx={{
											width: '100%',
											color: 'inherit !important',
											backgroundColor: 'inherit',
											'& .Mui-expanded': {
												margin: '16px 0',
											},
											'& .MuiSvgIcon-root': {
												color: color,
											},
										}}
									>
										<Typography
											sx={{
												width: '100%',
												marginLeft: 2,
												marginRight: 2,
												textAlign: 'center',
											}}
										>
											{note.Text}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid
											container
											spacing={2}
											sx={{ textAlign: 'center', alignItems: 'center' }}
										>
											<Grid
												sm={5}
												item
											>
												<p>
													<span
														style={{ fontWeight: 'bold', marginRight: '1em' }}
													>
														Created By:
													</span>
													{`${note.CreatedBy && note.CreatedBy.FirstName} ${
														note.CreatedBy && note.CreatedBy.LastName
													}`}
												</p>
												<p>
													<span
														style={{ fontWeight: 'bold', marginRight: '1em' }}
													>
														Created On:
													</span>
													{dayjs(note.CreatedDate).format(
														'MMM. DD, YYYY h:mm:s a'
													)}
												</p>
											</Grid>
											<Grid
												sm={5}
												item
											>
												<p>
													<span
														style={{ fontWeight: 'bold', marginRight: '1em' }}
													>
														LastModified By:
													</span>
													{`${
														note.LastModifiedBy && note.LastModifiedBy.FirstName
													} ${
														note.LastModifiedBy && note.LastModifiedBy.LastName
													}`}
												</p>
												<p>
													<span
														style={{ fontWeight: 'bold', marginRight: '1em' }}
													>
														Last Modified On:
													</span>
													{dayjs(note.LastModifiedDate).format(
														'MMM. DD, YYYY h:mm:s a'
													)}
												</p>
											</Grid>
											<Grid
												sm={2}
												item
											>
												<Tooltip title='Edit/Delete Note'>
													<IconButton
														onClick={() => handleEditModalOpen(note)}
														sx={{
															'& .MuiSvgIcon-root': {
																color: color,
															},
														}}
													>
														<EditSharp
															sx={{
																borderRadius: 2,
																fontSize: '1em',
															}}
														/>
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
						))}
				</Grid>
			</Grid>
			<Modal
				id='add-new-note-modal'
				open={addModalOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						width: '400px',
						height: '400px',
					}}
				>
					<Box
						component={'form'}
						onSubmit={addNote}
						name={'NoteModal'}
					>
						<TextField
							key='Text'
							autoFocus={true}
							required
							variant='standard'
							label='Text'
							value={addText}
							name='Name'
							onChange={handleAddChange}
							multiline
							rows={10}
							sx={{ width: '100%' }}
						/>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: '3em' }}
						>
							<Button
								variant='contained'
								onClick={handleAddModal}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
							<Button
								variant='contained'
								type='submit'
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Save
							</Button>
						</Stack>
					</Box>
				</Box>
			</Modal>
			<Modal
				id='edit-delete-note-modal'
				open={editModalOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						width: '400px',
						height: '400px',
					}}
				>
					<Box
						component={'form'}
						onSubmit={editNote}
					>
						<TextField
							key='Text'
							autoFocus={true}
							required
							variant='standard'
							label='Text'
							value={editText}
							name='EditText'
							onChange={handleEditChange}
							multiline
							rows={10}
							sx={{ width: '100%' }}
						/>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: '3em' }}
						>
							<Button
								variant='contained'
								onClick={handleEditModalClose}
								sx={{
									flexGrow: 1,
									backgroundColor: yellow[700],
									color: blueGrey[50],
									textShadow: `1px 1px 4px ${blueGrey[900]}`,
									'&:hover': { backgroundColor: yellow[800] },
								}}
							>
								Cancel
							</Button>
							<Button
								variant='contained'
								onClick={deleteNote}
								color='error'
								sx={{
									flexGrow: 1,
									backgroundColor: red[600],
									color: blueGrey[50],
									textShadow: `1px 1px 4px ${blueGrey[900]}`,
									'&:hover': { backgroundColor: red[700] },
								}}
							>
								Delete
							</Button>
							<Button
								variant='contained'
								type='submit'
								sx={{
									flexGrow: 1,
									backgroundColor: green[600],
									color: blueGrey[50],
									textShadow: `1px 1px 4px ${blueGrey[900]}`,
									'&:hover': { backgroundColor: green[700] },
								}}
							>
								Save
							</Button>
						</Stack>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
}
