import React, { useState } from 'react';
import {
	Box,
	Button,
	TextField,
	Grid,
	MenuItem,
	IconButton,
	//CircularProgress,
	Tooltip,
} from '@mui/material';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import {
	gridStyles,
	gridItemStyles,
	//textFields,
	//textFieldStylesWithEdit,
} from '../ComponentStyles';
import {
	//createNewAccountAsync,
	//selectEditingAccount,
	//addEditingAccount,
	changeEditingAccount,
	changeEditingTerritory,
} from '../../../redux/accountSlice';
import { MuiTelInput } from 'mui-tel-input';
import {
	//isPossiblePhoneNumber,
	isValidPhoneNumber,
	//validatePhoneNumberLength,
} from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { blueGrey, orange } from '@mui/material/colors';
import { selectCurrencies } from '../../../redux/currencySlice';
import RightsModal from '../rightsModal/RightsModal';
import AccountParentModal from '../AccountParentModal';

export default function Account({
	id,
	type,
	newAccount,
	user,
	users,
	handleNext,
}) {
	const dispatch = useDispatch();
	const currencies = useSelector(selectCurrencies);

	const [parentAccountModal, setParentAccountModal] = useState(false);
	function handleParentAccountModal() {
		setParentAccountModal(!parentAccountModal);
	}

	const [rightsModal, setRightsModal] = useState(false);

	function handleRightsModal() {
		setRightsModal(!rightsModal);
	}

	const handleChange = (event) => {
		if (!event.target) {
			dispatch(
				changeEditingAccount({
					id: id,
					name: 'Phone',
					value: event,
				})
			);
		} else {
			let string = event.target.value;

			if (event.target.name === 'Website') {
				if (string.indexOf('http') !== 0) {
					string = 'http://' + string;
				}
			}

			dispatch(
				changeEditingAccount({
					id: id,
					name: event.target.name,
					value: string,
				})
			);
		}
	};

	function submit(e) {
		e.preventDefault();

		handleNext();
	}

	return (
		<Box
			sx={{ backgroundColor: 'inherit', color: 'inherit' }}
			component={'form'}
			onSubmit={submit}
		>
			<Grid
				container
				sx={gridStyles}
			>
				<Grid
					sm={12}
					sx={gridItemStyles}
					item
				>
					<TextField
						key='Owner'
						select
						required
						variant='outlined'
						label='Owner'
						value={newAccount.Owner}
						name='Owner'
						color='secondary'
						onChange={handleChange}
						sx={textFields}
					>
						{users &&
							users.map((user) => (
								<MenuItem
									key={user._id}
									value={user._id}
									disabled={!user.IsActive}
									sx={{
										display: `${
											user.IsActive || newAccount.Owner === user._id
												? 'block'
												: 'none'
										}`,
									}}
								>{`${user.FirstName} ${user.LastName}`}</MenuItem>
							))}
					</TextField>
					<TextField
						key='Parent'
						variant='outlined'
						disabled={true}
						label='Parent Account:'
						value={newAccount.Parent ? newAccount.Parent.Name : ''}
						name='Parent'
						color='secondary'
						sx={textFieldStylesWithEdit}
					/>
					<Box
						sx={{
							display: 'inline-flex',
							minWidth: '10%',
							maxWidth: '10%',
							paddingLeft: '5%',
							marginTop: 3,
						}}
					>
						<Tooltip title='Add Parent Account'>
							<IconButton onClick={handleParentAccountModal}>
								<EditSharpIcon
									sx={{
										color: blueGrey[50],
										backgroundColor: 'transparent',
										borderRadius: 2,
									}}
								/>
							</IconButton>
						</Tooltip>
					</Box>
					<TextField
						key='Name'
						required
						variant='outlined'
						label='Name'
						value={newAccount.Name}
						name='Name'
						color='secondary'
						onChange={handleChange}
						sx={textFields}
					/>
					<MuiTelInput
						key='Phone'
						type='phone'
						required
						variant='outlined'
						label='Phone'
						value={newAccount.Phone}
						name='Phone'
						color='secondary'
						onChange={handleChange}
						forceCallingCode={true}
						error={!isValidPhoneNumber}
						sx={textFields}
					/>
					<TextField
						required
						variant='outlined'
						label='Website'
						type='url'
						value={newAccount.Website}
						name='Website'
						color='secondary'
						onChange={handleChange}
						sx={textFields}
					/>
					<TextField
						select
						variant='outlined'
						label='Currency'
						value={newAccount.Currency}
						name='Currency'
						color='secondary'
						onChange={handleChange}
						sx={textFields}
					>
						{currencies.map((currency) => (
							<MenuItem
								key={currency._id}
								value={currency.Name}
							>
								{currency.Name}
							</MenuItem>
						))}
					</TextField>
					<TextField
						key='Territory'
						variant='outlined'
						disabled={true}
						label='Territory'
						value={newAccount.Territory}
						name='Territory'
						color='secondary'
						multiline
						sx={textFieldStylesWithEdit}
					/>

					<Box
						sx={{
							display: 'inline-flex',
							minWidth: '10%',
							maxWidth: '1%',
							paddingLeft: '5%',
							marginTop: 3,
						}}
					>
						<Tooltip title='Edit Rights Tree'>
							<IconButton onClick={handleRightsModal}>
								<EditSharpIcon
									sx={{
										color: blueGrey[50],
										backgroundColor: 'transparent',
										borderRadius: 2,
									}}
								/>
							</IconButton>
						</Tooltip>
					</Box>
					<TextField
						key='Notes'
						variant='outlined'
						label='Notes'
						value={newAccount.Notes}
						name='Notes'
						color='secondary'
						onChange={handleChange}
						multiline
						rows={5}
						sx={textFields}
					/>
				</Grid>
				<AccountParentModal
					type={type}
					account={newAccount}
					parentAccountModal={parentAccountModal}
					handleParentAccountModal={handleParentAccountModal}
				/>
				{rightsModal && (
					<RightsModal
						id={newAccount._id}
						rightsTree={newAccount.RightsTree}
						rightsModal={rightsModal}
						handleRightsModal={handleRightsModal}
						changeEditingTerritory={changeEditingTerritory}
					/>
				)}
			</Grid>
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

export const textFieldStylesWithEdit = {
	minWidth: '85%',
	textAlign: 'left',
	color: blueGrey[50],
	backgroundColor: blueGrey[700],
	marginTop: 2,
	marginBottom: 2,
	pointer: 'none !important',
	'& .MuiInput-input': {
		color: blueGrey[50],
		//cursor: 'pointer !important',
		//cursor: 'none !important',
	},
	'& .MuiInput-root': {
		color: blueGrey[50],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiInputRoot:after': {
		borderColor: blueGrey[900],
	},
	'& .MuiDisabled:': {
		color: blueGrey[50],
		opacity: '1 !important',
	},
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: blueGrey[50],
		opacity: '1 !important',
		//cursor: 'none !important',
	},
};
