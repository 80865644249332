import React, { useState } from 'react';
import {
	Modal,
	Stack,
	Box,
	Button,
	Backdrop,
	Switch,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { blueGrey, orange } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SecondBranch from './SecondBranch';
import {
	selectAllTerritories,
	selectTopTerritories,
} from '../../../redux/territorySlice';
import { setTerritories } from '../../../utils/rights';

export default function RightsModal({
	id,
	program,
	rightsIn,
	rightsTree,
	rightsModal,
	handleRightsModal,
	changeEditingTerritory,
}) {
	const dispatch = useDispatch();
	const allTerritories = useSelector(selectAllTerritories());
	const territories = useSelector(selectTopTerritories());
	const [newRights, setNewRights] = useState(
		Array.isArray(rightsTree) ? rightsTree : []
	);

	function updateRights() {
		let currentTerritorites = setTerritories(allTerritories, newRights);

		dispatch(
			changeEditingTerritory({
				id: id,
				program: program,
				rightsIn: rightsIn,
				territory: currentTerritorites.regions,
				exclusions: currentTerritorites.exclusions,
				rights: newRights,
			})
		);
		handleRightsModal();
	}

	function checkBoxChange(e) {
		if (e.target.checked) {
			setNewRights([...newRights, e.target.name]);
		} else {
			let index = newRights.findIndex((x) => x === e.target.name);

			if (index !== -1) {
				setNewRights([...newRights.filter((x) => x !== e.target.name)]);
			}
		}
	}

	function switchChange(e, branch) {
		let adjustedRights = [...newRights];

		let t1 = allTerritories.filter(
			(t) => t.Parent === e.target.name && t.Branch === branch
		);

		t1.forEach((element) => {
			if (e.target.checked) {
				if (element.IsRegion) {
					let t2 = allTerritories.filter(
						(t) => t.Parent === element.Name && t.Branch === branch
					);

					t2.forEach((element) => {
						if (element.IsRegion) {
							let t3 = allTerritories.filter(
								(t) => t.Parent === element.Name && t.Branch === branch
							);

							t3.forEach((element) => {
								if (element.IsRegion) {
									let t4 = allTerritories.filter(
										(t) => t.Parent === element.Name && t.Branch === branch
									);

									t4.forEach((element) => {
										if (element.IsRegion) {
										} else {
											let i4 = adjustedRights.findIndex(
												(x) => x === element.Name
											);

											if (i4 === -1) {
												adjustedRights.push(element.Name);
											}
										}
									});
								} else {
									let i3 = adjustedRights.findIndex((x) => x === element.Name);

									if (i3 === -1) {
										adjustedRights.push(element.Name);
									}
								}
							});
						} else {
							let i2 = adjustedRights.findIndex((x) => x === element.Name);

							if (i2 === -1) {
								adjustedRights.push(element.Name);
							}
						}
					});
				} else {
					let index = adjustedRights.findIndex((x) => x === element.Name);

					if (index === -1) {
						adjustedRights.push(element.Name);
					}
				}
			} else {
				if (element.IsRegion) {
					let t2 = allTerritories.filter(
						(t) => t.Parent === element.Name && t.Branch === branch
					);

					t2.forEach((element) => {
						if (element.IsRegion) {
							let t3 = allTerritories.filter(
								(t) => t.Parent === element.Name && t.Branch === branch
							);

							t3.forEach((element) => {
								if (element.IsRegion) {
									console.log('REGION!');
								} else {
									let i3 = adjustedRights.findIndex((x) => x === element.Name);

									if (i3 !== -1) {
										adjustedRights.splice(i3, 1);
									}
								}
							});
						} else {
							let i2 = adjustedRights.findIndex((x) => x === element.Name);

							if (i2 !== -1) {
								adjustedRights.splice(i2, 1);
							}
						}
					});
				} else {
					let index = adjustedRights.findIndex((x) => x === element.Name);

					if (index !== -1) {
						adjustedRights.splice(index, 1);
					}
				}
			}
		});

		setNewRights([...adjustedRights]);
	}

	function determineSwitchStatus(name, branch) {
		let switchStatus = true;

		const t1 = allTerritories.filter(
			(t) => t.Parent === name && t.Branch === branch
		);

		t1.forEach((element) => {
			if (element.IsRegion) {
				const t2 = allTerritories.filter(
					(t) => t.Parent === element.Name && t.Branch === branch
				);

				t2.forEach((element) => {
					if (element.IsRegion) {
						const t3 = allTerritories.filter(
							(t) => t.Parent === element.Name && t.Branch === branch
						);

						t3.forEach((element) => {
							if (element.IsRegion) {
								const t4 = allTerritories.filter(
									(t) => t.Parent === element.Name && t.Branch === branch
								);

								t4.forEach((element) => {
									if (element.IsRegion) {
										console.log('REGION!!!!');
									} else {
										if (!newRights.includes(element.Name)) {
											switchStatus = false;
											return switchStatus;
										}
									}
								});
							} else {
								if (!newRights.includes(element.Name)) {
									switchStatus = false;
									return switchStatus;
								}
							}
						});
					} else {
						if (!newRights.includes(element.Name)) {
							switchStatus = false;
							return switchStatus;
						}
					}
				});
			} else {
				if (!newRights.includes(element.Name)) {
					switchStatus = false;
					return switchStatus;
				}
			}
		});

		return switchStatus;
	}

	return (
		<Modal
			open={rightsModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 1000,
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: blueGrey[800],
					color: blueGrey[50],
					width: '100%',
					height: '100vh',
				}}
			>
				<Box
					sx={{
						marginTop: 5,
						marginBottom: 60,
						position: 'relative',
						height: '90%',
						overflowY: 'scroll',
					}}
				>
					{territories.length > 0 &&
						territories.map((territory) => (
							<Grid
								key={territory._id}
								container
								sx={{
									fontSize: '1.25em',
									color: blueGrey[50],
									marginTop: 1,
									marginBottom: 1,
								}}
							>
								<Grid
									item
									xs={2}
								>
									<FormGroup>
										<FormControlLabel
											control={
												<Switch
													name={territory.Name}
													checked={determineSwitchStatus(
														territory.Name,
														territory.Branch
													)}
													onChange={(e) => switchChange(e, territory.Branch)}
													sx={{
														'&.MuiSwitch-root .MuiSwitch-switchBase': {
															color: blueGrey[50],
														},

														'&.MuiSwitch-root .Mui-checked': {
															color: orange[500],
														},
													}}
												/>
											}
											label={territory.Name}
											sx={{ marginLeft: 4, color: blueGrey[50] }}
										/>
									</FormGroup>
								</Grid>
								<Grid
									item
									xs={10}
								>
									<Accordion
										sx={{
											backgroundColor: blueGrey[600],
											color: blueGrey[50],
										}}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon sx={{ color: blueGrey[50] }} />
											}
										></AccordionSummary>
										<AccordionDetails>
											<SecondBranch
												topLevel={territory.Name}
												parent={territory.Name}
												branch={territory.Branch}
												newRights={newRights}
												checkBoxChange={checkBoxChange}
												switchChange={switchChange}
												determineSwitchStatus={determineSwitchStatus}
											/>
										</AccordionDetails>
									</Accordion>
								</Grid>
							</Grid>
						))}
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'center',
						textAlign: 'center',
						fontSize: '1.25em',
						color: blueGrey[50],
						backgroundColor: blueGrey[700],
						position: 'fixed',
						bottom: 0,
						paddingTop: 2,
						paddingBottom: 2,
						width: '100%',
						borderTop: `2px solid ${blueGrey[900]}`,
					}}
				>
					<Stack
						spacing={2}
						direction='row'
						justifyContent='center'
						sx={{
							width: '100%',
						}}
					>
						<Button
							variant='contained'
							onClick={handleRightsModal}
							sx={{
								backgroundColor: orange[500],
								'&:hover': { backgroundColor: orange[600] },
								paddingLeft: 4,
								paddingRight: 4,
								width: '150px',
							}}
						>
							Cancel
						</Button>
						<Button
							variant='contained'
							onClick={updateRights}
							sx={{
								backgroundColor: orange[500],
								'&:hover': { backgroundColor: orange[600] },
								paddingLeft: 4,
								paddingRight: 4,
								width: '150px',
							}}
						>
							Set
						</Button>
					</Stack>
				</Box>
			</Box>
		</Modal>
	);
}
