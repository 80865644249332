import { Link } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export default function ProgramsTab({ editingAccount }) {
	return (
		<Box sx={{ margin: 4 }}>
			{editingAccount.Programs.map((program) => (
				<Box
					key={program._id}
					sx={{ margin: 4 }}
				>
					<Link
						component={RouterLink}
						to={`/acquisitions/programs/${program._id}`}
					>
						{program.Name}
					</Link>
				</Box>
			))}
		</Box>
	);
}
