import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectFormats,
	getFormatsAsync,
	getFormatAsync,
	createFormatAsync,
	selectFormat,
	selectEditing,
	setEditing,
	removeFormat,
	updateFormatAsync,
} from '../../redux/formatSlice';
import { selectCurrentTab, updateTabStatus } from '../../redux/tabsSlice';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { blueGrey } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import { createAlertAsync } from '../../redux/alertsSlice';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
import Backdrop from '@mui/material/Backdrop';
import CreatedModified from '../shared/CreatedModified';

export default function Formats() {
	const dispatch = useDispatch();
	const currentTab = useSelector(selectCurrentTab);
	const formats = useSelector(selectFormats);
	const editing = useSelector(selectEditing());
	const format = useSelector(selectFormat());
	const [formatName, setFormatName] = useState('');
	const [addModalOpen, setAddModal] = useState(false);
	const [dialogueModalOpen, setDialogueModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleAddModal = () => setAddModal(!addModalOpen);
	const handleDialogueModal = () => setDialogueModal(!dialogueModalOpen);

	useEffect(() => {
		if (!formats) {
			dispatch(getFormatsAsync());
		}
	}, [formats, dispatch]);

	useEffect(() => {
		if (_.isEqual(editing, format)) {
			dispatch(updateTabStatus({ id: currentTab.id, status: true }));
		} else {
			dispatch(updateTabStatus({ id: currentTab.id, status: false }));
		}
	}, [currentTab, editing, format, dispatch]);

	useEffect(() => {
		if (editing) {
			setLoading(false);
		}
	}, [editing, formats]);

	function handleChange(event) {
		setFormatName(event.target.value);
	}

	function handleEdit(event) {
		dispatch(
			setEditing({ name: event.target.name, value: event.target.value })
		);
	}

	function addFormat(e) {
		e.preventDefault();

		const existingFormat = formats.find((format) => format.Name === formatName);

		console.log(existingFormat, formatName);

		if (!existingFormat) {
			dispatch(createFormatAsync(formatName));
			setFormatName('');
			handleAddModal();
		} else {
			dispatch(
				createAlertAsync({
					message: 'Format already exists',
					severity: 'error',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		}
	}

	function getFormat(id) {
		if (!editing || (editing && _.isEqual(format, editing))) {
			dispatch(removeFormat());
			setLoading(true);
			dispatch(getFormatAsync(id));
		} else {
			handleDialogueModal();
		}
	}

	function stopEditing() {
		if (_.isEqual(format, editing)) {
			setLoading(false);
			dispatch(removeFormat(editing._id));
		} else {
			handleDialogueModal();
		}
	}

	function updateFormat(e) {
		e.preventDefault();
		setLoading(true);
		dispatch(updateFormatAsync(editing));
	}

	function discardChanges() {
		setLoading(false);
		dispatch(removeFormat());
		handleDialogueModal();
	}

	let saveColor = _.isEqual(format, editing) ? 'green' : 'red';

	return (
		<Box>
			<Box sx={{ textAlign: 'right', marginBottom: 2, marginRight: 1 }}>
				<Tooltip title='Add Format'>
					<IconButton onClick={handleAddModal}>
						<AddCircleIcon
							sx={{
								color: blueGrey[600],
								backgroundColor: blueGrey[100],
								borderRadius: '5px',
								fontSize: '1.5em',
							}}
						/>
					</IconButton>
				</Tooltip>
			</Box>
			<Box
				sx={{
					display: 'flex',
				}}
			>
				<Box
					sx={{
						flexGrow: 1,
						backgroundColor: blueGrey[100],
						color: blueGrey[900],
						borderRadius: '5px',
						margin: 2,
						paddingTop: 4,
						paddingBottom: 4,
						textAlign: 'center',
						alignItems: 'center',
						width: '15em',
						minWidth: '15em',
						maxWidth: '15em',
						minHeight: '400px',
					}}
				>
					{formats && (
						<Box>
							<Box sx={{ color: blueGrey[900], marginBottom: 1 }}>ACTIVE</Box>
							{formats.map(
								(format) =>
									format.IsActive === true && (
										<Box
											key={format._id}
											sx={{ textAlign: 'center' }}
										>
											<Button
												variant='contained'
												onClick={() => getFormat(format._id)}
												sx={{
													minWidth: '10em',
													textTransform: 'capitalize',
													marginBottom: 2,
													backgroundColor: format.IsActive
														? blueGrey[800]
														: blueGrey[500],
													color: format.IsActive ? blueGrey[50] : blueGrey[900],
													'&:hover': {
														backgroundColor: blueGrey[900],
													},
												}}
											>
												{format.Name}
											</Button>
										</Box>
									)
							)}
						</Box>
					)}
					{formats && (
						<Box>
							<Box sx={{ color: blueGrey[900], marginTop: 4, marginBottom: 1 }}>
								INACTIVE
							</Box>
							{formats.map(
								(format) =>
									format.IsActive === false && (
										<Box
											key={format._id}
											sx={{ textAlign: 'center' }}
										>
											<Button
												variant='contained'
												onClick={() => getFormat(format._id)}
												sx={{
													minWidth: '10em',
													textTransform: 'capitalize',
													marginBottom: 2,
													backgroundColor: format.IsActive
														? blueGrey[800]
														: blueGrey[500],
													color: format.IsActive ? blueGrey[50] : blueGrey[900],
													'&:hover': {
														backgroundColor: blueGrey[900],
													},
												}}
											>
												{format.Name}
											</Button>
										</Box>
									)
							)}
						</Box>
					)}
					{!formats && (
						<Box>
							<CircularProgress />
						</Box>
					)}
				</Box>
				<Box
					sx={{
						flexGrow: 8,
						backgroundColor: blueGrey[100],
						color: blueGrey[900],
						borderRadius: '10px',
						margin: 2,
						paddingTop: 2,
						paddingBottom: 2,
						visibility: `${editing || loading ? 'visible' : 'hidden'}`,
						minWidth: '500px',
					}}
				>
					{editing && !loading ? (
						<>
							<Box
								component={'form'}
								onSubmit={updateFormat}
								sx={{ textAlign: 'right', marginRight: 4 }}
							>
								<Tooltip title='Save Format'>
									<IconButton
										onClick={updateFormat}
										sx={{ marginRight: 2 }}
									>
										<SaveIcon
											style={{
												color: saveColor,
												borderRadius: '5px',
												fontSize: '1.5em',
											}}
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Close'>
									<IconButton onClick={stopEditing}>
										<CancelIcon
											sx={{
												color: blueGrey[600],
												borderRadius: '5px',
												fontSize: '1.5em',
											}}
										/>
									</IconButton>
								</Tooltip>
							</Box>
							<Grid
								container
								spacing={2}
								sx={{ marginTop: 6 }}
							>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='IsActive'
										select
										required
										variant='standard'
										label='Is Active'
										value={editing.IsActive}
										name='IsActive'
										onChange={handleEdit}
										sx={{ width: '50%' }}
									>
										<MenuItem value={true}>TRUE</MenuItem>
										<MenuItem value={false}>FALSE</MenuItem>
									</TextField>
								</Grid>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Name'
										required
										variant='standard'
										label='Name'
										value={editing.Name}
										name='Name'
										onChange={handleEdit}
										sx={{
											width: '80%',
											'& .MuiInputBase-input': {
												textAlign: 'center',
											},
										}}
									/>
								</Grid>
							</Grid>
							<CreatedModified account={editing} />
						</>
					) : (
						<Box
							sx={{
								textAlign: 'center',
								alignItems: 'center',
								marginTop: 15,
								flexGrow: 20,
							}}
						>
							<CircularProgress
								size={60}
								color='primary'
								sx={{
									color: `${blueGrey[800]} !important`,
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Modal
				open={addModalOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Box
						component={'form'}
						onSubmit={addFormat}
					>
						<TextField
							key='Name'
							autoFocus
							required
							variant='standard'
							label='Name'
							value={formatName}
							name='Name'
							onChange={handleChange}
							//sx={textFieldStyles}
						/>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: '3em' }}
						>
							<Button
								variant='contained'
								onClick={handleAddModal}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
							<Button
								variant='contained'
								type='submit'
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Save
							</Button>
						</Stack>
					</Box>
				</Box>
			</Modal>
			<Modal
				open={dialogueModalOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Box
						sx={{
							textAlign: 'center',
							fontSize: '1.25em',
							color: blueGrey[900],
						}}
					>
						<Box>There are unsaved changes.</Box>
						<Box>How would you like to proceed?</Box>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: '3em' }}
						>
							<Button
								variant='contained'
								onClick={handleDialogueModal}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel & Go Back
							</Button>
							<Button
								variant='contained'
								onClick={discardChanges}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Discard Changes
							</Button>
						</Stack>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
}
