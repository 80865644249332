import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
	getScreenersAsync,
	selectScreeners,
	selectScreenersTableSettings,
	changeScreenerTableSettings,
} from '../../../../redux/screenerSlice';
import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	CircularProgress,
} from '@mui/material';
import ScreenersTableSearchBar from './ScreenersTableSearchBar';
import ScreenersTableHeader from './ScreenersTableHeader';
import ScreenersTablePagination from './ScreenersTablePagination';
import ScreenersTableRow from './ScreenersTableRow';
import { useNavigate } from 'react-router-dom';
import { screenerCells } from './screenersTableCells';
import { selectUser } from '../../../../redux/authSlice';
import { blueGrey } from '@mui/material/colors';

export default function ScreenersTable() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const cells = screenerCells();
	const screeners = useSelector(selectScreeners);
	const screenersTableSettings = useSelector(selectScreenersTableSettings);
	let count = 0;
	const user = useSelector(selectUser);

	useEffect(() => {
		if (screeners.length === 0 && !loading) {
			setLoading(true);
			dispatch(getScreenersAsync());
		}

		if (screeners.length > 0 && loading) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [screeners, loading]);

	function handleChange(e) {
		if (e.target.name === 'searchTerm') {
			dispatch(
				changeScreenerTableSettings({
					name: 'page',
					value: 1,
				})
			);
		}

		dispatch(
			changeScreenerTableSettings({
				name: e.target.name,
				value: e.target.value,
				checked: e.target.checked,
			})
		);
	}

	function handleSorting(id) {
		console.log(id);
		if (id === screenersTableSettings.sortBy) {
			dispatch(
				changeScreenerTableSettings({
					name: 'sortBy',
					sortBy: id,
					sortOrder:
						screenersTableSettings.sortOrder === 'asc' ? 'desc' : 'asc',
				})
			);
		} else {
			dispatch(
				changeScreenerTableSettings({
					name: 'sortBy',
					sortBy: id,
					sortOrder: 'asc',
				})
			);
		}
	}

	function handlePageChange(e, page) {
		dispatch(
			changeScreenerTableSettings({
				name: 'page',
				value: page + 1,
			})
		);
	}

	function handleRowsPerPageChange(e, name) {
		dispatch(
			changeScreenerTableSettings({
				name: name,
				value: e.target.value,
			})
		);
	}

	function renderScreeners() {
		let screenersToDisplay = [...screeners];

		if (!screenersTableSettings.showAll) {
			screenersToDisplay = screenersToDisplay.filter(
				(screener) => screener.Owner === user._id
			);
		}

		const searchTerms = screenersTableSettings.searchTerm
			.toLowerCase()
			.split(' ')
			.filter((word) => word !== '');

		if (screenersTableSettings.searchTerm.length > 0) {
			screenersToDisplay = screenersToDisplay.filter((screener, index) => {
				let anyTrue = false;

				if (screenersTableSettings.searchEvery) {
					if (
						searchTerms.every((term) =>
							screener.ScreenerNumber.toString().includes(term)
						) ||
						searchTerms.every((term) =>
							screener.Name.toLowerCase().includes(term)
						) ||
						searchTerms.every((term) =>
							screener.Status
								? screener.Status.toLowerCase().includes(term)
								: false
						)
					) {
						anyTrue = true;
					}
				} else {
					if (
						searchTerms.some((term) =>
							screener.ScreenerNumber.toString().includes(term)
						) ||
						searchTerms.some((term) =>
							screener.Name.toLowerCase().includes(term)
						) ||
						searchTerms.some((term) =>
							screener.Status
								? screener.Status.toLowerCase().includes(term)
								: false
						)
					) {
						anyTrue = true;
					}
				}
				return anyTrue;
			});
		}

		screenersToDisplay = screenersToDisplay.sort((screener1, screener2) => {
			if (
				!screener1[screenersTableSettings.sortBy] ||
				!screener2[screenersTableSettings.sortBy]
			) {
				return -1;
			}

			if (screenersTableSettings.sortOrder === 'asc') {
				if (
					screener2[screenersTableSettings.sortBy] >
					screener1[screenersTableSettings.sortBy]
				) {
					return -1;
				}

				if (
					screener2[screenersTableSettings.sortBy] <
					screener1[screenersTableSettings.sortBy]
				) {
					return 1;
				}

				return 0;
			} else {
				if (
					screener2[screenersTableSettings.sortBy] <
					screener1[screenersTableSettings.sortBy]
				) {
					return -1;
				}

				if (
					screener2[screenersTableSettings.sortBy] >
					screener1[screenersTableSettings.sortBy]
				) {
					return 1;
				}

				return 0;
			}
		});

		count = screenersToDisplay.length;

		screenersToDisplay = screenersToDisplay.slice(
			(screenersTableSettings.page - 1) * screenersTableSettings.pageSize,
			screenersTableSettings.page * screenersTableSettings.pageSize
		);

		return screenersToDisplay;
	}

	return (
		<Paper
			sx={{
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
				backgroundColor: 'inherit',
				color: 'inherit',
				boxShadow: 'none',
			}}
		>
			<ScreenersTableSearchBar
				dispatch={dispatch}
				navigate={navigate}
				screenersTableSettings={screenersTableSettings}
				changeScreenerTableSettings={changeScreenerTableSettings}
				handleChange={handleChange}
			/>
			<TableContainer>
				<Table>
					<ScreenersTableHeader
						cells={cells}
						handleSorting={handleSorting}
						handleChange={handleChange}
						screenersTableSettings={screenersTableSettings}
					/>
				</Table>
			</TableContainer>
			<TableContainer
				sx={{
					minHeight: 'calc(100vh - 280px)',
					maxHeight: 'calc(100vh - 280px)',
					borderTop: 'none',
					paddingBottom: 6,
					borderLeft: `10px solid ${blueGrey[800]}`,
					borderRight: `10px solid ${blueGrey[800]}`,
				}}
			>
				<Table>
					<TableBody
						sx={{
							width: '100%',
							overflowY: 'scroll',
						}}
					>
						{renderScreeners().map((screener) => (
							<ScreenersTableRow
								key={screener._id}
								cells={cells}
								screener={screener}
								navigate={navigate}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<ScreenersTablePagination
				count={count}
				screenersTableSettings={screenersTableSettings}
				handlePageChange={handlePageChange}
				handleRowsPerPageChange={handleRowsPerPageChange}
			/>
			{loading && (
				<CircularProgress
					color='warning'
					sx={{ position: 'absolute', top: '45%' }}
				/>
			)}
		</Paper>
	);
}

ScreenersTable.propTypes = {
	screeners: PropTypes.array,
};
