import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	licensedmedias: null,
	editing: null,
};

export const licensedmediaSlice = createSlice({
	name: 'licensedmedia',
	initialState: initialState,
	reducers: {
		createLicensedMedia: (state, action) => {
			state.licensedmedias.push(action.payload);

			state.licensedmedias = state.licensedmedias.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.licensedmedias = state.licensedmedias.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getLicensedMedia: (state, action) => {
			state.editing = action.payload;
		},
		getLicensedMedias: (state, action) => {
			state.licensedmedias = action.payload;

			state.licensedmedias = state.licensedmedias.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.licensedmedias = state.licensedmedias.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateLicensedMedia: (state, action) => {
			const licensedmediaIndex = state.licensedmedias.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.licensedmedias[licensedmediaIndex] = action.payload;

			state.licensedmedias = state.licensedmedias.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.licensedmedias = state.licensedmedias.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeLicensedMedias: (state, action) => {
			state.licensedmedias = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeLicensedMedia: (state, action) => {
			state.editing = null;
		},
		resetLicensedMedias: () => initialState,
	},
});

export const {
	createLicensedMedia,
	getLicensedMedia,
	getLicensedMedias,
	updateLicensedMedia,
	removeLicensedMedias,
	setEditing,
	removeLicensedMedia,
	resetLicensedMedias,
} = licensedmediaSlice.actions;

// Selectors
export const selectLicensedMedias = (state) =>
	state.licensedmedia.licensedmedias;

export const selectLicensedMedia = () => (state) => {
	if (state.licensedmedia.licensedmedias && state.licensedmedia.editing) {
		return state.licensedmedia.licensedmedias.find(
			(licensedmedia) => licensedmedia._id === state.licensedmedia.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.licensedmedia.editing;
};

// Create LicensedMedias Async
export const createLicensedMediaAsync = (licensedmedia) => async (dispatch) => {
	await api
		.post(
			`/admin/licensedmedia`,
			{ Name: licensedmedia },
			{ withCredentials: true }
		)
		.then((res) => {
			dispatch(createLicensedMedia(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get LicensedMedias Async
export const getLicensedMediasAsync = () => async (dispatch) => {
	await api
		.get(`/admin/licensedmedia`, { withCredentials: true })
		.then((res) => {
			dispatch(getLicensedMedias(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get LicensedMedia Async
export const getLicensedMediaAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/licensedmedia/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getLicensedMedia(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update LicensedMedia Async
export const updateLicensedMediaAsync = (licensedmedia) => async (dispatch) => {
	await api
		.put(`/admin/licensedmedia/${licensedmedia._id}`, licensedmedia, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateLicensedMedia(res.data));
			dispatch(
				createAlertAsync({
					message: 'LicensedMedia Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default licensedmediaSlice.reducer;
