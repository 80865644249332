import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	users: null,
	editing: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		createUser: (state, action) => {
			state.users.push(action.payload);

			state.users = state.users.sort((a, b) => {
				const nameA = a.LastName.toUpperCase();
				const nameB = b.LastName.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.users = state.users.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getUser: (state, action) => {
			const userIndex = state.users.findIndex(
				(user) => user._id === action.payload._id
			);

			if (userIndex !== -1) {
				state.users[userIndex] = action.payload;
			}

			state.editing = action.payload;
		},
		getUsers: (state, action) => {
			state.users = action.payload;

			state.users = state.users.sort((a, b) => {
				const nameA = a.LastName.toUpperCase();
				const nameB = b.LastName.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.users = state.users.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateUser: (state, action) => {
			const userIndex = state.users.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.users[userIndex] = action.payload;

			state.users = state.users.sort((a, b) => {
				const nameA = a.LastName.toUpperCase();
				const nameB = b.LastName.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.users = state.users.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeUsers: (state, action) => {
			state.users = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeUser: (state, action) => {
			state.editing = null;
		},
		resetUsers: () => initialState,
	},
});

export const {
	createUser,
	getUser,
	getUsers,
	updateUser,
	removeUsers,
	setEditing,
	removeUser,
	resetUsers,
} = userSlice.actions;

// Selectors
export const selectUsers = (state) => state.user.users;

export const selectUser = () => (state) => {
	if (state.user.users && state.user.editing) {
		return state.user.users.find((user) => user._id === state.user.editing._id);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.user.editing;
};

// Create Users Async
export const createUserAsync = (newUser) => async (dispatch) => {
	await api
		.post(`/admin/user`, newUser, { withCredentials: true })
		.then((res) => {
			dispatch(createUser(res.data));

			dispatch(
				createAlertAsync({
					message: 'User Created Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Users Async
export const getUsersAsync = () => async (dispatch) => {
	await api
		.get(`/admin/user`, { withCredentials: true })
		.then((res) => {
			dispatch(getUsers(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get User Async
export const getUserAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/user/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getUser(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update User Async
export const updateUserAsync = (user) => async (dispatch) => {
	await api
		.put(`/admin/user/${user._id}`, user, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateUser(res.data));
			dispatch(
				createAlertAsync({
					message: 'User Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Update User Password Async
export const updateUserPasswordAsync = (passwordForm) => async (dispatch) => {
	await api
		.put(`/auth/password`, passwordForm, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(
				createAlertAsync({
					message: 'Password Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default userSlice.reducer;
