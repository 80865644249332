import React from 'react';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { gridStyles } from './ComponentStyles';

export default function CreatedModified({ account, color, backgroundColor }) {
	return (
		<Grid
			container
			sx={gridStyles}
			style={{
				width: '100%',
				margin: 0,
				padding: 0,
				color: color,
				backgroundColor: backgroundColor,
				borderRadius: 8,
				marginTop: 16,
				marginBottom: 8,
			}}
		>
			<Grid
				sm={6}
				item
			>
				<p>
					<span style={{ fontWeight: 'bold', marginRight: '1em' }}>
						Created By:
					</span>
					{`${account.CreatedBy.FirstName} ${account.CreatedBy.LastName}`}
				</p>
				<p>
					<span style={{ fontWeight: 'bold', marginRight: '1em' }}>
						Created On:
					</span>
					{dayjs(account.CreatedDate).format('MMM. DD, YYYY h:mm:s a')}
				</p>
			</Grid>
			<Grid
				sm={6}
				item
			>
				<p>
					<span style={{ fontWeight: 'bold', marginRight: '1em' }}>
						LastModified By:
					</span>
					{`${account.LastModifiedBy.FirstName} ${account.LastModifiedBy.LastName}`}
				</p>
				<p>
					<span style={{ fontWeight: 'bold', marginRight: '1em' }}>
						Last Modified On:
					</span>
					{dayjs(account.LastModifiedDate).format('MMM. DD, YYYY h:mm:s a')}
				</p>
			</Grid>
		</Grid>
	);
}
