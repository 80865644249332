import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { blueGrey } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import dayjs from 'dayjs';
import { licensePeriodColor } from '../../../../utils/utilFunctions';

function Row({ sale }) {
	const [open, setOpen] = React.useState(false);

	const formatter = new Intl.NumberFormat(`en-US`, {
		style: 'currency',
		currency: sale.Currency,
	});

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						aria-label='expand row'
						size='small'
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell
					component='th'
					scope='row'
					align='center'
				>
					<Link
						to={`/sales/sales/${sale._id}`}
						component={RouterLink}
						sx={{
							textDecoration: 'none',
							color: blueGrey[800],
							fontWeight: '600',
							'&:hover': { color: blueGrey[600] },
						}}
					>
						{sale.ContractNumber}
					</Link>
				</TableCell>
				<TableCell align='center'>{sale.Territory}</TableCell>
				<TableCell
					align='center'
					sx={{ color: licensePeriodColor(sale.EndDate) }}
				>
					{dayjs(sale.StartDate).format('DD/MM/YYYY')} -{' '}
					{dayjs(sale.EndDate).format('DD/MM/YYYY')}
				</TableCell>
				<TableCell align='center'>{sale.TotalHours}</TableCell>
				<TableCell align='center'>
					{sale.Currency} {formatter.format(sale.TotalLicenceFee)}
				</TableCell>
				<TableCell align='center'>{`${sale.Owner.FirstName} ${sale.Owner.LastName}`}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
				>
					<Collapse
						in={open}
						timeout='auto'
						unmountOnExit
					>
						<Box sx={{ margin: 1 }}>
							<Typography
								gutterBottom
								component='div'
								sx={{ fontWeight: '500', paddingLeft: 16 }}
							>
								Sale Items
							</Typography>
							<Table size='small'>
								<TableHead>
									<TableRow>
										<TableCell
											align='left'
											sx={{ paddingLeft: 16, fontWeight: '500' }}
										>
											Program
										</TableCell>
										<TableCell align='center'>Hours</TableCell>
										<TableCell align='center'>Unit Price</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{sale.SaleItems.map((saleItem) => (
										<TableRow key={saleItem._id}>
											<TableCell
												component='th'
												scope='row'
												align='left'
												sx={{ paddingLeft: 16 }}
											>
												{saleItem.Program.Name}
											</TableCell>
											<TableCell align='center'>{saleItem.Hours}</TableCell>
											<TableCell align='center'>
												{sale.Currency} {formatter.format(saleItem.UnitPrice)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

export default function SalesTab({ sales }) {
	const [checked, setChecked] = React.useState(false);

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	let salesToDisplay = sales;

	if (salesToDisplay && checked) {
		salesToDisplay = salesToDisplay.filter((screener) => screener.IsActive);
	}

	return (
		<>
			<Grid
				//container
				sx={{ display: 'flex', marginTop: 4 }}
			>
				<Grid
					//item
					sx={{ flexGrow: 1 }}
				></Grid>
				<Grid
					//item
					sx={{ flexGrow: 0 }}
				>
					<Typography sx={{ display: 'inline' }}>Show All</Typography>
					<Switch
						checked={checked}
						onChange={handleChange}
						sx={{
							'& .Mui-checked': {
								color: `${blueGrey[800]} !important`,
							},
							'& .MuiSwitch-input': {
								color: blueGrey[800],
								backgroundColor: blueGrey[800],
							},
							'& .MuiSwitch-track': {
								color: blueGrey[800],
								backgroundColor: `${blueGrey[800]} !important`,
							},
						}}
					/>
					<Typography sx={{ display: 'inline' }}>Show Active</Typography>
				</Grid>
			</Grid>
			{salesToDisplay && salesToDisplay.length > 0 ? (
				<TableContainer
					component={Paper}
					sx={{ backgroundColor: blueGrey[50], boxShadow: 'none' }}
				>
					<Table aria-label='collapsible table'>
						<TableHead>
							<TableRow>
								<TableCell />
								<TableCell align='center'>Contract #</TableCell>
								<TableCell align='center'>Territory</TableCell>
								<TableCell align='center'>License Period</TableCell>
								<TableCell align='center'># of Hours</TableCell>
								<TableCell align='center'>Total Fee</TableCell>
								<TableCell align='center'>Owner</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{salesToDisplay.map((sale) => (
								<Row
									key={sale._id}
									sale={sale}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<div>No Sales</div>
			)}
		</>
	);
}
