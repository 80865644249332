import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	languages: null,
	editing: null,
};

export const languageSlice = createSlice({
	name: 'language',
	initialState: initialState,
	reducers: {
		createLanguage: (state, action) => {
			state.languages.push(action.payload);

			state.languages = state.languages.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.languages = state.languages.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getLanguage: (state, action) => {
			state.editing = action.payload;
		},
		getLanguages: (state, action) => {
			state.languages = action.payload;

			state.languages = state.languages.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.languages = state.languages.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateLanguage: (state, action) => {
			const languageIndex = state.languages.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.languages[languageIndex] = action.payload;

			state.languages = state.languages.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.languages = state.languages.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeLanguages: (state, action) => {
			state.languages = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeLanguage: (state, action) => {
			state.editing = null;
		},
		resetLanguages: () => initialState,
	},
});

export const {
	createLanguage,
	getLanguage,
	getLanguages,
	updateLanguage,
	removeLanguages,
	setEditing,
	removeLanguage,
	resetLanguages,
} = languageSlice.actions;

// Selectors
export const selectLanguages = (state) => state.language.languages;

export const selectLanguage = () => (state) => {
	if (state.language.languages && state.language.editing) {
		return state.language.languages.find(
			(language) => language._id === state.language.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.language.editing;
};

// Create Languages Async
export const createLanguageAsync = (language) => async (dispatch) => {
	await api
		.post(`/admin/language`, { Name: language }, { withCredentials: true })
		.then((res) => {
			dispatch(createLanguage(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Languages Async
export const getLanguagesAsync = () => async (dispatch) => {
	await api
		.get(`/admin/language`, { withCredentials: true })
		.then((res) => {
			dispatch(getLanguages(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Language Async
export const getLanguageAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/language/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getLanguage(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Language Async
export const updateLanguageAsync = (language) => async (dispatch) => {
	await api
		.put(`/admin/language/${language._id}`, language, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateLanguage(res.data));
			dispatch(
				createAlertAsync({
					message: 'Language Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default languageSlice.reducer;
