import React from 'react';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return {
			hasError: true,
			error: error,
		};
	}

	componentDidCatch(error, errorInfo) {
		// console.info('ERROR: ' + error);
		// Object.keys(errorInfo).map((key, index) =>
		// 	console.info(key.toUpperCase(), errorInfo[key])
		// );
		// this.setState = { error: error, errorInfo: errorInfo };
		// return {
		// 	hasError: true,
		// 	error: error,
		// 	errorInfo: JSON.stringify(errorInfo),
		// };
	}

	render() {
		const { error } = this.state;
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div>
					<h1>Something went wrong.</h1>
					<h3>{`Error: ${error.message}`}</h3>
				</div>
			);
		}

		return this.props.children;
	}
}
