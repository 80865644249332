import React, { useEffect, useState } from 'react';
import { blueGrey } from '@mui/material/colors';
import {
	//Accordion,
	//AccordionDetails,
	//AccordionSummary,
	//Typography,
	Box,
	//TextField,
	Grid,
	//MenuItem,
	Tooltip,
	IconButton,
	//CircularProgress,
	//ListItemText,
	//FormControl,
	//Select,
	//InputLabel,
	//Checkbox,
	//Input,
} from '@mui/material';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import { gridStyles, gridItemStyles } from '../../ComponentStyles';
import {
	changeEditingAccountContact,
	updateAccountContactAsync,
} from '../../../../redux/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle } from '@mui/icons-material';
//import SaveIcon from '@mui/icons-material/Save';
//import _ from 'lodash';
//import { MuiTelInput } from 'mui-tel-input';
import {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
	validatePhoneNumberLength,
} from 'libphonenumber-js';
import { createAlertAsync } from '../../../../redux/alertsSlice';
import ContactModal from '../../ContactModal';
//import CreatedModified from '../../CreatedModified';
import { selectDepartments } from '../../../../redux/departmentSlice';
import ContactAccordion from './contactsTab/ContactAccordion';

export default function ContactsTab({
	originalAccount,
	editingAccount,
	users,
}) {
	const dispatch = useDispatch();
	const [saving, setSaving] = useState(false);
	const departments = useSelector(selectDepartments);
	const [newContactModal, setNewContactModal] = useState(false);

	function newContactModalOpen() {
		setNewContactModal(true);
	}

	function newContactModalClose() {
		setNewContactModal(false);
	}

	function submit(e, contact) {
		e.preventDefault();
		if (
			isPossiblePhoneNumber(contact.MobilePhone) &&
			isValidPhoneNumber(contact.MobilePhone) &&
			validatePhoneNumberLength(contact.MobilePhone) !== 'TOO_SHORT' &&
			validatePhoneNumberLength(contact.MobilePhone) !== 'TOO_LONG'
		) {
			setSaving(true);
			dispatch(updateAccountContactAsync(contact));
		} else {
			dispatch(
				createAlertAsync({
					message: 'Phone Number Is Not Valid',
					severity: 'error',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		}
	}

	const handleContactChange = (e, contactId, targetName) => {
		let name = '';
		let value = '';

		if (!e.target) {
			if (targetName === 'Phone') {
				name = 'Phone';
				value = e;
			} else {
				name = 'MobilePhone';
				value = e;
			}
		} else {
			name = e.target.name;
			value = e.target.value;
		}

		dispatch(
			changeEditingAccountContact({
				id: editingAccount._id,
				contactId: contactId,
				name: name,
				value: value,
			})
		);
	};

	function handleDepartmentChange(e, contactId) {
		let newValue =
			typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');
		dispatch(
			changeEditingAccountContact({
				id: editingAccount._id,
				contactId: contactId,
				name: e.target.name,
				value: correctedValue,
			})
		);
	}

	useEffect(() => {
		setSaving(false);
	}, [originalAccount.Contacts]);

	// const textFieldStyles = {
	// 	minWidth: '80%',
	// 	textAlign: 'left',
	// 	color: blueGrey[900],
	// 	marginTop: '1em',
	// 	marginBottom: '1em',

	// 	'& .MuiInput-root': {
	// 		color: blueGrey[900],
	// 	},
	// 	'& .MuiInputLabel-root': {
	// 		color: blueGrey[900],
	// 	},
	// 	'& .MuiInput-root:after': {
	// 		borderColor: blueGrey[900],
	// 	},
	// };

	//const accordionGridStyles = { width: '25%', flexShrink: 0, fontWeight: 'bold' };

	useEffect(() => {
		//eslint disable-line
	}, [editingAccount.Contacts]);

	return (
		<Box
			sx={{
				margin: '2em 1em',
			}}
		>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[50],
					marginBottom: 2,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					color: blueGrey[900],
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Grid
					item
					sx={{ flexGrow: 1, fontWeight: 'bold', color: blueGrey[700] }}
				></Grid>
				<Grid
					item
					sx={{ flexGrow: 0 }}
				>
					<Tooltip
						title='Add Contact'
						arrow={true}
					>
						<IconButton onClick={newContactModalOpen}>
							<AddCircle
								sx={{
									color: blueGrey[600],
									backgroundColor: 'transparent',
									borderBottomRadius: 10,
									borderTopRadius: 10,
									fontSize: '1.5em',
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[50],
					color: blueGrey[800],
					padding: '1em 0',
					boxShadow: `0px 0px 2px .5px ${blueGrey[600]}`,
				}}
			>
				<Grid
					sm={3}
					item
				>
					Name
				</Grid>
				<Grid
					sm={2}
					item
				>
					Title
				</Grid>
				<Grid
					sm={2}
					item
				>
					Department
				</Grid>
				<Grid
					sm={3}
					item
				>
					Email
				</Grid>
				<Grid
					sm={2}
					item
				>
					Status
				</Grid>
			</Grid>
			{editingAccount.Contacts.length > 0 &&
				editingAccount.Contacts.map((contact, index) => (
					<Box key={contact._id}>
						{originalAccount.Contacts[index].IsActive && (
							<ContactAccordion
								originalAccount={originalAccount}
								editingAccount={editingAccount}
								contact={contact}
								index={index}
								submit={submit}
								saving={saving}
								handleContactChange={handleContactChange}
								users={users}
								handleDepartmentChange={handleDepartmentChange}
								departments={departments}
							/>
						)}
					</Box>
				))}
			{editingAccount.Contacts.length > 0 &&
				editingAccount.Contacts.map((contact, index) => (
					<Box key={contact._id}>
						{!originalAccount.Contacts[index].IsActive && (
							<ContactAccordion
								originalAccount={originalAccount}
								editingAccount={editingAccount}
								contact={contact}
								index={index}
								submit={submit}
								saving={saving}
								handleContactChange={handleContactChange}
								users={users}
								handleDepartmentChange={handleDepartmentChange}
								departments={departments}
							/>
						)}
					</Box>
				))}

			<ContactModal
				originalAccount={originalAccount}
				editingAccount={editingAccount}
				newContactModal={newContactModal}
				newContactModalClose={newContactModalClose}
				users={users}
			/>
		</Box>
	);
}
