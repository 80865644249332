import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { persistStore } from 'reduxjs-toolkit-persist';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/layout/Loading';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

let persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<PersistGate loading={<Loading />} persistor={persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
);
