import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableFooter, TablePagination, TableRow } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

export default function AccountsTablePagination({
	count,
	accountsTableSettings,
	handlePageChange,
	handleRowsPerPageChange,
}) {
	return (
		<Table
			sx={{
				position: 'absolute',
				marginTop: 2,
				bottom: -4,
				left: 0,
			}}
		>
			<TableFooter>
				<TableRow>
					<TablePagination
						count={count}
						color='secondary'
						variant='outlined'
						page={accountsTableSettings.page - 1}
						onPageChange={handlePageChange}
						rowsPerPage={accountsTableSettings.pageSize}
						onRowsPerPageChange={(e) => handleRowsPerPageChange(e, 'pageSize')}
						showFirstButton
						showLastButton
						rowsPerPageOptions={[10, 15, 25, 50, 100]}
						sx={{
							width: '100%',
							color: blueGrey[50],
							backgroundColor: blueGrey[800],
							border: `none`,
							//borderBottomLeftRadius: 32,
							//borderBottomRightRadius: 32,
							'& .MuiTablePagination-selectIcon': {
								color: blueGrey[50],
							},
							'& .MuiTablePagination-root': {
								position: 'sticky',
								bottom: -8,
							},
						}}
					/>
				</TableRow>
			</TableFooter>
		</Table>
	);
}

AccountsTablePagination.propTypes = {
	accountsTableSettings: PropTypes.object.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handleRowsPerPageChange: PropTypes.func.isRequired,
};
