import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTabs, selectCurrentTab } from '../redux/tabsSlice';
import ProgramsTable from '../components/acquisitions/programs/programsTable/ProgramsTable';
import { blueGrey } from '@mui/material/colors';
import AccountsTable from '../components/shared/accountsTable/AccountsTable';
import ProgramDetails from '../components/acquisitions/programs/programDetails/ProgramDetails';
import AccountDetails from '../components/shared/accountDetails/AccountDetails';
import NewProgramStepper from '../components/acquisitions/programs/newProgram/NewProgramStepper';
import NewAccountStepper from '../components/shared/newAccount/NewAccountStepper';

export default function Aquisitions({ setParams }) {
	let params = useParams();
	let location = useLocation();

	useEffect(() => {
		setParams(params);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	let tabs = useSelector(selectTabs);
	let tab = tabs.find((tab) => tab.link === location.pathname);
	let currentTab = useSelector(selectCurrentTab);

	function renderMainContent(tab) {
		if (tab) {
			if (tab.type === 'producers' && !params.id) {
				return <AccountsTable type={'Producer'} />;
			}

			if (tab.type === 'producers' && params.id === 'new-producer') {
				return (
					<NewAccountStepper
						id={tab.id}
						type={'Producer'}
					/>
				);
			}

			if (tab.type === 'producers' && params.id) {
				return (
					<AccountDetails
						id={params.id}
						type={'Producer'}
						currentTab={currentTab}
					/>
				);
			}

			if (tab.type === 'programs' && !params.id) {
				return <ProgramsTable />;
			}

			if (tab.type === 'programs' && params.id === 'new-program') {
				return <NewProgramStepper id={tab.id} />;
			}

			if (tab.type === 'programs' && params.id) {
				return (
					<ProgramDetails
						id={params.id}
						currentTab={currentTab}
					/>
				);
			}
		}
	}

	return (
		<div
			style={{
				//padding: 4,
				paddingLeft: 0,
				paddingRight: 0,
				//paddingBottom: 4,
				textAlign: 'center',
				backgroundColor: blueGrey[500],
				color: 'white',
				borderRadius: 8,
				overflow: 'hidden',
			}}
		>
			{tab && renderMainContent(tab)}
		</div>
	);
}
