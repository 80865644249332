import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteProgramAsync,
	getProgramAsync,
	selectEditingProgram,
	selectOriginalProgram,
	updateProgramAsync,
} from '../../../../redux/programSlice';
import {
	updateTabStatus,
	updateTertiaryTab,
} from '../../../../redux/tabsSlice';
import { selectUser } from '../../../../redux/authSlice';
import _ from 'lodash';
import {
	Box,
	Tabs,
	Tab,
	CircularProgress,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { DeleteForeverRounded, Save } from '@mui/icons-material';
import ProgramTab from './programTab/ProgramTab';
import RightsInTab from './rightsInTab/RightsInTab';
import RightsOutTab from './rightsOutTab/RightsOutTab';
import ProgramItemsTab from './programItemsTab/ProgramItemsTab';
import DeleteProgramModal from './DeleteProgramModal';

export default function ProgramDetails({ id, currentTab }) {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [deleteProgramModal, setDeleteProgramModal] = useState(false);
	const originalProgram = useSelector(selectOriginalProgram(id));
	const editingProgram = useSelector(selectEditingProgram(id));

	useEffect(() => {
		if (!editingProgram && !loading) {
			setLoading(true);
			dispatch(getProgramAsync(id));
		}

		if (editingProgram && loading) {
			setLoading(false);
		}

		//eslint-disable-next-line
	}, [editingProgram, loading]);

	useEffect(() => {
		if (_.isEqual(originalProgram, editingProgram)) {
			dispatch(updateTabStatus({ id: id, status: true }));
		} else {
			dispatch(updateTabStatus({ id: id, status: false }));
		}
	}, [id, originalProgram, editingProgram, dispatch]);

	const tertiaryTabChange = (event, newValue) => {
		dispatch(updateTertiaryTab({ id: id, newValue: newValue }));
	};

	function submit(e) {
		e.preventDefault();
		if (e.target.name === 'ProgramItemModal' || e.target.name === 'NoteModal') {
			return;
		}
		setSaving(true);
		dispatch(updateProgramAsync(editingProgram));
	}

	useEffect(() => {
		if (saving && _.isEqual(originalProgram, editingProgram)) {
			setSaving(false);
		}
	}, [saving, originalProgram, editingProgram]);

	function deleteProgram(e) {
		e.preventDefault();
		setDeleting(true);
		dispatch(deleteProgramAsync(editingProgram._id));
	}

	useEffect(() => {
		if (deleting && !editingProgram.IsActive) {
			setDeleting(false);
			setDeleteProgramModal(false);
		}
	}, [deleting, editingProgram]);

	return (
		<Box>
			{editingProgram ? (
				<Box
					component='form'
					onSubmit={submit}
					sx={{
						width: '100%',
						padding: 2,
					}}
				>
					<Grid container>
						<Grid
							sm={12}
							item
							sx={{ display: 'flex' }}
						>
							<Typography
								style={{
									flexGrow: 1,
									textAlign: 'left',
									marginLeft: 16,
									fontSize: '2em',
									color: `${
										_.isEqual(originalProgram, editingProgram)
											? blueGrey[50]
											: orange[800]
									}`,
								}}
							>
								{`${editingProgram.ProgramCode} - ${editingProgram.Name}`}
							</Typography>
							{/* {user.Role === 'admin' && (
								<Tooltip title='Mark Program Inactive'>
									<span>
										<IconButton
											onClick={() => setDeleteProgramModal(true)}
											disabled={deleting}
											sx={{ border: 'none', marginRight: 4 }}
										>
											{deleting && editingProgram.IsActive ? (
												<CircularProgress color='warning' />
											) : (
												<DeleteForeverRounded
													sx={{
														color: blueGrey[50],
														fontSize: '2em',
														cursor: 'pointer',
													}}
												/>
											)}
										</IconButton>
									</span>
								</Tooltip>
							)} */}
							<Tooltip title={saving ? 'Saving' : 'Save Program'}>
								<span>
									<IconButton
										type='submit'
										disabled={
											saving && !_.isEqual(originalProgram, editingProgram)
										}
										sx={{ marginRight: 2 }}
									>
										{saving ? (
											<CircularProgress color='warning' />
										) : (
											<Save
												sx={{
													color: `${
														_.isEqual(originalProgram, editingProgram)
															? blueGrey[50]
															: orange[800]
													}`,
													fontSize: '2em',
													cursor: 'pointer',
												}}
											/>
										)}
									</IconButton>
								</span>
							</Tooltip>
						</Grid>
					</Grid>
					<Tabs
						value={currentTab.tertiary || 0}
						onChange={tertiaryTabChange}
						variant='scrollable'
						scrollButtons={true}
						allowScrollButtonsMobile
						sx={{
							'& .MuiTabs-indicator': { backgroundColor: blueGrey[50] },
							'& .MuiTab-root': {
								margin: 0,
								color: blueGrey[50],
								backgroundColor: blueGrey[800],
								marginLeft: '1em',
								borderTopLeftRadius: '4px',
								borderTopRightRadius: '4px',
								paddingTop: '.5em',
								paddingBottom: '.5em',
								minHeight: '0px',
								top: '16px',
								opacity: 0.75,
								boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
								'&:hover': {
									//backgroundColor: 'white',
									//color: 'black',
									opacity: 0.75,
								},
							},
							'& .Mui-selected': {
								color: `${blueGrey[900]}`,
								backgroundColor: blueGrey[50],
								opacity: '1 !important',
								boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
							},
							'& .MuiTabs-scrollButtons': {
								color: 'white',
								'&.Mui-disabled': { opacity: 0, color: 'white' },
							},
						}}
					>
						<Tab
							label='Program'
							//sx={companyTabColor()}
							sx={programTabColor(originalProgram, editingProgram)}
						/>
						<Tab
							label='Program Items'
							//sx={companyTabColor()}
							sx={programItemsTabColor(
								originalProgram.ProgramItems,
								editingProgram.ProgramItems
							)}
						/>
						<Tab
							label='Rights In'
							//sx={companyTabColor()}
							sx={programRightsInTabColor(
								originalProgram.RightsIn,
								editingProgram.RightsIn
							)}
						/>
						<Tab
							label='Rights Out'
							//sx={companyTabColor()}
						/>
					</Tabs>
					<Box
						role='tabpanel'
						hidden={currentTab.tertiary !== 0}
						style={{
							backgroundColor: blueGrey[50],
							//paddingTop: 20,
							margin: 0,
							color: blueGrey[900],
							width: '100%',
							borderRadius: 8,
							overflow: 'hidden',
						}}
					>
						<ProgramTab
							id={id}
							currentTab={currentTab}
							originalProgram={originalProgram}
							editingProgram={editingProgram}
						/>
					</Box>
					<div
						role='tabpanel'
						hidden={currentTab.tertiary !== 1}
						style={{
							backgroundColor: blueGrey[50],
							paddingTop: 0,
							margin: 0,
							color: blueGrey[900],
							width: '100%',
							borderRadius: 8,
							overflow: 'hidden',
						}}
					>
						<ProgramItemsTab editingProgram={editingProgram} />
					</div>
					<div
						role='tabpanel'
						hidden={currentTab.tertiary !== 2}
						style={{
							backgroundColor: blueGrey[50],
							paddingTop: 0,
							margin: 0,
							color: blueGrey[900],
							width: '100%',
							borderRadius: 8,
							overflow: 'hidden',
						}}
					>
						<RightsInTab
							programId={editingProgram._id}
							rightsIn={editingProgram.RightsIn}
						/>
					</div>
					<div
						role='tabpanel'
						hidden={currentTab.tertiary !== 3}
						style={{
							backgroundColor: blueGrey[50],
							paddingTop: 0,
							margin: 0,
							color: blueGrey[900],
							width: '100%',
							borderRadius: 8,
							overflow: 'hidden',
						}}
					>
						<RightsOutTab rightsOut={editingProgram.RightsOut} />
					</div>
				</Box>
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '70vh',
						top: '30%',
					}}
				>
					<CircularProgress
						size={60}
						color='primary'
						sx={{
							color: `${blueGrey[100]} !important`,
						}}
					/>
				</div>
			)}
			<DeleteProgramModal
				deleting={deleting}
				deleteProgram={deleteProgram}
				deleteProgramModal={deleteProgramModal}
				setDeleteProgramModal={setDeleteProgramModal}
			/>
		</Box>
	);

	function programTabColor(originalAccount, editingAccount) {
		if (
			_.isEqual(originalAccount.Name, editingAccount.Name) &&
			_.isEqual(originalAccount.Genre, editingAccount.Genre) &&
			_.isEqual(
				originalAccount.CountryOfOrigin,
				editingAccount.CountryOfOrigin
			) &&
			_.isEqual(originalAccount.Language, editingAccount.Language) &&
			_.isEqual(originalAccount.Currency, editingAccount.Currency) &&
			_.isEqual(originalAccount.Format, editingAccount.Format) &&
			//_.isEqual(originalAccount.Season, editingAccount.Season) &&
			_.isEqual(
				originalAccount.YearOfProduction,
				editingAccount.YearOfProduction
			) &&
			_.isEqual(originalAccount.Episodes, editingAccount.Episodes) &&
			_.isEqual(originalAccount.Definition, editingAccount.Definition) &&
			_.isEqual(originalAccount.TotalHours, editingAccount.TotalHours) &&
			_.isEqual(originalAccount.DeliveryDate, editingAccount.DeliveryDate) &&
			_.isEqual(originalAccount.RunningTime, editingAccount.RunningTime) &&
			_.isEqual(
				originalAccount.DistributionAdvance,
				editingAccount.DistributionAdvance
			) &&
			_.isEqual(originalAccount.LaunchBudget, editingAccount.LaunchBudget) &&
			_.isEqual(
				originalAccount.PerformanceGuarantee,
				editingAccount.PerformanceGuarantee
			) &&
			_.isEqual(originalAccount.ExpensesCap, editingAccount.ExpensesCap) &&
			_.isEqual(
				originalAccount.PerformanceGuaranteeDate,
				editingAccount.PerformanceGuaranteeDate
			) &&
			_.isEqual(
				originalAccount.DistributionExpenses,
				editingAccount.DistributionExpenses
			) &&
			_.isEqual(
				originalAccount.PerformanceGuaranteeValue,
				editingAccount.PerformanceGuaranteeValue
			) &&
			_.isEqual(
				originalAccount.CrossCollateralize,
				editingAccount.CrossCollateralize
			) &&
			_.isEqual(
				originalAccount.CrossCollateralizePrograms,
				editingAccount.CrossCollateralizePrograms
			) &&
			_.isEqual(originalAccount.VimeoLink, editingAccount.VimeoLink) &&
			_.isEqual(originalAccount.Synposis, editingAccount.Synposis) &&
			_.isEqual(originalAccount.Awards, editingAccount.Awards)
		) {
			return {
				color: blueGrey[50],
				'& .Mui-selected': {
					color: `${blueGrey[800]} !important`,
					backgroundColor: blueGrey[50],
					opacity: '1 !important',
					boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
				},
			};
		} else {
			return {
				color: `${orange[800]} !important`,
				'& .Mui-selected': {
					color: `${orange[800]} !important`,
					backgroundColor: blueGrey[50],
					opacity: '1 !important',
					boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
				},
			};
		}
	}
}

function programItemsTabColor(originalProgramItems, editingProgramItems) {
	if (_.isEqual(originalProgramItems, editingProgramItems)) {
		return {
			color: blueGrey[50],
			'& .Mui-selected': {
				color: `${blueGrey[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	} else {
		return {
			color: `${orange[800]} !important`,
			'& .Mui-selected': {
				color: `${orange[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	}
}

function programRightsInTabColor(
	originalProgramRightsIn,
	editingProgramRightsIn
) {
	if (_.isEqual(originalProgramRightsIn, editingProgramRightsIn)) {
		return {
			color: blueGrey[50],
			'& .Mui-selected': {
				color: `${blueGrey[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	} else {
		return {
			color: `${orange[800]} !important`,
			'& .Mui-selected': {
				color: `${orange[800]} !important`,
				backgroundColor: blueGrey[50],
				opacity: '1 !important',
				boxShadow: `0px 0px .5px.5px ${blueGrey[900]}`,
			},
		};
	}
}
