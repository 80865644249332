import React from 'react';
import {
	Button,
	TextField,
	Grid,
	MenuItem,
	Box,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	Checkbox,
	ListItemText,
} from '@mui/material';
import {
	gridStyles,
	gridItemStyles,
	//textFieldStyles,
} from '../ComponentStyles';
import { changeEditingAccountContact } from '../../../redux/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MuiTelInput } from 'mui-tel-input';
import {
	//isPossiblePhoneNumber,
	isValidPhoneNumber,
	//validatePhoneNumberLength,
} from 'libphonenumber-js';
//import { selectDepartments } from '../../redux/departmentSlice';
import { blueGrey, orange } from '@mui/material/colors';
import { selectDepartments } from '../../../redux/departmentSlice';

export default function Contact({
	id,
	newAccount,
	user,
	users,
	handleNext,
	handleBack,
}) {
	const dispatch = useDispatch();
	const departments = useSelector(selectDepartments);
	const handleChange = (e, targetName) => {
		let name = '';
		let value = '';

		if (!e.target) {
			if (targetName === 'Phone') {
				name = 'Phone';
				value = e;
			} else {
				name = 'MobilePhone';
				value = e;
			}
		} else {
			name = e.target.name;
			value = e.target.value;
		}

		dispatch(
			changeEditingAccountContact({
				id: id,
				contactId: id,
				name: name,
				value: value,
			})
		);
	};

	function handleDepartmentChange(e, contactId) {
		let newValue =
			typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');
		dispatch(
			changeEditingAccountContact({
				id: newAccount._id,
				contactId: contactId,
				name: e.target.name,
				value: correctedValue,
			})
		);
	}

	function submit(e) {
		e.preventDefault();

		handleNext();
	}

	return (
		<Box
			component={'form'}
			onSubmit={submit}
			sx={{ color: 'inherit' }}
		>
			<Box sx={{ margin: 0, marginTop: 0 }}>
				<Grid
					container
					sx={gridStyles}
				>
					<Grid
						sm={6}
						sx={gridItemStyles}
						item
					>
						<TextField
							key='Salutation'
							variant='outlined'
							color='secondary'
							label='Salutation'
							value={newAccount.Contacts[0].Salutation}
							name='Salutation'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='FirstName'
							required
							variant='outlined'
							color='secondary'
							label='FirstName'
							value={newAccount.Contacts[0].FirstName}
							name='FirstName'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='LastName'
							required
							variant='outlined'
							color='secondary'
							label='LastName'
							value={newAccount.Contacts[0].LastName}
							name='LastName'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='Title'
							required
							variant='outlined'
							color='secondary'
							label='Title'
							value={newAccount.Contacts[0].Title}
							name='Title'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='Territory'
							variant='outlined'
							color='secondary'
							label='Territory'
							value={newAccount.Contacts[0].Territory}
							name='Territory'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
					</Grid>
					<Grid
						sm={6}
						sx={gridItemStyles}
						item
					>
						<TextField
							key='Owner'
							select
							required
							variant='outlined'
							color='secondary'
							label='Owner'
							value={newAccount.Contacts[0].Owner}
							name='Owner'
							onChange={handleChange}
							sx={textFieldStyles}
						>
							{users &&
								users.map((user) => (
									<MenuItem
										key={user._id}
										value={user._id}
										disabled={!user.IsActive}
										sx={{
											display: `${
												user.IsActive || newAccount.Owner === user._id
													? 'block'
													: 'none'
											}`,
										}}
									>{`${user.FirstName} ${user.LastName}`}</MenuItem>
								))}
						</TextField>
						<MuiTelInput
							key='Phone'
							type='phone'
							variant='outlined'
							color='secondary'
							label='Phone'
							value={newAccount.Contacts[0].Phone}
							name='Phone'
							onChange={(e) => handleChange(e, 'Phone')}
							forceCallingCode={true}
							error={!isValidPhoneNumber}
							sx={textFieldStyles}
						/>
						<MuiTelInput
							key='MobilePhone'
							type='phone'
							required
							variant='outlined'
							color='secondary'
							label='Mobile Phone'
							value={newAccount.Contacts[0].MobilePhone}
							name='MobilePhone'
							onChange={(e) => handleChange(e, 'MobilePhone')}
							forceCallingCode={true}
							error={!isValidPhoneNumber}
							sx={textFieldStyles}
						/>
						<TextField
							key='Email'
							required
							variant='outlined'
							color='secondary'
							type='email'
							label='Email'
							value={newAccount.Contacts[0].Email}
							name='Email'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<FormControl sx={textFieldStyles}>
							<InputLabel sx={{ left: -14 }}>Department</InputLabel>
							<Select
								name='Department'
								multiple
								value={newAccount.Contacts[0].Department}
								onChange={(e) => handleDepartmentChange(e, newAccount._id)}
								input={
									<OutlinedInput label='Department'>Department</OutlinedInput>
								}
								renderValue={(selected) => selected.join(', ')}
								MenuProps={{
									PaperProps: {
										style: {
											width: '20%',
											maxWidth: '20%',
										},
									},
								}}
							>
								{departments.map((department) => (
									<MenuItem
										key={department._id}
										value={department.Name}
									>
										<Checkbox
											checked={
												newAccount.Contacts[0].Department.indexOf(
													department.Name
												) > -1
											}
										/>
										<ListItemText primary={department.Name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
			<Box
				sx={{
					margin: 0,
					textAlign: 'left',
					marginLeft: 7,
					fontWeight: 'bold',
				}}
			>
				Address:
			</Box>
			<Box sx={{ margin: 0, marginTop: 0 }}>
				<Grid
					container
					sx={gridStyles}
				>
					<Grid
						sm={6}
						sx={gridItemStyles}
						item
					>
						<TextField
							key='Address1'
							variant='outlined'
							color='secondary'
							label='Address1'
							value={newAccount.Contacts[0].Address1}
							name='Address1'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='Address2'
							variant='outlined'
							color='secondary'
							label='Address2'
							value={newAccount.Contacts[0].Address2}
							name='Address2'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='City'
							variant='outlined'
							color='secondary'
							label='City'
							value={newAccount.Contacts[0].City}
							name='City'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='State'
							variant='outlined'
							color='secondary'
							label='State'
							value={newAccount.Contacts[0].State}
							name='State'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='PostalCode'
							variant='outlined'
							color='secondary'
							label='PostalCode'
							value={newAccount.Contacts[0].PostalCode}
							name='PostalCode'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
						<TextField
							key='Country'
							variant='outlined'
							color='secondary'
							label='Country'
							value={newAccount.Contacts[0].Country}
							name='Country'
							onChange={handleChange}
							sx={textFieldStyles}
						/>
					</Grid>
					<Grid
						sm={6}
						sx={gridItemStyles}
						item
					>
						<TextField
							key='Notes'
							variant='outlined'
							color='secondary'
							label='Notes'
							value={newAccount.Contacts[0].Notes}
							name='Notes'
							multiline
							rows={11}
							onChange={handleChange}
							sx={textFieldStyles}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					//disabled={activeStep === 0}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
						//display: `${activeStep === 0 ? 'none' : 'block'}`,
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					type='submit'
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Next
				</Button>
			</Box>
		</Box>
	);
}

const textFieldStyles = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],

		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};
