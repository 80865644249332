import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';
import {
	addAccountNote,
	updateAccountNote,
	deleteAccountNote,
} from './accountSlice';
import {
	addScreenerNote,
	updateScreenerNote,
	deleteScreenerNote,
} from './screenerSlice';
import {
	addProgramNote,
	updateProgramNote,
	deleteProgramNote,
} from './programSlice';

// Add Account Note Async
export const addNoteAsync = (id, type, text) => async (dispatch) => {
	await api
		.post(
			`/note/`,
			{ id: id, type: type, text: text },
			{
				withCredentials: true,
			}
		)
		.then((res) => {
			if (type === 'Account') {
				dispatch(addAccountNote(res.data));
			}

			if (type === 'Screener') {
				dispatch(addScreenerNote(res.data));
			}

			if (type === 'Program') {
				dispatch(addProgramNote(res.data));
			}

			dispatch(
				createAlertAsync({
					message: 'Note Added Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Account Note Async
export const updateNoteAsync = (id, text) => async (dispatch) => {
	await api
		.put(
			`/note/${id}`,
			{ text: text },
			{
				withCredentials: true,
			}
		)
		.then((res) => {
			if (res.data.Type === 'Account') {
				dispatch(updateAccountNote(res.data));
			}

			if (res.data.Type === 'Screener') {
				dispatch(updateScreenerNote(res.data));
			}

			if (res.data.Type === 'Program') {
				dispatch(updateProgramNote(res.data));
			}

			dispatch(
				createAlertAsync({
					message: 'Note Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

export const deleteNoteAsync = (id, noteId, type) => async (dispatch) => {
	await api
		.delete(`/note/${noteId}`, {
			withCredentials: true,
		})
		.then((res) => {
			if (type === 'Account') {
				dispatch(deleteAccountNote({ id: id, noteId: noteId }));
			}

			if (type === 'Screener') {
				dispatch(deleteScreenerNote({ id: id, noteId: noteId }));
			}

			if (type === 'Program') {
				dispatch(deleteProgramNote({ id: id, noteId: noteId }));
			}

			dispatch(
				createAlertAsync({
					message: 'Note Deleted Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};
