import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableContainer,
} from '@mui/material';
import { accountCells } from './accountsTableCells';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/authSlice';
import {
	changeAccountTableSettings,
	getAccountsAsync,
	removeAccounts,
} from '../../../redux/accountSlice';
import AccountsTableHeader from './AccountsTableHeader';
import AccountsTablePagination from './AccountsTablePagination';
import AccountsTableSearchBar from './AccountsTableSearchBar';
import AccountsTableRow from './AccountsTableRow';
import { blueGrey } from '@mui/material/colors';

export default function AccountsTable({ type }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let count = 0;
	const user = useSelector(selectUser);
	const cells = accountCells(type);
	const [loading, setLoading] = useState(false);
	const accounts = useSelector((state) => {
		switch (type) {
			case 'Buyer':
				return state.accounts.buyers;
			case 'Producer':
				return state.accounts.producers;
			case 'Other':
				return state.accounts.others;
			default:
				return null;
		}
	});

	const accountsTableSettings = useSelector((state) => {
		if (type === 'Buyer') {
			return state.accounts.buyersTableSettings;
		}
		if (type === 'Producer') {
			return state.accounts.producersTableSettings;
		}
		if (type === 'Other') {
			return state.accounts.othersTableSettings;
		}
		return null;
	});

	useEffect(() => {
		if (!accounts && !loading) {
			setLoading(true);
			dispatch(
				getAccountsAsync(
					type,
					accountsTableSettings.showActive ? 'active' : 'inactive'
				)
			);
		}

		if (loading && accounts) {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [accounts, loading]);

	function handleChange(e) {
		if (e.target.name === 'searchTerm') {
			dispatch(
				changeAccountTableSettings({
					type: type,
					name: 'page',
					value: 1,
				})
			);
		}

		dispatch(
			changeAccountTableSettings({
				type: type,
				name: e.target.name,
				value: e.target.value,
				checked: e.target.checked,
			})
		);

		if (e.target.name === 'showActive') {
			//TODO:  If checked, just filter out the inactive accounts instead
			// of making a new request for accounts
			//dispatch(removeAccounts(type));
			dispatch(
				getAccountsAsync(type, e.target.checked ? 'active' : 'inactive')
			);
		}
	}

	function handleSorting(id) {
		if (id === accountsTableSettings.sortBy) {
			dispatch(
				changeAccountTableSettings({
					type: type,
					name: 'sortBy',
					sortBy: id,
					sortOrder: accountsTableSettings.sortOrder === 'asc' ? 'desc' : 'asc',
				})
			);
		} else {
			dispatch(
				changeAccountTableSettings({
					type: type,
					name: 'sortBy',
					sortBy: id,
					sortOrder: 'asc',
				})
			);
		}
	}

	function handlePageChange(e, page) {
		dispatch(
			changeAccountTableSettings({ type: type, name: 'page', value: page + 1 })
		);
	}

	function handleRowsPerPageChange(e, name) {
		dispatch(
			changeAccountTableSettings({
				type: type,
				name: name,
				value: e.target.value,
			})
		);
	}

	function renderAccounts() {
		let accountsToDisplay = [];
		if (accounts) {
			accountsToDisplay = [...accounts];

			if (!accountsTableSettings.showAll) {
				accountsToDisplay = accountsToDisplay.filter(
					(account) => account.Owner === user._id
				);
			}

			const searchTerms = accountsTableSettings.searchTerm
				.toLowerCase()
				.split(' ')
				.filter((word) => word !== '');

			if (accountsTableSettings.searchTerm.length > 0) {
				accountsToDisplay = accountsToDisplay.filter((account, index) => {
					let anyTrue = false;

					if (accountsTableSettings.searchEvery) {
						if (
							searchTerms.every((term) =>
								account.AccountNumber.toString().includes(term)
							) ||
							searchTerms.every((term) =>
								account.Name.toLowerCase().includes(term)
							) ||
							searchTerms.every((term) =>
								account.Phone.toLowerCase().includes(term)
							) ||
							searchTerms.every(
								(term) =>
									account.Territory &&
									account.Territory.toLowerCase().includes(term)
							)
						) {
							anyTrue = true;
						}

						if (accountsTableSettings.includeContacts) {
							account.Contacts.forEach((contact) => {
								if (
									searchTerms.every((term) =>
										contact.FirstName.toLowerCase().includes(term)
									) ||
									searchTerms.every((term) =>
										contact.LastName.toLowerCase().includes(term)
									) ||
									searchTerms.every((term) =>
										contact.Phone.toLowerCase().includes(term)
									) ||
									searchTerms.every((term) =>
										contact.MobilePhone.toLowerCase().includes(term)
									) ||
									searchTerms.every((term) =>
										contact.Email.toLowerCase().includes(term)
									)
								) {
									anyTrue = true;
								}
							});
						}
					} else {
						if (
							searchTerms.some((term) =>
								account.AccountNumber.toString().includes(term)
							) ||
							searchTerms.some((term) =>
								account.Name.toLowerCase().includes(term)
							) ||
							searchTerms.some((term) =>
								account.Phone.toLowerCase().includes(term)
							) ||
							searchTerms.some(
								(term) =>
									account.Territory &&
									account.Territory.toLowerCase().includes(term)
							)
						) {
							anyTrue = true;
						}

						if (accountsTableSettings.includeContacts) {
							account.Contacts.forEach((contact) => {
								if (
									searchTerms.some((term) =>
										contact.FirstName.toLowerCase().includes(term)
									) ||
									searchTerms.some((term) =>
										contact.LastName.toLowerCase().includes(term)
									) ||
									searchTerms.some((term) =>
										contact.Phone.toLowerCase().includes(term)
									) ||
									searchTerms.some((term) =>
										contact.MobilePhone.toLowerCase().includes(term)
									) ||
									searchTerms.some((term) =>
										contact.Email.toLowerCase().includes(term)
									)
								) {
									anyTrue = true;
								}
							});
						}
					}
					return anyTrue;
				});
			}

			accountsToDisplay = accountsToDisplay.sort((account1, account2) => {
				if (accountsTableSettings.sortOrder === 'asc') {
					if (
						account2[accountsTableSettings.sortBy].toString().toLowerCase() >
						account1[accountsTableSettings.sortBy].toString().toLowerCase()
					) {
						return -1;
					}

					if (
						account2[accountsTableSettings.sortBy].toString().toLowerCase() <
						account1[accountsTableSettings.sortBy].toString().toLowerCase()
					) {
						return 1;
					}

					return 0;
				} else {
					if (
						account2[accountsTableSettings.sortBy].toString().toLowerCase() <
						account1[accountsTableSettings.sortBy].toString().toLowerCase()
					) {
						return -1;
					}

					if (
						account2[accountsTableSettings.sortBy].toString().toLowerCase() >
						account1[accountsTableSettings.sortBy].toString().toLowerCase()
					) {
						return 1;
					}

					return 0;
				}
			});

			count = accountsToDisplay.length;

			accountsToDisplay = accountsToDisplay.slice(
				(accountsTableSettings.page - 1) * accountsTableSettings.pageSize,
				accountsTableSettings.page * accountsTableSettings.pageSize
			);
		}

		return accountsToDisplay;
	}

	return (
		<Paper
			sx={{
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
				backgroundColor: 'inherit',
				color: 'inherit',
				boxShadow: 'none',
			}}
		>
			<AccountsTableSearchBar
				type={type}
				dispatch={dispatch}
				navigate={navigate}
				accountsTableSettings={accountsTableSettings}
				changeAccountTableSettings={changeAccountTableSettings}
				handleChange={handleChange}
			/>
			<TableContainer>
				<Table>
					<AccountsTableHeader
						cells={cells}
						handleSorting={handleSorting}
						handleChange={handleChange}
						accountsTableSettings={accountsTableSettings}
					/>
				</Table>
			</TableContainer>
			<TableContainer
				sx={{
					minHeight: 'calc(100vh - 300px)',
					maxHeight: 'calc(100vh - 300px)',
					borderTop: 'none',
					paddingBottom: 6,
					borderLeft: `10px solid ${blueGrey[800]}`,
					borderRight: `10px solid ${blueGrey[800]}`,
				}}
			>
				<Table>
					<TableBody
						sx={{
							width: '100%',
							overflowY: 'scroll !important',
							//height: '90vh',
						}}
					>
						{renderAccounts &&
							renderAccounts().map((account) => (
								<AccountsTableRow
									key={account._id}
									cells={cells}
									type={type}
									account={account}
									navigate={navigate}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<AccountsTablePagination
				count={count}
				accountsTableSettings={accountsTableSettings}
				handlePageChange={handlePageChange}
				handleRowsPerPageChange={handleRowsPerPageChange}
			/>
			{loading && (
				<CircularProgress
					color='warning'
					sx={{ position: 'absolute', top: '45%' }}
				/>
			)}
		</Paper>
	);
}

AccountsTable.propTypes = {
	type: PropTypes.string.isRequired,
};
