import { Table, TableFooter, TablePagination, TableRow } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import React from 'react';

export default function ProgramsTablePagination({
	programsToDisplayCount,
	programsTableSettings,
	handleChangePage,
	handleChangeRowsPerPage,
}) {
	return (
		<Table>
			<TableFooter>
				<TableRow>
					<TablePagination
						count={programsToDisplayCount}
						color='secondary'
						variant='outlined'
						page={programsTableSettings.page - 1}
						onPageChange={handleChangePage}
						rowsPerPage={programsTableSettings.pageSize}
						onRowsPerPageChange={(e) => handleChangeRowsPerPage(e, 'pageSize')}
						showFirstButton
						showLastButton
						rowsPerPageOptions={[10, 25, 50, 100]}
						sx={{
							width: '100% !important',
							color: blueGrey[50],
							backgroundColor: blueGrey[700],
							position: 'fixed',
							bottom: -2,
							borderBottom: `2px solid ${blueGrey[50]}`,

							'& .MuiTablePagination-selectIcon': {
								color: blueGrey[50],
							},
							'& .MuiTablePagination-root': {
								position: 'sticky',
								bottom: 2,
							},
						}}
					/>
				</TableRow>
			</TableFooter>
		</Table>
	);
}
