import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const tableCell = {
	color: blueGrey[50],
	paddingLeft: 4,
	border: 'none',
	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
	},
	'& .MuiTableSortLabel-root.MuiSvdIcon-root': {
		color: blueGrey[50],
	},
	'& .MuiTableSortLabel-icon ': {
		opacity: 0.5,
		color: 'blueGrey[50]',
	},
	'& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon':
		{
			opacity: 1,
			color: blueGrey[50],
		},
};

export default function ProgramsTableHeader({
	cells,
	handleSorting,
	accountsTableSettings,
}) {
	return (
		<TableHead
			sx={{
				position: 'sticky',
				top: 0,
				zIndex: 100,
				backgroundColor: blueGrey[800],
				color: blueGrey[50],
				marginBottom: 16,
				border: 'none',
			}}
		>
			<TableRow>
				<TableCell sx={{ minWidth: 60, border: 'none' }}></TableCell>
				{cells.map((cell) => (
					<TableCell
						key={cell.id}
						align={cell.align}
						padding={cell.disablePadding ? 'none' : 'normal'}
						sortDirection={
							accountsTableSettings.sortBy === cell.id
								? accountsTableSettings.sortOrder
								: false
						}
						sx={tableCell}
						style={{ width: `calc(100% / ${cells.length})` }}
					>
						{cell.label}
						{cell.sortable && (
							<TableSortLabel
								active={accountsTableSettings.sortBy === cell.id}
								direction={
									accountsTableSettings.sortBy === cell.id
										? accountsTableSettings.sortOrder
										: 'asc'
								}
								onClick={() => handleSorting(cell.id)}
								sx={tableCell}
							/>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

ProgramsTableHeader.propTypes = {
	cells: PropTypes.array.isRequired,
	handleSorting: PropTypes.func.isRequired,
	accountsTableSettings: PropTypes.object.isRequired,
};
