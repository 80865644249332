import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectOriginalScreener,
	selectEditingScreener,
	getScreenerAsync,
} from '../../../../redux/screenerSlice';
import _ from 'lodash';
import { addTab, closeTab, updateTabStatus } from '../../../../redux/tabsSlice';
import { Box, Grid, CircularProgress, Modal } from '@mui/material';
import { gridStyles } from '../../../shared/ComponentStyles';
import { blueGrey } from '@mui/material/colors';
import Notes from '../../../shared/Notes';
import CreatedModified from '../../../shared/CreatedModified';
import ScreenerDetailsItems from './ScreenerDetailsItems';
import RightsModal from '../../../shared/rightsModal/RightsModal';
import { changeEditingTerritory } from '../../../../redux/screenerSlice';
import {
	createScreenerAsync,
	updateScreenerAsync,
} from '../../../../redux/screenerSlice';
import { useNavigate } from 'react-router-dom';
import { createAlertAsync } from '../../../../redux/alertsSlice';
import ScreenerDetailsForm from './ScreenerDetailsForm';
import ConvertToSaleModal from './ConvertToSaleModal';
import { createSale } from '../../../../redux/salesSlice';

export default function ScreenerDetails({ id }) {
	const dispatch = useDispatch();
	const originalScreener = useSelector(selectOriginalScreener(id));
	const editingScreener = useSelector(selectEditingScreener(id));
	const [rightsModal, setRightsModal] = useState(false);
	const [convertToSaleModal, setConvertToSaleModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [convertSelectedItems, setConvertSelectedItems] = useState([]);
	const navigate = useNavigate();

	function handleRightsModal() {
		setRightsModal(!rightsModal);
	}

	useEffect(() => {
		if (
			!editingScreener &&
			id !== 'new-screener' &&
			!id.toLowerCase().includes('convert')
		) {
			dispatch(getScreenerAsync(id));
		}

		//eslint-disable-next-line
	}, [editingScreener]);

	useEffect(() => {
		if (_.isEqual(originalScreener, editingScreener)) {
			dispatch(updateTabStatus({ id: id, status: true }));
		} else {
			dispatch(updateTabStatus({ id: id, status: false }));
		}
	}, [id, editingScreener, originalScreener, dispatch]);

	useEffect(() => {
		if (saving && _.isEqual(originalScreener, editingScreener)) {
			setSaving(false);
		}

		// eslint-disable-next-line
	}, [editingScreener, saving]);

	useEffect(() => {
		if (
			editingScreener &&
			editingScreener.Screener &&
			editingScreener.Created
		) {
			console.log('Created!');

			setSaving(false);

			navigate(`/sales/screeners/${editingScreener.Screener}`);
		}

		if (editingScreener && editingScreener.New) {
			dispatch(closeTab('new-screener'));
			dispatch(getScreenerAsync(editingScreener._id));
		}

		// eslint-disable-next-line
	}, [editingScreener]);

	function handleConvertToSaleModal() {
		let items = [];
		editingScreener.ScreenerItems.forEach((screenerItem) => {
			let newItem = { ...screenerItem };
			newItem.Checked = true;
			items.push(newItem);
		});

		setConvertSelectedItems(items);
		setConvertToSaleModal(!convertToSaleModal);
	}

	function saveScreener(e) {
		e.preventDefault();

		if (
			!editingScreener.Territory ||
			editingScreener.Territory === '' ||
			editingScreener.Territory === 'None'
		) {
			dispatch(
				createAlertAsync({
					message: 'Territory Cannot Be Empty',
					severity: 'warning',
					autoDismiss: true,
					timeout: 6000,
				})
			);

			return;
		}

		if (editingScreener.ScreenerItems.length === 0) {
			dispatch(
				createAlertAsync({
					message: 'Must Add Screener Items',
					severity: 'warning',
					autoDismiss: true,
					timeout: 6000,
				})
			);

			return;
		}

		if (id === 'new-screener') {
			setSaving(true);
			dispatch(createScreenerAsync(editingScreener));
		} else {
			setSaving(true);
			dispatch(updateScreenerAsync(editingScreener));
		}
	}

	function convertToSale() {
		let saleItems = [];

		let totalHours = 0;

		convertSelectedItems.forEach((item) => {
			if (item.Checked) {
				item.Program.ProgramItems.forEach((programItem) => {
					const newSaleItem = {
						_id: crypto.randomUUID(),
						Checked: true,
						IsActive: true,
						Sale: `new-sale-${editingScreener._id}`,
						Program: item.Program,
						ProgramItem: programItem,
						Hours: programItem.RunningTime,
						UnitPrice: 0,
						RightsOut: editingScreener.RightsTree,
						//ExpenseType:,
						//ExpenseValue:,
						//ExpenseCurrency:,
						//CopyRightLetterDelivered:,
						//CertificateOfOriginDelivered:,
						//PaymentTerms:,
						//Commission:,
						//Notes:,
					};

					totalHours += programItem.RunningTime;

					saleItems.push(newSaleItem);
				});
			}
		});

		let newSale = {
			stepIndex: 0,
			_id: `convert-to-sale-${editingScreener._id}`,
			IsActive: true,
			Owner: editingScreener && editingScreener.Owner._id,
			Currency: editingScreener.Account.Currency,
			Screener: editingScreener._id,
			Account: editingScreener.Account,
			ContractContact: editingScreener.Contact,
			InvoiceContact: editingScreener.Contact,
			DeliveryContact: editingScreener.Contact,
			SaleItems: saleItems,
			Status: 'Draft',
			StartDate: null,
			EndDate: null,
			Language: 'English',
			Territory: editingScreener.Territory,
			RightsTree: editingScreener.RightsTree,
			WithholdingTaxRate: '',
			NoWithholdingTaxRate: true,
			TotalMaterialsFee: '',
			MaterialsFeeDueBy: null,
			MaterialsFeeReceived: null,
			IncludedInTotalPrice: false,
			TotalLicenceFee: '',
			DeliveryDate: null,
			DeliveryMethod: '',
			DeliveryComplete: '',
			LicensedChannel: '',
			TotalHours: totalHours,
			NumberOfExhibitions: '',
			Exclusivity: '',
			PaymentTerms: [],
			Holdback: '',
			SpecialConditions: '',
			DateOfAgreement: null,
			ContractSigned: false,
			CertificateOfOriginRequired: false,
		};

		dispatch(createSale(newSale));
		dispatch(
			addTab({
				id: `convert-to-sale-${editingScreener._id}`,
				title: `Convert To Sale: ${editingScreener.Name}`,
				link: `/sales/convert/${newSale._id}`,
				type: 'convert',
				tertiary: 0,
				status: true,
				delete: true,
			})
		);
		navigate(`/sales/convert/${newSale._id}`);
	}

	return (
		<>
			{editingScreener ? (
				<>
					<Box
						component='form'
						onSubmit={saveScreener}
						sx={{
							borderRadius: 2,
							color: blueGrey[900],
							padding: 4,
							height: 'calc(100vh - 175px)',
							overflowY: 'auto',
							marginTop: 2,
							marginBottom: 2,
						}}
					>
						<ScreenerDetailsForm
							id={id}
							saving={saving}
							convertToSale={convertToSale}
							handleRightsModal={handleRightsModal}
							originalScreener={originalScreener}
							editingScreener={editingScreener}
							handleConvertToSaleModal={handleConvertToSaleModal}
						/>
						<Grid
							container
							sx={gridStyles}
						>
							<Grid
								item
								sm={12}
							>
								<ScreenerDetailsItems
									id={id}
									selectedItems={selectedItems}
									screenerItems={editingScreener.ScreenerItems}
									setSelectedItems={setSelectedItems}
									rightsTree={editingScreener.RightsTree}
								/>
							</Grid>
						</Grid>
						{id !== '' && id !== 'new-screener' && (
							<Notes
								id={id}
								type={'Screener'}
								notes={editingScreener && editingScreener.Notes}
								color={blueGrey[50]}
								backgroundColor={blueGrey[700]}
							/>
						)}

						{id !== '' && id !== 'new-screener' && (
							<CreatedModified
								account={editingScreener}
								color={blueGrey[50]}
								backgroundColor={blueGrey[700]}
							/>
						)}

						{rightsModal && (
							<RightsModal
								id={editingScreener._id}
								rightsTree={editingScreener.RightsTree}
								rightsModal={rightsModal}
								handleRightsModal={handleRightsModal}
								changeEditingTerritory={changeEditingTerritory}
							/>
						)}
					</Box>
					<Modal open={saving}>
						<Box sx={progressModalStyle}>
							<CircularProgress
								color='warning'
								size={80}
							/>
						</Box>
					</Modal>
					<ConvertToSaleModal
						editingScreener={editingScreener}
						convertToSale={convertToSale}
						convertToSaleModal={convertToSaleModal}
						handleConvertToSaleModal={handleConvertToSaleModal}
						convertSelectedItems={convertSelectedItems}
						setConvertSelectedItems={setConvertSelectedItems}
					/>
				</>
			) : (
				<CircularProgress
					color='warning'
					sx={{ margin: 20 }}
				/>
			)}
		</>
	);
}

const progressModalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
};
