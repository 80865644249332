import React, { createRef, useEffect, useState } from 'react';
import { blueGrey } from '@mui/material/colors';
import {
	CircularProgress,
	Grid,
	Paper,
	Table,
	TableBody,
	TableContainer,
} from '@mui/material';
import ProgramsTableHeader from './ProgramsTableHeader';
import ProgramsTableRow from './ProgramsTableRow';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramsAsync, selectPrograms } from '../../../redux/programSlice';
import ProgramsTablePagination from './ProgramsTablePagination';
import { programTableCells } from './programTableCells';
import { renderPrograms } from './programModalUtils';

export default function ProgramsTable({
	rightsTree,
	selectedPrograms,
	setSelectedPrograms,
}) {
	const tableRef = createRef();
	const dispatch = useDispatch();
	const programs = useSelector(selectPrograms);
	const [searchTerms, setSearchTerms] = useState({
		Name: '',
		Territory: '',
		LicensedMedia: [],
		Format: [],
		Definition: [],
		Genre: [],
		YOP: '',
		Exclusive: '',
	});

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!programs) {
			setLoading(true);
			dispatch(getProgramsAsync());
		}

		if (programs && loading) {
			setLoading(false);
		}

		//eslint-disable-next-line
	}, [programs]);

	const [programsTableSettings, setProgramsTableSettings] = useState({
		page: 1,
		pageSize: 25,
		sortBy: 'Name',
		sortOrder: 'asc',
	});

	const handleSorting = (id) => (event) => {
		if (id === programsTableSettings.sortBy) {
			setProgramsTableSettings({
				...programsTableSettings,
				sortOrder: programsTableSettings.sortOrder === 'asc' ? 'desc' : 'asc',
			});
		} else {
			setProgramsTableSettings({
				...programsTableSettings,
				sortBy: id,
				sortOrder: 'asc',
			});
		}
	};

	function handleChangePage(e, page) {
		setProgramsTableSettings({
			...programsTableSettings,
			page: page + 1,
		});
		console.log(tableRef);
		tableRef.current.scrollTop = 0;
	}

	function handleChangeRowsPerPage(e, pageSize) {
		setProgramsTableSettings({
			...programsTableSettings,
			pageSize: e.target.value,
		});
	}

	//console.log(typeof programs);
	console.log(searchTerms);

	let programsToDisplay = renderPrograms(
		programs,
		searchTerms,
		rightsTree,
		programsTableSettings
	);

	let programsToDisplayCount = programs ? programs.length : 0;

	return (
		<>
			{programs && programs.length > 0 ? (
				<>
					<Paper
						sx={{
							width: '100%',
							overflow: 'hidden',
							backgroundColor: blueGrey[800],
							boxShadow: 'none',
						}}
					>
						<TableContainer
							sx={{
								maxHeight: '90vh',
								overflow: 'auto',
								borderTop: `2px solid ${blueGrey[50]}`,
								paddingBottom: 8,
							}}
						>
							<Table
								id='table'
								classes={{
									rowStyle: { minHeight: 70, height: 70, maxHeight: 70 },
								}}
								stickyHeader
							>
								<ProgramsTableHeader
									cells={programTableCells}
									handleSorting={handleSorting}
									programsTableSettings={programsTableSettings}
									setSelectedPrograms={setSelectedPrograms}
									selectedPrograms={selectedPrograms}
									searchTerms={searchTerms}
									setSearchTerms={setSearchTerms}
								/>
								<TableBody
									id='tableBody'
									ref={tableRef}
									sx={{
										width: '100%',
										overflowY: 'scroll',
									}}
								>
									{programsToDisplay.map((program) => (
										<ProgramsTableRow
											key={program._id}
											program={program}
											setSelectedPrograms={setSelectedPrograms}
											selectedPrograms={selectedPrograms}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<ProgramsTablePagination
							programsToDisplayCount={programsToDisplayCount}
							programsTableSettings={programsTableSettings}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</Paper>
				</>
			) : (
				<Grid
					sx={{
						backgroundColor: blueGrey[800],
						height: '300px',
						paddingTop: '8em',
						textAlign: 'center',
					}}
				>
					<CircularProgress color='warning' />
				</Grid>
			)}
		</>
	);
}
