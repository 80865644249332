import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	departments: null,
	editing: null,
};

export const departmentSlice = createSlice({
	name: 'department',
	initialState: initialState,
	reducers: {
		createDepartment: (state, action) => {
			state.departments.push(action.payload);

			state.departments = state.departments.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.departments = state.departments.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getDepartment: (state, action) => {
			state.editing = action.payload;
		},
		getDepartments: (state, action) => {
			state.departments = action.payload;

			state.departments = state.departments.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.departments = state.departments.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateDepartment: (state, action) => {
			const departmentIndex = state.departments.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.departments[departmentIndex] = action.payload;

			state.departments = state.departments.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.departments = state.departments.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeDepartments: (state, action) => {
			state.departments = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeDepartment: (state, action) => {
			state.editing = null;
		},
		resetDepartments: () => initialState,
	},
});

export const {
	createDepartment,
	getDepartment,
	getDepartments,
	updateDepartment,
	removeDepartments,
	setEditing,
	removeDepartment,
	resetDepartments,
} = departmentSlice.actions;

// Selectors
export const selectDepartments = (state) => state.department.departments;

export const selectDepartment = () => (state) => {
	if (state.department.departments && state.department.editing) {
		return state.department.departments.find(
			(department) => department._id === state.department.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.department.editing;
};

// Create Departments Async
export const createDepartmentAsync = (department) => async (dispatch) => {
	await api
		.post(`/admin/department`, { Name: department }, { withCredentials: true })
		.then((res) => {
			dispatch(createDepartment(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Departments Async
export const getDepartmentsAsync = () => async (dispatch) => {
	await api
		.get(`/admin/department`, { withCredentials: true })
		.then((res) => {
			dispatch(getDepartments(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Department Async
export const getDepartmentAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/department/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getDepartment(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Department Async
export const updateDepartmentAsync = (department) => async (dispatch) => {
	await api
		.put(`/admin/department/${department._id}`, department, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateDepartment(res.data));
			dispatch(
				createAlertAsync({
					message: 'Department Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default departmentSlice.reducer;
