import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	genres: null,
	editing: null,
};

export const genreSlice = createSlice({
	name: 'genre',
	initialState: initialState,
	reducers: {
		createGenre: (state, action) => {
			state.genres.push(action.payload);

			state.genres = state.genres.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.genres = state.genres.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getGenre: (state, action) => {
			state.editing = action.payload;
		},
		getGenres: (state, action) => {
			state.genres = action.payload;

			state.genres = state.genres.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.genres = state.genres.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateGenre: (state, action) => {
			const genreIndex = state.genres.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.genres[genreIndex] = action.payload;

			state.genres = state.genres.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.genres = state.genres.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeGenres: (state, action) => {
			state.genres = null;
		},
		setEditing: (state, action) => {
			state.editing = {
				...state.editing,
				[action.payload.name]: action.payload.value,
			};
		},
		removeGenre: (state, action) => {
			state.editing = null;
		},
		resetGenres: () => initialState,
	},
});

export const {
	createGenre,
	getGenre,
	getGenres,
	updateGenre,
	removeGenres,
	setEditing,
	removeGenre,
	resetGenres,
} = genreSlice.actions;

// Selectors
export const selectGenres = (state) => state.genre.genres;

export const selectGenre = () => (state) => {
	if (state.genre.genres && state.genre.editing) {
		return state.genre.genres.find(
			(genre) => genre._id === state.genre.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.genre.editing;
};

// Create Genres Async
export const createGenreAsync = (genre) => async (dispatch) => {
	await api
		.post(`/admin/genre`, { Name: genre }, { withCredentials: true })
		.then((res) => {
			dispatch(createGenre(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

// Get Genres Async
export const getGenresAsync = () => async (dispatch) => {
	await api
		.get(`/admin/genre`, { withCredentials: true })
		.then((res) => {
			dispatch(getGenres(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Genre Async
export const getGenreAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/genre/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getGenre(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Genre Async
export const updateGenreAsync = (genre) => async (dispatch) => {
	await api
		.put(`/admin/genre/${genre._id}`, genre, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateGenre(res.data));
			dispatch(
				createAlertAsync({
					message: 'Genre Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default genreSlice.reducer;
