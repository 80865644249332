import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Grid,
	Box,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	OutlinedInput,
	FormLabel,
	Button,
	Typography,
	Tooltip,
	IconButton,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { textFieldStyles } from '../../../../shared/ComponentStyles';
import { selectGenres } from '../../../../../redux/genreSlice';
import { selectLanguages } from '../../../../../redux/languageSlice';
import { selectFormats } from '../../../../../redux/formatSlice';
import { selectDefinitions } from '../../../../../redux/definitionSlice';
import { selectCurrencies } from '../../../../../redux/currencySlice';
import {
	changeCrossCollateralizePrograms,
	changeEditingProgram,
	deleteCrossCollateralizePrograms,
} from '../../../../../redux/programSlice';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import CreatedModified from '../../../../shared/CreatedModified';
import Notes from '../../../../shared/Notes';
import { selectUser } from '../../../../../redux/authSlice';
import ProgramsModal from '../../../../shared/programsModal/ProgramsModal';
import { Link, RouterLink } from 'react-router-dom';
import { EditSharp } from '@mui/icons-material';

export default function ProgramTab({ originalProgram, editingProgram }) {
	const dispatch = useDispatch();
	const genres = useSelector(selectGenres);
	const languages = useSelector(selectLanguages);
	const formats = useSelector(selectFormats);
	const definitions = useSelector(selectDefinitions);
	const currencies = useSelector(selectCurrencies);
	const user = useSelector(selectUser);
	const [rightsModal, setRightsModal] = React.useState(false);

	//useEffect(() => {}, [editingProgram]);

	const handleChange = (e) => {
		let value;

		switch (e.target.name) {
			case 'CrossCollateralize':
			case 'PerformanceGuarantee':
			case 'DistributionAdvance':
				value = e.target.checked ? true : false;
				break;
			default:
				value = e.target.value;
				break;
		}

		if (e.target.name === 'VimeoLink') {
			if (value.indexOf('http://') !== 0) {
				value = 'http://' + value;
			}
		}

		dispatch(
			changeEditingProgram({
				id: editingProgram._id,
				name: e.target.name,
				value: value,
			})
		);
	};

	function handleArrayChange(e) {
		let newValue =
			typeof value === 'string' ? e.target.value.split(',') : e.target.value;

		let correctedValue = newValue.filter((value) => value !== '');

		if (e.target.name === 'Definition') {
			const indexOf4K = e.target.value.indexOf('4K');
			if (indexOf4K !== -1) {
				const indexOfHd = e.target.value.indexOf('HD');

				if (indexOfHd === -1) {
					correctedValue.splice(indexOf4K + 1, 0, 'HD');
				}
			}
		}

		console.log(correctedValue);

		dispatch(
			changeEditingProgram({
				id: editingProgram._id,
				name: e.target.name,
				value: correctedValue,
			})
		);
	}

	function handleCalendarChange(e, name) {
		let date = null;

		if (e && e.$d) date = new Date(e.$d).toDateString();

		if (date === 'Invalid Date') {
			date = null;
		}

		console.log(date);

		dispatch(
			changeEditingProgram({
				id: editingProgram._id,
				name: name,
				value: date,
			})
		);
	}

	function renderProgramsForCrossCollateral(programs) {
		console.log(programs);

		return programs.map((program) => (
			<Link
				key={program._id}
				to={`/acquisitions/programs/${program._id}`}
				sx={{ display: 'inline-block', width: '100%' }}
			>
				{program.Name}
			</Link>
		));
	}

	return (
		<Box
			sx={{
				color: blueGrey[900],
				height: 'calc(100vh - 265px)',
				overflowY: 'auto',
				position: 'relative',
			}}
		>
			<Box
				sx={{
					position: 'sticky',
					height: 20,
					top: 0,
					width: '100%',
					zIndex: 100,
					borderRadius: 2,
					backgroundColor: blueGrey[50],
				}}
			></Box>
			<Grid
				container
				sx={{
					width: '100%',
					margin: '0 auto',
					marginTop: 4,
					marginBottom: 4,
					alignContent: 'center',
					alignItems: 'center',
				}}
			>
				<Grid
					item
					sm={2}
					sx={{
						//width: '100%',
						textAlign: 'center',
						alignContent: 'center',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							backgroundColor: blueGrey[900],
							width: '75px',
							height: '75px',
							margin: '0 auto',
						}}
					/>
				</Grid>
				<Grid
					item
					sm={8}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Name/Title'
						value={editingProgram.Name}
						name='Name'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
						style={{ width: '80%' }}
					/>
				</Grid>
				<Grid
					item
					sm={1}
					//sx={{ marginLeft: 4 }}
				>
					<TextField
						color='secondary'
						required
						select
						disabled={user.Role !== 'admin'}
						variant='outlined'
						label='Status'
						value={editingProgram.IsActive}
						name='IsActive'
						onChange={(e) => handleChange(e)}
						sx={{
							marginTop: 2,
							marginBottom: 2,
							width: '80%',
							'& .MuiOutlinedInput-input.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[700]} !important`,
								WebkitTextFillColor: `${blueGrey[700]} !important`,
								'& > fieldset': {
									borderColor: `${blueGrey[700]} !important`,
								},
								'& .MuiOutlinedInput-root.Mui-disabled': {
									'& > fieldset': {
										borderColor: `${blueGrey[700]} !important`,
									},
								},
							},
							'& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[900]} !important`,
							},
						}}
					>
						<MenuItem value={true}>Active</MenuItem>
						<MenuItem value={false}>Inactive</MenuItem>
					</TextField>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						disabled
						variant='outlined'
						label='Program Code'
						value={editingProgram.ProgramCode}
						sx={{
							marginTop: 2,
							marginBottom: 2,
							width: '80%',
							'& .MuiOutlinedInput-input.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[700]} !important`,
								WebkitTextFillColor: `${blueGrey[700]} !important`,
								'& > fieldset': {
									borderColor: `${blueGrey[700]} !important`,
								},
								'& .MuiOutlinedInput-root.Mui-disabled': {
									'& > fieldset': {
										borderColor: `${blueGrey[700]} !important`,
									},
								},
							},
							'& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[900]} !important`,
							},
						}}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						disabled
						variant='outlined'
						label='Production Company'
						value={editingProgram.ProductionCompany.Name}
						name='ProductionCompany'
						onChange={(e) => handleChange(e)}
						sx={{
							marginTop: 2,
							marginBottom: 2,
							width: '80%',
							'& .MuiOutlinedInput-input.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[700]} !important`,
								WebkitTextFillColor: `${blueGrey[700]} !important`,
								'& > fieldset': {
									borderColor: `${blueGrey[700]} !important`,
								},
								'& .MuiOutlinedInput-root.Mui-disabled': {
									'& > fieldset': {
										borderColor: `${blueGrey[700]} !important`,
									},
								},
							},
							'& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[900]} !important`,
							},
						}}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFieldStyles}>
						<InputLabel>Genre</InputLabel>
						<Select
							name='Genre'
							multiple
							multiline
							required
							value={editingProgram.Genre}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									label='Genre'
									color='secondary'
								>
									Genre
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{genres.map((genre) => (
								<MenuItem
									key={genre._id}
									value={genre.Name}
								>
									<Checkbox
										checked={editingProgram.Genre.indexOf(genre.Name) > -1}
									/>
									<ListItemText primary={genre.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Country of Origin'
						value={editingProgram.CountryOfOrigin}
						name='CountryOfOrigin'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFieldStyles}>
						<InputLabel>Language</InputLabel>
						<Select
							name='Language'
							multiple
							required
							value={editingProgram.Language}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Language'
								>
									Language
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{languages.map((language) => (
								<MenuItem
									key={language._id}
									value={language.Name}
								>
									<Checkbox
										checked={
											editingProgram.Language.indexOf(language.Name) > -1
										}
									/>
									<ListItemText primary={language.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<FormControl
						variant='outlined'
						sx={textFieldStyles}
					>
						<InputLabel>Currency</InputLabel>
						<Select
							name='Currency'
							multiple
							required
							variant='outlined'
							value={editingProgram.Currency}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Currency'
								>
									Currency
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
							//sx={textFieldStyles}
						>
							{currencies.map((currency) => (
								<MenuItem
									key={currency._id}
									value={currency.Name}
								>
									<Checkbox
										checked={
											editingProgram.Currency.indexOf(currency.Name) > -1
										}
									/>
									<ListItemText primary={currency.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFieldStyles}>
						<InputLabel>Format</InputLabel>
						<Select
							name='Format'
							//multiple
							required
							value={editingProgram.Format}
							onChange={(e) => handleChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Format'
								>
									Format
								</OutlinedInput>
							}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{formats.map((format) => (
								<MenuItem
									key={format._id}
									value={format.Name}
								>
									{format.Name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{editingProgram.Format === 'Series' && (
						<TextField
							disabled
							color='secondary'
							variant='outlined'
							label='Season'
							value={Number(editingProgram.Name.slice(-1)) || 1}
							name='Season'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={{
								marginTop: 2,
								marginBottom: 2,
								width: '80%',
								'& .MuiOutlinedInput-input.Mui-disabled': {
									borderColor: `${blueGrey[700]} !important`,
									color: `${blueGrey[700]} !important`,
									WebkitTextFillColor: `${blueGrey[700]} !important`,
									'& > fieldset': {
										borderColor: `${blueGrey[700]} !important`,
									},
									'& .MuiOutlinedInput-root.Mui-disabled': {
										'& > fieldset': {
											borderColor: `${blueGrey[700]} !important`,
										},
									},
								},
								'& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
									borderColor: `${blueGrey[700]} !important`,
									color: `${blueGrey[900]} !important`,
								},
							}}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Year of Production'
						type='number'
						value={editingProgram.YearOfProduction}
						name='YearOfProduction'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{editingProgram.Format === 'Series' && (
						<TextField
							disabled
							color='secondary'
							variant='outlined'
							label='Number Of Episodes'
							value={
								editingProgram.ProgramItems.length > 0 &&
								editingProgram.ProgramItems.length /
									editingProgram.Definition.length
							}
							name='Episodes'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={{
								marginTop: 2,
								marginBottom: 2,
								width: '80%',
								'& .MuiOutlinedInput-input.Mui-disabled': {
									borderColor: `${blueGrey[700]} !important`,
									color: `${blueGrey[700]} !important`,
									WebkitTextFillColor: `${blueGrey[700]} !important`,
									'& > fieldset': {
										borderColor: `${blueGrey[700]} !important`,
									},
									'& .MuiOutlinedInput-root.Mui-disabled': {
										'& > fieldset': {
											borderColor: `${blueGrey[700]} !important`,
										},
									},
								},
								'& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
									borderColor: `${blueGrey[700]} !important`,
									color: `${blueGrey[900]} !important`,
								},
							}}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl sx={textFieldStyles}>
						<InputLabel>Definition</InputLabel>
						<Select
							name='Definition'
							multiple
							required
							value={editingProgram.Definition}
							onChange={(e) => handleArrayChange(e)}
							input={
								<OutlinedInput
									color='secondary'
									label='Definition'
								>
									Definition
								</OutlinedInput>
							}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={{
								PaperProps: {
									style: {
										width: '20%',
										maxWidth: '20%',
									},
								},
							}}
						>
							{definitions.map((definition) => (
								<MenuItem
									key={definition._id}
									value={definition.Name}
								>
									<Checkbox
										checked={
											editingProgram.Definition.indexOf(definition.Name) > -1
										}
									/>
									<ListItemText primary={definition.Name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						disabled
						required
						variant='outlined'
						label='Total Hours'
						value={
							editingProgram.RunningTime *
							(editingProgram.ProgramItems.length / 60)
						}
						name='TotalHours'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={{
							marginTop: 2,
							marginBottom: 2,
							width: '80%',
							'& .MuiOutlinedInput-input.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[700]} !important`,
								WebkitTextFillColor: `${blueGrey[700]} !important`,
								'& > fieldset': {
									borderColor: `${blueGrey[700]} !important`,
								},
								'& .MuiOutlinedInput-root.Mui-disabled': {
									'& > fieldset': {
										borderColor: `${blueGrey[700]} !important`,
									},
								},
							},
							'& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
								borderColor: `${blueGrey[700]} !important`,
								color: `${blueGrey[900]} !important`,
							},
						}}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DesktopDatePicker
							color='secondary'
							key='DeliveryDate'
							name='DeliveryDate'
							label='Delivery Date'
							inputFormat='DD/MM/YYYY'
							value={editingProgram.DeliveryDate}
							onChange={(e) => handleCalendarChange(e, 'DeliveryDate')}
							sx={textFieldStyles}
							InputProps={{
								sx: {
									'& .MuiSvgIcon-root': { color: blueGrey[700] },
								},
							}}
							PopperProps={{ sx: pickerStyles }}
							renderInput={(params) => (
								<TextField
									color='secondary'
									{...params}
									key='DeliveryDate'
									variant='outlined'
									label='Delivery Date'
									value={editingProgram.DeliveryDate}
									name='DeliveryDate'
									sx={textFieldStyles}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Running Time'
						value={editingProgram.RunningTime}
						name='RunningTime'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<FormControl
						color='secondary'
						sx={textFieldStyles}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							alignContent: 'center',
							width: '80%',
							marginLeft: 80,
						}}
					>
						<Box>
							<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
								Distribution Advance
							</FormLabel>
							<Checkbox
								name='DistributionAdvance'
								checked={editingProgram.DistributionAdvance}
								onChange={(e) => handleChange(e)}
								sx={{ display: 'inline-flex' }}
							/>
						</Box>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{editingProgram.DistributionAdvance && (
						<TextField
							color='secondary'
							variant='outlined'
							label='Distribution Advance Value'
							value={editingProgram.DistributionAdvanceValue}
							name='DistributionAdvanceValue'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={textFieldStyles}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						variant='outlined'
						label='LaunchBudget'
						value={editingProgram.LaunchBudget}
						name='LaunchBudget'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					<FormControl
						color='secondary'
						sx={textFieldStyles}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							alignContent: 'center',
							width: '80%',
							marginLeft: 80,
						}}
					>
						<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
							Performance Guarantee
						</FormLabel>
						<Checkbox
							name='PerformanceGuarantee'
							checked={editingProgram.PerformanceGuarantee}
							onChange={(e) => handleChange(e)}
							sx={{ display: 'inline-flex' }}
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						variant='outlined'
						label='Expenses Cap %'
						value={editingProgram.ExpensesCap}
						name='ExpensesCap'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{editingProgram.PerformanceGuarantee && (
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								key='PerformanceGuaranteeDate'
								name='PerformanceGuaranteeDate'
								label='Performance Guarantee Date'
								inputFormat='DD/MM/YYYY'
								value={editingProgram.PerformanceGuaranteeDate}
								onChange={(e) =>
									handleCalendarChange(e, 'PerformanceGuaranteeDate')
								}
								//sx={pickerStyles}
								InputProps={{
									sx: { '& .MuiSvgIcon-root': { color: blueGrey[700] } },
								}}
								PopperProps={{ sx: pickerStyles }}
								renderInput={(params) => (
									<TextField
										color='secondary'
										{...params}
										key='PerformanceGuaranteeDate'
										required
										variant='outlined'
										label='Performance Guarantee Date'
										value={editingProgram.PerformanceGuaranteeDate}
										name='PerformanceGuaranteeDate'
										sx={textFieldStyles}
									/>
								)}
							/>
						</LocalizationProvider>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={6}
				>
					<TextField
						color='secondary'
						variant='outlined'
						label='Distribution Expense'
						value={editingProgram.DistributionExpense}
						name='DistributionExpense'
						type='number'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
					/>
				</Grid>
				<Grid
					item
					sm={6}
				>
					{editingProgram.PerformanceGuarantee && (
						<TextField
							color='secondary'
							variant='outlined'
							label='Performance Guarantee Value'
							value={editingProgram.PerformanceGuaranteeValue}
							name='PerformanceGuaranteeValue'
							type='number'
							onChange={(e) => handleChange(e)}
							sx={textFieldStyles}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={2}
					sx={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<FormControl
						color='secondary'
						sx={textFieldStyles}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							alignContent: 'center',
							width: '80%',
							marginLeft: 80,
						}}
					>
						<FormLabel sx={{ display: 'inline-flex', color: 'inherit' }}>
							Cross Collateralise
						</FormLabel>
						<Checkbox
							name='CrossCollateralize'
							checked={editingProgram.CrossCollateralize}
							onChange={(e) => handleChange(e)}
							sx={{ display: 'inline-flex' }}
						/>
					</FormControl>
				</Grid>
				<Grid
					item
					sm={2}
					sx={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<Tooltip title='Edit Programs for CrossCollateralization'>
						<IconButton onClick={() => setRightsModal(true)}>
							<EditSharp
								sx={{
									color: blueGrey[600],
									backgroundColor: 'transparent',
									borderRadius: 2,
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid
					item
					sm={8}
					sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
				>
					{editingProgram.CrossCollateralize && (
						<Box
							sx={{
								border: `1px solid ${blueGrey[200]}`,
								padding: 2,
								width: '92%',
								borderRadius: 2,
								// display: 'flex',
								// alignContent: 'center',
								// alignItems: 'center',
							}}
						>
							{editingProgram.CrossCollateralizePrograms &&
								editingProgram.CrossCollateralizePrograms.map((program) => (
									<Box
										component={Link}
										key={program._id}
										to={`/acquisitions/programs/${program._id}`}
										sx={{
											display: 'inline-block',
											//width: '100%',
											//minWidth: '100%',
											margin: 2,
											padding: 1,
											backgroundColor: blueGrey[600],
											color: blueGrey[50],
											textDecoration: 'none',
											borderRadius: 4,
										}}
									>
										{program.Name}
									</Box>
								))}
						</Box>
						// <TextField
						// 	color='secondary'
						// 	variant='outlined'
						// 	multiline
						// 	disabled
						// 	label='Programs For Cross Collateralise'
						// 	value={

						// 	}
						// 	name='ProgramsForCrossCollateralize'
						// 	onChange={(e) => handleChange(e)}
						// 	sx={textFieldStyles}
						// 	style={{ width: '88%' }}
						// />
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={12}
				>
					<TextField
						color='secondary'
						required
						variant='outlined'
						label='Vimeo Link'
						value={editingProgram.VimeoLink}
						name='VimeoLink'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
						style={{ width: '90%' }}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={12}
				>
					<TextField
						color='secondary'
						required
						multiline
						variant='outlined'
						label='Synopsis'
						value={editingProgram.Synopsis}
						name='Synopsis'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
						style={{ width: '90%' }}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					sm={12}
				>
					<TextField
						color='secondary'
						variant='outlined'
						multiline
						label='Awards'
						value={editingProgram.Awards}
						name='Awards'
						onChange={(e) => handleChange(e)}
						sx={textFieldStyles}
						style={{ width: '90%' }}
					/>
				</Grid>
			</Grid>
			<CreatedModified
				account={editingProgram}
				color={blueGrey[800]}
				backgroundColor={blueGrey[50]}
			/>
			<hr style={{ color: 'inherit', width: '95%' }} />
			<Box
				sx={{
					marginTop: 4,
					borderRadius: 2,
					backgroundColor: blueGrey[50],
					color: blueGrey[800],
				}}
			>
				<Notes
					id={editingProgram._id}
					type={'Program'}
					notes={editingProgram && editingProgram.Notes}
				/>
			</Box>
			<ProgramsModal
				id={editingProgram._id}
				programsModalOpen={rightsModal}
				handleProgramsModalClose={setRightsModal}
				selectedPrograms={editingProgram.CrossCollateralizePrograms || []}
				updatePrograms={changeCrossCollateralizePrograms}
				deleteProgram={deleteCrossCollateralizePrograms}
			/>
		</Box>
	);
}

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
