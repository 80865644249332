import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';
import { updateTabTitle } from './tabsSlice';

const initialState = {
	sales: [],
	editing: [],
	original: [],
	salesTableSettings: {
		page: 1,
		pageSize: 25,
		searchTerm: '',
		searchEvery: false,
		showAll: true,
		sortBy: 'Name',
		sortOrder: 'asc',
	},
};

export const salesSlice = createSlice({
	name: 'sales',
	initialState,
	reducers: {
		getSales: (state, action) => {
			state.sales = action.payload;
		},
		getSale: (state, action) => {
			//Populate Original Sale
			if (state.original.length > 0) {
				let indexOfOriginal = state.original.findIndex(
					(sales) => sales._id === action.payload._id
				);

				if (indexOfOriginal !== -1) {
					state.original[indexOfOriginal] = action.payload;
				} else {
					state.original.push(action.payload);
				}
			} else {
				state.original.push(action.payload);
			}

			//Populate Editing Sale
			if (state.editing.length > 0) {
				let indexOfEditing = state.editing.findIndex(
					(edit) => edit._id === action.payload._id
				);

				if (indexOfEditing !== -1) {
					state.editing[indexOfEditing] = action.payload;
				} else {
					state.editing.push(action.payload);
				}
			} else {
				state.editing.push(action.payload);
			}
		},
		createSale: (state, action) => {
			//Populate Original Sale
			if (state.original.length > 0) {
				let indexOfOriginal = state.original.findIndex(
					(sales) => sales._id === action.payload._id
				);

				if (indexOfOriginal !== -1) {
					state.original[indexOfOriginal] = { _id: action.payload._id };
				} else {
					state.original.push({ _id: action.payload._id });
				}
			} else {
				state.original.push({ _id: action.payload._id });
			}

			//Populate Editing Sale
			if (state.editing.length > 0) {
				let indexOfEditing = state.editing.findIndex(
					(edit) => edit._id === action.payload._id
				);

				if (indexOfEditing !== -1) {
					state.editing[indexOfEditing] = action.payload;
				} else {
					state.editing.push(action.payload);
				}
			} else {
				state.editing.push(action.payload);
			}
		},
		changeEditingSale: (state, action) => {
			let index = state.editing.findIndex(
				(edit) => edit._id === action.payload._id
			);

			if (index !== -1) {
				state.editing[index][action.payload.name] = action.payload.value;
			}
		},
		changeEditingSaleItem: (state, action) => {
			let index = state.editing.findIndex(
				(sale) => sale._id === action.payload.sale
			);

			if (index !== -1) {
				state.editing[index].SaleItems.filter(
					(item) => item.Program._id === action.payload.program
				).forEach((item) => {
					item[action.payload.name] = action.payload.value;
				});
			}
		},
		changeEditingSaleItemChecked: (state, action) => {
			let index = state.editing.findIndex(
				(sale) => sale._id === action.payload.sale
			);

			if (index !== -1) {
				if (action.payload.name === 'Select All') {
					state.editing[index].SaleItems.filter(
						(item) => item.Program._id === action.payload.program
					).forEach((item) => {
						item.Checked = action.payload.checked;
					});
				} else {
					let itemIndex = state.editing[index].SaleItems.findIndex(
						(item) => item._id === action.payload.name
					);

					if (itemIndex !== -1) {
						state.editing[index].SaleItems[itemIndex].Checked =
							action.payload.checked;
					}
				}
			}
		},
		deleteEditingSaleItem: (state, action) => {},
		changeEditingTerritory: (state, action) => {},
		addEditingPaymentTerm: (state, action) => {
			let index = state.editing.findIndex(
				(edit) => edit._id === action.payload._id
			);

			if (index !== -1) {
				state.editing[index].PaymentTerms = [
					...state.editing[index].PaymentTerms,
					action.payload.paymentTerm,
				];
			}

			let terms = [...state.editing[index].PaymentTerms];

			terms.forEach((term) => {
				term.Percent = (100 / state.editing[index].PaymentTerms.length).toFixed(
					2
				);
			});

			state.editing[index].PaymentTerms = terms;
		},
		changeEditingPaymentTerm: (state, action) => {
			let index = state.editing.findIndex(
				(edit) => edit._id === action.payload._id
			);

			if (index !== -1) {
				let paymentTermIndex = state.editing[index].PaymentTerms.findIndex(
					(term) => term._id === action.payload.paymentTermId
				);

				if (paymentTermIndex !== -1) {
					state.editing[index].PaymentTerms[paymentTermIndex][
						action.payload.name
					] = action.payload.value;
				}
			}
		},
		deleteEditingPaymentTerm: (state, action) => {
			let index = state.editing.findIndex(
				(edit) => edit._id === action.payload._id
			);

			if (index !== -1) {
				state.editing[index].PaymentTerms = state.editing[
					index
				].PaymentTerms.filter((x) => x._id !== action.payload.paymentTermId);
			}

			let terms = [...state.editing[index].PaymentTerms];

			terms.forEach((term) => {
				term.Percent = (100 / state.editing[index].PaymentTerms.length).toFixed(
					2
				);
			});

			state.editing[index].PaymentTerms = terms;
		},
		changeSalesTableSettings: (state, action) => {
			if (
				action.payload.name === 'searchTerm' ||
				action.payload.name === 'pageSize' ||
				action.payload.name === 'page'
			) {
				state.salesTableSettings = {
					...state.salesTableSettings,
					[action.payload.name]: action.payload.value,
				};
			} else if (action.payload.name === 'sortBy') {
				state.salesTableSettings = {
					...state.salesTableSettings,
					[action.payload.name]: action.payload.value,
					sortBy: action.payload.sortBy,
					sortOrder: action.payload.sortOrder,
				};
			} else {
				state.salesTableSettings = {
					...state.salesTableSettings,
					[action.payload.name]: action.payload.checked,
				};
			}
		},
		removeSale: (state, action) => {
			state.original = state.original.filter((x) => x._id !== action.payload);
			state.editing = state.editing.filter((x) => x._id !== action.payload);
		},
		resetSales: () => initialState,
	},
});

export const {
	getSales,
	getSale,
	createSale,
	changeEditingSale,
	changeEditingSaleItem,
	deleteEditingSaleItem,
	changeEditingSaleItemChecked,
	changeEditingTerritory,
	addEditingPaymentTerm,
	changeEditingPaymentTerm,
	deleteEditingPaymentTerm,
	changeSalesTableSettings,
	removeSale,
	resetSales,
} = salesSlice.actions;

export const selectOriginalSale = (id) => (state) => {
	if (state.sales.original.length > 0) {
		return state.sales.original.find((sales) => sales._id === id);
	} else {
		return null;
	}
};

export const selectEditingSale = (id) => (state) => {
	if (state.sales.editing.length > 0) {
		return state.sales.editing.find((sales) => sales._id === id);
	} else {
		return null;
	}
};

export const selectSales = (state) => state.sales.sales;

export const selectSalesTableSettings = (state) =>
	state.sales.salesTableSettings;

// Load Saless (By Type) Async
export const getSalesAsync = (type) => async (dispatch) => {
	await api
		.get(`/sale/`, { withCredentials: true })
		.then((res) => {
			dispatch(getSales(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Load Sales
export const getSaleAsync = (id) => async (dispatch) => {
	await api
		.get(`/sale/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getSale(res.data));

			dispatch(
				updateTabTitle({
					id: res.data._id,
					title: `${res.data.ContractNumber} - ${res.data.Name}`,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			//dispatch(verifyAuthAsync(error.response.data.message));
		});
};

//Create Sale
export const createSaleAsync = (sale) => async (dispatch) => {
	dispatch(handleErrorAlertAsync('Error!!!'));
};

//Update Sale
export const updateSaleAsync = (sale) => async (dispatch) => {
	dispatch(handleErrorAlertAsync('Error!!!'));
};

export default salesSlice.reducer;
