export function renderPrograms(
	programs,
	searchTerms,
	rightsTree,
	programsTableSettings
) {
	let programsToDisplay = programs ? programs : [];

	if (searchTerms) {
		if (searchTerms.Name !== '') {
			programsToDisplay = programsToDisplay.filter((p) =>
				p.Name.toLowerCase().includes(searchTerms.Name.toLowerCase())
			);
		}

		// if (searchTerms.LicensedMedia.length > 0) {
		// 	programsToDisplay = programsToDisplay.filter((p) =>
		// 		searchTerms.LicensedMedia.some((term) =>
		// 			p.LicensedMedia.some((media) => media.includes(term))
		// 		)
		// 	);
		// }

		if (searchTerms.Format !== '') {
			programsToDisplay = programsToDisplay.filter(
				(p) => p.Format && p.Format.includes(searchTerms.Format)
			);
		}

		if (searchTerms.Definition.length > 0) {
			programsToDisplay = programsToDisplay.filter((p) =>
				searchTerms.Definition.some((term) =>
					p.Definition.some((defintion) => defintion.includes(term))
				)
			);
		}

		if (searchTerms.Genre.length > 0) {
			programsToDisplay = programsToDisplay.filter((p) =>
				searchTerms.Genre.some((term) =>
					p.Genre.some((genre) => genre.includes(term))
				)
			);
		}

		if (searchTerms.YOP !== '') {
			programsToDisplay = programsToDisplay.filter(
				(p) =>
					p.YearOfProduction && p.YearOfProduction.includes(searchTerms.YOP)
			);
		}
	}

	// selectedProgramsToDisplay = selectedProgramsToDisplay.sort(
	// 	(program1, program2) => {
	// 		if (
	// 			!program1[programsTableSettings.sortBy] ||
	// 			!program2[programsTableSettings.sortBy]
	// 		) {
	// 			return -1;
	// 		}

	// 		if (programsTableSettings.sortOrder === 'asc') {
	// 			if (
	// 				program2[programsTableSettings.sortBy].toLowerCase() >
	// 				program1[programsTableSettings.sortBy].toLowerCase()
	// 			) {
	// 				return -1;
	// 			}

	// 			if (
	// 				program2[programsTableSettings.sortBy].toLowerCase() <
	// 				program1[programsTableSettings.sortBy].toLowerCase()
	// 			) {
	// 				return 1;
	// 			}

	// 			return 0;
	// 		} else {
	// 			if (
	// 				program2[programsTableSettings.sortBy].toLowerCase() <
	// 				program1[programsTableSettings.sortBy].toLowerCase()
	// 			) {
	// 				return -1;
	// 			}

	// 			if (
	// 				program2[programsTableSettings.sortBy].toLowerCase() >
	// 				program1[programsTableSettings.sortBy].toLowerCase()
	// 			) {
	// 				return 1;
	// 			}

	// 			return 0;
	// 		}
	// 	}
	// );

	programsToDisplay = programsToDisplay.sort((program1, program2) => {
		if (
			!program1[programsTableSettings.sortBy] ||
			!program2[programsTableSettings.sortBy]
		) {
			return -1;
		}

		if (programsTableSettings.sortOrder === 'asc') {
			if (
				program2[programsTableSettings.sortBy].toLowerCase() >
				program1[programsTableSettings.sortBy].toLowerCase()
			) {
				return -1;
			}

			if (
				program2[programsTableSettings.sortBy].toLowerCase() <
				program1[programsTableSettings.sortBy].toLowerCase()
			) {
				return 1;
			}

			return 0;
		} else {
			if (
				program2[programsTableSettings.sortBy].toLowerCase() <
				program1[programsTableSettings.sortBy].toLowerCase()
			) {
				return -1;
			}

			if (
				program2[programsTableSettings.sortBy].toLowerCase() >
				program1[programsTableSettings.sortBy].toLowerCase()
			) {
				return 1;
			}

			return 0;
		}
	});

	//programsToDisplay = [...selectedProgramsToDisplay, ...programsToDisplay];

	programsToDisplay = programsToDisplay.slice(
		(programsTableSettings.page - 1) * programsTableSettings.pageSize,
		programsTableSettings.page * programsTableSettings.pageSize
	);

	return programsToDisplay;
}
