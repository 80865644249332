import {
	Box,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	NavigateBeforeTwoTone,
	NavigateNextTwoTone,
} from '@mui/icons-material';
import { blueGrey } from '@mui/material/colors';
import ConvertToSaleStep4Row from './ConvertToSaleStep4Row';
import { useDispatch } from 'react-redux';
import { createAlertAsync } from '../../../../redux/alertsSlice';

export default function ConvertToSaleStep4({ newSale, handleChange }) {
	const dispatch = useDispatch();

	console.log(newSale.SaleItems.some((item) => item.UnitPrice === 0));

	function submit(e) {
		e.preventDefault();

		if (newSale.SaleItems.some((item) => item.UnitPrice === '0')) {
			dispatch(
				createAlertAsync({
					message: 'Unit Price Cannot Be Zero',
					severity: 'warning',
					autoDismiss: true,
				})
			);
		} else {
			handleChange({ target: { name: 'stepIndex', value: 4 } });
		}
	}

	return (
		<Box
			component='form'
			onSubmit={submit}
			sx={{ height: '84%', overflowX: 'auto' }}
		>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[900],
					height: 64,
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Grid
					item
					xs={1}
				>
					<Tooltip title='Back'>
						<IconButton
							onClick={() =>
								handleChange({ target: { name: 'stepIndex', value: 2 } })
							}
							sx={{ padding: 0 }}
						>
							<NavigateBeforeTwoTone
								sx={{
									color: blueGrey[50],
									backgroundColor: blueGrey[800],
									height: 40,
									width: 40,
									borderRadius: 40,
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid
					item
					xs={10}
				>
					<Typography
						variant='h5'
						component='h2'
						sx={{ color: blueGrey[50] }}
					>
						Programs
					</Typography>
				</Grid>
				<Grid
					item
					xs={1}
				>
					<Tooltip title='Next'>
						<IconButton
							type='submit'
							sx={{ padding: 0 }}
						>
							<NavigateNextTwoTone
								sx={{
									color: blueGrey[50],
									backgroundColor: blueGrey[800],
									height: 40,
									width: 40,
									borderRadius: 40,
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<TableContainer
				sx={{ color: blueGrey[50], backgroundColor: blueGrey[900] }}
			>
				<Table sx={{ color: blueGrey[50], backgroundColor: blueGrey[900] }}>
					<TableHead sx={{ color: blueGrey[50], backgroundColor: [900] }}>
						<TableRow
							sx={{ color: blueGrey[50], backgroundColor: blueGrey[900] }}
						>
							<TableCell
								sx={{
									color: blueGrey[50],
									textAlign: 'center',
									borderBottom: `1px solid ${blueGrey[500]}`,
								}}
							/>
							<TableCell
								sx={{
									color: blueGrey[50],
									textAlign: 'center',
									borderBottom: `1px solid ${blueGrey[500]}`,
								}}
							>
								Program Name
							</TableCell>
							<TableCell
								sx={{
									color: blueGrey[50],
									textAlign: 'center',
									borderBottom: `1px solid ${blueGrey[500]}`,
								}}
							>
								Unit Price
							</TableCell>
							<TableCell
								sx={{
									color: blueGrey[50],
									textAlign: 'center',
									borderBottom: `1px solid ${blueGrey[500]}`,
								}}
							>
								Hours
							</TableCell>
							<TableCell
								sx={{
									color: blueGrey[50],
									textAlign: 'center',
									borderBottom: `1px solid ${blueGrey[500]}`,
								}}
							>
								Total Fee
							</TableCell>
							<TableCell
								sx={{
									color: blueGrey[50],
									textAlign: 'center',
									borderBottom: `1px solid ${blueGrey[500]}`,
								}}
							>
								Programs Items
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{[
							...new Set(newSale.SaleItems.map((item) => item.Program._id)),
						].map((program, index) => (
							<ConvertToSaleStep4Row
								key={program}
								program={program}
								index={index}
								newSale={newSale}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
