import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScreenerDetailsItemsRow from './ScreenerDetailsItemsRow';
import { blueGrey } from '@mui/material/colors';
import {
	Checkbox,
	IconButton,
	MenuItem,
	TextField,
	Tooltip,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { useState } from 'react';
import ProgramsModal from '../../../shared/programsModal/ProgramsModal';
import {
	changeEditingScreenerItems,
	deleteEditingScreenerItem,
	updateScreenerItemsStatus,
} from '../../../../redux/screenerSlice';
import { useDispatch } from 'react-redux';

export default function ScreenerDetailsItems({
	id,
	screenerItems,
	selectedItems,
	setSelectedItems,
	rightsTree,
}) {
	const dispatch = useDispatch();
	const [statusSelect, setStatusSelect] = useState('Select Status');
	const [programsModalOpen, setProgramsModalOpen] = useState(false);

	function handleProgramsModalOpen() {
		setProgramsModalOpen(true);
	}

	function handleProgramsModalClose() {
		setProgramsModalOpen(false);
	}

	function onChange(e) {
		if (e.target.checked) {
			let itemsToAdd = [];
			screenerItems.forEach((item) => {
				itemsToAdd.push(item._id);
			});

			setSelectedItems(itemsToAdd);
		} else {
			setSelectedItems([]);
		}
	}

	function handleScreenerItemStatusChange(e) {
		dispatch(
			updateScreenerItemsStatus({
				id: id,
				selectedItems: selectedItems,
				value: e.target.value,
			})
		);
		setStatusSelect('Select Status');
	}

	return (
		<>
			<TableContainer
				component={Paper}
				sx={{
					backgroundColor: blueGrey[700],
					color: blueGrey[50],
					boxShadow: 'none',
					paddingBottom: 4,
					marginBottom: 2,
				}}
			>
				<Table>
					<TableHead>
						<TableRow sx={{ backgroundColor: blueGrey[800], height: '100px' }}>
							<TableCell sx={cellStyles}>
								<Checkbox
									name='all'
									color='primary'
									checked={screenerItems.length === selectedItems.length}
									onChange={onChange}
								/>
							</TableCell>
							<TableCell
								align='center'
								sx={cellStyles}
							>
								Name
							</TableCell>
							<TableCell
								align='center'
								sx={cellStyles}
							>
								Program
							</TableCell>
							{selectedItems && selectedItems.length > 0 ? (
								<TableCell
									align='center'
									sx={cellStyles}
								>
									<TextField
										key='SelectStatus'
										select
										variant='outlined'
										label='Status'
										value={statusSelect}
										name='Status'
										onChange={handleScreenerItemStatusChange}
										color='secondary'
										InputLabelProps={{ shrink: false }}
										//notched={false}
										SelectProps={{
											MenuProps: {
												sx: {
													'& .MuiPaper-root': {
														color: blueGrey[50],
														backgroundColor: blueGrey[700],
														padding: 2,
														textAlign: 'center !important',
													},
													'& .MuiMenuItem-root': {
														color: blueGrey[50],
														backgroundColor: blueGrey[700],
														padding: 2,
														textAlign: 'center !important',
													},
												},
											},
										}}
										sx={textFields}
									>
										<MenuItem
											value={'Select Status'}
											disabled
										>
											Select Status
										</MenuItem>
										<MenuItem value={'Draft'}>Draft</MenuItem>
										<MenuItem value={'Sent'}>Sent</MenuItem>
										<MenuItem value={'Passed'}>Passed</MenuItem>
										<MenuItem value={'Sale Made'}>Sale Made</MenuItem>
									</TextField>
								</TableCell>
							) : (
								<TableCell
									align='center'
									sx={cellStyles}
								>
									Status
								</TableCell>
							)}

							<TableCell
								align='right'
								sx={cellStyles}
							>
								<Tooltip
									title='Add Programs'
									arrow={true}
								>
									<IconButton
										onClick={handleProgramsModalOpen}
										sx={{ padding: 0 }}
									>
										<AddCircle
											sx={{
												color: blueGrey[50],
												backgroundColor: 'transparent',
												borderBottomRadius: 10,
												borderTopRadius: 10,
												fontSize: '1.5em',
												height: 30,
											}}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{screenerItems.map((screenerItem) => (
							<ScreenerDetailsItemsRow
								key={screenerItem._id}
								screenerItem={screenerItem}
								selectedItems={selectedItems}
								setSelectedItems={setSelectedItems}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<ProgramsModal
				id={id}
				rightsTree={rightsTree}
				programsModalOpen={programsModalOpen}
				selectedPrograms={screenerItems}
				updatePrograms={changeEditingScreenerItems}
				deleteProgram={deleteEditingScreenerItem}
				handleProgramsModalClose={handleProgramsModalClose}
			/>
		</>
	);
}

const cellStyles = {
	color: blueGrey[50],
	borderBottom: 'none',
	minWidth: '95%',
	maxWidth: '95%',
	'& .MuiSvgIcon-root': { color: blueGrey[50] },
};

const textFields = {
	minWidth: '95%',
	maxWidth: '95%',
	textAlign: 'center',
	color: blueGrey[50],
	borderRadius: 8,

	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: blueGrey[50],
		},
		'&:hover fieldset': {
			borderColor: blueGrey[50],
		},
		color: blueGrey[50],
		backgroundColor: blueGrey[800],
	},
	'& .MuiOutlinedInput-input': {
		color: blueGrey[50],
		backgroundColor: blueGrey[800],
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[800],
		backgroundColor: blueGrey[800],
	},
	'& .MuiOutlinedInput-colorSecondary': {
		color: blueGrey[50],
		backgroundColor: blueGrey[800],
	},
};
