import { Button, Modal, TextField, Typography } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { Box, Stack } from '@mui/system';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserPasswordAsync } from '../../../redux/userSlice';
import { createAlertAsync } from '../../../redux/alertsSlice';

export default function PasswordChangeModal({ modalOpen, setModalOpen }) {
	const dispatch = useDispatch();
	const [passwordForm, setPasswordForm] = useState({
		currentPassword: '',
		newPassword: '',
		repeatNewPassword: '',
	});

	function handleChange(e) {
		setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
	}

	function submit(e) {
		e.preventDefault();

		if (passwordForm.currentPassword === passwordForm.newPassword) {
			dispatch(
				createAlertAsync({
					message: 'Current Password And New Password Cannot Be The Same!',
					severity: 'error',
					autoDismiss: false,
				})
			);

			return;
		}

		if (passwordForm.newPassword !== passwordForm.repeatNewPassword) {
			dispatch(
				createAlertAsync({
					message: 'New Passwords Do Not Match',
					severity: 'error',
					autoDismiss: false,
				})
			);

			return;
		}

		dispatch(updateUserPasswordAsync(passwordForm));

		setPasswordForm({
			currentPassword: '',
			newPassword: '',
			repeatNewPassword: '',
		});

		setModalOpen(false);
	}

	return (
		<Modal
			disableEscapeKeyDown
			open={modalOpen}
			//onClose={() => setModalOpen(false)}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					minWidth: '50%',
					minHeight: '50%',
					backgroundColor: blueGrey[900],
					color: blueGrey[50],
					border: `2px solid ${blueGrey[500]}`,
					boxShadow: 24,
					borderRadius: 4,
				}}
			>
				<Box
					sx={{
						p: 4,
						width: '100%',
						textAlign: 'center',
					}}
				>
					<Typography
						variant='h3'
						gutterBottom
					>
						Password Change:
					</Typography>
					<Box
						sx={{ marginTop: 4 }}
						component='form'
						onSubmit={submit}
					>
						<TextField
							required
							variant='outlined'
							color='secondary'
							label='Current Password:'
							value={passwordForm.currentPassword}
							name='currentPassword'
							onChange={(e) => handleChange(e)}
							inputProps={{ style: { color: blueGrey[50] } }}
							sx={{
								minWidth: '80%',
								textAlign: 'left',
								backgroundColor: blueGrey[900],
								marginTop: 2,
								marginBottom: 2,
								'& .MuiInput-root': {
									color: blueGrey[50],
								},
								'& .MuiInputLabel-root': {
									color: blueGrey[50],
								},

								'& label.Mui-focused': {
									color: orange[500],
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'yellow',
								},
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: 'white',
									},
									'&:hover fieldset': {
										borderColor: 'white',
									},
									'&.Mui-focused fieldset': {
										borderColor: orange[500],
									},
								},
							}}
						/>
						<TextField
							color='secondary'
							required
							variant='outlined'
							label='New Password:'
							value={passwordForm.newPassword}
							name='newPassword'
							onChange={(e) => handleChange(e)}
							inputProps={{ style: { color: blueGrey[50] } }}
							sx={{
								minWidth: '80%',
								textAlign: 'left',
								backgroundColor: blueGrey[900],
								marginTop: 2,
								marginBottom: 2,
								'& .MuiInput-root': {
									color: blueGrey[50],
								},
								'& .MuiInputLabel-root': {
									color: blueGrey[50],
								},

								'& label.Mui-focused': {
									color: orange[500],
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'yellow',
								},
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: 'white',
									},
									'&:hover fieldset': {
										borderColor: 'white',
									},
									'&.Mui-focused fieldset': {
										borderColor: orange[500],
									},
								},
							}}
						/>
						<TextField
							color='secondary'
							required
							variant='outlined'
							label='Repeat New Password:'
							value={passwordForm.repeatNewPassword}
							name='repeatNewPassword'
							onChange={(e) => handleChange(e)}
							inputProps={{ style: { color: blueGrey[50] } }}
							sx={{
								minWidth: '80%',
								textAlign: 'left',
								backgroundColor: blueGrey[900],
								marginTop: 2,
								marginBottom: 2,
								'& .MuiInput-root': {
									color: blueGrey[50],
								},
								'& .MuiInputLabel-root': {
									color: blueGrey[50],
								},

								'& label.Mui-focused': {
									color: orange[500],
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'yellow',
								},
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: 'white',
									},
									'&:hover fieldset': {
										borderColor: 'white',
									},
									'&.Mui-focused fieldset': {
										borderColor: orange[500],
									},
								},
							}}
						/>
					</Box>
				</Box>
				<Box
					sx={{
						p: 4,
						bottom: '5%',
						width: '100%',
						position: 'fixed',
						textAlign: 'center',
					}}
				>
					<Stack
						spacing={2}
						direction='row'
						sx={{ width: '100%' }}
					>
						<Button
							variant='contained'
							onClick={() => setModalOpen(false)}
							sx={{
								flexGrow: 1,
								backgroundColor: blueGrey[500],
								'&:hover': { backgroundColor: blueGrey[600] },
							}}
						>
							Cancel
						</Button>
						<Button
							variant='contained'
							onClick={submit}
							type='submit'
							sx={{
								flexGrow: 1,
								backgroundColor: blueGrey[500],
								'&:hover': { backgroundColor: blueGrey[600] },
							}}
						>
							Save Password
						</Button>
					</Stack>
				</Box>
			</Box>
		</Modal>
	);
}
