import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectUsers,
	getUsersAsync,
	getUserAsync,
	createUserAsync,
	selectUser,
	selectEditing,
	setEditing,
	removeUser,
	updateUserAsync,
} from '../../redux/userSlice';
import { selectCurrentTab, updateTabStatus } from '../../redux/tabsSlice';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { blueGrey, orange } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import { createAlertAsync } from '../../redux/alertsSlice';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
import Backdrop from '@mui/material/Backdrop';
import CreatedModified from '../shared/CreatedModified';
import { selectDepartments } from '../../redux/departmentSlice';
import { selectRoles } from '../../redux/roleSlice';

export default function Users() {
	const dispatch = useDispatch();
	const currentTab = useSelector(selectCurrentTab);
	const users = useSelector(selectUsers);
	const roles = useSelector(selectRoles);
	const departments = useSelector(selectDepartments);
	const editing = useSelector(selectEditing());
	const user = useSelector(selectUser());
	const [newUser, setNewUser] = useState({
		FirstName: '',
		LastName: '',
		Email: '',
		Password: '',
		Role: 'user',
		Department: '',
		Title: '',
		Phone: '',
		Manager: '',
		Approver: '',
	});
	const [addModalOpen, setAddModal] = useState(false);
	const [dialogueModalOpen, setDialogueModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleAddModal = () => setAddModal(!addModalOpen);
	const handleDialogueModal = () => setDialogueModal(!dialogueModalOpen);

	useEffect(() => {
		if (!users) {
			dispatch(getUsersAsync());
		}
	}, [users, dispatch]);

	useEffect(() => {
		if (_.isEqual(editing, user)) {
			dispatch(updateTabStatus({ id: currentTab.id, status: true }));
		} else {
			dispatch(updateTabStatus({ id: currentTab.id, status: false }));
		}
	}, [currentTab, editing, user, dispatch]);

	useEffect(() => {
		if (editing) {
			setLoading(false);
		}
	}, [editing, users]);

	function handleChange(e) {
		setNewUser({ ...newUser, [e.target.name]: e.target.value });
	}

	function handleEdit(event) {
		dispatch(
			setEditing({ name: event.target.name, value: event.target.value })
		);
	}

	function addUser(e) {
		e.preventDefault();

		const exitingUser = users.find((user) => user.Email === newUser.Email);

		if (!exitingUser) {
			dispatch(createUserAsync(newUser));
			setNewUser('');
			handleAddModal();
		} else {
			dispatch(
				createAlertAsync({
					message: 'User already exists',
					severity: 'error',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		}
	}

	function getUser(id) {
		if (!editing || (editing && _.isEqual(user, editing))) {
			dispatch(removeUser());
			setLoading(true);
			dispatch(getUserAsync(id));
		} else {
			handleDialogueModal();
		}
	}

	function stopEditing() {
		if (_.isEqual(user, editing)) {
			setLoading(false);
			dispatch(removeUser(editing._id));
		} else {
			handleDialogueModal();
		}
	}

	function updateUser(e) {
		e.preventDefault();
		setLoading(true);
		dispatch(updateUserAsync(editing));
	}

	function discardChanges() {
		setLoading(false);
		dispatch(removeUser());
		handleDialogueModal();
	}

	const saveColor = _.isEqual(user, editing) ? blueGrey[800] : orange[800];

	return (
		<Box>
			<Box sx={{ textAlign: 'right', marginBottom: 2, marginRight: 1 }}>
				<Tooltip title='Add User'>
					<IconButton onClick={handleAddModal}>
						<AddCircleIcon
							sx={{
								color: blueGrey[600],
								backgroundColor: blueGrey[100],
								borderRadius: '5px',
								fontSize: '1.5em',
							}}
						/>
					</IconButton>
				</Tooltip>
			</Box>
			<Box
				sx={{
					display: 'flex',
				}}
			>
				<Box
					sx={{
						flexGrow: 1,
						backgroundColor: blueGrey[100],
						color: blueGrey[900],
						borderRadius: '5px',
						margin: 2,
						paddingTop: 4,
						paddingBottom: 4,
						textAlign: 'center',
						alignItems: 'center',
						width: '15em',
						minWidth: '15em',
						maxWidth: '15em',
						minHeight: '400px',
					}}
				>
					{users && (
						<Box>
							<Box sx={{ color: blueGrey[900], marginBottom: 1 }}>ACTIVE</Box>
							{users.map(
								(user) =>
									user.IsActive === true && (
										<Box
											key={user._id}
											sx={{ textAlign: 'center' }}
										>
											<Button
												variant='contained'
												onClick={() => getUser(user._id)}
												sx={{
													minWidth: '10em',
													textTransform: 'capitalize',
													marginBottom: 2,
													backgroundColor:
														user && editing && user._id === editing._id
															? orange[800]
															: blueGrey[800],
													color: user.IsActive ? blueGrey[50] : blueGrey[900],
													'&:hover': {
														backgroundColor: blueGrey[900],
													},
												}}
											>
												{user.FirstName} {user.LastName}
											</Button>
										</Box>
									)
							)}
						</Box>
					)}
					{users && (
						<Box>
							<Box sx={{ color: blueGrey[900], marginTop: 4, marginBottom: 1 }}>
								INACTIVE
							</Box>
							{users.map(
								(user) =>
									user.IsActive === false && (
										<Box
											key={user._id}
											sx={{ textAlign: 'center' }}
										>
											<Button
												variant='contained'
												onClick={() => getUser(user._id)}
												sx={{
													minWidth: '10em',
													textTransform: 'capitalize',
													marginBottom: 2,
													backgroundColor:
														user && editing && user._id === editing._id
															? orange[800]
															: blueGrey[500],
													color: user.IsActive ? blueGrey[50] : blueGrey[900],
													'&:hover': {
														backgroundColor: blueGrey[900],
													},
												}}
											>
												{user.FirstName} {user.LastName}
											</Button>
										</Box>
									)
							)}
						</Box>
					)}
					{!users && (
						<Box>
							<CircularProgress />
						</Box>
					)}
				</Box>
				<Box
					sx={{
						flexGrow: 8,
						backgroundColor: blueGrey[100],
						color: blueGrey[900],
						borderRadius: '10px',
						margin: 2,
						paddingTop: 2,
						paddingBottom: 2,
						visibility: `${editing || loading ? 'visible' : 'hidden'}`,
						minWidth: '500px',
					}}
				>
					{editing && !loading ? (
						<>
							<Box
								component={'form'}
								onSubmit={updateUser}
								sx={{ textAlign: 'right', marginRight: 4 }}
							>
								<Tooltip title='Save User'>
									<IconButton
										onClick={updateUser}
										sx={{ marginRight: 2 }}
									>
										<SaveIcon
											style={{
												color: saveColor,
												borderRadius: '5px',
												fontSize: '1.5em',
											}}
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Close'>
									<IconButton onClick={stopEditing}>
										<CancelIcon
											sx={{
												color: blueGrey[600],
												borderRadius: '5px',
												fontSize: '1.5em',
											}}
										/>
									</IconButton>
								</Tooltip>
							</Box>
							<Grid
								container
								spacing={2}
								sx={{ marginTop: 6 }}
							>
								<Grid
									sm={6}
									item
								></Grid>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='IsActive'
										select
										required
										variant='outlined'
										color='secondary'
										label='Status:'
										value={editing.IsActive}
										name='IsActive'
										onChange={handleEdit}
										sx={{ width: '80%' }}
									>
										<MenuItem value={true}>Active</MenuItem>
										<MenuItem value={false}>Inactive</MenuItem>
									</TextField>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								sx={{ marginTop: 6 }}
							>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='FirstName'
										required
										variant='outlined'
										color='secondary'
										label='First Name'
										value={editing.FirstName}
										name='FirstName'
										onChange={handleEdit}
										sx={{ width: '80%' }}
									/>
								</Grid>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='LastName'
										required
										variant='outlined'
										color='secondary'
										label='Last Name'
										value={editing.LastName}
										name='LastName'
										onChange={handleEdit}
										sx={{
											width: '80%',
											'& .MuiInputBase-input': {},
										}}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								sx={{ marginTop: 6 }}
							>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Email'
										required
										variant='outlined'
										color='secondary'
										label='Email'
										value={editing.Email}
										name='Email'
										onChange={handleEdit}
										sx={{ width: '80%' }}
									/>
								</Grid>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Password'
										placeholder="Leave Blank Unless Changing User's Password"
										variant='outlined'
										color='secondary'
										label='Password'
										value={editing.Password}
										name='Password'
										onChange={handleEdit}
										sx={{ width: '80%' }}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								sx={{ marginTop: 6 }}
							>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Role'
										required
										select
										variant='outlined'
										color='secondary'
										label='Role'
										value={editing.Role}
										name='Role'
										onChange={handleEdit}
										sx={{
											width: '80%',
											'& .MuiInputBase-input': {},
										}}
									>
										{roles &&
											roles.map((role) => (
												<MenuItem
													key={role._id}
													value={role.Name}
													disabled={!role.IsActive}
												>
													{role.Name}
												</MenuItem>
											))}
									</TextField>
								</Grid>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Department'
										required
										select
										variant='outlined'
										color='secondary'
										label='Department'
										value={editing.Department}
										name='Department'
										onChange={handleEdit}
										sx={{
											width: '80%',
											'& .MuiInputBase-input': {},
										}}
									>
										{departments &&
											departments.map((department) => (
												<MenuItem
													key={department._id}
													value={department.Name}
													disabled={!department.IsActive}
												>
													{department.Name}
												</MenuItem>
											))}
									</TextField>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								sx={{ marginTop: 6 }}
							>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Title'
										required
										variant='outlined'
										color='secondary'
										label='Title'
										value={editing.Title}
										name='Title'
										onChange={handleEdit}
										sx={{ width: '80%' }}
									/>
								</Grid>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Phone'
										placeholder='Phone'
										variant='outlined'
										color='secondary'
										label='Phone'
										value={editing.Phone}
										name='Phone'
										onChange={handleEdit}
										sx={{ width: '80%' }}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								sx={{ marginTop: 6 }}
							>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Manager'
										required
										select
										variant='outlined'
										color='secondary'
										label='Manager'
										value={editing.Manager}
										name='Manager'
										onChange={handleEdit}
										sx={{
											width: '80%',
											'& .MuiInputBase-input': {},
										}}
									>
										{users &&
											users.map(
												(user) =>
													user.IsActive && (
														<MenuItem
															key={user._id}
															value={user._id}
															disabled={!user.IsActive}
														>
															{user.FirstName} {user.LastName}
														</MenuItem>
													)
											)}
									</TextField>
								</Grid>
								<Grid
									sm={6}
									item
								>
									<TextField
										key='Approver'
										required
										select
										variant='outlined'
										color='secondary'
										label='Approver'
										value={editing.Approver}
										name='Approver'
										onChange={handleEdit}
										sx={{
											width: '80%',
											'& .MuiInputBase-input': {},
										}}
									>
										{users &&
											users.map(
												(user) =>
													user.IsActive && (
														<MenuItem
															key={user._id}
															value={user._id}
															disabled={!user.IsActive}
														>
															{user.FirstName} {user.LastName}
														</MenuItem>
													)
											)}
									</TextField>
								</Grid>
							</Grid>
							<CreatedModified account={editing} />
						</>
					) : (
						<Box
							sx={{
								textAlign: 'center',
								alignItems: 'center',
								marginTop: 15,
								flexGrow: 20,
							}}
						>
							<CircularProgress
								size={60}
								color='primary'
								sx={{
									color: `${blueGrey[800]} !important`,
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Modal
				open={addModalOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						width: '75%',
						height: '75%',
						textAlign: 'center',
					}}
				>
					<Box
						component={'form'}
						onSubmit={addUser}
					>
						<Grid
							container
							spacing={2}
							sx={{ marginTop: 6 }}
						>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='FirstName'
									autoFocus
									required
									variant='outlined'
									color='secondary'
									label='First Name'
									value={newUser.FirstName}
									name='FirstName'
									onChange={handleChange}
									sx={{ width: '80%' }}
								/>
							</Grid>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='LastName'
									required
									variant='outlined'
									color='secondary'
									label='Last Name'
									value={newUser.LastName}
									name='LastName'
									onChange={handleChange}
									sx={{
										width: '80%',
										'& .MuiInputBase-input': {},
									}}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={2}
							sx={{ marginTop: 6 }}
						>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Email'
									required
									variant='outlined'
									color='secondary'
									label='Email'
									value={newUser.Email}
									name='Email'
									onChange={handleChange}
									sx={{ width: '80%' }}
								/>
							</Grid>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Password'
									required
									//placeholder="Leave Blank Unless Changing User's Password"
									variant='outlined'
									color='secondary'
									label='Password'
									value={newUser.Password}
									name='Password'
									onChange={handleChange}
									sx={{ width: '80%' }}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={2}
							sx={{ marginTop: 6 }}
						>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Role'
									required
									select
									variant='outlined'
									color='secondary'
									label='Role'
									value={newUser.Role}
									name='Role'
									onChange={handleChange}
									sx={{
										width: '80%',
										'& .MuiInputBase-input': {},
									}}
								>
									{roles &&
										roles.map((role) => (
											<MenuItem
												key={role._id}
												value={role.Name}
												disabled={!role.IsActive}
											>
												{role.Name}
											</MenuItem>
										))}
								</TextField>
							</Grid>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Department'
									required
									select
									variant='outlined'
									color='secondary'
									label='Department'
									value={newUser.Department}
									name='Department'
									onChange={handleChange}
									sx={{
										width: '80%',
										'& .MuiInputBase-input': {},
									}}
								>
									<MenuItem
										value={'department.Name'}
										disabled
									>
										Choose a Department
									</MenuItem>
									{departments &&
										departments.map((department) => (
											<MenuItem
												key={department._id}
												value={department.Name}
												disabled={!department.IsActive}
											>
												{department.Name}
											</MenuItem>
										))}
								</TextField>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={2}
							sx={{ marginTop: 6 }}
						>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Title'
									required
									variant='outlined'
									color='secondary'
									label='Title'
									value={newUser.Title}
									name='Title'
									onChange={handleChange}
									sx={{ width: '80%' }}
								/>
							</Grid>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Phone'
									required
									placeholder='Phone'
									variant='outlined'
									color='secondary'
									label='Phone'
									value={newUser.Phone}
									name='Phone'
									onChange={handleChange}
									sx={{ width: '80%' }}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={2}
							sx={{ marginTop: 6 }}
						>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Manager'
									required
									select
									variant='outlined'
									color='secondary'
									label='Manager'
									value={newUser.Manager}
									name='Manager'
									onChange={handleChange}
									sx={{
										width: '80%',
										'& .MuiInputBase-input': {},
									}}
								>
									{users &&
										users.map(
											(user) =>
												user.IsActive && (
													<MenuItem
														key={user._id}
														value={user._id}
														disabled={!user.IsActive}
													>
														{user.FirstName} {user.LastName}
													</MenuItem>
												)
										)}
								</TextField>
							</Grid>
							<Grid
								sm={6}
								item
							>
								<TextField
									key='Approver'
									required
									select
									variant='outlined'
									color='secondary'
									label='Approver'
									value={newUser.Approver}
									name='Approver'
									onChange={handleChange}
									sx={{
										width: '80%',
										'& .MuiInputBase-input': {},
									}}
								>
									{users &&
										users.map(
											(user) =>
												user.IsActive && (
													<MenuItem
														key={user._id}
														value={user._id}
														disabled={!user.IsActive}
													>
														{user.FirstName} {user.LastName}
													</MenuItem>
												)
										)}
								</TextField>
							</Grid>
						</Grid>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: 10 }}
						>
							<Button
								variant='contained'
								onClick={() => setAddModal(false)}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel
							</Button>
							<Button
								variant='contained'
								type='submit'
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Save
							</Button>
						</Stack>
					</Box>
				</Box>
			</Modal>
			<Modal
				open={dialogueModalOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: blueGrey[50],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Box
						sx={{
							textAlign: 'center',
							fontSize: '1.25em',
							color: blueGrey[900],
						}}
					>
						<Box>There are unsaved changes.</Box>
						<Box>How would you like to proceed?</Box>
						<Stack
							spacing={2}
							direction='row'
							sx={{ marginTop: '3em' }}
						>
							<Button
								variant='contained'
								onClick={handleDialogueModal}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Cancel & Go Back
							</Button>
							<Button
								variant='contained'
								onClick={discardChanges}
								sx={{
									flexGrow: 1,
									backgroundColor: blueGrey[500],
									'&:hover': { backgroundColor: blueGrey[600] },
								}}
							>
								Discard Changes
							</Button>
						</Stack>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
}
