import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import ProgramDetails1 from './ProgramDetails1';
import ProgramDetails2 from './ProgramDetails2';
import RightsIn from './RightsIn';
import Commission from './Commission';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectEditingProgram,
	addEditingProgram,
	changeEditingProgram,
} from '../../../../redux/programSlice';
import { selectUser } from '../../../../redux/authSlice';
import { selectUsers } from '../../../../redux/userSlice';
import { blueGrey, orange } from '@mui/material/colors';
import { Fade } from '@mui/material';
import Submit from './Submit';
import rightsTree from '../../../shared/territories.json';

const steps = [
	'Required Info',
	'Adition Info',
	'Rights In',
	'Commission',
	`Create New Program `,
];

export default function NewProgramStepper({ id, type }) {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const users = useSelector(selectUsers);
	const newProgram = useSelector(selectEditingProgram(id));

	useEffect(() => {
		if (!newProgram) {
			dispatch(
				addEditingProgram({
					saving: false,
					saved: false,
					newId: null,
					step: 0,
					_id: id,
					IsActive: true,
					ProductionCompany: '',
					ProgramStatus: '',
					Name: '',
					Synopsis: '',
					Currency: [],
					Genre: [],
					Language: [],
					Format: '',
					Definition: [],
					RunningTime: '',
					Season: '',
					Episodes: '',
					TotalHours: '',
					YearOfProduction: '',
					CountryOfOrigin: '',
					Territory: 'None',
					VimeoLink: '',
					Thumbnail: '',
					Awards: '',
					DistributionAdvance: false,
					DistributionAdvanceValue: '',
					DistributionExpenses: '',
					ProgramItems: [],
					SaleItems: [],
					RightsIn: [
						{
							_id: `new-rights-${crypto.randomUUID()}`,
							IsActive: true,
							Program: '',
							StartDate: '',
							IsEndDate: false,
							EndDate: '',
							Exclusive: false,
							Holdback: '',
							HoldbackEndDate: '',
							ContinueRollover: false,
							RightsIn: true,
							Territory: '',
							RightsTree: rightsTree,
							LicensedMedia: [],
						},
					],
					DeliveryDate: '',
					PerformanceGuarantee: false,
					PerformanceGuaranteeDate: '',
					PerformanceGuaranteeValue: '',
					LaunchBudget: '',
					ExpensesCap: '',
					CrossCollateralize: false,
					CrossCollateralizePrograms: '',
					MaterialsAvailable: '',
					TechSpecs: '',
					Commissions: [
						{
							_id: `new-commission-${crypto.randomUUID()}`,
							IsActive: true,
							//ProgramItem: ,
							Status: '',
							Commission: '',
						},
					],
					Notes: [],
				})
			);
			// dispatch(
			// 	addEditingProgram({
			// 		saving: false,
			// 		saved: false,
			// 		newId: null,
			// 		step: 0,
			// 		_id: id,
			// 		IsActive: true,
			// 		ProductionCompany: '',
			// 		ProgramStatus: 'Finished',
			// 		Name: '',
			// 		Synopsis: 'asdfasdfasdf',
			// 		Currency: ['USD'],
			// 		Genre: ['History'],
			// 		Language: ['English'],
			// 		Format: 'Series',
			// 		Definition: ['HD', '4K'],
			// 		RunningTime: 60,
			// 		Season: 1,
			// 		Episodes: 6,
			// 		TotalHours: '',
			// 		YearOfProduction: 2023,
			// 		CountryOfOrigin: 'US',
			// 		Territory: 'None',
			// 		VimeoLink: 'asdfasdfasdf',
			// 		Thumbnail: '',
			// 		Awards: 'asdfasdfasdf',
			// 		DistributionAdvance: true,
			// 		DistributionAdvanceValue: 500,
			// 		DistributionExpenses: 500,
			// 		ProgramItems: [],
			// 		SaleItems: [],
			// 		RightsIn: [
			// 			{
			// 				_id: `new-rights-${crypto.randomUUID()}`,
			// 				IsActive: true,
			// 				Program: '',
			// 				StartDate: new Date(),
			// 				IsEndDate: true,
			// 				EndDate: new Date(),
			// 				Exclusive: true,
			// 				Holdback: 'Europe',
			// 				HoldbackEndDate: new Date(),
			// 				RightsIn: true,
			// 				Territory: 'None',
			// 				RightsTree: rightsTree,
			// 				LicensedMedia: ['AVOD', 'Hotel', 'PayTV'],
			// 			},
			// 		],
			// 		DeliveryDate: new Date(),
			// 		PerformanceGuarantee: true,
			// 		PerformanceGuaranteeDate: new Date(),
			// 		PerformanceGuaranteeValue: 500,
			// 		LaunchBudget: 1000,
			// 		ExpensesCap: 1500,
			// 		CrossCollateralize: false,
			// 		CrossCollateralizePrograms: '',
			// 		MaterialsAvailable: '',
			// 		TechSpecs: '',
			// 		Commissions: [
			// 			{
			// 				_id: `new-commission-${crypto.randomUUID()}`,
			// 				IsActive: true,
			// 				//ProgramItem: ,
			// 				Status: 'Finished',
			// 				Commission: 30,
			// 			},
			// 		],
			// 		Notes: [],
			// 	})
			// );
		}
		//eslint-disable-next-line
	}, [newProgram]);

	const handleNext = () => {
		dispatch(
			changeEditingProgram({
				id: newProgram._id,
				name: 'step',
				value: newProgram.step + 1,
			})
		);
	};

	const handleBack = () => {
		dispatch(
			changeEditingProgram({
				id: newProgram._id,
				name: 'step',
				value: newProgram.step - 1,
			})
		);
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '90vh',
				overflow: 'auto',
				backgroundColor: 'inherit',
				padding: 8,
				borderRadius: 2,
			}}
		>
			<Stepper activeStep={newProgram ? newProgram.step : 0}>
				{steps.map((label, index) => {
					const stepProps = { color: orange[800] };
					const labelProps = { color: orange[800] };
					return (
						<Step
							key={label}
							{...stepProps}
							sx={{
								'& .MuiStepLabel-root .Mui-completed': {
									color: 'secondary.main', // circle color (COMPLETED)
								},
								'& .MuiStepLabel-root .Mui-active': {
									color: 'secondary.dark', // circle color (ACTIVE)
								},
								'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
									fill: blueGrey[50], // circle's number (ACTIVE)
								},
							}}
						>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<Typography sx={{ mt: 2, mb: 4, color: blueGrey[800] }}></Typography>
			{newProgram && (
				<>
					{newProgram.step === 0 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<ProgramDetails1
									id={id}
									type={type}
									newProgram={newProgram}
									user={user}
									users={users}
									handleNext={handleNext}
								/>
							</div>
						</Fade>
					)}
					{newProgram.step === 1 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<ProgramDetails2
									id={id}
									type={type}
									newProgram={newProgram}
									user={user}
									users={users}
									handleNext={handleNext}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
					{newProgram.step === 2 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<RightsIn
									id={id}
									type={type}
									newProgram={newProgram}
									user={user}
									users={users}
									handleNext={handleNext}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
					{newProgram.step === 3 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Commission
									id={id}
									type={type}
									newProgram={newProgram}
									user={user}
									users={users}
									handleNext={handleNext}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
					{newProgram.step === 4 && (
						<Fade
							in={true}
							timeout={{ enter: 800, exit: 800 }}
						>
							<div>
								<Submit
									dispatch={dispatch}
									newProgram={newProgram}
									handleBack={handleBack}
								/>
							</div>
						</Fade>
					)}
				</>
			)}
		</Box>
	);
}
