import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Switch,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { blueGrey, orange } from '@mui/material/colors';
import { Box } from '@mui/system';
import ThirdBranch from './ThirdBranch';
import { useSelector } from 'react-redux';
import {
	selectTerritories,
	selectRegions,
} from '../../../redux/territorySlice';

export default function SecondBranch({
	parent,
	branch,
	newRights,
	checkBoxChange,
	switchChange,
	determineSwitchStatus,
}) {
	const regions = useSelector(selectRegions(branch, parent));
	const territories = useSelector(selectTerritories(branch, parent));

	return (
		<Box>
			{territories && territories.length > 0 && (
				<Grid
					container
					sx={{ marginTop: 0, marginBottom: 2 }}
				>
					{regions && regions.length > 0 && (
						<Grid
							item
							xs={2}
						></Grid>
					)}
					<Grid
						item
						xs={regions && regions.length > 0 ? 10 : 12}
						sx={{
							backgroundColor: blueGrey[700],
							padding: 2,
							borderRadius: 2,
							textAlign: 'center',
						}}
					>
						{territories.map((territory) => (
							<FormControlLabel
								key={territory._id}
								control={
									<Checkbox
										name={territory.Name}
										checked={newRights.includes(territory.Name)}
										onChange={checkBoxChange}
										sx={{
											color: blueGrey[50],
											'&.Mui-checked': {
												color: orange[500],
											},
										}}
									/>
								}
								label={territory.Name}
								sx={{
									marginLeft: 4,
									marginRight: 4,
									display: 'inline-block',
									color: blueGrey[50],
									// color: newRights.includes(territory.Name)
									// 	? orange[500]
									// 	: blueGrey[50],
									'&.Mui-checked': {
										color: orange[500],
									},
								}}
							/>
						))}
					</Grid>
				</Grid>
			)}
			{regions &&
				regions.length > 0 &&
				regions.map((region) => (
					<Grid
						key={region._id}
						container
						sx={{ marginBottom: 2 }}
					>
						<Grid
							item
							xs={2}
						>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											onChange={(e) => switchChange(e, region.Branch)}
											checked={determineSwitchStatus(
												region.Name,
												region.Branch
											)}
											name={region.Name}
											sx={{
												'&.MuiSwitch-root .MuiSwitch-switchBase': {
													color: blueGrey[50],
												},

												'&.MuiSwitch-root .Mui-checked': {
													color: orange[500],
												},
											}}
										/>
									}
									label={region.Name}
								/>
							</FormGroup>
						</Grid>
						<Grid
							item
							xs={10}
						>
							<Accordion
								sx={{
									backgroundColor: blueGrey[700],
									color: blueGrey[50],
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon sx={{ color: blueGrey[50] }} />}
								></AccordionSummary>
								<AccordionDetails>
									<Box sx={{ marginLeft: 4 }}>
										<ThirdBranch
											secondLevel={region.Name}
											parent={region.Name}
											branch={region.Branch}
											newRights={newRights}
											checkBoxChange={checkBoxChange}
											switchChange={switchChange}
											determineSwitchStatus={determineSwitchStatus}
										/>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				))}
		</Box>
	);
}
