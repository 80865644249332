import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { closeTab } from '../../../../redux/tabsSlice';
import {
	changeEditingProgram,
	createProgramAsync,
	removeProgram,
} from '../../../../redux/programSlice';

export default function Submit({ newProgram, dispatch, handleBack }) {
	const navigate = useNavigate();

	function saveNewProgram() {
		dispatch(
			changeEditingProgram({
				id: newProgram._id,
				name: 'saving',
				value: true,
			})
		);
		dispatch(createProgramAsync(newProgram));
	}

	useEffect(() => {
		if (!newProgram.saving && newProgram.saved && newProgram.newId) {
			dispatch(removeProgram(newProgram._id));
			navigate(`/acquisitions/programs/${newProgram.newId}`);
			dispatch(closeTab(`new-program`));
		}
		// eslint-disable-next-line
	}, [newProgram, dispatch]);

	return (
		<Box>
			{newProgram.saving ? (
				<CircularProgress color='warning' />
			) : (
				<Typography>{newProgram.Name}</Typography>
			)}

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button
					//disabled={newProgram.saving}
					onClick={handleBack}
					sx={{
						mr: 1,
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					//disabled={newProgram.saving}
					onClick={saveNewProgram}
					sx={{
						color: blueGrey[50],
						backgroundColor: orange[800],
						'&:hover': {
							backgroundColor: orange[900],
						},
					}}
				>
					Save
				</Button>
			</Box>
		</Box>
	);
}
