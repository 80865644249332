export function licensePeriodColor(endDate) {
	const now = new Date();
	const end = new Date(endDate);

	if (end < now) {
		return 'red';
	}

	if (end > now && end <= new Date().setMonth(now.getMonth() + 3)) {
		return 'orange';
	}

	if (end > now && end > new Date().setMonth(now.getMonth() + 3)) {
		return 'green';
	}

	return 'black';
}
