import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTabs, selectCurrentTab } from '../redux/tabsSlice';
import AccountsTable from '../components/shared/accountsTable/AccountsTable';
import SalesTable from '../components/sales/sales/salesTable/SalesTable';
import AccountDetails from '../components/shared/accountDetails/AccountDetails';
import { blueGrey } from '@mui/material/colors';
import SaleDetails from '../components/sales/sales/saleDetails/SaleDetails';
import NewAccountStepper from '../components/shared/newAccount/NewAccountStepper';
import ScreenerDetails from '../components/sales/screeners/screenerDetails/ScreenerDetails';
import ScreenersTable from '../components/sales/screeners/screenersTable/ScreenersTable';
import ConvertToSale from '../components/sales/screeners/convertToSale/ConvertToSale';

const Sales = ({ setParams }) => {
	const params = useParams();
	const location = useLocation();
	const tabs = useSelector(selectTabs);
	const tab = tabs.find((tab) => tab.link === location.pathname);
	const currentTab = useSelector(selectCurrentTab);

	useEffect(() => {
		setParams(params);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	//console.log(location, params);

	function renderMainContent() {
		if (tab && currentTab.id === tab.id) {
			if (tab.type === 'buyers' && !params.id) {
				return <AccountsTable type={'Buyer'} />;
			}

			if (tab.type === 'buyers' && params.id === 'new-buyer') {
				return (
					<NewAccountStepper
						type={'Buyer'}
						id={tab.id}
					/>
				);
			}

			if (tab.type === 'buyers' && params.id) {
				return (
					<AccountDetails
						id={params.id}
						type={'Buyer'}
						currentTab={currentTab}
					/>
				);
			}

			if (tab.type === 'screeners' && !params.id) {
				return (
					<ScreenersTable
						id={tab.id}
						currentTab={currentTab}
					/>
				);
			}

			if (tab.type === 'screeners' && params.id) {
				return (
					<ScreenerDetails
						id={params.id}
						currentTab={currentTab}
					/>
				);
			}

			if (tab.type === 'convert' && params.id) {
				return <ConvertToSale id={params.id} />;
			}

			if (tab.type === 'sales' && !params.id) {
				return <SalesTable />;
			}

			if (tab.type === 'sales' && params.id) {
				return (
					<SaleDetails
						id={params.id}
						currentTab={currentTab}
					/>
				);
			}
		}
	}

	return (
		<div
			style={{
				padding: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
				textAlign: 'center',
				backgroundColor: blueGrey[500],
				color: 'white',
				borderRadius: '8px',
				//height: 'calc(100vh - 150px)',
				overflowY: 'hidden',
			}}
		>
			{currentTab && renderMainContent()}
		</div>
	);
};

export default Sales;
