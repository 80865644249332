export function programsCells(type) {
	return [
		{
			id: 'Name',
			sortable: true,
			align: 'left',
			disablePadding: true,
			label: 'Name',
		},
		{
			id: 'Genre',
			sortable: true,
			align: 'center',
			disablePadding: false,
			label: 'Genre',
		},
		{
			id: 'Format',
			sortable: true,
			align: 'center',
			disablePadding: false,
			label: 'Format',
		},
		{
			id: 'YearOfProduction',
			sortable: true,
			align: 'center',
			disablePadding: false,
			label: 'Year',
		},
	];
}
