import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';
import { updateTabTitle, addTab } from './tabsSlice';
import _ from 'lodash';

const initialState = {
	editing: [],
	original: [],
	buyers: null,
	producers: null,
	others: null,
	saving: false,
	buyersTableSettings: {
		page: 1,
		pageSize: 25,
		searchTerm: '',
		searchEvery: false,
		showActive: true,
		showAll: true,
		includeContacts: false,
		sortBy: 'Name',
		sortOrder: 'asc',
	},
	producersTableSettings: {
		page: 1,
		pageSize: 25,
		searchTerm: '',
		showActive: true,
		searchEvery: false,
		showAll: true,
		includeContacts: false,
		sortBy: 'Name',
		sortOrder: 'asc',
	},
	othersTableSettings: {
		page: 1,
		pageSize: 25,
		searchTerm: '',
		showActive: true,
		searchEvery: false,
		showAll: true,
		includeContacts: false,
		sortBy: 'Name',
		sortOrder: 'asc',
	},
};

export const accountSlice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		setSaving: (state, action) => {
			state.saving = action.payload;
		},
		setSaved: (state, action) => {
			let index = state.editing.findIndex((x) => x._id === action.payload.type);

			if (index !== -1) {
				state.editing[index] = {
					...state.editing[index],
					saving: false,
					saved: true,
					newId: action.payload.id,
				};
			}
		},
		getBuyers: (state, action) => {
			state.buyers = action.payload;
		},
		getProducers: (state, action) => {
			state.producers = action.payload;
		},
		getOthers: (state, action) => {
			state.others = action.payload;
		},
		changeAccountTableSettings: (state, action) => {
			if (
				action.payload.name === 'searchTerm' ||
				action.payload.name === 'pageSize' ||
				action.payload.name === 'page'
			) {
				state[`${action.payload.type.toLowerCase()}sTableSettings`] = {
					...state[`${action.payload.type.toLowerCase()}sTableSettings`],
					[action.payload.name]: action.payload.value,
				};
			} else if (action.payload.name === 'sortBy') {
				state[`${action.payload.type.toLowerCase()}sTableSettings`] = {
					...state[`${action.payload.type.toLowerCase()}sTableSettings`],
					[action.payload.name]: action.payload.value,
					sortBy: action.payload.sortBy,
					sortOrder: action.payload.sortOrder,
				};
			} else {
				state[`${action.payload.type.toLowerCase()}sTableSettings`] = {
					...state[`${action.payload.type.toLowerCase()}sTableSettings`],
					[action.payload.name]: action.payload.checked,
				};
			}
		},
		getAccount: (state, action) => {
			//Populate Original Account
			if (state.original.length > 0) {
				let indexOfOriginal = state.original.findIndex(
					(account) => account._id === action.payload._id
				);

				if (indexOfOriginal !== -1) {
					state.original[indexOfOriginal] = action.payload;
				} else {
					state.original.push(action.payload);
				}
			} else {
				state.original.push(action.payload);
			}

			//Populate Editing Account
			if (state.editing.length > 0) {
				let indexOfEditing = state.editing.findIndex(
					(edit) => edit._id === action.payload._id
				);

				if (indexOfEditing !== -1) {
					state.editing[indexOfEditing] = action.payload;
				} else {
					state.editing.push(action.payload);
				}
			} else {
				state.editing.push(action.payload);
			}

			// const updatedAccount = {
			// 	IsActive: action.payload.IsActive,
			// 	Owner: action.payload.Owner,
			// 	Name: action.payload.Name,
			// 	Phone: action.payload.Phone,
			// 	Territory: action.payload.Territory,
			// 	AccountNumber: action.payload.AccountNumber,
			// 	Contacts: action.payload.Contacts,
			// };

			//TODO:  Fix this so it doesn't throw an error when the array doesn't exist

			// Update Buyers/Producers/Others Lists
			// if (action.payload.Type === 'Buyer') {
			// 	let index = -1;
			// 	console.log(index);
			// 	if (state.buyers) {
			// 		index = state.buyers.findIndex(
			// 			(buyer) => buyer._id === action.payload._id
			// 		);
			// 	}
			// 	console.log(index);
			// 	if (index !== -1) {
			// 		state.buyers[index] = {
			// 			...state.buyers[index],
			// 			...updatedAccount,
			// 		};
			// 	} else {
			// 		state.buyers.push({
			// 			_id: action.payload._id,
			// 			Owner: action.payload.Owner,
			// 			Name: action.payload.Name,
			// 			Phone: action.payload.Phone,
			// 			Territory: action.payload.Territory,
			// 			AccountNumber: action.payload.AccountNumber,
			// 			Contacts: action.payload.Contacts,
			// 		});

			// 		state.buyers.sort((a, b) =>
			// 			a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
			// 		);
			// 	}
			// } else if (action.payload.Type === 'Producer') {
			// 	let index = state.producers.findIndex(
			// 		(producer) => producer._id === action.payload._id
			// 	);

			// 	if (index !== -1) {
			// 		state.producers[index] = {
			// 			...state.producers[index],
			// 			IsActive: action.payload.IsActive,
			// 			Owner: action.payload.Owner,
			// 			Name: action.payload.Name,
			// 			Phone: action.payload.Phone,
			// 			Territory: action.payload.Territory,
			// 			AccountNumber: action.payload.AccountNumber,
			// 			Contacts: action.payload.Contacts,
			// 		};
			// 	} else {
			// 		state.producers.push({
			// 			_id: action.payload._id,
			// 			Owner: action.payload.Owner,
			// 			Name: action.payload.Name,
			// 			Phone: action.payload.Phone,
			// 			Territory: action.payload.Territory,
			// 			AccountNumber: action.payload.AccountNumber,
			// 			Contacts: action.payload.Contacts,
			// 		});

			// 		state.producers.sort((a, b) =>
			// 			a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
			// 		);
			// 	}
			// } else if (action.payload.Type === 'Other') {
			// 	let index = state.others.findIndex(
			// 		(other) => other._id === action.payload._id
			// 	);

			// 	if (index !== -1) {
			// 		state.others[index] = {
			// 			...state.others[index],
			// 			IsActive: action.payload.IsActive,
			// 			Owner: action.payload.Owner,
			// 			Name: action.payload.Name,
			// 			Phone: action.payload.Phone,
			// 			Territory: action.payload.Territory,
			// 			AccountNumber: action.payload.AccountNumber,
			// 			Contacts: action.payload.Contacts,
			// 		};
			// 	} else {
			// 		state.others.push({
			// 			_id: action.payload._id,
			// 			Owner: action.payload.Owner,
			// 			Name: action.payload.Name,
			// 			Phone: action.payload.Phone,
			// 			Territory: action.payload.Territory,
			// 			AccountNumber: action.payload.AccountNumber,
			// 			Contacts: action.payload.Contacts,
			// 		});

			// 		state.others.sort((a, b) =>
			// 			a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
			// 		);
			// 	}
			// }
		},
		deleteAccount: (state, action) => {
			state.original = state.original.filter(
				(x) => x._id !== action.payload.id
			);
			state.editing = state.editing.filter((x) => x._id !== action.payload.id);

			switch (action.payload.type) {
				case 'Buyer':
					state.buyers = state.buyers.filter(
						(x) => x._id !== action.payload.id
					);
					break;
				case 'Producer':
					state.producers = state.producers.filter(
						(x) => x._id !== action.payload.id
					);
					break;
				case 'Other':
					state.others = state.others.filter(
						(x) => x._id !== action.payload.id
					);
					break;
				default:
					break;
			}
		},
		addAccountScreener: (state, action) => {
			let originalAccountIndex = state.original.findIndex(
				(x) => x._id === action.payload.Account._id
			);

			if (originalAccountIndex !== -1) {
				state.original[originalAccountIndex].Screeners.push(action.payload);
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Account._id
			);

			if (editingAccountIndex !== -1) {
				state.editing[editingAccountIndex].Screeners.push(action.payload);
			}
		},
		addAccountContact: (state, action) => {
			let originalAccountIndex = state.original.findIndex(
				(x) => x._id === action.payload.Account
			);

			if (originalAccountIndex !== -1) {
				state.original[originalAccountIndex].Contacts.push(action.payload);
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Account
			);

			if (editingAccountIndex !== -1) {
				state.editing[editingAccountIndex].Contacts.push(action.payload);
			}
		},
		updateAccountContact: (state, action) => {
			console.log(action.payload);
			let originalAccountIndex = state.original.findIndex(
				(x) => x._id === action.payload.Account
			);

			console.log(originalAccountIndex);

			if (originalAccountIndex !== -1) {
				let originalContactIndex = state.original[
					originalAccountIndex
				].Contacts.findIndex((x) => x._id === action.payload._id);

				console.log(originalContactIndex);

				if (originalContactIndex !== -1) {
					state.original[originalAccountIndex].Contacts[originalContactIndex] =
						action.payload;
				}
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Account
			);

			if (editingAccountIndex !== -1) {
				let editingContactIndex = state.editing[
					editingAccountIndex
				].Contacts.findIndex((x) => x._id === action.payload._id);

				if (editingContactIndex !== -1) {
					state.editing[editingAccountIndex].Contacts[editingContactIndex] =
						action.payload;
				}
			}
		},
		deleteAccountContact: (state, action) => {},
		addAccountNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (originalIndex !== -1) {
				state.original[originalIndex].Notes.unshift(action.payload);
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (editingAccountIndex !== -1) {
				state.editing[editingAccountIndex].Notes.unshift(action.payload);
			}
		},
		updateAccountNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (originalIndex !== -1) {
				let orginalNoteIndex = state.original[originalIndex].Notes.findIndex(
					(x) => x._id === action.payload._id
				);

				if (orginalNoteIndex !== -1) {
					state.original[originalIndex].Notes[orginalNoteIndex] =
						action.payload;
				}
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (editingAccountIndex !== -1) {
				let editingNoteIndex = state.editing[
					editingAccountIndex
				].Notes.findIndex((x) => x._id === action.payload._id);

				if (editingNoteIndex !== -1) {
					state.editing[editingAccountIndex].Notes[editingNoteIndex] =
						action.payload;
				}
			}
		},
		deleteAccountNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.id
			);

			if (originalIndex !== -1) {
				state.original[originalIndex].Notes = state.original[
					originalIndex
				].Notes.filter((note) => note._id !== action.payload.noteId);
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (editingAccountIndex !== -1) {
				state.editing[editingAccountIndex].Notes = state.editing[
					editingAccountIndex
				].Notes.filter((note) => note._id !== action.payload.noteId);
			}
		},
		addEditingAccount: (state, action) => {
			state.editing.push(action.payload);
		},
		changeEditingAccount: (state, action) => {
			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (editingAccountIndex !== -1) {
				if (action.payload.name === 'Phone') {
					state.editing[editingAccountIndex] = {
						...state.editing[editingAccountIndex],
						Phone: action.payload.value,
					};
				} else {
					state.editing[editingAccountIndex] = {
						...state.editing[editingAccountIndex],
						[action.payload.name]: action.payload.value,
					};
				}
			}
		},
		changeEditingAccountParent: (state, action) => {
			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (editingAccountIndex !== -1) {
				if (action.payload.add) {
					state.editing[editingAccountIndex] = {
						...state.editing[editingAccountIndex],
						Parent: action.payload.parent,
					};

					let parentEditingAccountIndex = state.editing.findIndex(
						(x) => x._id === action.payload.parent._id
					);

					if (parentEditingAccountIndex !== -1) {
						state.editing[parentEditingAccountIndex].Children.push(
							action.payload.account
						);
					}

					let listAccountIndex = state[action.payload.type].findIndex(
						(x) => x._id === action.payload.id
					);

					if (listAccountIndex !== -1) {
						state[action.payload.type][listAccountIndex] = {
							...state[action.payload.type][listAccountIndex],
							Parent: action.payload.parent,
						};
					}

					let listAccountParentIndex = state[action.payload.type].findIndex(
						(x) => x._id === action.payload.parent._id
					);

					if (listAccountParentIndex !== -1) {
						state[action.payload.type][listAccountParentIndex].Children.push(
							action.payload.account
						);
					}
				} else {
					state.editing[editingAccountIndex] = _.omit(
						state.editing[editingAccountIndex],
						['Parent']
					);

					let parentAccountIndex = state.editing.findIndex(
						(x) => x._id === action.payload.parent._id
					);

					if (parentAccountIndex !== -1) {
						state.editing[parentAccountIndex].Children.splice(
							parentAccountIndex,
							1
						);
					}

					let listAccountIndex = state[action.payload.type].findIndex(
						(x) => x._id === action.payload.id
					);

					if (listAccountIndex !== -1) {
						state[action.payload.type][listAccountIndex] = _.omit(
							state[action.payload.type][listAccountIndex],
							['Parent']
						);
					}

					let listAccountParentIndex = state[action.payload.type].findIndex(
						(x) => x._id === action.payload.parent._id
					);

					if (listAccountParentIndex !== -1) {
						let childIndex = state[action.payload.type][
							listAccountParentIndex
						].Children.findIndex((x) => x._id === action.payload.account._id);

						if (childIndex !== -1) {
							state[action.payload.type][
								listAccountParentIndex
							].Children.splice(childIndex, 1);
						}
					}
				}
			}
		},
		changeEditingAccountContact: (state, action) => {
			const accountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			const contactIndex = state.editing[accountIndex].Contacts.findIndex(
				(contact) => contact._id === action.payload.contactId
			);

			state.editing[accountIndex].Contacts[contactIndex] = {
				...state.editing[accountIndex].Contacts[contactIndex],
				[action.payload.name]: action.payload.value,
			};
		},
		changeEditingTerritory: (state, action) => {
			const accountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			state.editing[accountIndex].Territory = action.payload.territory;
			state.editing[accountIndex].RightsTree = action.payload.rights;
		},
		removeAccount: (state, action) => {
			state.original = state.original.filter((x) => x._id !== action.payload);
			state.editing = state.editing.filter((x) => x._id !== action.payload);
		},
		removeAccounts: (state, action) => {
			state[`${action.payload.toLowerCase()}s`] = null;
		},
		resetAccounts: () => initialState,
	},
});

export const {
	setSaving,
	setSaved,
	getBuyers,
	changeAccountTableSettings,
	getProducers,
	getOthers,
	getAccount,
	deleteAccount,
	addAccountScreener,
	addAccountContact,
	updateAccountContact,
	deleteAccountContact,
	addAccountNote,
	updateAccountNote,
	deleteAccountNote,
	addEditingAccount,
	changeEditingAccount,
	changeEditingAccountParent,
	changeEditingAccountContact,
	changeEditingTerritory,
	removeAccount,
	removeAccounts,
	resetAccounts,
} = accountSlice.actions;

export const selectOriginalAccount = (id) => (state) => {
	if (state.accounts.original.length > 0) {
		return state.accounts.original.find((account) => account._id === id);
	} else {
		return null;
	}
};
export const selectEditingAccount = (id) => (state) => {
	if (state.accounts.editing.length > 0) {
		return state.accounts.editing.find((account) => account._id === id);
	} else {
		return null;
	}
};
export const selectSaving = (state) => state.accounts.saving;
export const selectBuyers = (state) => state.accounts.buyers;
export const selectAccountsGridSettings = (state) =>
	state.accounts.accountsGridSettings;
export const selectProducers = (state) => state.accounts.producers;
export const selectOthers = (state) => state.accounts.others;
export const selectAccountsEditing = (state) => state.accounts.accountsEditing;

// Load Accounts (By Type) Async
export const getAccountsAsync =
	(type, status = 'active') =>
	async (dispatch) => {
		await api
			.get(`/account/type/${type}/status/${status}`, { withCredentials: true })
			.then((res) => {
				console.log(res);
				if (type === 'Buyer') {
					dispatch(getBuyers(res.data));
				} else if (type === 'Producer') {
					dispatch(getProducers(res.data));
				} else if (type === 'Other') {
					dispatch(getOthers(res.data));
				} else {
					let error = new Error({
						name: 'Account Type Not Valid',
						message: 'Account Type passed is not valid.',
					});
					throw error;
				}
			})
			.catch((error) => {
				dispatch(handleErrorAlertAsync(error));
				dispatch(verifyAuthAsync(error.response.data.message));
			});
	};

// Load Account
export const getAccountAsync = (id) => async (dispatch) => {
	await api
		.get(`/account/id/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getAccount(res.data));
			dispatch(updateTabTitle({ id: res.data._id, title: res.data.Name }));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Create Account Async
export const createNewAccountAsync = (account) => async (dispatch) => {
	console.log(account);
	await api
		.post(`/account/`, account, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(getAccount(res.data));

			let link;

			switch (res.data.Type) {
				case 'Buyer':
					link = `/sales/buyers/${res.data._id}`;
					break;
				case 'Producer':
					link = `/acquisitions/producers/${res.data._id}`;
					break;
				case 'Other':
					link = `/finance/others/${res.data._id}`;
					break;
				default:
					link = 'theproperlinkcouldnotbecreated';
					break;
			}

			const newTab = {
				id: res.data._id,
				title: res.data.Name,
				link: link,
				type: res.data.Type.toLowerCase(),
				tertiary: 0,
				status: true,
				delete: true,
			};

			dispatch(addTab(newTab));
			dispatch(
				setSaved({
					id: res.data._id,
					type: `new-${account.Type.toLowerCase()}`,
				})
			);

			dispatch(
				createAlertAsync({
					message: 'Account Created Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Account Async
export const updateAccountAsync = (account) => async (dispatch) => {
	await api
		.put(`/account/${account._id}`, account, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(getAccount(res.data));
			dispatch(updateTabTitle({ id: res.data._id, title: res.data.Name }));
			dispatch(
				createAlertAsync({
					message: 'Account Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Delete Account Async
export const deleteAccountAsync = (id, type) => async (dispatch, callback) => {
	await api
		.delete(`/account/${id}`, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(deleteAccount({ id: id, type: type }));

			dispatch(
				createAlertAsync({
					message: 'Account Deleted Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 10000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Add Account Contact Async
export const addAccountContactAsync = (contact) => async (dispatch) => {
	await api
		.post(`/contact/`, contact, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(addAccountContact(res.data));

			dispatch(
				createAlertAsync({
					message: 'Account Contact Added Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Account Contact Async
export const updateAccountContactAsync = (contact) => async (dispatch) => {
	await api
		.put(`/contact/${contact._id}`, contact, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateAccountContact(res.data));

			dispatch(
				createAlertAsync({
					message: 'Account Contact Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Delete Account Contact Async
export const deleteAccountContactAsync =
	(id, contactId) => async (dispatch) => {
		await api
			.delete(`/contact/${contactId}`, {
				withCredentials: true,
			})
			.then((res) => {
				dispatch(deleteAccountContact({ id: id, contactId: contactId }));

				dispatch(
					createAlertAsync({
						message: 'Account Contact Deleted Successfully',
						severity: 'success',
						autoDismiss: true,
						timeout: 5000,
					})
				);
			})
			.catch((error) => {
				dispatch(handleErrorAlertAsync(error));
				dispatch(verifyAuthAsync(error.response.data.message));
			});
	};

export default accountSlice.reducer;
