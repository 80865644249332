//////////////////////////////////
// Rights/Rights Utilities //
//////////////////////////////////

export function determineSwitchStatus(regions, rights) {
	console.log(regions);

	let switchStatus = true;

	regions.forEach((region) => {
		if (region.regions) {
			region.regions.forEach((subRegion) => {
				if (subRegion.regions) {
					//console.log(subRegion.regions);
				} else {
					console.log(rights[subRegion]);
					if (!rights[subRegion]) {
						switchStatus = false;
					}
				}
			});
		} else {
			if (!rights[region]) {
				switchStatus = false;
			}
		}
	});

	return switchStatus;
}

export function setTerritories(allTerritories, newRights) {
	let territories = [];
	let excluded = [];
	let regions = {};

	const t1 = allTerritories.filter(
		(t) => t.Parent === 'Worldwide' && t.IsRegion
	);

	t1.forEach((e1) => {
		regions = { ...regions, [e1.Name]: { count: 0, true: 0, false: 0 } };

		const t2 = allTerritories.filter(
			(t) => t.Parent === e1.Name && t.Branch === 'Worldwide'
		);

		t2.forEach((e2) => {
			if (e2.IsRegion) {
				const t3 = allTerritories.filter(
					(t) => t.Parent === e2.Name && t.Branch === 'Worldwide'
				);

				t3.forEach((e3) => {
					if (e3.IsRegion) {
						const t4 = allTerritories.filter(
							(t) => t.Parent === e3.Name && t.Branch === 'Worldwide'
						);

						t4.forEach((e4) => {
							if (e4.IsRegion) {
								console.log('Region');
							} else {
								regions[e1.Name].count++;
								if (newRights.includes(e4.Name)) {
									regions[e1.Name].true++;
								} else {
									regions[e1.Name].false++;
									excluded.push({ Name: e4.Name, Region: e1.Name });
								}
							}
						});
					} else {
						regions[e1.Name].count++;
						if (newRights.includes(e3.Name)) {
							regions[e1.Name].true++;
						} else {
							regions[e1.Name].false++;
							excluded.push({ Name: e3.Name, Region: e1.Name });
						}
					}
				});
			} else {
				regions[e1.Name].count++;
				if (newRights.includes(e2.Name)) {
					regions[e1.Name].true++;
				} else {
					regions[e1.Name].false++;
					excluded.push({ Name: e2.Name, Region: e1.Name });
				}
			}
		});
	});

	Object.entries(regions).forEach((entry) => {
		const [key, value] = entry;

		if (value.count === value.true) {
			territories.push(key);
		} else if (value.true > 0 && value.false > 0) {
			territories.push(`${key}*`);
		} else if (value.count === value.false) {
			// Remove regions from Exclusions
			excluded = excluded.filter((x) => x.Region !== key);
		}
	});

	let exclusions = [];

	excluded.forEach((element) => {
		exclusions.push(element.Name);
	});

	exclusions = [...new Set(exclusions)];

	return {
		regions: territories.length > 0 ? territories.join(', ') : '',
		exclusions: exclusions.length > 0 ? exclusions.join(', ') : '',
	};
}
