import React from 'react';
import CompanyTab from './CompanyTab';
import ContactsTab from './ContactsTab';
import DeliveriesTab from './DeliveriesTab';
import FinanceTab from './FinanceTab';
import ProgramsTab from './ProgramsTab';
import SalesTab from './SalesTab';
import ScreenersTab from './ScreenersTab';
import TechSpecsTab from './TechSpecsTab';

export default function TabSelector({
	id,
	tab,
	currentTab,
	accounts,
	user,
	users,
	originalAccount,
	editingAccount,
	handleChange,
	renderAccountName,
	handle,
}) {
	function renderTab() {
		switch (tab.name) {
			case 'Company':
				return (
					<CompanyTab
						id={id}
						currentTab={currentTab}
						user={user}
						users={users}
						accounts={accounts}
						originalAccount={originalAccount}
						editingAccount={editingAccount}
						handleChange={handleChange}
						renderAccountName={renderAccountName}
					/>
				);
			case 'Contacts':
				return (
					<ContactsTab
						originalAccount={originalAccount}
						editingAccount={editingAccount}
						user={user}
						users={users}
					/>
				);
			case 'Screeners':
				return (
					<ScreenersTab
						editingAccount={editingAccount}
						user={user}
					/>
				);
			case 'Sales':
				return (
					<SalesTab
						id={id}
						sales={editingAccount.Sales}
					/>
				);
			case 'Finance':
				return (
					<FinanceTab
						id={id}
						currentTab={currentTab}
						accounts={accounts}
						originalAccount={originalAccount}
						editingAccount={editingAccount}
					/>
				);
			case 'Deliveries':
				return (
					<DeliveriesTab
						id={id}
						currentTab={currentTab}
						accounts={accounts}
						originalAccount={originalAccount}
						editingAccount={editingAccount}
					/>
				);
			case 'Tech Specs':
				return (
					<TechSpecsTab
						id={id}
						currentTab={currentTab}
						accounts={accounts}
						originalAccount={originalAccount}
						editingAccount={editingAccount}
					/>
				);
			case 'Programs':
				return (
					<ProgramsTab
						id={id}
						currentTab={currentTab}
						accounts={accounts}
						originalAccount={originalAccount}
						editingAccount={editingAccount}
					/>
				);
			default:
				break;
		}
	}

	return <div>{renderTab()}</div>;
}
