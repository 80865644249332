import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteTaskAsync,
	getTasksAsync,
	selectTasks,
	updateTaskAsync,
} from '../../redux/taskSlice';
import { Box } from '@mui/system';
import { blueGrey, orange } from '@mui/material/colors';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import dayjs from 'dayjs';

export default function Tasks({ isAuthenticated, user }) {
	const dispatch = useDispatch();
	const tasks = useSelector(selectTasks);
	const [taskModal, setTaskModal] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [selectedTask, setSelectedTask] = useState(null);

	useEffect(() => {
		if (isAuthenticated && new Date(user.expires) > Date.now()) {
			dispatch(getTasksAsync());
		}

		// eslint-disable-next-line
	}, []);

	function handleTaskModalOpen(task) {
		setSelectedTask(task);
		setTaskModal(true);
	}

	function updateTask(task) {
		dispatch(updateTaskAsync(task));
		setSelectedTask(null);
		setTaskModal(false);
	}

	function confirmDelete(task) {
		setSelectedTask(task);
		setConfirmDeleteModal(true);
	}

	function deleteTask(id) {
		dispatch(deleteTaskAsync(id));
		setSelectedTask(null);
		setConfirmDeleteModal(false);
	}

	return (
		<Box
			sx={{
				border: 'solid',
				borderColor: blueGrey[900],
				backgroundColor: blueGrey[700],
				borderRadius: 2,
				marginLeft: 2,
				height: '50%',
				overflowY: 'auto',
			}}
		>
			{tasks && tasks.length > 0 ? (
				<>
					<Box
						sx={{
							backgroundColor: blueGrey[700],
							padding: 2,
						}}
					>
						Tasks
					</Box>
					<Grid
						container
						sx={{
							backgroundColor: blueGrey[800],
							paddingTop: 2,
							paddingBottom: 2,
							alignItems: 'center',
							alignContent: 'center',
						}}
					>
						<Grid
							item
							sm={5}
						>
							Task:
						</Grid>
						<Grid
							item
							sm={4}
						>
							Due Date:
						</Grid>
						<Grid
							item
							sm={1}
						></Grid>
						<Grid
							item
							sm={1}
						></Grid>
						<Grid
							item
							sm={1}
						></Grid>
					</Grid>
				</>
			) : (
				<Grid
					container
					justify='flex-end'
					alignItems='center'
					sx={{ height: '100%' }}
				>
					<Grid
						item
						xs={12}
					>
						No Tasks
					</Grid>
				</Grid>
			)}

			{tasks &&
				tasks.length > 0 &&
				tasks.map((task, index) => (
					<Grid
						container
						key={task._id}
						sx={{
							backgroundColor: index % 2 === 0 ? blueGrey[700] : blueGrey[800],
							paddingTop: 2,
							paddingBottom: 2,
							alignItems: 'center',
							alignContent: 'center',
						}}
					>
						<Grid
							item
							sm={5}
						>
							{task.Text}
						</Grid>
						<Grid
							item
							sm={4}
						>
							{dayjs(task.DueDate).format('MMM. DD, YYYY')}
						</Grid>
						<Grid
							item
							sm={1}
						>
							<Tooltip
								title={`Go to ${task.Type.charAt(
									0
								).toUpperCase()}${task.Type.slice(1)}`}
							>
								<IconButton>
									<Link
										to={task.Link}
										component={RouterLink}
										sx={{
											color: blueGrey[50],
											textDecoration: 'none',
											'&:hover': { color: blueGrey[100], fontWeight: 'bold' },
										}}
									>
										<OpenInNewIcon />
									</Link>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid
							item
							sm={1}
						>
							<Tooltip title='Update - Disable'>
								<span>
									<IconButton
										disabled
										onClick={() => handleTaskModalOpen(task)}
										sx={{ color: blueGrey[50] }}
									>
										<EditIcon />
									</IconButton>
								</span>
							</Tooltip>
						</Grid>
						<Grid
							item
							sm={1}
						>
							<Tooltip title='Delete Task'>
								<IconButton
									onClick={() => confirmDelete(task)}
									sx={{ color: blueGrey[50] }}
								>
									<DeleteForeverIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				))}

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={taskModal}
			>
				<CircularProgress
					color='secondary'
					size={80}
					sx={{ color: orange[800] }}
				/>
			</Backdrop>
			<Modal
				open={taskModal}
				//onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={modalStyle}>
					<Typography
						id='modal-modal-title'
						variant='h6'
						component='h2'
					>
						Text in a modal
					</Typography>
					<Typography
						id='modal-modal-description'
						sx={{ mt: 2 }}
					>
						Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
					</Typography>
				</Box>
			</Modal>
			<Modal
				open={confirmDeleteModal}
				//onClose={handleClose}
			>
				<Box sx={modalStyle}>
					<Typography
						variant='h6'
						component='h2'
						sx={{ margin: 4 }}
					>
						Are you sure you want to delete this task?
					</Typography>
					<Grid
						container
						//sx={gridStyles}
					>
						<Grid
							sm={6}
							//sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={() => setConfirmDeleteModal(false)}
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[700] },
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							sm={6}
							//sx={gridItemStyles}
							item
						>
							<Button
								variant='contained'
								onClick={() => deleteTask(selectedTask._id)}
								sx={{
									backgroundColor: blueGrey[500],
									width: 200,
									'&:hover': { backgroundColor: blueGrey[700] },
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</Box>
	);
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	minWidth: '525px',
	maxWidth: '600px',
	backgroundColor: blueGrey[900],
	color: blueGrey[50],
	border: `3px solid ${orange[800]}`,
	borderRadius: 4,
	boxShadow: 24,
	p: 4,
	textAlign: 'center',
};
