import React, { useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Grid,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { createAlertAsync } from '../../../../redux/alertsSlice';
import { useDispatch } from 'react-redux';

export default function ConvertToSaleModal({
	convertToSale,
	convertToSaleModal,
	handleConvertToSaleModal,
	convertSelectedItems,
	setConvertSelectedItems,
}) {
	const dispatch = useDispatch();
	const [closeModal, setCloseModal] = useState(false);

	function handleCloseModal() {
		setCloseModal(!closeModal);
	}

	function handleContinue() {
		if (convertSelectedItems.some((item) => item.Checked)) {
			convertToSale();
		} else {
			dispatch(
				createAlertAsync({
					message: 'Must Select At Least One Program to Continue',
					severity: 'warning',
					autoDismiss: true,
				})
			);
		}
	}

	function onChange(e) {
		if (e.target.name === 'all') {
			let items = [...convertSelectedItems];

			items.forEach((item) => {
				item.Checked = e.target.checked;
			});

			setConvertSelectedItems(items);
		} else {
			let index = convertSelectedItems.findIndex(
				(selectedItem) => selectedItem._id === e.target.name
			);

			if (index !== -1) {
				convertSelectedItems[index].Checked = e.target.checked;

				setConvertSelectedItems([...convertSelectedItems]);
			}
		}
	}

	return (
		<>
			<Modal
				disableEscapeKeyDown
				onClose={null}
				open={convertToSaleModal}
				sx={{
					zIndex: 10,
					'& .MuiBackdrop-root': {
						backgroundColor: 'rgba(0,0,0,.75)',
					},
				}}
			>
				<Box sx={convertToSaleModalStyles}>
					<Box sx={{ height: '95%', overflowX: 'none' }}>
						<Grid
							container
							sx={{ justifyItems: 'middle', marginBottom: 0 }}
						>
							<Grid
								item
								xs={12}
							>
								<Typography
									variant='h5'
									component='h2'
									sx={{ color: blueGrey[50], marginBottom: 2 }}
								>
									Select Programs to Convert to Sale
								</Typography>
							</Grid>
						</Grid>
						<Box
							sx={{
								marginBottom: 0,
								overflowX: 'auto',
								height: '95%',
								borderRadius: 4,
							}}
						>
							<TableContainer
								component={Paper}
								sx={{
									backgroundColor: blueGrey[800],
									color: blueGrey[50],
									boxShadow: 'none',
									height: '100%',
									borderRadius: 4,
									//overflowX: 'auto',
								}}
							>
								<Table
									stickyHeader
									sx={{ borderRadius: 4 }}
								>
									<TableHead>
										<TableRow
											sx={{
												backgroundColor: blueGrey[900],
												height: '100px',
												borderRadius: 4,
											}}
										>
											<TableCell
												sx={cellStyles}
												style={{
													backgroundColor: blueGrey[900],
													border: 'none',
												}}
											>
												<Checkbox
													name='all'
													color='primary'
													checked={convertSelectedItems.every(
														(item) => item.Checked
													)}
													onChange={onChange}
												/>
											</TableCell>
											<TableCell
												align='center'
												sx={cellStyles}
												style={{
													backgroundColor: blueGrey[900],
													border: 'none',
												}}
											>
												Program
											</TableCell>
											<TableCell
												align='center'
												sx={cellStyles}
												style={{
													backgroundColor: blueGrey[900],
												}}
											>
												Status
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{convertSelectedItems.map((screenerItem, index) => (
											<TableRow
												key={screenerItem._id}
												sx={{
													borderBottom: 'none',
													'& > *': { borderBottom: 'none' },
													backgroundColor:
														index % 2 === 0 ? blueGrey[800] : blueGrey[700],
												}}
											>
												<TableCell
													sx={styles}
													style={{
														backgroundColor:
															index % 2 === 0 ? blueGrey[800] : blueGrey[700],
													}}
												>
													<Checkbox
														name={screenerItem._id}
														color='primary'
														checked={screenerItem.Checked}
														onChange={onChange}
													/>
												</TableCell>
												<TableCell
													align='center'
													sx={styles}
													style={{
														backgroundColor:
															index % 2 === 0 ? blueGrey[800] : blueGrey[700],
													}}
												>
													{screenerItem.Program && screenerItem.Program.Name}
												</TableCell>
												<TableCell
													align='center'
													sx={styles}
													style={{
														backgroundColor:
															index % 2 === 0 ? blueGrey[800] : blueGrey[700],
													}}
												>
													{screenerItem.Status}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Box>
					<Grid
						container
						sx={{ marginTop: 2 }}
					>
						<Grid
							item
							xs={6}
						>
							<Button
								variant='contained'
								onClick={handleCloseModal}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<Button
								variant='contained'
								onClick={handleContinue}
							>
								Continue
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				disableAutoFocus
				disableEscapeKeyDown
				onClose={null}
				open={closeModal}
				sx={{
					zIndex: 20,
					//'& .MuiBackdrop-root': { backgroundColor: orange[900] },
					'& .MuiBackdrop-root': { backgroundColor: 'rgba(0,0,0,.75)' },
				}}
			>
				<Box sx={cancelModalStyle}>
					<Grid
						container
						//sx={{ position: 'fixed', bottom: 10 }}
					>
						<Grid
							item
							xs={12}
							sx={{ marginTop: 4, marginBottom: 10 }}
						>
							<Typography
								variant='h5'
								component='h2'
								sx={{ marginBottom: 6 }}
							>
								Are you sure you want to cancel Converting to Sale?
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<Button
								variant='contained'
								onClick={handleCloseModal}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<Button
								variant='contained'
								onClick={function () {
									handleConvertToSaleModal();
									handleCloseModal();
								}}
							>
								Confirm
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
}

const convertToSaleModalStyles = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	height: '90%',
	minWidth: '90%',
	minHeight: '90%',
	backgroundColor: blueGrey[800],
	color: blueGrey[50],
	border: `2px solid ${blueGrey[900]}`,
	p: 4,
	textAlign: 'center',
	zIndex: 80,
	borderRadius: 2,
};

const cancelModalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: blueGrey[800],
	color: blueGrey[50],
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	zIndex: 100,
	textAlign: 'center',
};

const cellStyles = {
	color: blueGrey[50],
	borderBottom: 'none',
	minWidth: '95%',
	maxWidth: '95%',
	'& .MuiSvgIcon-root': { color: blueGrey[50] },
};

const styles = {
	color: blueGrey[50],
	borderBottom: 'none',
	'& .MuiSvgIcon-root': { color: blueGrey[50] },
};
