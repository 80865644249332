import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	CircularProgress,
	Grid,
	Modal,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import ConvertToSaleStep1 from './ConvertToSaleStep1';
import ConvertToSaleStep2 from './ConvertToSaleStep2';
import ConvertToSaleStep3 from './ConvertToSaleStep3';
import ConvertToSaleStep4 from './ConvertToSaleStep4';
import ConvertToSaleStep5 from './ConvertToSaleStep5';
import {
	changeEditingSale,
	selectEditingSale,
} from '../../../../redux/salesSlice';
import {
	convertScreenerToSale,
	selectEditingScreener,
} from '../../../../redux/screenerSlice';

export default function ConvertToSale({ id }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [saving, setSaving] = useState(false);
	const newSale = useSelector(selectEditingSale(id));
	const screener = useSelector(selectEditingScreener(newSale.Screener));

	useEffect(() => {
		// Wait for Server Response and Navigate to Sale Page
		if (screener && screener.ScreenerConverted && screener.SaleId) {
			console.log('Converted...');

			setSaving(false);
			navigate(`/sales/sales/${screener.SaleId}`);
		}

		//eslint-disable-next-line
	}, [screener]);

	function handleChange(e) {
		dispatch(
			changeEditingSale({
				_id: newSale._id,
				name: e.target.name,
				value: e.target.value,
			})
		);
	}

	function handleCalendarChange(e, name) {
		dispatch(
			changeEditingSale({
				_id: newSale._id,
				name: name,
				value: new Date(e.$d).toDateString(),
			})
		);
	}

	function convertToSale() {
		setSaving(true);
		dispatch(convertScreenerToSale(newSale));
	}

	return (
		<Box
			sx={{
				marginTop: 4,
				marginBottom: 2,
				//paddingBottom: 4,
				height: 'calc(100vh - 175px)',
				overflowY: 'hidden',
			}}
		>
			<Grid
				container
				sx={{ justifyItems: 'middle' }}
			>
				<Grid
					item
					xs={12}
				>
					<Typography
						variant='h5'
						component='h2'
						sx={{ color: blueGrey[50] }}
					>
						Convert to Sales Agreement
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					xs={12}
				>
					<Stepper
						activeStep={newSale.stepIndex}
						alternativeLabel
						sx={{
							marginTop: 2,
							marginBottom: 4,
							'& .MuiStepIcon-root.Mui-active': {
								color: orange[800],
							},
							'& .Mui-active': {
								color: orange[800],
								//'& .MuiStepIcon-text': { fill: blueGrey[900] },
							},
						}}
					>
						<Step
							index={0}
							active={newSale.stepIndex === 0}
							//onClick={() => handleStepChange(0)}
							sx={{
								'& .MuiStepLabel-label.Mui-active': {
									color: orange[800],
								},
								'& .MuiStepLabel-label': {
									color: blueGrey[50],
								},
							}}
						>
							<StepLabel
								sx={{
									'& .MuiStepLabel-label.Mui-active': {
										color: orange[700],
									},
									'& .MuiStepLabel-label': {
										color: blueGrey[50],
									},
									'& .Mui-completed': {
										color: `${blueGrey[50]} !important`,
									},
								}}
							>
								General Info
							</StepLabel>
						</Step>
						<Step
							index={1}
							active={newSale.stepIndex === 1}
							//onClick={() => handleStepChange(1)}
							sx={{
								'& .MuiStepLabel-label.Mui-active': {
									color: orange[700],
								},
								'& .MuiStepLabel-label': {
									color: blueGrey[50],
								},
								'& .Mui-completed': {
									color: `${blueGrey[50]} !important`,
								},
							}}
						>
							<StepLabel>Payment Terms</StepLabel>
						</Step>
						<Step
							index={2}
							active={newSale.stepIndex === 2}
							//onClick={() => handleStepChange(2)}
							sx={{
								'& .MuiStepLabel-label.Mui-active': {
									color: orange[700],
								},
								'& .MuiStepLabel-label': {
									color: blueGrey[50],
								},
								'& .Mui-completed': {
									color: `${blueGrey[50]} !important`,
								},
							}}
						>
							<StepLabel>Delivery</StepLabel>
						</Step>
						<Step
							index={3}
							active={newSale.stepIndex === 3}
							//onClick={() => handleStepChange(3)}
							sx={{
								'& .MuiStepLabel-label.Mui-active': {
									color: orange[700],
								},
								'& .MuiStepLabel-label': {
									color: blueGrey[50],
								},
								'& .Mui-completed': {
									color: `${blueGrey[50]} !important`,
								},
							}}
						>
							<StepLabel>Programs</StepLabel>
						</Step>
						<Step
							index={4}
							active={newSale.stepIndex === 4}
							//onClick={() => handleStepChange(4)}
							sx={{
								'& .MuiStepLabel-label.Mui-active': {
									color: orange[700],
								},
								'& .MuiStepLabel-label': {
									color: blueGrey[50],
								},
								'& .Mui-completed': {
									color: `${blueGrey[50]} !important`,
								},
							}}
						>
							<StepLabel>Invoicing</StepLabel>
						</Step>
					</Stepper>
				</Grid>
			</Grid>
			{newSale && (
				<>
					{newSale.stepIndex === 0 && (
						<ConvertToSaleStep1
							newSale={newSale}
							handleChange={handleChange}
							handleCalendarChange={handleCalendarChange}
						/>
					)}
					{newSale.stepIndex === 1 && (
						<ConvertToSaleStep2
							newSale={newSale}
							handleChange={handleChange}
						/>
					)}
					{newSale.stepIndex === 2 && (
						<ConvertToSaleStep3
							newSale={newSale}
							handleChange={handleChange}
							handleCalendarChange={handleCalendarChange}
						/>
					)}
					{newSale.stepIndex === 3 && (
						<ConvertToSaleStep4
							newSale={newSale}
							handleChange={handleChange}
							handleCalendarChange={handleCalendarChange}
						/>
					)}
					{newSale.stepIndex === 4 && (
						<ConvertToSaleStep5
							newSale={newSale}
							saving={saving}
							convertToSale={convertToSale}
							handleChange={handleChange}
							handleCalendarChange={handleCalendarChange}
						/>
					)}
				</>
			)}
			<Modal open={saving}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<CircularProgress
						color='warning'
						size={80}
					/>
				</Box>
			</Modal>
		</Box>
	);
}
