import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';
import { updateTabTitle } from './tabsSlice';
import { addAccountScreener } from './accountSlice';

const initialState = {
	screeners: [],
	original: [],
	editing: [],
	screenersTableSettings: {
		page: 1,
		pageSize: 25,
		searchTerm: '',
		searchEvery: false,
		showAll: true,
		sortBy: 'Name',
		sortOrder: 'asc',
	},
};

export const screenerSlice = createSlice({
	name: 'screeners',
	initialState,
	reducers: {
		createScreener: (state, action) => {
			state.original.push(action.payload);
			state.editing.push(action.payload);
			state.screeners.push(action.payload);
			// const index = state.editing.findIndex((x) => x._id === 'new-screener');

			// if (index !== -1) {
			// 	state.editing[index] = {
			// 		...state.editing[index],
			// 		isSaved: true,
			// 		savedId: action.payload._id,
			// 	};
			// }
		},
		getScreeners: (state, action) => {
			state.screeners = action.payload;
		},
		getScreener: (state, action) => {
			//Populate Original Account
			if (state.original.length > 0) {
				let indexOfOriginal = state.original.findIndex(
					(account) => account._id === action.payload._id
				);

				if (indexOfOriginal !== -1) {
					state.original[indexOfOriginal] = action.payload;
				} else {
					state.original.push(action.payload);
				}
			} else {
				state.original.push(action.payload);
			}

			//Populate Editing Account
			if (state.editing.length > 0) {
				let indexOfEditing = state.editing.findIndex(
					(edit) => edit._id === action.payload._id
				);

				if (indexOfEditing !== -1) {
					state.editing[indexOfEditing] = action.payload;
				} else {
					state.editing.push(action.payload);
				}
			} else {
				state.editing.push(action.payload);
			}
		},
		changeEditingScreener: (state, action) => {
			let editingScreenerIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (editingScreenerIndex !== -1) {
				if (action.payload.name === 'Phone') {
					state.editing[editingScreenerIndex] = {
						...state.editing[editingScreenerIndex],
						Phone: action.payload.value,
					};
				} else {
					state.editing[editingScreenerIndex] = {
						...state.editing[editingScreenerIndex],
						[action.payload.name]: action.payload.value,
					};
				}
			}
		},
		changeEditingScreenerItems: (state, action) => {
			console.log(action.payload);
			const index = state.editing.findIndex((x) => x._id === action.payload.id);

			const newProgram = {
				_id: `new-screener-${action.payload.program._id}}`,
				IsActive: true,
				Name: action.payload.program.Name,
				Screener: action.payload.id,
				Comments: '',
				Status: 'Draft',
				Program: action.payload.program,
			};

			state.editing[index].ScreenerItems.push(newProgram);
		},
		selectEditingScreenerItems: (state, action) => {
			if (action.payload.item === 'all') {
				const index = state.editing.findIndex(
					(x) => x._id === action.payload.screenerId
				);

				state.editing[index].Checked = action.payload.checked;

				state.editing[index].ScreenerItems.forEach((item) => {
					item.Selected = action.payload.checked;
				});
			} else {
				console.log(action.payload.screenerId, action.payload.item);

				const index = state.editing.findIndex(
					(x) => x._id === action.payload.screenerId
				);

				const itemIndex = state.editing[index].ScreenerItems.findIndex(
					(x) => x._id === action.payload.item
				);

				if (state.editing[index].Checked && !action.payload.checked) {
					state.editing[index].Checked = false;
				}

				state.editing[index].ScreenerItems[itemIndex].Selected =
					action.payload.checked;
			}
		},
		updateScreenerItemsStatus: (state, action) => {
			const index = state.editing.findIndex((x) => x._id === action.payload.id);

			state.editing[index].ScreenerItems.forEach((item) => {
				if (action.payload.selectedItems.includes(item._id)) {
					item.Status = action.payload.value;
				}
			});
		},
		deleteEditingScreenerItem: (state, action) => {
			const index = state.editing.findIndex((x) => x._id === action.payload.id);

			state.editing[index].ScreenerItems = state.editing[
				index
			].ScreenerItems.filter(
				(screenerItem) => screenerItem.Program._id !== action.payload.program
			);
		},
		changeEditingTerritory: (state, action) => {
			const index = state.editing.findIndex((x) => x._id === action.payload.id);

			state.editing[index].Territory = action.payload.territory;
			state.editing[index].RightsTree = action.payload.rights;
		},
		changeScreenerTableSettings: (state, action) => {
			if (
				action.payload.name === 'searchTerm' ||
				action.payload.name === 'pageSize' ||
				action.payload.name === 'page'
			) {
				state.screenersTableSettings = {
					...state.screenersTableSettings,
					[action.payload.name]: action.payload.value,
				};
			} else if (action.payload.name === 'sortBy') {
				state.screenersTableSettings = {
					...state.screenersTableSettings,
					[action.payload.name]: action.payload.value,
					sortBy: action.payload.sortBy,
					sortOrder: action.payload.sortOrder,
				};
			} else {
				state.screenersTableSettings = {
					...state.screenersTableSettings,
					[action.payload.name]: action.payload.checked,
				};
			}
		},
		addScreenerNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (originalIndex !== -1) {
				state.original[originalIndex].Notes.unshift(action.payload);
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (editingAccountIndex !== -1) {
				state.editing[editingAccountIndex].Notes.unshift(action.payload);
			}
		},
		updateScreenerNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (originalIndex !== -1) {
				let orginalNoteIndex = state.original[originalIndex].Notes.findIndex(
					(x) => x._id === action.payload._id
				);

				if (orginalNoteIndex !== -1) {
					state.original[originalIndex].Notes[orginalNoteIndex] =
						action.payload;
				}
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.Parent
			);

			if (editingAccountIndex !== -1) {
				let editingNoteIndex = state.editing[
					editingAccountIndex
				].Notes.findIndex((x) => x._id === action.payload._id);

				if (editingNoteIndex !== -1) {
					state.editing[editingAccountIndex].Notes[editingNoteIndex] =
						action.payload;
				}
			}
		},
		deleteScreenerNote: (state, action) => {
			let originalIndex = state.original.findIndex(
				(x) => x._id === action.payload.id
			);

			if (originalIndex !== -1) {
				state.original[originalIndex].Notes = state.original[
					originalIndex
				].Notes.filter((note) => note._id !== action.payload.noteId);
			}

			let editingAccountIndex = state.editing.findIndex(
				(x) => x._id === action.payload.id
			);

			if (editingAccountIndex !== -1) {
				state.editing[editingAccountIndex].Notes = state.editing[
					editingAccountIndex
				].Notes.filter((note) => note._id !== action.payload.noteId);
			}
		},
		removeScreener: (state, action) => {
			state.original = state.original.filter((x) => x._id !== action.payload);
			state.editing = state.editing.filter((x) => x._id !== action.payload);
		},
		removeScreeners: (state, action) => {
			state.screeners = [];
		},
		resetScreeners: () => initialState,
	},
});

export const {
	createScreener,
	getScreeners,
	getScreener,
	removeScreener,
	removeScreeners,
	resetScreeners,
	changeEditingScreener,
	changeEditingScreenerItems,
	selectEditingScreenerItems,
	updateScreenerItemsStatus,
	deleteEditingScreenerItem,
	changeEditingTerritory,
	changeScreenerTableSettings,
	addScreenerNote,
	updateScreenerNote,
	deleteScreenerNote,
} = screenerSlice.actions;

export const selectScreeners = (state) => state.screeners.screeners;

export const selectOriginalScreener = (id) => (state) => {
	if (state.screeners.original.length > 0) {
		return state.screeners.original.find((screener) => screener._id === id);
	} else {
		return null;
	}
};

export const selectEditingScreener = (id) => (state) => {
	if (state.screeners.editing.length > 0) {
		return state.screeners.editing.find((screener) => screener._id === id);
	} else {
		return null;
	}
};

export const selectScreenersTableSettings = (state) =>
	state.screeners.screenersTableSettings;

// Get All Screeners Async
export const getScreenersAsync = (type) => async (dispatch) => {
	await api
		.get(`/screener/`, { withCredentials: true })
		.then((res) => {
			dispatch(getScreeners(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get Screener Async
export const getScreenerAsync = (id) => async (dispatch) => {
	await api
		.get(`/screener/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getScreener(res.data));

			dispatch(
				updateTabTitle({
					id: res.data._id,
					title: `${res.data.ScreenerNumber} - ${res.data.Name}`,
				})
			);
		})
		.catch((error) => {
			console.log(error);
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Create New Screener
export const createScreenerAsync = (screener) => async (dispatch) => {
	await api
		.post(`/screener/`, screener, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(
				getScreener({
					...res.data,
					_id: screener._id,
					Screener: res.data._id,
					Created: true,
				})
			);
			dispatch(createScreener({ ...res.data, New: true }));
			dispatch(addAccountScreener(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update Screener
export const updateScreenerAsync = (screener) => async (dispatch) => {
	await api
		.put(`/screener/${screener._id}`, screener, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(getScreener(res.data));
			dispatch(addAccountScreener(res.data));
			dispatch(
				createAlertAsync({
					message: 'Screener Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Convert Screener To Sale
export const convertScreenerToSale = (convertToSale) => async (dispatch) => {
	await api
		.put(`/screener/convert/${convertToSale.Screener}`, convertToSale, {
			withCredentials: true,
		})
		.then((res) => {
			//console.log(res.data);
			let screener = res.data.screener;

			screener.ScreenerConverted = true;
			screener.SaleId = res.data.sale;

			dispatch(getScreener(screener));
			//dispatch(addAccountScreener(res.data));

			//console.log(res.data);

			dispatch(
				createAlertAsync({
					message: 'Screener Converted Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 5000,
				})
			);
		})
		.catch((error) => {
			console.log(error);
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

export default screenerSlice.reducer;
