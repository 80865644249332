import React from 'react';
import {
	Checkbox,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { ClearRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectFormats } from '../../../redux/formatSlice';
import { selectDefinitions } from '../../../redux/definitionSlice';
import { selectGenres } from '../../../redux/genreSlice';
import { selectLicensedMedias } from '../../../redux/licensedmediaSlice';

const tableCell = {
	color: blueGrey[50],
	'& .MuiCheckbox-root.Mui-checked': {
		color: blueGrey[50],
	},
	'& .MuiTableSortLabel-root.MuiSvdIcon-root': {
		color: blueGrey[50],
	},
	'& .MuiTableSortLabel-icon ': {
		opacity: 0.5,
		color: blueGrey[50],
	},
	'& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon':
		{
			opacity: 1,
			color: blueGrey[50],
		},
};

export default function ProgramsTableHeader({
	cells,
	handleSorting,
	programsTableSettings,
	setSelectedPrograms,
	searchTerms,
	setSearchTerms,
}) {
	const formats = useSelector(selectFormats);
	const definitions = useSelector(selectDefinitions);
	const genres = useSelector(selectGenres);
	const licensedMedia = useSelector(selectLicensedMedias);

	function handleSearch(e) {
		//console.log(e.target);

		if (
			e.target.name === 'Format' ||
			e.target.name === 'Definition' ||
			e.target.name === 'LicensedMedia'
		) {
			let newValue =
				typeof value === 'string' ? e.target.value.split(',') : e.target.value;

			//let correctedValue = newValue.filter((value) => value !== '');

			setSearchTerms({
				...searchTerms,
				[e.target.name]: newValue,
			});
		} else {
			setSearchTerms({
				...searchTerms,
				[e.target.name]: e.target.value,
			});
		}
	}
	function handleClear(name) {
		setSearchTerms({ ...searchTerms, [name]: '' });
	}

	return (
		<TableHead
			sx={{
				position: 'sticky',
				top: -10,
				zIndex: 10,
				backgroundColor: blueGrey[700],
				color: blueGrey[50],
			}}
		>
			<TableRow>
				<TableCell
					align='left'
					sx={tableCell}
				>
					{/* <Checkbox
						onClick={(e) => setSelectedPrograms(e, 'ALL')}
						sx={tableCell}
						//style={{ marginTop: 40 }}
					/> */}
				</TableCell>
				{cells.map((cell) => (
					<TableCell
						key={cell.id}
						align={cell.align}
						padding={cell.disablePadding ? 'none' : 'normal'}
						sortDirection={
							programsTableSettings.sortBy === cell.id
								? programsTableSettings.sortOrder
								: false
						}
						sx={tableCell}
					>
						<Grid
							wrap='nowrap'
							container
							sx={{
								alignContent: 'center',
								alignItem: 'center',
								width: '100%',
							}}
						>
							<Grid
								item
								xs={2}
								sx={{
									display: 'flex',
									alignContent: 'center',
									alignItem: 'center',
									minWidth: '10%',
								}}
							>
								{cell.sortable && (
									<TableSortLabel
										active={programsTableSettings.sortBy === cell.id}
										direction={
											programsTableSettings.sortBy === cell.id
												? programsTableSettings.sortOrder
												: 'asc'
										}
										onClick={handleSorting(cell.id)}
										sx={tableCell}
										//style={{ display: 'inline-block' }}
									/>
								)}
							</Grid>
							<Grid
								item
								xs={10}
								sx={{ minWidth: '150px' }}
							>
								{returnTextField(cell)}
							</Grid>
						</Grid>

						{/* {cell.label} */}
						{/* //{console.log(cell.label)} */}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);

	function returnTextField(cell) {
		if (cell.label === 'Format') {
			return (
				<FormControl
					sx={{
						display: 'block',
						minWidth: '100%',
						width: '160px',
						'& .MuiInputLabel-root': {
							//height: 40,
							color: orange[500],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiInputBase-root': {
							//height: 40,
							color: blueGrey[50],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiOutlinedInput-root': {
							borderColor: `${orange[500]} !important`,
							'&.Mui-focused fieldset': {
								borderColor: `${orange[500]} !important`,
							},
							'& fieldset': {
								borderColor: `${orange[500]} !important`,
							},
						},
					}}
				>
					<InputLabel>Format</InputLabel>
					<Select
						name={cell.label}
						multiple
						value={searchTerms.Format}
						onChange={(e) => handleSearch(e)}
						input={
							<OutlinedInput
								label={cell.label}
								sx={{
									'& .MuiInputLabel-root': {
										//height: 40,
										color: orange[500],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiInputBase-root': {
										//height: 40,
										color: blueGrey[50],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiOutlinedInput-root': {
										borderColor: `${orange[500]} !important`,
										'&.Mui-focused fieldset': {
											borderColor: `${orange[500]} !important`,
										},
										'& fieldset': {
											borderColor: `${orange[500]} !important`,
										},
									},
								}}
							>
								{cell.label}
							</OutlinedInput>
						}
						renderValue={(selected) => selected.join(', ')}
						// MenuProps={{
						// 	PaperProps: {
						// 		style: {
						// 			width: '20%',
						// 			maxWidth: '20%',
						// 		},
						// 	},
						// }}
						sx={{
							//marginTop: 2,
							width: '100%',
							maxWidth: '100%',
							minWidth: '100%',
							color: blueGrey[50],
							'& .MuiSvgIcon-root': {
								color: orange[500],
							},
							// '& .MuiOutlinedInput-inputAdornedEnd': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							// '& .MuiOutlinedInput-inputTypeSearch': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							'& .MuiInputLabel-root': {
								//height: 40,
								color: orange[500],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiInputBase-root': {
								//height: 40,
								color: blueGrey[50],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiOutlinedInput-root': {
								borderColor: `${orange[500]} !important`,
								'&.Mui-focused fieldset': {
									borderColor: `${orange[500]} !important`,
								},
								'& fieldset': {
									borderColor: `${orange[500]} !important`,
								},
							},
						}}
					>
						{formats.map((format) => (
							<MenuItem
								key={format._id}
								value={format.Name}
							>
								<Checkbox
									checked={searchTerms.Format.indexOf(format.Name) > -1}
								/>
								<ListItemText primary={format.Name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		} else if (cell.label === 'Definition') {
			return (
				<FormControl
					sx={{
						display: 'block',
						minWidth: '100%',
						width: '160px',
						'& .MuiInputLabel-root': {
							//height: 40,
							color: orange[500],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiInputBase-root': {
							//height: 40,
							color: blueGrey[50],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiOutlinedInput-root': {
							borderColor: `${orange[500]} !important`,
							'&.Mui-focused fieldset': {
								borderColor: `${orange[500]} !important`,
							},
							'& fieldset': {
								borderColor: `${orange[500]} !important`,
							},
						},
					}}
				>
					<InputLabel>Defintion</InputLabel>
					<Select
						name={cell.label}
						multiple
						value={searchTerms.Definition}
						onChange={(e) => handleSearch(e)}
						input={
							<OutlinedInput
								label={cell.label}
								sx={{
									'& .MuiInputLabel-root': {
										//height: 40,
										color: orange[500],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiInputBase-root': {
										//height: 40,
										color: blueGrey[50],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiOutlinedInput-root': {
										borderColor: `${orange[500]} !important`,
										'&.Mui-focused fieldset': {
											borderColor: `${orange[500]} !important`,
										},
										'& fieldset': {
											borderColor: `${orange[500]} !important`,
										},
									},
								}}
							>
								{cell.label}
							</OutlinedInput>
						}
						renderValue={(selected) => selected.join(', ')}
						// MenuProps={{
						// 	PaperProps: {
						// 		style: {
						// 			width: '20%',
						// 			maxWidth: '20%',
						// 		},
						// 	},
						// }}
						sx={{
							//marginTop: 2,
							width: '100%',
							maxWidth: '100%',
							minWidth: '100%',
							color: blueGrey[50],
							'& .MuiSvgIcon-root': {
								color: orange[500],
							},
							// '& .MuiOutlinedInput-inputAdornedEnd': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							// '& .MuiOutlinedInput-inputTypeSearch': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							'& .MuiInputLabel-root': {
								//height: 40,
								color: orange[500],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiInputBase-root': {
								//height: 40,
								color: blueGrey[50],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiOutlinedInput-root': {
								borderColor: `${orange[500]} !important`,
								'&.Mui-focused fieldset': {
									borderColor: `${orange[500]} !important`,
								},
								'& fieldset': {
									borderColor: `${orange[500]} !important`,
								},
							},
						}}
					>
						{definitions.map((definition) => (
							<MenuItem
								key={definition._id}
								value={definition.Name}
							>
								<Checkbox
									checked={searchTerms.Definition.indexOf(definition.Name) > -1}
								/>
								<ListItemText primary={definition.Name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		} else if (cell.label === 'Genre') {
			return (
				<FormControl
					sx={{
						display: 'block',
						minWidth: '100%',
						width: '160px',
						'& .MuiInputLabel-root': {
							//height: 40,
							color: orange[500],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiInputBase-root': {
							//height: 40,
							color: blueGrey[50],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiOutlinedInput-root': {
							borderColor: `${orange[500]} !important`,
							'&.Mui-focused fieldset': {
								borderColor: `${orange[500]} !important`,
							},
							'& fieldset': {
								borderColor: `${orange[500]} !important`,
							},
						},
					}}
				>
					<InputLabel>Genre</InputLabel>
					<Select
						name={cell.label}
						multiple
						value={searchTerms.Genre}
						onChange={(e) => handleSearch(e)}
						input={
							<OutlinedInput
								label={cell.label}
								sx={{
									'& .MuiInputLabel-root': {
										//height: 40,
										color: orange[500],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiInputBase-root': {
										//height: 40,
										color: blueGrey[50],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiOutlinedInput-root': {
										borderColor: `${orange[500]} !important`,
										'&.Mui-focused fieldset': {
											borderColor: `${orange[500]} !important`,
										},
										'& fieldset': {
											borderColor: `${orange[500]} !important`,
										},
									},
								}}
							>
								{cell.label}
							</OutlinedInput>
						}
						renderValue={(selected) => selected.join(', ')}
						// MenuProps={{
						// 	PaperProps: {
						// 		style: {
						// 			width: '20%',
						// 			maxWidth: '20%',
						// 		},
						// 	},
						// }}
						sx={{
							//marginTop: 2,
							width: '100%',
							maxWidth: '100%',
							minWidth: '100%',
							color: blueGrey[50],
							'& .MuiSvgIcon-root': {
								color: orange[500],
							},
							// '& .MuiOutlinedInput-inputAdornedEnd': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							// '& .MuiOutlinedInput-inputTypeSearch': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							'& .MuiInputLabel-root': {
								//height: 40,
								color: orange[500],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiInputBase-root': {
								//height: 40,
								color: blueGrey[50],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiOutlinedInput-root': {
								borderColor: `${orange[500]} !important`,
								'&.Mui-focused fieldset': {
									borderColor: `${orange[500]} !important`,
								},
								'& fieldset': {
									borderColor: `${orange[500]} !important`,
								},
							},
						}}
					>
						{genres.map((genre) => (
							<MenuItem
								key={genre._id}
								value={genre.Name}
							>
								<Checkbox
									checked={searchTerms.Genre.indexOf(genre.Name) > -1}
								/>
								<ListItemText primary={genre.Name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		} else if (cell.label === 'LicensedMedia') {
			return (
				<FormControl
					sx={{
						display: 'block',
						minWidth: '100%',
						width: '160px',
						'& .MuiInputLabel-root': {
							//height: 40,
							color: orange[500],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiInputBase-root': {
							//height: 40,
							color: blueGrey[50],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiOutlinedInput-root': {
							borderColor: `${orange[500]} !important`,
							'&.Mui-focused fieldset': {
								borderColor: `${orange[500]} !important`,
							},
							'& fieldset': {
								borderColor: `${orange[500]} !important`,
							},
						},
					}}
				>
					<InputLabel>Licensed Media</InputLabel>
					<Select
						name={cell.label}
						multiple
						multiline
						rows={6}
						value={searchTerms.LicensedMedia}
						onChange={(e) => handleSearch(e)}
						input={
							<OutlinedInput
								label={cell.label}
								sx={{
									height: '100%',
									'& .MuiInputLabel-root': {
										//height: 40,
										color: orange[500],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiInputBase-root': {
										//height: 40,
										color: blueGrey[50],
										'& .MuiOutlinedInput-input': {
											//height: 40,
											color: blueGrey[50],
										},
									},
									'& .MuiOutlinedInput-root': {
										borderColor: `${orange[500]} !important`,
										'&.Mui-focused fieldset': {
											borderColor: `${orange[500]} !important`,
										},
										'& fieldset': {
											borderColor: `${orange[500]} !important`,
										},
									},
								}}
							>
								{cell.label}
							</OutlinedInput>
						}
						renderValue={(selected) => selected.join(', ')}
						// MenuProps={{
						// 	PaperProps: {
						// 		style: {
						// 			width: '20%',
						// 			maxWidth: '20%',
						// 		},
						// 	},
						// }}
						sx={{
							//marginTop: 2,
							width: '100%',
							maxWidth: '100%',
							minWidth: '100%',
							color: blueGrey[50],
							'& .MuiSvgIcon-root': {
								color: orange[500],
							},
							// '& .MuiOutlinedInput-inputAdornedEnd': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							// '& .MuiOutlinedInput-inputTypeSearch': {
							// 	color: orange[500],
							// 	fill: orange[500],
							// 	'& .MuiSvgIcon-root': {
							// 		color: 'white',
							// 	},
							// },
							'& .MuiInputLabel-root': {
								//height: 40,
								color: orange[500],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiInputBase-root': {
								//height: 40,
								color: blueGrey[50],
								'& .MuiOutlinedInput-input': {
									//height: 40,
									color: blueGrey[50],
								},
							},
							'& .MuiOutlinedInput-root': {
								borderColor: `${orange[500]} !important`,
								'&.Mui-focused fieldset': {
									borderColor: `${orange[500]} !important`,
								},
								'& fieldset': {
									borderColor: `${orange[500]} !important`,
								},
							},
						}}
					>
						{licensedMedia.map((media) => (
							<MenuItem
								key={media._id}
								value={media.Name}
							>
								<Checkbox
									checked={searchTerms.LicensedMedia.indexOf(media.Name) > -1}
								/>
								<ListItemText primary={media.Name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		} else {
			return (
				<TextField
					label={cell.label}
					variant='outlined'
					color='secondary'
					name={cell.label}
					value={searchTerms[cell.label]}
					onChange={handleSearch}
					InputProps={{
						endAdornment: searchTerms[cell.label] !== '' && (
							<InputAdornment
								type='button'
								position='end'
							>
								<ClearRounded
									name={cell.label}
									onClick={() => handleClear(cell.label)}
									sx={{ cursor: 'pointer' }}
								/>
							</InputAdornment>
						),
					}}
					sx={{
						display: 'inline-block',
						//marginTop: 2,
						width: '100%',
						maxWidth: '100%',
						color: blueGrey[50],
						'& .MuiSvgIcon-root': {
							color: orange[500],
						},
						'& .MuiInputLabel-root': {
							//height: 40,
							color: orange[500],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiInputBase-root': {
							//height: 40,
							color: blueGrey[50],
							'& .MuiOutlinedInput-input': {
								//height: 40,
								color: blueGrey[50],
							},
						},
						'& .MuiOutlinedInput-root': {
							borderColor: `${orange[500]} !important`,
							'&.Mui-focused fieldset': {
								borderColor: `${orange[500]} !important`,
							},
							'& fieldset': {
								borderColor: `${orange[500]} !important`,
							},
						},
					}}
				/>
			);
		}
	}
}
