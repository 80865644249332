import React from 'react';
import {
	Drawer,
	Box,
	IconButton,
	Button,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material';
import DrawerHeader from './DrawerHeader';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { blueGrey, orange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getScreener } from '../../redux/screenerSlice';
import { useDispatch } from 'react-redux';

function DrawerMenu({ handleDrawerClose, open, drawerwidth }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	function addNewAccount(type) {
		if (type === 'buyer') {
			navigate('/sales/buyers/new-buyer');
		}

		if (type === 'producer') {
			navigate('/acquisitions/producers/new-producer');
		}

		if (type === 'other') {
			navigate('/finance/others/new-other');
		}
	}

	function addNewScreener() {
		dispatch(
			getScreener({
				_id: 'new-screener',
				isSaved: false,
				savedId: null,
				IsActive: true,
				Owner: null,
				Account: null,
				Contact: null,
				ScreenerNumber: '',
				Name: '',
				Status: 'Draft',
				IsClosed: false,
				IsWon: false,
				Currency: null,
				Territory: null,
				RightsTree: null,
				DateSent: undefined,
				FollowUpDate: new Date(
					new Date().setDate(new Date().getDate() + 14)
				).setHours(0, 0, 0, 0),
				ScreenerItems: [],
			})
		);
		navigate('/sales/screeners/new-screener');
	}

	return (
		<Drawer
			className={'drawer'}
			sx={{
				width: drawerwidth,
				flexShrink: 0,

				'& .MuiDrawer-paper': {
					width: drawerwidth,
					boxSizing: 'border-box',
					border: 'none',
					boxShadow: `5px 48px 5px ${blueGrey[900]} !important`,
				},

				'& .MuiDivider-root': {
					border: 'none',
				},
			}}
			variant='persistent'
			anchor='left'
			color='primary'
			open={open}
		>
			<DrawerHeader
				className={'tacos'}
				sx={{
					backgroundColor: blueGrey[900],
					border: 'none',
				}}
			>
				<IconButton onClick={handleDrawerClose}>
					<ChevronLeftIcon sx={{ color: blueGrey[50], marginTop: '10px' }} />
				</IconButton>
			</DrawerHeader>
			<Box
				sx={{
					backgroundColor: blueGrey[700],
					boxShadow: `5px 5px 5px black !important`,
					height: '100%',
					textAlign: 'center',
					color: blueGrey[50],
				}}
			>
				<Accordion
					sx={{
						position: 'static',
						boxShadow: 'none',
						'& .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
						'& .MuiPaper-root .MuiAccordion-root .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon sx={{ color: blueGrey[50] }} />}
						sx={{
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
							textAlign: 'center',
						}}
					>
						<Typography sx={{ textAlign: 'center' }}>Accounts</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							paddingLeft: 0,
							paddingRight: 0,
							paddingTop: 0,
							paddingBottom: 4,
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
							'& .Mui-expanded': {
								marginTop: 0,
								marginBottom: 0,
							},
							'& .MuiPaper-root.MuiAccordion-root.Mui-expanded': {
								marginTop: 0,
								marginBottom: 0,
							},
						}}
					>
						<Button
							onClick={() => addNewAccount('buyer')}
							sx={{
								paddingLeft: 4,
								paddingRight: 4,
								backgroundColor: orange[900],
								color: orange[50],
								//marginTop: 4,
								width: '80%',
								'&:hover': { backgroundColor: orange[800] },
							}}
						>
							New Buyer
						</Button>
						<Button
							onClick={() => addNewAccount('producer')}
							sx={{
								paddingLeft: 4,
								paddingRight: 4,
								backgroundColor: orange[900],
								color: orange[50],
								marginTop: 4,
								width: '80%',
								'&:hover': { backgroundColor: orange[800] },
							}}
						>
							New Producer
						</Button>
						<Button
							onClick={() => addNewAccount('other')}
							sx={{
								paddingLeft: 4,
								paddingRight: 4,
								backgroundColor: orange[900],
								color: orange[50],
								marginTop: 4,
								width: '80%',
								'&:hover': { backgroundColor: orange[800] },
							}}
						>
							New Other
						</Button>
					</AccordionDetails>
				</Accordion>
				<Accordion
					sx={{
						position: 'static',
						boxShadow: 'none',
						'& .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
						'& .MuiPaper-root .MuiAccordion-root .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon sx={{ color: blueGrey[50] }} />}
						sx={{
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
							textAlign: 'center',
						}}
					>
						<Typography sx={{ textAlign: 'center' }}>Screeners</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							paddingLeft: 0,
							paddingRight: 0,
							paddingTop: 0,
							paddingBottom: 4,
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
						}}
					>
						{/* <Button
							onClick={() => addNewScreener()}
							sx={{
								paddingLeft: 4,
								paddingRight: 4,
								backgroundColor: orange[900],
								color: orange[50],
								//marginTop: 4,
								width: '80%',
								'&:hover': { backgroundColor: orange[800] },
							}}
						>
							New Screener
						</Button> */}
					</AccordionDetails>
				</Accordion>
				<Accordion
					sx={{
						position: 'static',
						boxShadow: 'none',
						'& .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
						'& .MuiPaper-root .MuiAccordion-root .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon sx={{ color: blueGrey[50] }} />}
						sx={{
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
							textAlign: 'center',
						}}
					>
						<Typography sx={{ textAlign: 'center' }}>Sales</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							paddingLeft: 0,
							paddingRight: 0,
							paddingTop: 0,
							paddingBottom: 4,
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
						}}
					>
						<Button
							onClick={() => addNewAccount('buyer')}
							sx={{
								paddingLeft: 4,
								paddingRight: 4,
								backgroundColor: orange[900],
								color: orange[50],
								//marginTop: 4,
								width: '80%',
								'&:hover': { backgroundColor: orange[800] },
							}}
						>
							New Sale
						</Button>
					</AccordionDetails>
				</Accordion>
				<Accordion
					sx={{
						position: 'static',
						boxShadow: 'none',
						'& .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
						'& .MuiPaper-root .MuiAccordion-root .Mui-expanded': {
							marginTop: 0,
							marginBottom: 0,
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon sx={{ color: blueGrey[50] }} />}
						sx={{
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
							textAlign: 'center',
						}}
					>
						<Typography sx={{ textAlign: 'center' }}>Programs</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							paddingLeft: 0,
							paddingRight: 0,
							paddingTop: 0,
							paddingBottom: 4,
							backgroundColor: blueGrey[700],
							color: blueGrey[50],
						}}
					>
						<Button
							onClick={() => navigate('/acquisitions/programs/new-program')}
							sx={{
								paddingLeft: 4,
								paddingRight: 4,
								backgroundColor: orange[900],
								color: orange[50],
								//marginTop: 4,
								width: '80%',
								'&:hover': { backgroundColor: orange[800] },
							}}
						>
							New Program
						</Button>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Drawer>
	);
}

export default DrawerMenu;
