import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
	getProgramsAsync,
	selectPrograms,
	selectProgramsTableSettings,
	changeProgramsTableSettings,
} from '../../../../redux/programSlice';
import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	CircularProgress,
} from '@mui/material';
import ProgramsTableSearchBar from './ProgramsTableSearchBar';
import ProgramsTableHeader from './ProgramsTableHeader';
import ProgramsTablePagination from './ProgramsTablePagination';
import ProgramsTableRow from './ProgramsTableRow';
import { useNavigate } from 'react-router-dom';
import { programsCells } from './programsTableCells';
import { selectUser } from '../../../../redux/authSlice';
import { blueGrey } from '@mui/material/colors';

export default function ProgramsTable() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const cells = programsCells();
	const programs = useSelector(selectPrograms);
	const programsTableSettings = useSelector(selectProgramsTableSettings);
	let count = 0;
	const user = useSelector(selectUser);

	useEffect(() => {
		if (!programs && !loading) {
			setLoading(true);
			dispatch(
				getProgramsAsync(
					programsTableSettings.showActive ? 'active' : 'inactive'
				)
			);
		}

		if (programs && loading) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [programs, loading]);

	function handleChange(e) {
		if (e.target.name === 'searchTerm') {
			dispatch(
				changeProgramsTableSettings({
					name: 'page',
					value: 1,
				})
			);
		}

		dispatch(
			changeProgramsTableSettings({
				name: e.target.name,
				value: e.target.value,
				checked: e.target.checked,
			})
		);

		if (e.target.name === 'showActive') {
			//TODO:  If checked, just filter out the inactive accounts instead
			// of making a new request for accounts
			//dispatch(removeAccounts(type));
			dispatch(getProgramsAsync(e.target.checked ? 'active' : 'inactive'));
		}
	}

	function handleSorting(id) {
		if (id === programsTableSettings.sortBy) {
			dispatch(
				changeProgramsTableSettings({
					name: 'sortBy',
					sortBy: id,
					sortOrder: programsTableSettings.sortOrder === 'asc' ? 'desc' : 'asc',
				})
			);
		} else {
			dispatch(
				changeProgramsTableSettings({
					name: 'sortBy',
					sortBy: id,
					sortOrder: 'asc',
				})
			);
		}
	}

	function handlePageChange(e, page) {
		dispatch(
			changeProgramsTableSettings({
				name: 'page',
				value: page + 1,
			})
		);
	}

	function handleRowsPerPageChange(e, name) {
		dispatch(
			changeProgramsTableSettings({
				name: name,
				value: e.target.value,
			})
		);
	}

	function renderPrograms() {
		let programsToDisplay = [];

		if (programs) {
			programsToDisplay = [...programs];

			if (!programsTableSettings.showAll) {
				programsToDisplay = programsToDisplay.filter(
					(program) => program.Owner === user._id
				);
			}

			const searchTerms = programsTableSettings.searchTerm
				.toLowerCase()
				.split(' ')
				.filter((word) => word !== '');

			if (programsTableSettings.searchTerm.length > 0) {
				programsToDisplay = programsToDisplay.filter((program, index) => {
					let anyTrue = false;

					if (programsTableSettings.searchEvery) {
						if (
							searchTerms.every((term) =>
								program.Name.toLowerCase().includes(term)
							) ||
							searchTerms.every((term) =>
								program.Genre
									? program.Genre.some((genre) =>
											genre.toLowerCase().includes(term)
									  )
									: false
							) ||
							searchTerms.every((term) =>
								program.Format && program.Format.length > 0
									? program.Format.toLowerCase().includes(term)
									: false
							) ||
							searchTerms.every((term) =>
								program.Year ? program.Year.toString().includes(term) : false
							)
						) {
							anyTrue = true;
						}
					} else {
						if (
							searchTerms.some((term) =>
								program.Name.toLowerCase().includes(term)
							) ||
							searchTerms.some((term) =>
								program.Genre
									? program.Genre.some((genre) =>
											genre.toLowerCase().includes(term)
									  )
									: false
							) ||
							searchTerms.some((term) =>
								program.Format && program.Format.length > 0
									? program.Format.toLowerCase().includes(term)
									: false
							) ||
							searchTerms.some((term) =>
								program.Year ? program.Year.toString().includes(term) : false
							)
						) {
							anyTrue = true;
						}
					}
					return anyTrue;
				});
			}

			programsToDisplay = programsToDisplay.sort((program1, program2) => {
				if (
					!program1[programsTableSettings.sortBy] ||
					!program2[programsTableSettings.sortBy]
				) {
					return -1;
				}

				if (programsTableSettings.sortOrder === 'asc') {
					if (
						program2[programsTableSettings.sortBy] >
						program1[programsTableSettings.sortBy]
					) {
						return -1;
					}

					if (
						program2[programsTableSettings.sortBy] <
						program1[programsTableSettings.sortBy]
					) {
						return 1;
					}

					return 0;
				} else {
					if (
						program2[programsTableSettings.sortBy] <
						program1[programsTableSettings.sortBy]
					) {
						return -1;
					}

					if (
						program2[programsTableSettings.sortBy] >
						program1[programsTableSettings.sortBy]
					) {
						return 1;
					}

					return 0;
				}
			});

			count = programsToDisplay.length;

			programsToDisplay = programsToDisplay.slice(
				(programsTableSettings.page - 1) * programsTableSettings.pageSize,
				programsTableSettings.page * programsTableSettings.pageSize
			);
		}

		return programsToDisplay;
	}

	return (
		<Paper
			sx={{
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
				backgroundColor: 'inherit',
				color: 'inherit',
				boxShadow: 'none',
			}}
		>
			<ProgramsTableSearchBar
				dispatch={dispatch}
				navigate={navigate}
				programsTableSettings={programsTableSettings}
				changeProgramsTableSettings={changeProgramsTableSettings}
				handleChange={handleChange}
			/>
			<TableContainer>
				<Table>
					<ProgramsTableHeader
						cells={cells}
						handleSorting={handleSorting}
						handleChange={handleChange}
						programsTableSettings={programsTableSettings}
					/>
				</Table>
			</TableContainer>
			<TableContainer
				sx={{
					minHeight: 'calc(100vh - 280px)',
					maxHeight: 'calc(100vh - 280px)',
					borderTop: 'none',
					paddingBottom: 8,
					borderLeft: `10px solid ${blueGrey[800]}`,
					borderRight: `10px solid ${blueGrey[800]}`,
				}}
			>
				<Table>
					<TableBody
						sx={{
							width: '100%',
							overflowY: 'scroll',
						}}
					>
						{renderPrograms().map((program) => (
							<ProgramsTableRow
								key={program._id}
								cells={cells}
								program={program}
								navigate={navigate}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<ProgramsTablePagination
				count={count}
				programsTableSettings={programsTableSettings}
				handlePageChange={handlePageChange}
				handleRowsPerPageChange={handleRowsPerPageChange}
			/>
			{loading && (
				<CircularProgress
					color='warning'
					sx={{ position: 'absolute', top: '45%' }}
				/>
			)}
		</Paper>
	);
}

ProgramsTable.propTypes = {
	programs: PropTypes.array,
};
