import { useSelector } from 'react-redux';
import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import { NavigateBeforeTwoTone, Save } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { selectCurrencies } from '../../../../redux/currencySlice';

export default function ConvertToSaleStep5({
	newSale,
	saving,
	convertToSale,
	handleChange,
	handleCalendarChange,
}) {
	const currencies = useSelector(selectCurrencies);

	function calculateTotalLicenseFee() {
		let total = 0;

		newSale.SaleItems.filter((x) => x.Checked).forEach((saleItem) => {
			total = total + Number(saleItem.UnitPrice);
		});

		handleChange({ target: { name: 'TotalLicenceFee', value: total } });
		return total.toFixed(2);
	}

	function submit(e) {
		e.preventDefault();
		convertToSale();
	}

	return (
		<Box
			component='form'
			onSubmit={submit}
		>
			<Grid
				container
				sx={{
					backgroundColor: blueGrey[900],
					height: 64,
					justifyContent: 'center',
					alignItems: 'center',
					alignContent: 'center',
					marginBottom: 6,
				}}
			>
				<Grid
					item
					xs={1}
				>
					<Tooltip title='Back'>
						<IconButton
							onClick={() =>
								handleChange({ target: { name: 'stepIndex', value: 3 } })
							}
							sx={{ padding: 0 }}
						>
							<NavigateBeforeTwoTone
								sx={{
									color: blueGrey[50],
									backgroundColor: blueGrey[800],
									height: 40,
									width: 40,
									borderRadius: 40,
								}}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid
					item
					xs={10}
				>
					<Typography
						variant='h5'
						component='h2'
						sx={{ color: blueGrey[50] }}
					>
						Invoicing
					</Typography>
				</Grid>
				<Grid
					item
					xs={1}
				>
					{saving ? (
						<CircularProgress color='warning' />
					) : (
						<Tooltip title='Save'>
							<IconButton
								type='submit'
								sx={{ padding: 0 }}
							>
								<Save
									sx={{
										color: orange[700],
										backgroundColor: 'transparent',
										height: 40,
										width: 40,
									}}
								/>
							</IconButton>
						</Tooltip>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					xs={6}
				>
					<TextField
						key='InvoiceContact'
						select
						required
						variant='outlined'
						label='Invoice Contact'
						value={newSale.InvoiceContact}
						name='InvoiceContact'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					>
						{newSale.Account &&
							newSale.Account.Contacts &&
							newSale.Account.Contacts.map((contact) => (
								<MenuItem
									key={contact._id}
									value={contact._id}
								>{`${contact.FirstName} ${contact.LastName} : ${contact.Department}`}</MenuItem>
							))}
					</TextField>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<TextField
						key='InvoiceContactEmail'
						disabled
						variant='outlined'
						label='Invoice Contact Email'
						value={
							newSale.Account &&
							newSale.Account.Contacts &&
							newSale.Account.Contacts.find(
								(contact) => contact._id === newSale.InvoiceContact
							).Email
						}
						name='InvoiceContactEmail'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					xs={6}
					S
				>
					<TextField
						select
						required
						variant='outlined'
						label='Currency'
						value={newSale.Currency}
						name='Currency'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					>
						{currencies.map((currency) => (
							<MenuItem
								key={currency._id}
								value={currency.Name}
							>
								{currency.Name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<TextField
						key='WithholdingTaxRate'
						type='numbers'
						required
						variant='outlined'
						label='Withholding Tax Rate'
						value={newSale.WithholdingTaxRate}
						name='WithholdingTaxRate'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					xs={6}
				>
					<TextField
						key='TotalMaterialsFee'
						required
						type='number'
						variant='outlined'
						label='Total Materials Fee'
						value={newSale.TotalMaterialsFee}
						name='TotalMaterialsFee'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					/>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DesktopDatePicker
							required
							type='date'
							key='MaterialsFeeDueBy'
							name='MaterialsFeeDueBy'
							label='Materials Fee Due By'
							inputFormat='DD/MM/YYYY'
							value={newSale.MaterialsFeeDueBy}
							onChange={(e) => handleCalendarChange(e, 'MaterialsFeeDueBy')}
							sx={pickerStyles}
							style={pickerStyles}
							InputProps={{
								sx: { '& .MuiSvgIcon-root': { color: blueGrey[50] } },
							}}
							PopperProps={{ sx: pickerStyles }}
							renderInput={(params) => (
								<TextField
									{...params}
									key='MaterialsFeeDueBy'
									type='date'
									required
									disabled
									variant='outlined'
									label='Materials Fee Due By'
									value={newSale.MaterialsFeeDueBy}
									name='MaterialsFeeDueBy'
									color='secondary'
									sx={textFields}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					item
					xs={6}
				>
					<TextField
						key='TotalPrice'
						required
						//type='number'
						variant='outlined'
						label='Total Price'
						value={'What is this vs Total License Fee???'}
						name='TotalPrice'
						onChange={handleChange}
						color='secondary'
						sx={{
							minWidth: '95%',
							textAlign: 'center',
							color: blueGrey[50],
							marginTop: 2,
							marginBottom: 2,
							borderRadius: 8,
							'& .MuiOutlinedInput-root': {
								color: 'red !important',
								textAlign: 'center',
								backgroundColor: blueGrey[700],
							},
							'& .MuiInput-input': {
								color: 'red',
								textAlign: 'center',
								backgroundColor: blueGrey[700],
							},
							'& .MuiInputLabel-outlined': {
								color: blueGrey[50],
								backgroundColor: blueGrey[700],
								paddingLeft: 2,
								paddingRight: 2,
								textAlign: 'center',
								//textShadow: `0px 2px ${blueGrey[900]}`,
							},
							'& .MuiInputLabel-shrink': {
								top: -6,
								left: -6,
								borderTopLeftRadius: 4,
								borderTopRightRadius: 4,
								textAlign: 'center',
								border: `1px solid ${blueGrey[900]}`,
								borderBottom: 'none',
							},
							'& .MuiInputLabel-shrink.Mui-focused': {
								top: -9,
								left: -6,
								borderTopLeftRadius: 8,
								borderTopRightRadius: 8,
								textAlign: 'center',
								border: `4px solid ${orange[500]}`,
								borderBottom: 'none',
							},
						}}
					/>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<TextField
						key='TotalLicenseFee'
						required
						disabled
						type='number'
						variant='outlined'
						label='Total License Fee'
						value={calculateTotalLicenseFee()}
						name='TotalLicenseFee'
						onChange={handleChange}
						color='secondary'
						sx={textFields}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

const textFields = {
	minWidth: '95%',
	textAlign: 'left',
	color: blueGrey[50],
	marginTop: 2,
	marginBottom: 2,
	borderRadius: 8,
	'& .MuiOutlinedInput-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiInput-input': {
		color: 'blueGrey[50]',
		backgroundColor: blueGrey[700],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[50],
	},
	'& .MuiSelect-iconOutlined': {
		color: blueGrey[50],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[50],
	},
	'& .MuiOutlinedInput-input .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiOutlinedInput-input.Mui-disabled': {
		color: `${blueGrey[50]} !important`,
		WebkitTextFillColor: `${blueGrey[50]} !important`,
	},
	'& .MuiInputLabel-shrink': {
		top: -6,
		left: -6,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		border: `1px solid ${blueGrey[900]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-shrink.Mui-focused': {
		top: -9,
		left: -6,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		border: `4px solid ${orange[500]}`,
		borderBottom: 'none',
	},
	'& .MuiInputLabel-outlined': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
};

const pickerStyles = {
	'& .MuiPaper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPoppersUnstyled-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root.MuiPickersPopper-root': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPaper-root-MuiPickersPopper-paper': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
		paddingLeft: 2,
		paddingRight: 2,
		textShadow: `0px 2px ${blueGrey[900]}`,
	},
	'& .MuiPickersDay-dayWithMargin': {
		color: blueGrey[50],
		backgroundColor: blueGrey[700],
	},
	'& .MuiDayPicker-weekDayLabel': {
		color: blueGrey[50],
		backgroundColor: blueGrey[900],
	},
	'& .MuiPickersCalendarHeader-switchViewIcon': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiPickersArrowSwitcher-button': {
		color: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
	'& .MuiButtonBase-root.MuiIconButton-root': {
		color: blueGrey[50],
		fill: blueGrey[50],
		//backgroundColor: blueGrey[900],
	},
};
